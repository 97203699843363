<ion-content *ngIf="showModal$ | async as boolean" class="bio-notification">
    <ion-row *ngIf="biometricInfo$ | async as biometricInfo">
        <form class="bio-message" [formGroup]="bioForm">
            <img [src]="biometricInfo.biometryImagePath" alt="biometrics icon" />
            <bh-heading
                size="medium"
                [text]="
                    'login.bio.quick-login-title'
                        | translate : { biometryType: biometricInfo.biometryType }
                "
            ></bh-heading>
            <bh-text
                [text]="
                    'login.bio.quick-login-message'
                        | translate : { biometryType: biometricInfo.biometryType } 
                "
            ></bh-text>
            <bh-text
                [text]="
                    'login.bio.quick-login-question'
                        | translate : { biometryType: biometricInfo.biometryType }
                "
            ></bh-text>
            <bh-button
                [text]="'login.bio.quick-login-enable' | translate"
                [full]="true"
                type="secondary"
                (click)="enableBiometrics()"
            ></bh-button>
            <bh-button
                [text]="'login.bio.quick-login-dismiss' | translate"
                [full]="true"
                type="text"
                (click)="closeModal()"
            ></bh-button>
        </form>
    </ion-row>
</ion-content>
