import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class FooterScrollService {
    private renderer: Renderer2
    private isScrolledUp: boolean = false
    private lastScrollPosition: number = 0

    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null)
    }

    async moveFooterOnScroll(ev: any, footerElementRef: ElementRef) {
        if (!footerElementRef) {
            return
        }

        const footerElement = footerElementRef.nativeElement.querySelector('.footerStandard') as HTMLElement
        const position = ev.detail.scrollTop
        const scrollElement = await ev.target.getScrollElement();
        const maxScrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;        

        if (
            (position > 0 && position < maxScrollHeight) ||
            (position === 0 && position > maxScrollHeight)
        ) {
            const scrolledUp = position > this.lastScrollPosition
            if (this.isScrolledUp !== scrolledUp) {
                this.renderer?.setStyle(footerElement, 'bottom', scrolledUp ? `-${footerElement.clientHeight}px` : '0px')
                this.isScrolledUp = scrolledUp
            }
            
            this.lastScrollPosition = position
        }
    }
}
