import { createAction, props } from '@ngrx/store'
import { CenterInfo } from '../models/center-info'

export const loadCenterInfo = createAction(
    '[Center Info] Load Center Info',
    props<{ centerInfoId: string }>()
)

export const loadCenterInfoSuccess = createAction(
    '[Center Info] Load Center Info Success',
    props<{ centerInfo: CenterInfo }>()
)

export const loadCenterInfoError = createAction(
    '[Center Info] Load Center Info Error',
    props<{ error: Error }>()
)

export const healthCheckCardClicked = createAction('[Health Check Cards] Health Check Card clicked')
