import { AfterViewInit, Component, Input, NgZone, OnInit, ViewChild } from '@angular/core'
import {
    loadCenterInfo,
    MediaAltText,
    selectDependantById,
    selectCenterInfoById
} from '@events'
import { Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators'
import { SwiperComponent } from 'swiper/angular'
import Swiper from 'swiper'

@Component({
    selector: 'bh-document-detail-item',
    templateUrl: './detail-item.component.html',
    styleUrls: ['./detail-item.component.scss']
})
export class DocumentDetailItemComponent implements OnInit, AfterViewInit {
    private _item$ = new BehaviorSubject<DocumentDetailItemType>({
        id: '',
        dependentId: '',
        type: '',
        isShareAvailable: true,
        title: '',
        witwhtDescription: '',
        sortDate: new Date(),
        centerId: '',
        attachmentId: '',
        description: '',
        domain: '',
        attribute: '',
        selections: [] as any
    })
    @ViewChild('swiperElement') swiperElement!: SwiperComponent

    get item$(): Observable<DocumentDetailItemType> {
        return this._item$.asObservable()
    }
    @Input() set item(item: DocumentDetailItemType) {
        this._item$.next(item)
    }

    public isZoomActive = false
    public mediaAltTextList: MediaAltText = {
        image: 'memories.imageAltText',
        audio: 'memories.audioAltText',
        video: 'memories.videoAltText'
    }
    public dependant$ = this._item$.pipe(
        filter((item) => item && !!item.dependentId && !!item.id),
        switchMap((item) => this.store.select(selectDependantById(item.dependentId))),
        filter((dependant) => !!Object.keys(dependant).length),
        distinctUntilChanged()
    )
    public center$ = this.dependant$.pipe(
        switchMap((dependant) => this.store.select(selectCenterInfoById(dependant.centerId)))
    )
    
    constructor(private store: Store, private zone: NgZone) {}

    ngOnInit(): void {
        this.dispatchCenterInfo()
    }

    ngAfterViewInit(): void {
        const swiper: Swiper = this.swiperElement?.swiperRef
        swiper?.on('zoomChange', (swiper: Swiper, scale: number) => {
            this.zone.run(() => {
                this.isZoomActive = scale > 1
            })
        })
    }

    private dispatchCenterInfo() {
        combineLatest([this.dependant$, this.center$])
            .pipe(take(1))
            .subscribe(([dependant, center]) => {
                if (Object.keys(center).length === 0) {
                    this.store.dispatch(loadCenterInfo({ centerInfoId: dependant.centerId }))
                }
            })
    }
}

export type DocumentDetailItemType = {
    id: string
    memoryId?: string
    dependentId: string
    type: string
    isShareAvailable: boolean
    title: string
    witwhtDescription: string
    sortDate: Date
    centerId: string
    attachmentId: string
    description: string
    domain: string
    attribute: string
    selections: any[]
    isDailyReport?: boolean
    activityType?: string
}
