import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { Cacheable } from '@bh/data'
import { Shareability } from '../models/shareability'

@Injectable({
    providedIn: 'root'
})
export class ShareabilityService {
    constructor(private httpClient: HttpClient) {}

    @Cacheable({ key: 'snapshotShareability', ttl: 8.64e7 })
    public getSnapshotShareability(
        guardianId: string,
        snapshotId: string
    ): Observable<Shareability> {
        return this.httpClient
            .get(
                `${
                    environment.config().security.backendHost
                }/guardian/${guardianId}/shareability/snapshot/${snapshotId}`
            )
            .pipe(map((data: any) => new Shareability(guardianId, snapshotId, data)))
    }

    @Cacheable({ key: 'observationShareability', ttl: 8.64e7 })
    public getObservationShareability(
        guardianId: string,
        snapshotId: string
    ): Observable<Shareability> {
        return this.httpClient
            .get(
                `${
                    environment.config().security.backendHost
                }/guardian/${guardianId}/shareability/observation/${snapshotId}`
            )
            .pipe(map((data: any) => new Shareability(guardianId, snapshotId, data)))
    }
}
