import { ActionReducer, createReducer, on } from '@ngrx/store'
import { getDependentStageHistorySuccess } from './stage-history.actions'

export const stageHistoryFeatureKey = 'stage-history'

export interface IStageHistoryMap {
    stage: string
    start_date: string
    end_date: string | null
    dependent_id: string
    attachment: string | null
    portfolio_attachment: string | null
    observation_attachment: string | null
}

export interface StageHistoryState {
    stageHistoryMap: Map<string, IStageHistoryMap[]>
}

const initialState: StageHistoryState = {
    stageHistoryMap: new Map<string, IStageHistoryMap[]>()
}

export const StageHistoryReducer: ActionReducer<StageHistoryState> = createReducer(
    initialState,
    on(getDependentStageHistorySuccess, (state: StageHistoryState, { stageHistory }) => {
        const stageHistoryMap = new Map()
        stageHistory.forEach((stage) => {
            const key = stage.dependent_id
            if (stageHistoryMap.get(key)) {
                stageHistoryMap.set(key, [
                    ...stageHistoryMap.get(key),
                    stage
                ])
            } else {
                stageHistoryMap.set(key, [stage])
            }
        })
        return {
            ...state,
            stageHistoryMap
        }
    })
)
