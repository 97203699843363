export class PaymentInfo {
    sessionUrl: string
    errorCode: string
    isValid: boolean

    constructor(data: any) {
        this.sessionUrl = data.sessionUrl
        this.errorCode = data.errorCode
        this.isValid = data.isValid
    }
}
