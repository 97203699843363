export class TextUtil {
    public static createLinks(text: string): string {
 		const spaceAfterPunctationRegex = /\.(https?:\/\/|(www\.))/g
        const formattingUrlRegex = /(www)\. |\. (com)|(\.)\ \./gi
        const emailRegex = /([\w\.\-\pL]+@\w+\.\w+)/gi
        const linksRegEx = /(?:https?:\/\/(?:www\.)?|www\.)([\w.-]+\.[a-z]{2,3}(?:-\d{1,5})?(?:\/[^,\s]*)?)([,\s]|$)/gi

        if (text) {
            text = text.replace(spaceAfterPunctationRegex, '. $1')
            text = text.replace(formattingUrlRegex, '$1.$2')
            text = text.replace(emailRegex, '<a href="mailto:$1" target="_blank">$1</a> ')
            text = text.replace(linksRegEx, (match, domain) => {
                return `<a href="https://${domain}" target="_blank">${match} </a> ` // Returning the entire matched string to keep "https://" and "www."
            })
        }

        return text
    }

    public static initials(name: string): string {
        return name
            .split(' ')
            .map((m: string) => m.charAt(0))
            .join('')
            .toUpperCase()
    }
}
