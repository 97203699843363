import { createFeatureSelector, createSelector } from '@ngrx/store'

import { mediaDownloadFeatureKey, BulkMediaDownloadState } from './bulk-media-download.reducers'
import { BulkMediaEntry } from '../models/photo-manager'

const bulkMediaDownloadSelector =
    createFeatureSelector<BulkMediaDownloadState>(mediaDownloadFeatureKey)

export const selectMediaDownloadId = createSelector(
    bulkMediaDownloadSelector,
    (state: BulkMediaDownloadState) => state.selectedDownloadId
)

export const selectBulkMedia = createSelector(
    bulkMediaDownloadSelector,
    (state: BulkMediaDownloadState) =>
        state.downloadLinkData.find(
            (media: BulkMediaEntry) => media.mediaId === state.selectedDownloadId
        )
)

export const selectRecentDownloads = createSelector(
    bulkMediaDownloadSelector,
  (state: BulkMediaDownloadState) => state.downloadLinkData
)
