import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { ArrivalNotesEffects } from './arrival-notes/arrival-notes.effects'
import { arrivalNotesFeatureKey, ArrivalNotesReducer } from './arrival-notes/arrival-notes.reducers'
import { AttendanceEffects } from './attendance/attendance.effects'
import { attendanceFeatureKey, AttendanceReducer } from './attendance/attendance.reducers'
import { CalendarEffects } from './calendar/calendar.effects'
import { calendarFeatureKey, CalendarReducer } from './calendar/calendar.reducers'
import { CenterInfoEffects } from './center-info/center-info.effects'
import { centerInfoFeatureKey, CenterInfoReducer } from './center-info/center-info.reducers'
import { childNotesFeatureKey, ChildNotesReducer } from './child-notes/child-notes.reducers'
import { ConfirmationEffects } from './confirmation/confirmation.effects'
import { DependantEffects } from './dependant/dependant.effects'
import { dependantFeatureKey, DependantReducer } from './dependant/dependant.reducers'
import { AlertEffects } from './emergency-alerts/emergency-alerts.effects'
import {
    alertsFeatureKey,
    EmergencyAlertsReducer
} from './emergency-alerts/emergency-alerts.reducers'
import { HealthCheckEffects } from './health-check/health-check.effects'
import { healthCheckFeatureKey, HealthCheckReducer } from './health-check/health-check.reducers'
import { loadingFeatureKey, LoadingReducer } from './loading/loading.reducers'
import { MediaEffects } from './media/media.effects'
import { mediaFeatureKey, MediaReducer } from './media/media.reducers'
import { MemoriesEffects } from './memories/memories.effects'
import { memoriesFeatureKey, MemoriesReducer } from './memories/memories.reducers'
import { NotificationEffects } from './notification/notification.effects'
import { notificationFeatureKey, NotificationsReducer } from './notification/notification.reducers'
import { FileNamePipe } from './pipes/file-name.pipe'
import { HasEventsPipe } from './pipes/has-events.pipe'
import { JoinPipe } from './pipes/join.pipe'
import { PhotoPipe } from './pipes/photo.pipe'
import { ProfileEffects } from './profile/profile.effects'
import { profileFeatureKey, ProfileReducer } from './profile/profile.reducers'
import { RefreshActionEffects } from './refresh-action/refresh-action.effects'
import {
    refreshActionFeatureKey,
    RefreshActionReducer
} from './refresh-action/refresh-action.reducers'
import { RoutingEffects } from './routing/routing.effects'
import { settingsFeatureKey, SettingsReducer } from './settings/settings.reducers'
import { ToastEffects } from './toast/toast.effects'
import { myLearnersFeatureKey, MyLearnersReducer } from './my-learners/my-learners.reducers'
import { MyLearnersEffects } from './my-learners/my-learners.effects'
import { balanceInfoFeatureKey, BalancesReducer } from './balance-info/balance-info.reducers'
import { BalanceInfoEffects } from './balance-info/balance-info.effects'
import { CurrentYearPipe } from './pipes/current-year.pipe'
import {
    developmentalObservationFeatureKey,
    DevelopmentalObservationReducer
} from './developmental-observation/developmental-observation.reducers'
import { dailyReportFeatureKey, DailyReportReducer } from './daily-report/daily-report.reducers'
import { DailyReportEffects } from './daily-report/daily-report.effects'
import { DailyReportPipe } from './pipes/daily-report.pipe'
import { stageHistoryFeatureKey, StageHistoryReducer } from './stage-history/stage-history.reducers'
import { StageHistoryEffects } from './stage-history/stage-history.effects'
import { AppreciationEffects } from './appreciation/appreciation.effects'
import { BulkMediaDownloadEffects } from './bulk-media-download/bulk-media-download.effects'
import { BulkMediaDownloadReducer, mediaDownloadFeatureKey } from './bulk-media-download/bulk-media-download.reducers'

@NgModule({
    declarations: [
        PhotoPipe,
        FileNamePipe,
        JoinPipe,
        HasEventsPipe,
        CurrentYearPipe,
        DailyReportPipe
    ],
    imports: [
        StoreModule.forFeature(profileFeatureKey, ProfileReducer),
        StoreModule.forFeature(dependantFeatureKey, DependantReducer),
        StoreModule.forFeature(centerInfoFeatureKey, CenterInfoReducer),
        StoreModule.forFeature(alertsFeatureKey, EmergencyAlertsReducer),
        StoreModule.forFeature(healthCheckFeatureKey, HealthCheckReducer),
        StoreModule.forFeature(childNotesFeatureKey, ChildNotesReducer),
        StoreModule.forFeature(attendanceFeatureKey, AttendanceReducer),
        StoreModule.forFeature(mediaFeatureKey, MediaReducer),
        StoreModule.forFeature(memoriesFeatureKey, MemoriesReducer),
        StoreModule.forFeature(notificationFeatureKey, NotificationsReducer),
        StoreModule.forFeature(calendarFeatureKey, CalendarReducer),
        StoreModule.forFeature(settingsFeatureKey, SettingsReducer),
        StoreModule.forFeature(loadingFeatureKey, LoadingReducer),
        StoreModule.forFeature(refreshActionFeatureKey, RefreshActionReducer),
        StoreModule.forFeature(myLearnersFeatureKey, MyLearnersReducer),
        StoreModule.forFeature(balanceInfoFeatureKey, BalancesReducer),
        StoreModule.forFeature(arrivalNotesFeatureKey, ArrivalNotesReducer),
        StoreModule.forFeature(developmentalObservationFeatureKey, DevelopmentalObservationReducer),
        StoreModule.forFeature(dailyReportFeatureKey, DailyReportReducer),
        StoreModule.forFeature(stageHistoryFeatureKey, StageHistoryReducer),
        StoreModule.forFeature(mediaDownloadFeatureKey, BulkMediaDownloadReducer),
        EffectsModule.forFeature([
            ProfileEffects,
            DependantEffects,
            CenterInfoEffects,
            RoutingEffects,
            ConfirmationEffects,
            AlertEffects,
            HealthCheckEffects,
            ToastEffects,
            AttendanceEffects,
            MemoriesEffects,
            MediaEffects,
            NotificationEffects,
            CalendarEffects,
            ArrivalNotesEffects,
            RefreshActionEffects,
            MyLearnersEffects,
            BalanceInfoEffects,
            DailyReportEffects,
            StageHistoryEffects,
            AppreciationEffects,
            BulkMediaDownloadEffects
        ]),
        StoreRouterConnectingModule.forRoot()
    ],
    providers: [PhotoPipe, FileNamePipe, JoinPipe, HasEventsPipe, CurrentYearPipe, DailyReportPipe],
    exports: [PhotoPipe, FileNamePipe, JoinPipe, HasEventsPipe, CurrentYearPipe, DailyReportPipe]
})
export class EventsModule {}
