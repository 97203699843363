import { Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, mergeMap, tap } from 'rxjs/operators'
import { FamilyInfo } from '../models/family-info'
import { FamilyInfoService } from '../services/family-info.service'
import { loadFamilyInfo, familyInfoSuccess, familyInfoError } from './balance-info.actions'

@Injectable()
export class BalanceInfoEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private familyInfoService: FamilyInfoService
    ) {
        this.logger = this.loggerFactory.getLogger('BalanceInfoEffects')
    }

    listBalances = createEffect(() =>
        this.actions.pipe(
            ofType(loadFamilyInfo),
            tap(() => {
                this.logger.debug(`Starting to list balances for center`)
            }),
            mergeMap(({ centers }) =>
                this.familyInfoService.getFamilyInfo().pipe(
                    tap((familyInfo: FamilyInfo) =>
                        this.logger.debug('Family Info loaded', familyInfo)
                    ),
                    mergeMap((familyInfo: FamilyInfo) => [
                        familyInfoSuccess({ familyInfo, centers })
                    ]),
                    catchError((error: Error) => {
                        this.logger.error('Error listing Family Info', error.message, error)
                        return of(familyInfoError({ error }))
                    })
                )
            )
        )
    )
}
