import { Component, Input } from '@angular/core'
import {
    DocumentItem,
    getMemoryShareability,
    loadActivityFeed,
    loadActivityFeedDomain,
    MemoryUtil,
    PhotoItem,
    selectActiveDocument,
    selectActiveDocumentData,
    selectActivityFeed,
    selectActivityFeedDomain,
    selectCardInfo,
    selectDocumentMap,
    selectPhotosMap,
    selectProfile,
    SnapshotEntry
} from '@events'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { combineLatest, Observable } from 'rxjs'
import { filter, map, take, takeUntil, tap } from 'rxjs/operators'
import { UnsubscriberComponent } from '@bh/security'

@Component({
    selector: 'bh-memory-details',
    templateUrl: './memory-details.component.html',
    styleUrls: ['./memory-details.component.scss']
})
export class MemoryDetailsComponent extends UnsubscriberComponent {
    public activeDocument$?: Observable<DocumentItem>
    public activePhoto$?: Observable<PhotoItem>
    @Input() limitDate: boolean | undefined = true 
    @Input() dependantIdList: string[] = []

    constructor(protected store: Store) {
        super()
        this.store.dispatch(loadActivityFeedDomain())

        this.store
            .select(selectActiveDocumentData)
            .pipe(
                takeUntil(this.destroy$),
                filter((memory) => !!memory),
                take(1),
                tap((memory) => {
                    this.store.dispatch(
                        loadActivityFeed({
                            dependantId: memory.dependantId,
                            date: moment(memory.sortDate).toDate()
                        })
                    )
                }),
                tap((memory) => {
                    if (MemoryUtil.isDocument(memory)) {
                        this.activeDocument$ = MemoryDetailsComponent.getDocumentSelected(
                            store.select(selectActiveDocument),
                            this.store
                        )
                    } else {
                        this.activePhoto$ = MemoryDetailsComponent.getMediaSelected(
                            store.select(selectActiveDocument),
                            this.store
                        )
                    }
                })
            )
            .subscribe()
    }

    public static getDocumentSelected(
        docId: Observable<string>,
        store: Store,
        getShareability = true
    ): Observable<DocumentItem> {
        return combineLatest([
            docId,
            store.select(selectDocumentMap),
            store.select(selectProfile),
            store.select(selectActivityFeedDomain)
        ]).pipe(
            map((dataArr) => ({
                guardianId: dataArr[2]!.id,
                item: dataArr[1][dataArr[0]],
                activity: dataArr[3]
            })),
            filter((dataArr) => !!dataArr.item),
            map((dataArr) => ({
                ...dataArr.item,
                selections: dataArr.item.selections?.map((s) => ({
                    ...s,
                    domain: dataArr.activity.domainList.find((a: any) => a.id === s.domainId)?.name,
                    attribute: dataArr.activity.attributeList.find(
                        (a: any) => a.id === s.attributeId
                    )?.name,
                    progression: dataArr.activity.progressionList.find(
                        (a: any) => a.id === s.progressionId
                    )?.name
                })),
                memoryId: dataArr.item.memoryId,
                id: dataArr.item.id,
                guardianId: dataArr.guardianId,
                isDocument: true
            })),
            tap((item) => {
                if (item.type === 'observation' && getShareability) {
                    store.dispatch(
                        getMemoryShareability({
                            guardianId: item.guardianId!,
                            memoryId: item.id,
                            memoryType: (item as any).activityType
                        })
                    )
                }
            }),
            take(1)
        )
    }

    public static getMediaSelected(
        docId: Observable<string>,
        store: Store,
        getShareability = true
    ): Observable<PhotoItem> {
        return combineLatest([
            docId,
            store.select(selectPhotosMap),
            store.select(selectProfile),
            store.select(selectCardInfo),
            store.select(selectActivityFeed),
            store.select(selectActivityFeedDomain)
        ]).pipe(
            map((dataArr) => {
                const item = dataArr[1][dataArr[0]]
                const feed = dataArr[4]
                const activity = dataArr[5]
                const itemDate = moment(item.sortDate).format('YYYY-MM-DD')
                let activityFeed: any = []

                if (feed !== undefined ? feed[itemDate] !== undefined : false) {
                    activityFeed =
                        feed[itemDate][item.dependantId] !== undefined
                            ? feed[itemDate][item.dependantId].timeline
                            : []
                }

                return {
                    guardianId: dataArr[2]!.id,
                    item,
                    cardInfo: dataArr[3],
                    activityFeed,
                    activity
                }
            }),
            filter((dataArr) => !!dataArr.item),
            map((dataArr) => {
                // even though the type is a snapshot, we need a way to find out if it's really a WITWHT with a null description
                // if that is the case, the description should be set to an empty string instead of undefined or null
                const { activityFeed, cardInfo, item } = dataArr
                let description = item.description ?? cardInfo.description
                let activitySnapshot = false

                if (
                    activityFeed.length === 0 &&
                    item.description !== '' &&
                    cardInfo.description !== ''
                ) {
                    description = item.description
                } else {
                    for (const a of activityFeed) {
                        if (a.activityType !== 'witwht' && a.snapshots === undefined) {
                            continue
                        }
                        const index = a.snapshots.findIndex((x: SnapshotEntry) => x.id === item.id)
                        if (index === -1) {
                            continue
                        }

                        activitySnapshot = true

                        if (a.note === null) {
                            description = ''
                        }
                    }
                    if (!activitySnapshot) {
                        cardInfo.title = ''
                    }
                }

                return {
                    ...item,
                    id: item.id,
                    memoryId: item.memoryId,
                    guardianId: dataArr.guardianId,
                    title: item.title || cardInfo.title,
                    description,
                    type: item.type,
                    isDocument: false,
                    selections: item.selections?.map((s) => ({
                        ...s,
                        domain: dataArr.activity.domainList.find((a: any) => a.id === s.domainId)?.name,
                        attribute: dataArr.activity.attributeList.find(
                            (a: any) => a.id === s.attributeId
                        )?.name,
                        progression: dataArr.activity.progressionList.find(
                            (a: any) => a.id === s.progressionId
                        )?.name
                    })) || [],
                }
            }),
            take(1),
            tap((item) => {
                if (getShareability) {
                    store.dispatch(
                        getMemoryShareability({
                            guardianId: item.guardianId,
                            memoryId: item.id,
                            memoryType: item.activityType
                        })
                    )
                }
            })
        )
    }
}
