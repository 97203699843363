import { AttendanceActions } from '../enums'
import { AttendanceTransition } from './attendance-transition'
import { CenterAttendance, CenterAttendanceType } from './center-attedance'
import { ETAAttendance } from './eta-attendance'
import { ParentAttendance } from './parent-attendance'

export class Absence {
    id: string
    dependentName: string
    dependentId: string
    status: string
    note: string
    startDate: string
    endDate: string
    return_date?: string

    constructor(
        attendance: CenterAttendance | null = null,
        startDate = '',
        endDate = '',
        return_date = ''
    ) {
        this.id = attendance ? attendance.id : ''
        this.status = attendance ? attendance.status : ''
        this.note = attendance ? attendance.note : ''
        this.dependentId = attendance ? attendance.dependentId : ''
        this.dependentName = attendance && attendance.dependentName ? attendance.dependentName : ''
        this.startDate = startDate
        this.endDate = endDate
        this.return_date = return_date
    }
}

export class AttendanceBase {
    id: string
    action: CenterAttendanceType
    dependentId: string
    origin: AttendanceOriginType
    capturedDate?: Date
    guardianId?: string
    note?: string
    inTime?: Date
    outTime?: Date
    centerInTime?: Date
    centerOutTime?: Date
    parentInTime?: Date
    parentOutTime?: Date
    arrivalTime?: Date

    constructor(data: AttendanceBase) {
        this.id = data.id
        this.action = data.action
        this.capturedDate = data.capturedDate
        this.dependentId = data.dependentId
        this.note = data.note
        this.inTime = data.inTime
        this.outTime = data.outTime
        this.origin = data.origin
        this.centerInTime = data.centerInTime
        this.centerOutTime = data.centerOutTime
        this.parentInTime = data.parentInTime
        this.parentOutTime = data.parentOutTime
        this.arrivalTime = data.arrivalTime
    }
}

export class Attendance extends AttendanceBase {
    sortDate: Date
    isAbsentAction: boolean
    parentAction?: string

    constructor(data: Attendance) {
        super(data)

        this.isAbsentAction = !['in', 'out', 'eta'].find((a) => a === this.action)
        this.parentAction = ['in', 'out'].find((a) => a === this.action) ? this.action : undefined
        this.sortDate = this.capturedDate || this.outTime || this.inTime || new Date()
    }

    static fromParentAttendance(
        parentAttendance: ParentAttendance,
        transition: AttendanceTransition
    ): Attendance {
        return new Attendance({
            id: parentAttendance.id,
            dependentId: parentAttendance.dependentId,
            guardianId: transition.guardianId,
            capturedDate: transition.capturedDate,
            parentInTime: transition.action === AttendanceActions.In ? transition.capturedDate : undefined,
            parentOutTime: transition.action === AttendanceActions.Out ? transition.capturedDate : undefined,
            action: transition.action,
            origin: 'parent'
        } as Attendance)
    }

    static fromCenterAttendance(centerAttendance: CenterAttendance): Attendance {
        return new Attendance({
            id: centerAttendance.id,
            dependentId: centerAttendance.dependentId,
            action: centerAttendance.status,
            note: centerAttendance.note,
            inTime: centerAttendance.inTime,
            outTime: centerAttendance.outTime,
            centerInTime: centerAttendance.inTime,
            centerOutTime: centerAttendance.outTime,
            origin: 'center'
        } as Attendance)
    }

    static fromETAAttendance(etaAttendance: ETAAttendance): Attendance {
        return new Attendance({
            id: etaAttendance.id,
            action: 'eta',
            dependentId: etaAttendance.dependentId,
            origin: 'eta',
            capturedDate: etaAttendance.date,
            guardianId: etaAttendance.guardianId,
            arrivalTime: etaAttendance.date,
            note: etaAttendance.eta
        } as Attendance)
    }
}

export type AttendanceMap = { [dependantId: string]: Attendance }
export type AttendanceByDependentMap = { [dependentId: string]: Attendance[] }
export type AttendanceOriginType = 'center' | 'parent' | 'eta'
