import { createAction, props } from '@ngrx/store'

export const addFoodItem = createAction('[ChildNotes] Adds another food item')

export const addTodayItem = createAction('[ChildNotes] Adds another today item')

export const deleteFoodItem = createAction(
    '[ChildNotes] Delete food item',
    props<{ index: number }>()
)

export const deleteTodayItem = createAction(
    '[ChildNotes] Delete today item',
    props<{ index: number }>()
)

export const resetFoodForm = createAction('[ChildNotes] Reset food form')

export const updateFoodVal = createAction(
    '[ChildNotes] Update food value',
    props<{ index: number; value: string }>()
)

export const updateTodayVal = createAction(
    '[ChildNotes] Update today value',
    props<{ index: number; value: string }>()
)
