<ion-content [ngClass]="{ homeBackground: (selectedBottomNav$ | async) === 0 }">
    <bh-refresh></bh-refresh>
    <div
        class="headerWrapper"
        [ngClass]="{ withCalendar: (showCalendar$ | async) }"
        #header
        (click)="scrollToTop()"
    >
        <ion-header [ngClass]="{ withCalendar: (showCalendar$ | async) }">
            <ion-toolbar mode="md" *ngIf="!(showCalendar$ | async)">
                <bh-nav-top><ion-buttons slot="start"></ion-buttons></bh-nav-top>
            </ion-toolbar>
            <div class="calendarWrapper title" *ngIf="showCalendar$ | async">
                <bh-calendar [minDate]="minDate$ | async"></bh-calendar>
                <div class="calendarLoading" *ngIf="isLoading$ | async as boolean">
                    <bh-loading></bh-loading>
                </div>
            </div>
            <div class="calendarWrapper calendar-week" *ngIf="showCalendar$ | async">
                <bh-calendar [minDate]="minDate$ | async" [enabled]="false"></bh-calendar>
            </div>
        </ion-header>
    </div>
    <ion-content
        forceOverscroll="false"
        [scrollEvents]="true"
        (ionScroll)="updateScrollPosition($event)"
        class="ion-content-scroll-host"
        [ngClass]="{
            hasScroll: (showCalendar$ | async),
            calendarBackground: (showCalendar$ | async),
            homeBackground: (selectedBottomNav$ | async) === 0
        }"
    >
        <div class="calendarWrapper calendar-month" *ngIf="showCalendar$ | async">
            <bh-loading *ngIf="isLoading$ | async as boolean"></bh-loading>
            <bh-calendar [minDate]="minDate$ | async" [enabled]="false"></bh-calendar>
        </div>
        <div>
            <div
                class="backdropContainer"
                [ngClass]="{ closed: (listOpened$ | async), hasCalendar: (showCalendar$ | async) }"
            >
            <ng-container *ngIf="selectedBottomNav$ | async as selectedBottomNav">
                <bh-backdrop *ngIf="selectedBottomNav === 3 || selectedBottomNav === 2"></bh-backdrop>
            </ng-container>
            </div>
            <div
                class="contentWrapper"
                [ngClass]="{
                    listOpened: (listOpened$ | async),
                    hasCalendar: (showCalendar$ | async)
                }"
            >
                <ng-content></ng-content>
            </div>
        </div>
        <div *ngIf="listOpened$ | async">
            <div class="overlay"></div>
        </div>
    </ion-content>
</ion-content>

<div class="preload">
    <ion-icon icon="md-home"></ion-icon>
    <ion-icon icon="md-memories"></ion-icon>
    <ion-icon icon="md-calendar"></ion-icon>
    <ion-icon icon="md-my-learners"></ion-icon>
    <ion-icon icon="md-home-active"></ion-icon>
    <ion-icon icon="md-memories-active"></ion-icon>
    <ion-icon icon="md-calendar-active"></ion-icon>
    <ion-icon icon="md-my-learners-active"></ion-icon>
</div>
