export class AttendanceTransition {
    guardianId: string
    action: AttendanceTransitionType
    capturedDate: Date

    constructor(data: any) {
        this.guardianId = data.guardian_id
        this.action = data.action
        this.capturedDate = data.captured && new Date(data.captured)
    }
}

export type AttendanceTransitionType = 'in' | 'out'
