import moment from 'moment'

export class DateUtil {
    public static readonly DAYS_OF_WEEK = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

    private static readonly MS_PER_DAYS = 1000 * 3600 * 24
    private static readonly MIN_PER_HOUR = 60
    private static readonly MS_PER_MIN = 1000 * 60

    public static getUtcDate(dateString: string): Date {
        return moment(dateString.split('T')[0]).utcOffset(0, false).toDate()
    }

    public static localToUtc(date: Date): Date {
        return moment(date)
            .add(date.getTimezoneOffset() / DateUtil.MIN_PER_HOUR, 'hours')
            .toDate()
    }

    public static getTomorrow(): Date {
        const tomorrow = new Date(new Date())
        tomorrow.setDate(tomorrow.getDate() + 1)
        return tomorrow
    }

    public static getTomorrowAbbreviation(): string {
        return DateUtil.DAYS_OF_WEEK[DateUtil.getTomorrow().getDay()]
    }

    public static ignoreTZ(date: Date): Date {
        return moment()
            .date(date.getDate())
            .month(date.getMonth())
            .year(date.getFullYear())
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .toDate()
    }

    public static getNumDaysInDateRange(startDate: string, endDate: string): number {
        const start = DateUtil.getUtcDate(startDate)
        const end = DateUtil.getUtcDate(endDate)
        return (end.getTime() - start.getTime()) / DateUtil.MS_PER_DAYS
    }

    public static nowIsAfterTime(time: string, timeZone = ''): boolean {
        const hours = +time.split(':')[0]
        const min = +time.split(':')[1]
        const sec = +time.split(':')[2]

        let todayAtTime = new Date()
        todayAtTime.setHours(hours, min, sec, 0)

        if (timeZone) {
            todayAtTime = DateUtil.getDateWithTimeZone(
                timeZone,
                todayAtTime.getFullYear(),
                todayAtTime.getMonth(),
                todayAtTime.getDate(),
                hours,
                min,
                sec
            )
        }

        return new Date() > todayAtTime
    }

    public static getDateWithTimeZone(
        timeZone: string,
        year: number,
        month: number,
        day: number,
        hour: number,
        minute: number,
        second: number
    ): Date {
        const date = new Date(Date.UTC(year, month, day, hour, minute, second))

        const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
        const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }))
        const offset = utcDate.getTime() - tzDate.getTime()

        date.setTime(date.getTime() + offset)

        return date
    }

    public static getMinutesBetween(startDate: Date, endDate: Date): number {
        return (endDate.getTime() - startDate.getTime()) / DateUtil.MS_PER_MIN
    }

    public static getDayOfWeekForDate(date: Date) {
        return DateUtil.DAYS_OF_WEEK[date.getDay()]
    }

    public static isBetween(date: Date, startDate: Date, endDate: Date) {
        return (
            DateUtil.getUtcDate(date.toISOString()) >=
                DateUtil.getUtcDate(startDate.toISOString()) &&
            DateUtil.getUtcDate(date.toISOString()) <= DateUtil.getUtcDate(endDate.toISOString())
        )
    }

    public static getTodayAtTime(timeOfDay: string, hours: string, minutes: string): Date {
        const now = moment()
        if (timeOfDay == 'AM' && hours != '12') {
            now.hour(Number(hours))
        } else if ((timeOfDay == 'PM' && hours != '12') || (timeOfDay == 'AM' && hours == '12')) {
            now.hour(Number(hours) + 12)
        } else if (timeOfDay == 'PM' && hours == '12') {
            now.hour(Number(hours))
        }

        now.minutes(Number(minutes))
        now.seconds(0)
        now.milliseconds(0)
        return now.toDate()
    }

    public static getAggregatedDates(items: any[]): DatedEntry[] {
        const datedEntries: DatedEntry[] = []
        let index = 0
        while (index < items.length) {
            const entry = items[index]
            if (index === items.length - 1) {
                datedEntries.push({
                    id: entry.id,
                    startDate: entry.startDate,
                    endDate: entry.endDate
                })
                break
            }

            let nextEntry = items[index + 1]
            let nextStartDate = DateUtil.ignoreTZ(nextEntry.startDate)
            let currentStartDate = DateUtil.ignoreTZ(entry.startDate)

            let isNextDay =
                (nextStartDate.getTime() - currentStartDate.getTime()) / DateUtil.MS_PER_DAYS === 1

            if (isNextDay) {
                let days = 0
                const aggregatedEntry = entry
                while (isNextDay && index < items.length) {
                    if (index === items.length - 1) {
                        index++
                    } else {
                        const thisEntry = items[index]
                        nextEntry = items[index + 1]
                        nextStartDate = DateUtil.ignoreTZ(nextEntry.startDate)
                        currentStartDate = DateUtil.ignoreTZ(thisEntry.startDate)

                        isNextDay =
                            (nextStartDate.getTime() - currentStartDate.getTime()) /
                                DateUtil.MS_PER_DAYS ===
                            1

                        if (isNextDay) {
                            index++
                            days++
                        }
                    }
                }

                aggregatedEntry.endDate.setDate(entry.startDate.getDate() + days)
                datedEntries.push({
                    id: aggregatedEntry.id,
                    startDate: aggregatedEntry.startDate,
                    endDate: aggregatedEntry.endDate
                })
            } else {
                datedEntries.push({
                    id: entry.id,
                    startDate: entry.startDate,
                    endDate: entry.endDate
                })
            }

            index++
        }
        return datedEntries
    }

    public static isWeekend(date: Date): boolean {
        return moment(date).isoWeekday() > 5
    }

    public static getNextWeekDate(date: Date): Date {
        const weekDay = moment(date).isoWeekday()
        if (weekDay === 5) {
            return moment(date).add(3,'days').toDate()
        } else if (weekDay === 6) {
            return moment(date).add(2, 'days').toDate()
        } else {
            return moment(date).add(1, 'days').toDate()
        }
    }

    public static ignoreTZDate(date: string, month: string, year: string): Date {
        return moment().date(Number(date)).month(Number(month) - 1).year(Number(year)).toDate()
    }
}

export interface DatedEntry {
    id: string
    startDate: Date
    endDate: Date
}
