import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Cacheable } from '@bh/data'
import { IStageHistoryMap } from '../stage-history/stage-history.reducers'
import { environment } from '../../../../app/src/environments/environment'


@Injectable({
    providedIn: 'root'
})
export class StageHistoryService {

    constructor(private http: HttpClient) {}

    @Cacheable({ key: 'dependentStageHistory', isList: true, ttl: 2.16e7 })
    public getDependentStageHistory(guardiandId: string): Observable<IStageHistoryMap[]> {
        return this.http.get<IStageHistoryMap[]>(
            `${environment.config().security.backendHost}/dependents/stages/${guardiandId}`
        )
    }
}
