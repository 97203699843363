export class DependentClassroomTransition {
    category?: string
    date?: Date
    detail?: string
    name?: string
    stage?: string

    constructor(data: any) {
        this.category = data.category
        this.date = data.date && new Date(data.date)
        this.detail = data.detail
        this.name = data.name
        this.stage = data.stage
    }
}
