import { createFeatureSelector, createSelector } from '@ngrx/store'
import { childNotesFeatureKey, ChildNotesState } from './child-notes.reducers'

const childNotesSelector = createFeatureSelector<ChildNotesState>(childNotesFeatureKey)

export const selectFoodItems = createSelector(
    childNotesSelector,
    (state: ChildNotesState) => state.food.items
)

export const selectFoodFormIsEmpty = createSelector(
    childNotesSelector,
    (state: ChildNotesState) => {
        const emptyFoods = state.food.items.filter((item) => item.value === '')
        const emptyTodays = state.today.items.filter((item) => item.value === '')
        return emptyFoods.length > 0 && emptyTodays.length > 0
    }
)

export const selectFoodItemsCount = createSelector(
    childNotesSelector,
    (state: ChildNotesState) => state.food.items.length
)

export const selectTodayItems = createSelector(
    childNotesSelector,
    (state: ChildNotesState) => state.today.items
)

export const selectTodayItemsCount = createSelector(
    childNotesSelector,
    (state: ChildNotesState) => state.today.items.length
)
