import { createAction, props } from '@ngrx/store'
import { Subscriptions } from './settings.reducers'

export const loadSettings = createAction(
    '[Settings] Loaded settings',
    props<{ guardianId: string }>()
)

export const loadSettingsSuccess = createAction(
    '[Settings] Loaded settings successfully',
    props<{ subscriptions: Subscriptions }>()
)

export const loadSettingsError = createAction(
    '[Settings] Loaded settings with error',
    props<{ error: Error }>()
)

export const updateSettings = createAction(
    '[Settings] Update settings',
    props<{ guardianId: string; subscriptions: Subscriptions; field: string; enabled: boolean }>()
)

export const updateSettingsSuccess = createAction(
    '[Settings] Update settings successfully',
    props<{ subscriptions: Subscriptions; field: string; enabled: boolean }>()
)

export const updateSettingsError = createAction(
    '[Settings] Update settings with error',
    props<{ error: Error }>()
)

export const toggleTuitionAlert = createAction(
    '[Settings] Toggle tuition alert',
    props<{ decline: boolean }>()
)

export const setTuitionMsgId = createAction(
    '[Settings] Set tuition message ID',
    props<{ messageId: string }>()
)
