import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DesignSystemModule } from '@bh/design-system'
import { EventsModule } from '@events'
import { IonicModule } from '@ionic/angular'
import { TranslateModule, TranslatePipe } from '@ngx-translate/core'
import { ActiveLoaderComponent } from './components/active-loader/active-loader.component'
import { ActivityCardComponent } from './components/activity-card/activity-card.component'
import { DailyReportComponent } from './components/daily-report/daily-report.component'
import { DocumentDetailItemComponent } from './components/detail-item/detail-item.component'
import { DocumentDetailListComponent } from './components/detail-list/detail-list.component'
import { GroupedActivityComponent } from './components/grouped-activity/grouped-activity.component'
import { MemoryDetailsComponent } from './components/memory-details/memory-details.component'
import { PortfolioComponent } from './components/portfolio/portfolio.component'
import { SortMultiAttachment } from './pipes/activity-card.pipes'
import { SortFoodCard } from './pipes/food-card.pipes'
import { SortWeekDay } from './pipes/sort-week.pipes'
import { SwiperModule } from 'swiper/angular'
import SwiperCore, { Zoom } from 'swiper'
import { ReactiveFormsModule } from '@angular/forms'
import { BiometricsModalComponent } from './components/biometrics-modal/biometrics-modal.component'

SwiperCore.use([Zoom])
@NgModule({
    declarations: [
        GroupedActivityComponent,
        ActivityCardComponent,
        SortMultiAttachment,
        SortFoodCard,
        MemoryDetailsComponent,
        PortfolioComponent,
        DocumentDetailItemComponent,
        DocumentDetailListComponent,
        DailyReportComponent,
        ActiveLoaderComponent,
        SortWeekDay,
        BiometricsModalComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        DesignSystemModule,
        ReactiveFormsModule,
        EventsModule,
        IonicModule.forRoot(),
        SwiperModule
    ],
    exports: [
        GroupedActivityComponent,
        ActivityCardComponent,
        SortMultiAttachment,
        MemoryDetailsComponent,
        PortfolioComponent,
        DocumentDetailItemComponent,
        DocumentDetailListComponent,
        DailyReportComponent,
        ActiveLoaderComponent,
        SwiperModule,
        SortWeekDay,
        BiometricsModalComponent
    ],
    providers: [
        TranslatePipe,
        SortMultiAttachment,
        MemoryDetailsComponent,
        SortWeekDay
    ]
})
export class SharedModule {}
