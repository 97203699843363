<ion-content forceOverscroll="false">
    <div class="background" *ngIf="reAnimate">
        <div class="title-container">
            <bh-heading size="large" [text]="'login.title' | translate"></bh-heading>
        </div>
        <div class="logo-container" [ngClass]="{ slideUp: isKeyboardVisible }">
            <img class="logo" alt="logo" src="assets/logo.svg" />
        </div>
    </div>
    <div class="container" [ngClass]="{ slideUp: isKeyboardVisible }" *ngIf="reAnimate">
        <div class="bh-title">
            <bh-heading size="medium" [text]="'login.bh' | translate"></bh-heading>
        </div>
        <div class="empty-header">
            <div class="version">
                <bh-text [text]="'login.version' | translate : { version: version }"></bh-text>
            </div>
            <bh-backdrop
                class="login-backdrop"
                [profilePicture]="backdropImage"
                background="medium-blue"
            ></bh-backdrop>
        </div>
        <div class="form-container">
            <div class="form">
                <bh-heading size="large" [text]="'login.welcome' | translate"></bh-heading>
                <bh-text size="small" [text]="'login.sign-in-message' | translate"></bh-text>
                <form [formGroup]="authForm">
                    <ion-grid>
                        <ion-row>
                            <ion-col>
                                <bh-input #userInput>
                                    <ion-item>
                                        <ion-label position="stacked">{{
                                            'login.user' | translate
                                        }}</ion-label>
                                        <ion-input #userNameInput
                                            [placeholder]="'login.user-placeholder' | translate"
                                            type="text"
                                            id="username"
                                            [required]="true"
                                            formControlName="user"
                                            ngDefaultControl
                                        >
                                        </ion-input>
                                    </ion-item>
                                </bh-input>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <bh-input #passInput>
                                    <ion-item [ngClass]="{'store-login' : isPasswordStored }">
                                        <ion-label position="stacked">{{
                                            'login.pass' | translate
                                        }}</ion-label>
                                        <ion-input
                                            [placeholder]="'login.pass-placeholder' | translate"
                                            type="password"
                                            [required]="true"
                                            id="password"
                                            formControlName="pass"
                                            [clearOnEdit]="false"
                                            (ionInput)="onPasswordInputUpdated()"
                                            ngDefaultControl
                                        >
                                        </ion-input>
                                    </ion-item>
                                </bh-input>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ng-container
                                *ngIf="
                                    (biometricsAvailable$ | async) &&
                                    (biometricInfo$ | async) as biometryInfo
                                "
                            >
                                <ion-col
                                    *ngIf="biometryInfo.biometryId && !biometryInfo.notifyUser"
                                > 
                                    <bh-button
                                        class="login biometric"
                                        type="outlined"
                                        color="navy"
                                        background="standard"
                                        [text]="biometryInfo.biometryType"
                                        [full]="true"
                                        (click)="getStoredCredentials(true)"
                                        behaviour="button"
                                    >
                                        <img
                                            class="open"
                                            [src]="biometryInfo.biometryImagePath"
                                            alt="biometrics icon"
                                            start
                                        />
                                    </bh-button>
                                </ion-col>
                            </ng-container>
                            <ion-col>
                                <bh-button
                                    class="login"
                                    [text]="'login.login-cta' | translate"
                                    size="standard"
                                    type="secondary"
                                    [full]="true"
                                    loader="[@bh/security] Request Login"
                                    (click)="authenticate(authForm)"
                                >
                                </bh-button>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <bh-button
                                    [text]="'login.forgot-user' | translate"
                                    class="border"
                                    size="small"
                                    type="text"
                                    background="standard"
                                    (click)="handleUrlOpen('forgotUsername', authForm)"
                                    [full]="true"
                                >
                                </bh-button>
                            </ion-col>
                            <ion-col>
                                <bh-button
                                    [text]="'login.forgot-pass' | translate"
                                    size="small"
                                    type="text"
                                    background="standard"
                                    (click)="handleUrlOpen('forgotPassword', authForm)"
                                    [full]="true"
                                >
                                </bh-button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </form>
            </div>
        </div>
        <div class="signup-wrapper">
            <div class="buttons" [ngClass]="{ hidden: isKeyboardVisible }">
                <bh-text
                    size="small"
                    [text]="'login.create-account-message' | translate"
                    class="signup-text"
                >
                </bh-text>
                <bh-button
                    [text]="'login.create-account-cta' | translate"
                    size="small"
                    class="signup"
                    type="text"
                    background="standard"
                    (click)="handleUrlOpen('signup', authForm)"
                    [full]="true"
                >
                </bh-button>

                <div class="help-buttons">
                    <bh-button
                        [text]="'login.need-help-cta' | translate"
                        class="need-help"
                        size="small"
                        type="text"
                        background="standard"
                        (click)="handleUrlOpen('help')"
                        [full]="true"
                    >
                    </bh-button>
                    <bh-button
                        [text]="'login.learn-more' | translate"
                        class="learn-more"
                        size="small"
                        type="text"
                        background="standard"
                        (click)="handleUrlOpen('learnMore')"
                        [full]="true"
                    >
                    </bh-button>
                </div>
            </div>
        </div>
    </div>
</ion-content>
<bh-biometrics-modal></bh-biometrics-modal>
