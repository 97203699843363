import { Injectable } from '@angular/core'
import { confirmationDoneButtonTapped } from '@bh/design-system'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { AnalyticsService } from '@bh/security'
import {
    catchError,
    defaultIfEmpty,
    filter,
    map,
    mergeMap,
    switchMap,
    take,
    tap
} from 'rxjs/operators'
import { loadingDone, loadingStarted } from '../loading/loading.actions'
import {
    QRResponse,
    QRResponseGroup,
    QuestionnaireResponseRequestBody
} from '../models/questionnaire-response-request'
import { HealthCheckService } from '../services/health-check.service'
import {
    checkHealthCheckReminderRequired,
    healthCheckReminderEmpty,
    healthCheckReminderInteraction,
    healthCheckReminderInteractionError,
    loadHealthCheckQuestionnaire,
    loadHealthCheckQuestionnaireError,
    loadHealthCheckQuestionnaireResponse,
    loadHealthCheckQuestionnaireResponseError,
    loadHealthCheckQuestionnaireResponseSuccess,
    loadHealthCheckQuestionnaireSuccess,
    submitHealthCheckQuestionnaire,
    submitHealthCheckQuestionnaireError,
    submitHealthCheckQuestionnaireSuccess
} from './health-check.actions'

@Injectable()
export class HealthCheckEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private healthCheckService: HealthCheckService,
        private store: Store,
        private analyticsService: AnalyticsService
    ) {
        this.logger = this.loggerFactory.getLogger('HealthCheckEffects')
    }

    loadQuestionnaire = createEffect(() =>
        this.actions.pipe(
            ofType(loadHealthCheckQuestionnaire),
            tap((centerId) => {
                this.logger.debug(`Starting to load questionnaire for  center${centerId}`)
                this.store.dispatch(loadingStarted({ model: 'HealthCheckQuestionnaire' }))
            }),
            mergeMap(({ centerId }) =>
                this.healthCheckService.getQuestionnaire(centerId).pipe(
                    tap((questionnaire) =>
                        this.logger.debug('Questionnaire loaded', questionnaire)
                    ),
                    mergeMap((questionnaire) => [
                        loadHealthCheckQuestionnaireSuccess({ questionnaire, centerId }),
                        loadingDone({ model: 'HealthCheckQuestionnaire' })
                    ]),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error loading questionnaire for ${centerId}`,
                            error.message,
                            error
                        )
                        this.store.dispatch(loadingDone({ model: 'HealthCheckQuestionnaire' }))
                        return of(loadHealthCheckQuestionnaireError({ error }))
                    })
                )
            )
        )
    )

    submitQuestionnaire = createEffect(() =>
        this.actions.pipe(
            ofType(submitHealthCheckQuestionnaire),
            tap(({ questionGroups, answers, dependantId, questionnaireId }) => {
                this.analyticsService.logEvent('cta_click', {
                    link_text: 'submit health check',
                    link_section: 'footer'
                })
                this.logger.debug(
                    `Starting to submit questionnaire ${questionnaireId} for child ${dependantId}`,
                    answers,
                    questionGroups
                )
            }),
            mergeMap(({ questionGroups, answers, dependantId, questionnaireId }) =>
                this.healthCheckService
                    .postQuestionnaireResponse(
                        dependantId,
                        questionnaireId,
                        new QuestionnaireResponseRequestBody({
                            response_groups: questionGroups.map(
                                (questionGroup, indexQG) =>
                                    new QRResponseGroup({
                                        group_description: questionGroup.groupDescription,
                                        responses: questionGroup.questions.map(
                                            (question, indexQ) =>
                                                new QRResponse({
                                                    kind: question.kind,
                                                    text: question.text,
                                                    alert_value: question.alertValue,
                                                    is_required: question.isRequired,
                                                    should_alert: question.shouldAlert,
                                                    options: question.options,
                                                    response_value: answers[indexQG][indexQ]
                                                })
                                        )
                                    })
                            )
                        })
                    )
                    .pipe(
                        tap(() => this.logger.debug('Questionnaire submitted successfully')),
                        map(() => submitHealthCheckQuestionnaireSuccess()),
                        catchError((error: Error) => {
                            this.logger.error(
                                `Error submitting questionnaire ${questionnaireId} for child ${dependantId}`,
                                error.message,
                                error
                            )
                            return of(submitHealthCheckQuestionnaireError({ error }))
                        })
                    )
            )
        )
    )

    loadQuestionnaireResponse = createEffect(() =>
        this.actions.pipe(
            ofType(loadHealthCheckQuestionnaireResponse),
            tap(({ dependentId, questionnaireId }) =>
                this.logger.debug(
                    `Starting to load questionnaire response for dependent ${dependentId} & questionnaire ${questionnaireId}`
                )
            ),
            mergeMap(({ dependentId, questionnaireId, centerID }) =>
                this.healthCheckService.getQuestionnaireResponse(dependentId, questionnaireId).pipe(
                    tap((questionnaireResponse) =>
                        this.logger.debug('Questionnaire Response loaded', questionnaireResponse)
                    ),
                    map((questionnaireResponse) =>
                        loadHealthCheckQuestionnaireResponseSuccess({
                            dependentId,
                            questionnaireId,
                            centerID,
                            questionnaireResponse
                        })
                    ),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error loading questionnaire response for ${dependentId} & ${questionnaireId}`,
                            error.message,
                            error
                        )
                        return of(loadHealthCheckQuestionnaireResponseError({ error }))
                    })
                )
            )
        )
    )

    validateHealthCheckAlert = createEffect(() =>
        this.actions.pipe(
            ofType(checkHealthCheckReminderRequired),
            tap(({ centerId, dependentId }) =>
                this.logger.debug(
                    `Starting validate if Health Check has been completed for child ${dependentId} and center ${centerId}`
                )
            ),
            mergeMap(({ centerId, dependentId }) =>
                this.healthCheckService.getQuestionnaire(centerId).pipe(
                    take(1),
                    tap((questionnaire) =>
                        this.logger.debug(
                            'Questionnaire loaded',
                            questionnaire.id,
                            questionnaire.centerId
                        )
                    ),
                    switchMap((questionnaire) =>
                        this.healthCheckService
                            .getQuestionnaireResponse(dependentId, questionnaire.id)
                            .pipe(defaultIfEmpty())
                    ),
                    map((questionnaireResponse: any) => {
                        if (
                            !!questionnaireResponse &&
                            !!questionnaireResponse.responseGroups &&
                            questionnaireResponse.responseGroups.length > 0
                        ) {
                            return healthCheckReminderEmpty()
                        }

                        return healthCheckReminderInteraction()
                    }),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error checking if Health Check reminder is needed`,
                            error.message,
                            error
                        )
                        return of(healthCheckReminderInteractionError({ error }))
                    })
                )
            )
        )
    )

    checkHealthCheckReminderRequired = createEffect(() =>
        this.actions.pipe(
            ofType(confirmationDoneButtonTapped),
            filter((action) => action.pageId === 'attendance'),
            tap(() => this.logger.debug(`Attendance was submitted, verify for Health Check`)),
            map(() => {
                return healthCheckReminderInteraction()
            })
        )
    )
}
