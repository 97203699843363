import { AttendanceTransition } from './attendance-transition'

export class ParentAttendance {
    id: string
    dependentId: string
    transitions: AttendanceTransition[]
    date: Date

    constructor(data: any) {
        this.id = data?.id
        this.dependentId = data?.dependent_id
        this.transitions = (data?.transitions || [])
            .map((transition: any) => new AttendanceTransition(transition))
            .sort(
                (a: AttendanceTransition, b: AttendanceTransition) =>
                    a.capturedDate.getTime() - b.capturedDate.getTime()
            )
        this.date = data?.for_date && new Date(data.for_date)
    }
}
