import { CalendarTileTypes } from '@bh/design-system'
import moment from 'moment'

import { DateUtil } from '../utils/date-util'
import { Absence } from './attendance'
import { CalendarEntryType } from '../enums'


export class CalendarEntry {
    id: string
    entryType: CalendarEntryType
    eventType: CalendarTileTypes
    startDate: Date
    endDate: Date
    message: string
    isReminder: boolean
    dependentId: string
    centerId: string
    absenceReason: string
    attachmentId: string
    dependentNames: string

    constructor(data: any) {
        this.id = data.id
        this.entryType = data.entry_type
        this.eventType = CalendarEntry.getEventType(this.entryType)
        this.startDate = DateUtil.localToUtc(new Date(data.start))
        this.endDate = DateUtil.localToUtc(new Date(data.end))
        this.message = data.message
        this.isReminder = data.is_reminder
        this.dependentId = data.dependent_id || ''
        this.centerId = data.center_id || ''
        this.absenceReason = data.absence_reason || ''
        this.attachmentId = data.attachment_id || ''
        this.dependentNames = ''
    }

    private static getEventType(entryType: CalendarEntryType): CalendarTileTypes {
        switch (entryType) {
            case CalendarEntryType.SNACK_MENU:
                return 'foods'
            case CalendarEntryType.STAFF_ANNOUNCEMENT:
            case CalendarEntryType.CENTER_ANNOUNCEMENT:
            case CalendarEntryType.MARKETING_ANNOUNCEMENT:
            case CalendarEntryType.CENTER_CLOSURE:
                return 'center'
            case CalendarEntryType.ABSENCE:
                return 'attendance'
            default:
                return 'child'
        }
    }

    public static getAbsenceCalendarEntry(a: Absence): CalendarEntry {
        return {
            id: a.id,
            entryType: 'absence',
            eventType: 'attendance',
            startDate: moment(a.startDate).toDate(),
            endDate: moment(a.endDate).toDate(),
            message: a.note,
            isReminder: false,
            dependentId: a.dependentId,
            centerId: '',
            absenceReason: a.status
        } as CalendarEntry
    }
}

export type CalendarEntryMap = { [depedentId: string]: CalendarEntry[] }
