export class DependentClassroomHistory {
    brightstarId?: string
    category?: string
    classroomId?: string
    endDate?: Date
    name?: string
    stage: string
    startDate: Date

    constructor(data: any) {
        this.brightstarId = data.brightstar_id 
        this.category = data.category 
        this.classroomId = data.classroom_id 
        this.endDate = data.end_date && new Date(data.end_date)
        this.name = data.name 
        this.stage = data.stage 
        this.startDate = data.start_date && new Date(data.start_date)
    }
}