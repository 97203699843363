export class Domain {
    name: string
    id: string

    constructor(id: string, name: string) {
        this.id = id
        this.name = name
    }
}

export class ActivityFeedDomain {
    domainList: Domain[]
    attributeList: Domain[]
    progressionList: Domain[]

    constructor(data: any) {
        this.domainList = Object.keys(data.domains || {}).map(
            (k) => new Domain(k, data.domains[k].name)
        )
        this.attributeList = Object.keys(data.attributes || {}).map(
            (k) => new Domain(k, data.attributes[k].name)
        )
        this.progressionList = Object.keys(data.progressions || {}).map(
            (k) => new Domain(k, data.progressions[k].title)
        )
    }
}
