import moment from 'moment'

export class ETAAttendance {
    id: string
    eta?: string
    centerId: string
    arrived: string
    notifyStatus: string
    notifyTime: string
    dependentId: string
    guardianId: string
    date: Date

    constructor(data: any) {
        this.id = data.id
        this.eta = !data.eta || data.eta === 'null' ? undefined : moment(data.eta).format('hh:mm A')
        this.centerId = data.center_id
        this.arrived = data.arrived
        this.notifyStatus = data.notify_status
        this.notifyTime = data.notify_time
        this.dependentId = data.dependent_ids && data.dependent_ids[0]
        this.guardianId = data.guardian_id
        this.date = data.updated ? new Date(data.updated) : new Date(this.notifyTime)
    }
}
