import { createAction, props } from '@ngrx/store'
import { Answers, QuestionGroups, Questionnaire } from '../models/questionnaire'
import { QuestionnaireResponse } from '../models/questionnaire-response'

export enum ShouldRemindHealthCheckType {
    'DO_NOT_VALIDATE' = 0,
    'WAITING',
    'VALIDATE'
}

export const loadHealthCheckQuestionnaire = createAction(
    '[HealthCheck] Loading HealthCheck Questionnaire',
    props<{ centerId: string }>()
)

export const loadHealthCheckQuestionnaireSuccess = createAction(
    '[HealthCheck] Loaded HealthCheck Questionnaire Successfully',
    props<{ centerId: string; questionnaire: Questionnaire }>()
)

export const loadHealthCheckQuestionnaireError = createAction(
    '[HealthCheck] Error Loading HealthCheck Questionnaire',
    props<{ error: Error }>()
)

export const submitHealthCheckQuestionnaire = createAction(
    '[HealthCheck] Submitting HealthCheck Questionnaire',
    props<{
        dependantId: string
        questionnaireId: string
        answers: Answers
        questionGroups: QuestionGroups[]
    }>()
)

export const submitHealthCheckQuestionnaireSuccess = createAction(
    '[HealthCheck] Submitted HealthCheck Questionnaire Successfully'
)

export const submitHealthCheckQuestionnaireError = createAction(
    '[HealthCheck] Error Submiting HealthCheck Questionnaire',
    props<{ error: Error }>()
)

export const loadHealthCheckQuestionnaireResponse = createAction(
    '[HealthCheck] Loading HealthCheck Questionnaire Response',
    props<{ dependentId: string; centerID: string; questionnaireId: string }>()
)

export const loadHealthCheckQuestionnaireResponseSuccess = createAction(
    '[HealthCheck] Loaded HealthCheck Questionnaire Response Successfully',
    props<{
        dependentId: string
        questionnaireId: string
        centerID: string
        questionnaireResponse: QuestionnaireResponse
    }>()
)

export const loadHealthCheckQuestionnaireResponseError = createAction(
    '[HealthCheck] Error Loading HealthCheck Questionnaire Response',
    props<{ error: Error }>()
)

export const checkHealthCheckReminderRequired = createAction(
    '[Health Check] Checking if Health Check Reminder is needed',
    props<{ dependentId: string; centerId: string }>()
)

export const healthCheckReminderInteraction = createAction(
    '[Health Check] Succesfully interacted a page that will trigger a Health Check Reminder'
)

export const healthCheckReminderInteractionCleared = createAction(
    '[Health Check] Health check reminder displayed - clearing state'
)

export const healthCheckReminderEmpty = createAction(
    '[Health Check] Health Check Reminder is not needed'
)

export const healthCheckReminderInteractionError = createAction(
    '[Health Check] Error checking if Health Check Reminder is needed',
    props<{ error: Error }>()
)

export const setHasBeenSubmittedToday = createAction(
    '[Health Check] Set has been submitted today',
    props<{ centerId: string, submitted: boolean }>()
)
