import { ActionReducer, createReducer, on } from '@ngrx/store'
import { familyInfoSuccess, setFamilyInfoCenterId } from './balance-info.actions'
import { Balance } from '../models/balance-info'
import { Reminder } from '../models/reminder'
import { Notification } from '../models/notification'
import moment from 'moment'

export const balanceInfoFeatureKey = 'balanceInfo'

export interface BalanceState {
    isPlayer: boolean
    balanceList?: Balance[]
    reminders: Reminder[]
    remindersNotification: Notification[]
    isShowMyBalance: boolean
    centerId: string
}

const initialState: BalanceState = {
    isPlayer: false,
    balanceList: [],
    reminders: [],
    remindersNotification: [],
    isShowMyBalance: false,
    centerId: ''
}

export const BalancesReducer: ActionReducer<BalanceState> = createReducer(
    initialState,
    on(familyInfoSuccess, (state: BalanceState, { familyInfo, centers }) => {
        const newBalanceList: Balance[] = []
        const balanceList = familyInfo.balances

        balanceList.forEach((b) => {
            const exCenter = centers.find((c: any) => c.name === b.centerName)

            if (exCenter !== undefined) {
                const newBalance = new Balance(b)
                newBalanceList.push(newBalance)
            }
        })

        const sortedBalances = newBalanceList.sort((a, b) => b.currentBalance - a.currentBalance)

        const balanceNotifications: Notification[] = familyInfo.reminders
            .slice()
            .sort((x, y) => y.sortOrder - x.sortOrder)
            .map(
                (d) =>
                    new Notification({
                        id: '',
                        guardian_id: '',
                        notification_type: d.reminderTypeNotificationName,
                        note_entry_id: '',
                        message: d.reminderMessage,
                        read: false,
                        show_in_messages: false,
                        show_in_reminders: true,
                        deleted: false,
                        read_in_messages: false,
                        read_in_reminders: false,
                        show_in_homepage: true,
                        created: moment().toDate(),
                        attachment_id: '',
                        calendar_entry_start_date: null,
                        dependent_id: '',
                        calendar_entry_id: '',
                        center_id: '',
                        title: d.reminderTitle,
                        showInHomepage: true,
                        centerName: d.centerName
                    })
            )

        return {
            ...state,
            isPlayer: familyInfo.isPayer,
            balanceList: sortedBalances,
            reminders: familyInfo.reminders,
            remindersNotification: balanceNotifications,
            isShowMyBalance: familyInfo.isShowMyBalance
        }
    }),
    on(setFamilyInfoCenterId, (state: BalanceState, { centerId }) => {
        return {
            ...state,
            centerId
        }
    })
)
