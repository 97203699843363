import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class AppreciationService {
    constructor(
        private httpClient: HttpClient,
    ) {}

    public addReaction(dependentId: string, mediaId: string, reaction: string): Observable<void> {
        return this.httpClient
            .post(
                `${
                    environment.config().security.backendHost
                }/media/${mediaId}/dependent/${dependentId}/reaction`,
                {
                    reaction: reaction
                }
            )
            .pipe(
                map(() => {
                    return
                })
            )
    }

    public deleteReaction(dependentId: string, mediaId: string): Observable<void> {
        return this.httpClient
            .delete(
                `${
                    environment.config().security.backendHost
                }/media/${mediaId}/dependent/${dependentId}/reaction`
            )
            .pipe(
                map(() => {
                    return
                })
            )
    }

}
