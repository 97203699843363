import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { BulkMediaEntry, LinkGenerationRequest, MediaLinkGenerateResponse } from '@events'

@Injectable({
    providedIn: 'root'
})
export class BulkMediaDownloadService {
    constructor(
        private httpClient: HttpClient
    ) {}

    public submitBulkMediaLinkGenerate(guardianId: string, filter: LinkGenerationRequest): Observable<MediaLinkGenerateResponse> {
        return this.httpClient
            .post(
                `${
                    environment.config().security.backendHost
                }/media/download/${guardianId}/generate`,
                filter
            )
            .pipe(
                map((data: any) => {
                    return data
                })
            )
    }

    public bulkMediaLinkGenerationProcess(guardianId: string, mediaId: string): Observable<BulkMediaEntry> {
        return this.httpClient
            .get(
                `${
                    environment.config().security.backendHost
                }/media/download/${guardianId}/m/${mediaId}`
            )
            .pipe(
                map((data: any) => {
                    return new BulkMediaEntry(data)
                })
            )
    }

    public bulkRecentDownloads(guardianId: string): Observable<BulkMediaEntry[]> {
        return this.httpClient
            .get<BulkMediaEntry[]>(`${environment.config().security.backendHost}/media/download/${guardianId}/recent`)
            .pipe(
                map((data: any) => {
                    return data
                })
            )
    }
}
