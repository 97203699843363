import { ActionTileState } from '@bh/design-system'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import moment from 'moment'
import { notificationFeatureKey, NotificationState } from './notification.reducers'
import { TextUtil } from '../utils/text-util'
import { NotificationType } from '../models/notification'

const notificationSelector = createFeatureSelector<NotificationState>(notificationFeatureKey)

export const selectNotificationList = createSelector(
    notificationSelector,
    (state: NotificationState) => state.notificationList
)

export const selectActiveNotificationList = createSelector(
    notificationSelector,
    (state: NotificationState) => state.notificationList.filter((n) => n.showInHomepage)
)

export const selectReminderNotificationList = createSelector(
    notificationSelector,
    (state: NotificationState) =>
        state.notificationList.filter((n, index, array) => {
            return (
                n.showInHomepage &&
                n.isReminder &&
                n.type !== 'emergency_alert' &&
                moment().add(7, 'days').isSameOrAfter(moment(n.startDate)) &&
                getPleaseBringToday(n.startDate, n.type) &&
                index ===
                    array.findIndex(
                        (findn) =>
                            findn.type === n.type &&
                            findn.dependentId === n.dependentId &&
                            findn.startDate === n.startDate &&
                            findn.message === n.message
                    )
            )
        })
)

export const selectEmergencyAlertList = createSelector(
    notificationSelector,
    (state: NotificationState) =>
        state.notificationList.filter((n) => n.showInHomepage && n.type === 'emergency_alert')
)

export const selectUnreadReminderNotificaionCount = createSelector(
    notificationSelector,
    (state: NotificationState): number =>
        state.notificationList.filter(
            (n, index, array) =>
                n.showInHomepage &&
                n.isReminder &&
                !n.readInReminders &&
                n.type !== 'emergency_alert' &&
                moment().add(7, 'days').isSameOrAfter(moment(n.startDate)) &&
                getPleaseBringToday(n.startDate, n.type) &&
                index ===
                    array.findIndex(
                        (findn) =>
                            findn.startDate === n.startDate &&
                            findn.message === n.message &&
                            findn.dependentId === n.dependentId
                    )
        ).length
)

export const selectMessageNotificationList = createSelector(
    notificationSelector,
    (state: NotificationState) =>
        state.notificationList
            .filter((n) => n.isMessage)
            .map((m) => ({
                ...m,
                message: TextUtil.createLinks(m.message)
            }))
)

export const selectUnreadMessageNotificationList = createSelector(
    notificationSelector,
    (state: NotificationState) =>
        state.notificationList.filter(
            (n) =>
                n.message &&
                n.isMessage &&
                !n.readInMessages &&
                !(n.type === 'emergency_alert' && !n.centerId)
        )
)

export const selectFilteredMessageNotificationsList = (filterState: ActionTileState) =>
    createSelector(notificationSelector, (state: NotificationState) =>
        state.notificationList.filter((n) => {
            const dependents = new Set()

            Object.values(filterState.selectedList).forEach((selected) => {
                if (selected.selected === true) {
                    dependents.add(selected.objectId)
                }
            })

            if (n.isMessage && n.message) {
                if (dependents.has(n.dependentId) && !n.centerId) {
                    return n
                }

                if (n.centerId) {
                    return filterState.centerIds.length > 0
                        ? filterState.centerIds.includes(n.centerId)
                        : n
                }

                if (
                    n.type === 'mcc_general' ||
                    n.type === 'mcc_learning_at_home' ||
                    n.type === 'mcc_my_bright_journey_insight'
                ) {
                    return n
                }
            }
            return null
        })
    )

const getPleaseBringToday: (date: string | undefined, type: NotificationType) => boolean = (
    date,
    type
) => {
    const seventhDay = moment(date).add(7, 'days')
    const isBetween = moment().isBetween(date, seventhDay, 'd', '[]')

    return type === 'please_bring' ? isBetween : true
}
