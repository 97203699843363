import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../../app/src/environments/environment'
import { FaceDetection, ProcessResult } from '@bh/face-detection'
import { FileSharer } from '@byteowls/capacitor-filesharer'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { IDailyReportMap } from '../daily-report/daily-report.reducers'
import { LoggerFactory, LoggerService } from '@bh/logging'

@Injectable({
    providedIn: 'root'
})
export class DailyReportService {
    private loggerService: LoggerService

    constructor(private http: HttpClient, private loggerFactory: LoggerFactory) {
        this.loggerService = this.loggerFactory.getLogger('DailyReportService')
    }

    public generateDailyReportSignedUrl(daily_report_id: string): Observable<any> {
        return this.http.get(
            `${environment.config().security.backendHost}/generate_pdf/${daily_report_id}`
        )
    }

    async shareDailyReport(dailyReport: IDailyReportMap): Promise<string> {
        try {
            const result: ProcessResult = await FaceDetection.process({
                type: 'application/pdf',
                image: dailyReport.url
            })

            await FileSharer.share({
                filename: `${dailyReport.fileName}`,
                base64Data: result.base64Image,
                contentType: 'application/pdf'
            })
        } catch (e) {
            this.loggerService.error('Daily Report PDF sharing failed', e)
        }

        return dailyReport.url
    }

    async downloadDailyReport(dailyReport: IDailyReportMap): Promise<string | Error> {
        try {
            const result: ProcessResult = await FaceDetection.process({
                type: 'application/pdf',
                image: dailyReport.url
            })

            const savedFile = await Filesystem.writeFile({
                path: dailyReport.fileName,
                data: result.base64Image,
                directory: Directory.Documents,
                recursive: true
            })

            return savedFile.uri
        } catch (e) {
            this.loggerService.error('Daily Report PDF download failed', e)

            return new Error('download failed')
        }
    }
}
