import { createAction, props } from '@ngrx/store'
import { CurriculumPlans } from '../models/curriculum-plans'
import { ObservationEntry } from '../models/activity-feed'
import { DependentStageHistory } from '../models/dependent-stage-history'

export const groupedObservationsCleared = createAction('[My Learners] grouped observations cleared')

export const groupedMBJPReportsCleared = createAction('[My Learners] grouped reports cleared')

export const groupedCurriculumPlansCleared = createAction(
    '[My Learners] grouped Curriculum Plans cleared'
)

export const portfolioListClicked = createAction('[My Learners] portfolio list clicked')

export const portfolioListRouted = createAction('[My Learners] portfolio list routed')

export const portfolioDetailClicked = createAction('[My Learners] portfolio detail clicked',props<{ url: string | undefined, isFromDeepLink: boolean }>())

export const portfolioDetailRouted = createAction('[My Learners] portfolio detail routed')

export const observationsLandingClicked = createAction('[My Learners] observations landing clicked')

export const observationsLandingRouted = createAction('[My Learners] observations landing routed')

export const observationsListClicked = createAction('[My Learners] observations list clicked')

export const observationsDetailClicked = createAction('[My Learners] observation detail clicked', props<{ id: string, dependentId: string, isFromDeepLink: boolean }>())

export const observationsListRouted = createAction('[My Learners] observations list routed')

export const backToMyLearners = createAction('[My Learners] back to my learners')

export const backToMyLearnersRouted = createAction('[My Learners] back to my learners routed')

export const setDepId = createAction('[My Learners] set dependent id', props<{ depId: string }>())

export const loadCurriculumPlans = createAction(
    '[My Learners] Load Curriculum plans for dependents',
    props<{ dependentId: string; startDate: string | Date; endDate: string | Date }>()
)
export const loadCurriculumPlansSuccess = createAction(
    '[My Learners] Load Curriculum plans for dependents success',
    props<{ curriculumPlans: CurriculumPlans[] }>()
)
export const loadCurriculumPlansError = createAction(
    '[My Learners] Load Curriculum plans for dependents error',
    props<{ error: Error }>()
)
export const cancelLoadCurriculumPlanRequest = createAction(
    '[My Learners] Cancel load Curriculum plans for dependents'
)

export const loadPolaroidPhotos = createAction(
    '[My Learners] Load Photo Rotation for dependents',
    props<{ photos: string[] }>()
)
export const updatePolaroidPhotos = createAction(
    '[My Learners] Update childs polaroid photos',
    props<{ startDate: Date; endDate: Date }>()
)

export const clearPolaroidPhotos = createAction('[My Learners] Clear childs polaroid photos')

export const ourCurriculumBannerClicked = createAction(
    '[My Learners] our curriculum banner clicked'
)

export const outCurriculumBannerRouted = createAction('[My Learners] our curriculum banner router')

export const curriculumPlansListClicked = createAction(
    '[My Learners] curriculum plans list clicked'
)

export const curriculumPlansClicked = createAction('[My Learners] curriculum plan clicked', props<{ id: string, date: Date, isFromDeepLink: boolean }>())

export const curriculumPlansListRouted = createAction('[My Learners] curriculum plans list routed')

export const curriculumPlansListSortToggle = createAction(
    '[My Learners] curriculum plans list toggle'
)

export const loadDevObservations = createAction(
    '[My Learners] Load Dev Observations for My learners',
    props<{
        dependentId: string
        startDate: string
        endDate?: string
        limit: number
        stageHistory?: DependentStageHistory[]
        paginationEvent?: Event
        pullToRefresh?: boolean
    }>()
)
export const loadDevObservationsSuccess = createAction(
    '[My Learners] Load Dev Observations for My learners success',
    props<{
        devObservations: ObservationEntry[]
        lastFetchedDate: string
        isPaginationDisabled: boolean
        pullToRefresh?: boolean
    }>()
)
export const loadDevObservationsError = createAction(
    '[My Learners] Load Dev Observations for My learners error',
    props<{ error: Error }>()
)

export const cancelLoadObservationsRequest = createAction(
    '[My Learners] Cancel Load Observations for My learners'
)
