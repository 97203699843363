export class QuestionnaireResponse {
    id: string
    name: string
    dependentId: string
    guardianId: string
    questionnaireId: string
    centerId: string
    forDate: Date
    responseGroups: ResponseGroup[]
    isFlagged: boolean
    creationDate?: Date
    updateDate?: Date

    constructor(data: any) {
        this.id = data.id
        this.name = data.name
        this.dependentId = data.dependent_id
        this.guardianId = data.guardian_id
        this.questionnaireId = data.guardian_id
        this.centerId = data.center_id
        this.forDate = data.for_date && new Date(data.for_date)
        this.responseGroups = (data.response_groups || []).map((r: any) => new ResponseGroup(r))
        this.isFlagged = data.is_flagged
        this.creationDate = data.created && new Date(data.created)
        this.updateDate = data.updated && new Date(data.updated)
    }
}

export class ResponseGroup {
    group_description: string
    responses: Response[]
    isFlagged: boolean

    constructor(data: any) {
        this.group_description = data.group_description
        this.responses = (data.responses || []).map((r: any) => new Response(r))
        this.isFlagged = data.is_flagged
    }
}

export class Response {
    kind: string
    text: string
    alert_value: string
    is_required: boolean
    should_alert: boolean
    options: string[]
    response_value: string
    isFlagged: boolean

    constructor(data: any) {
        this.kind = data.kind
        this.text = data.text
        this.alert_value = data.alert_value
        this.is_required = data.is_required
        this.should_alert = data.should_alert
        this.options = data.options
        this.response_value = data.response_value
        this.isFlagged = data.is_flagged
    }
}

export type QuestionnaireResponseMap = { [questionnaireId: string]: QuestionnaireResponse }
