import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment'

@Pipe({
    name: 'currentYear'
})
export class CurrentYearPipe implements PipeTransform {
    transform(date: Date): boolean {
        return moment(new Date(date)).year() === moment().year();
    }
}
