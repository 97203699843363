import { ActionReducer, createReducer, on } from '@ngrx/store'
import { clearEmergencyAlert, loadEmergencyAlertsSuccess } from './emergency-alerts.actions'

export const alertsFeatureKey = 'alerts'

export interface EmergencyAlertsState {
    emergencyAlerts: any[]
}

const initialState: EmergencyAlertsState = {
    emergencyAlerts: []
}

export const EmergencyAlertsReducer: ActionReducer<EmergencyAlertsState> = createReducer(
    initialState,
    on(loadEmergencyAlertsSuccess, (state: EmergencyAlertsState, { emergencyAlerts }) => ({
        ...state,
        emergencyAlerts: emergencyAlerts
    })),
    on(clearEmergencyAlert, (state: EmergencyAlertsState, { id }) => {
        const index = state.emergencyAlerts.findIndex((a) => a.id === id)
        const updated = state.emergencyAlerts.slice()
        if (index > -1) {
            updated.splice(index, 1)
        }

        return {
            ...state,
            emergencyAlerts: updated
        }
    })
)
