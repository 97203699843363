import { Injectable, Injector } from '@angular/core'
import { AuthenticationService, BaseAppInitService, loginSuccess } from '@bh/security'
import { Preferences } from '@capacitor/preferences'
import { ParentAppVersionService, ParentAuthentication, ParentAuthenticationService } from '@events'
import { combineLatest, from, Observable, of, throwError } from 'rxjs'
import { catchError, filter, switchMap, tap } from 'rxjs/operators'

@Injectable()
export class AppInitService extends BaseAppInitService {
    public static readonly STORAGE_CURRENT_ENV = 'currentEnv'
    public static readonly STORAGE_PROFILE_ID = 'profileId'
    public static readonly STORAGE_API_KEY = 'apiKey'
    public static readonly STORAGE_JWT_TOKEN = 'jwtToken'
    public static readonly LOGIN_STATUS = 'loginStatus'
    private authenticationService: ParentAuthenticationService
    private appVersionService: ParentAppVersionService

    constructor(protected injector: Injector) {
        super(injector)

        this.authenticationService = this.injector.get(ParentAuthenticationService)
        this.appVersionService = this.injector.get(ParentAppVersionService)
    }

    protected getRefreshTime(): number {
        return 2.16e7 //6 hours
    }

    protected localAuthentication(): Observable<any> {
        return combineLatest([
            from(
                Preferences.get({
                    key: AuthenticationService.STORAGE_USER
                })
            ),
            from(
                Preferences.get({
                    key: AuthenticationService.STORAGE_PASS
                })
            ),
            from(
                Preferences.get({
                    key: AppInitService.STORAGE_JWT_TOKEN
                })
            ),
            from(
                Preferences.get({
                    key: AppInitService.LOGIN_STATUS
                })
            ),
            this.appVersionService.getVersion()
        ]).pipe(
            filter((creds) => creds[3].value !== 'false'),
            switchMap((creds) => {
                this.logger.trace('Requesting to refresh session')
                const user = creds[0].value ?? ''
                const pass = creds[1].value ?? ''
                const token = creds[2].value ?? ''
                const appVersion = creds[4] ?? ''
                if (!token) {
                    if (!user || !pass) {
                        return throwError('** Internal: No token **')
                    }
                    return this.authenticationService.authenticate(user, pass)
                } else {
                    const decodedToken = this.authenticationService.decodeJwtToken(token)
                    return of(
                        new ParentAuthentication(
                            token,
                            decodedToken?.api_key,
                            decodedToken?.sa_id,
                            user,
                            appVersion
                        )
                    )
                }
            }),
            tap(async (auth) => {
                this.store.dispatch(loginSuccess({ auth }))
                await Preferences.set({
                    key: AppInitService.STORAGE_API_KEY,
                    value: auth.apiKey
                })
            }),
            catchError(async (e) => {
                this.logger.error(`Error trying to create shallow session for user ${e}`)
                if (!(typeof e === 'string' && /\*\* Internal: No token \*\*/.test(e))) {
                    await Preferences.set({
                        key: AppInitService.LOGIN_STATUS,
                        value: 'false'
                    })
                    await Preferences.remove({
                        key: AuthenticationService.STORAGE_PASS
                    })
                    this.authenticationService.clearAuth()
                }
                return throwError(e)
            })
        )
    }
}
