import { ActionReducer, createReducer, on } from '@ngrx/store'
import { Notification } from '../models/notification'
import {
    listNotificationsSuccess,
    markNotificationAsDeleted,
    updateNotificationReadStatusSuccess
} from './notification.actions'

export const notificationFeatureKey = 'notifications'

export interface NotificationState {
    notificationList: Notification[]
}

const initialState: NotificationState = {
    notificationList: []
}

export const NotificationsReducer: ActionReducer<NotificationState> = createReducer(
    initialState,
    on(listNotificationsSuccess, (state: NotificationState, { notificationList }) => ({
        ...state,
        notificationList
    })),
    on(
        updateNotificationReadStatusSuccess,
        (state: NotificationState, { notificationId, readInMessages, readInReminders }) => ({
            ...state,
            notificationList: state.notificationList.map((n) =>
                n.id !== notificationId
                    ? n
                    : {
                          ...n,
                          isRead: true,
                          readInMessages: readInMessages,
                          readInReminders: readInReminders
                      }
            )
        })
    ),
    on(markNotificationAsDeleted, (state: NotificationState, { notificationId }) => ({
        ...state,
        notificationList: state.notificationList.map((n) =>
            n.id !== notificationId ? n : { ...n, isDeleted: true, showInHomepage: false }
        )
    }))
)
