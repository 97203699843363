import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { Cacheable } from '@bh/data'
import { CenterInfo } from '../models/center-info'

@Injectable({
    providedIn: 'root'
})
export class CenterInfoService {
    constructor(private http: HttpClient) {}

    @Cacheable({ key: 'centerInfo', ttl: 900000 })
    public getCenterInfoList(centerInfoId: string): Observable<CenterInfo> {
        return this.http
            .get<unknown[]>(`${environment.config().security.backendHost}/center/${centerInfoId}`)
            .pipe(map((data: unknown[]) => (data && new CenterInfo(data)) || null))
    }
}
