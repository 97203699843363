import { defaultEnv } from './environment.default'

declare let window: any
export const environment = {
    prodBuild: true,
    buildEnv: 'prod',
    config: () => {
        if (window.config) {
            return { ...defaultEnv, ...window.config }
        } else {
            return defaultEnv
        }
    }
}
