import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { Cacheable } from '@bh/data'
import { Questionnaire } from '../models/questionnaire'
import { QuestionnaireResponse } from '../models/questionnaire-response'
import { QuestionnaireResponseRequestBody } from '../models/questionnaire-response-request'

@Injectable({
    providedIn: 'root'
})
export class HealthCheckService {
    constructor(private http: HttpClient) {}

    @Cacheable('questionnaireList')
    public getQuestionnaire(centerInfoId: string): Observable<Questionnaire> {
        return this.http
            .get(
                `${environment.config().security.backendHost}/center/${centerInfoId}/questionnaire`
            )
            .pipe(map((data) => new Questionnaire(data, centerInfoId)))
    }

    @Cacheable({
        key: 'questionnaireResponses',
        ttl: new Date().setHours(24, 0, 0, 0) - new Date().getTime()
    })
    public getQuestionnaireResponse(
        dependentId: string,
        questionnaireId: string
    ): Observable<QuestionnaireResponse> {
        return this.http
            .get(
                `${
                    environment.config().security.backendHost
                }/dependent/${dependentId}/questionnaire/${questionnaireId}/response`
            )
            .pipe(
                filter((data: any) => !!data?.dependent_id),
                map((data) => new QuestionnaireResponse(data))
            )
    }

    public postQuestionnaireResponse(
        dependentId: string,
        questionnaireId: string,
        responses: QuestionnaireResponseRequestBody
    ): Observable<void> {
        return this.http
            .post(
                `${
                    environment.config().security.backendHost
                }/dependent/${dependentId}/questionnaire/${questionnaireId}/response`,
                responses
            )
            .pipe(
                map(() => {
                    return
                })
            )
    }
}
