import { Component } from '@angular/core'
import { UnsubscriberComponent } from '@bh/security'
import { Observable, of, Subject } from 'rxjs'

@Component({
    selector: 'bh-active-loader',
    template: ''
})
export class ActiveLoaderComponent extends UnsubscriberComponent {
    private viewDismissed = new Subject<boolean>()
    protected viewDismissed$: Observable<boolean> = of()

    constructor() {
        super()
        this.setup()
    }

    ionViewWillLeave() {
        this.tearDown()
    }

    ngOnDestroy(): void {
        this.tearDown()
        super.ngOnDestroy()
    }

    private setup(): void {
        this.viewDismissed$ = this.viewDismissed.asObservable()
    }

    private tearDown(): void {
        this.viewDismissed.next(true)
    }
}
