export enum StageEnum {
    INFANT = 'infant',
    TODDLER = 'toddler',
    TWOS = 'twos',
    PRESCHOOL = 'preschool',
    SCHOOL = 'school',
    NEXT_STEPS = 'next steps'
}

export class DependentStageHistory {
    stage: string
    startDate: Date
    endDate?: Date
    constructor(data: any) {
        this.stage = data.stage
        this.startDate = data.start_date && new Date(data.start_date)
        this.endDate = data.end_date && new Date(data.end_date)
    }
}
