import { PhotoItem, DocumentItem, MemoriesSummaryDisplay } from '@events'
import moment from 'moment'

export class MemoryUtil {
    public static isDocument(memory: PhotoItem | DocumentItem): boolean {
        return (
            memory?.type !== undefined &&
            !!['portfolio', 'report', 'note', 'observation'].find((t) => t === memory.type)
        )
    }

    public static getMemoryId(memoryId: string, dependentId: string): string {
        return `${memoryId}_${dependentId}`
    }

    public static getMemoryType(entryType: string) {
        if (entryType === 'empty_daily_report') {
            return 'report'
        } else if (entryType === 'my_bright_journey') {
            return 'portfolio'
        }
        return entryType.split('_')[0]
    }

    public static getDailyLabel(date: Date): string {
        let unitDate = moment(date).format('dddd, MMMM D')
        const isToday = moment(date).isSame(new Date(), 'day')
        const isYesterday = moment(date).isSame(moment().subtract(1, 'day'), 'day')

        if (isToday) {
            unitDate = `Today, ${moment(date).format('MMMM D')}`
        }

        if (isYesterday) {
            unitDate = `Yesterday, ${moment(date).format('MMMM D')}`
        }

        return unitDate
    }

    public static getMonthLabel(date: Date): string {
        return moment(date).format('MMMM YYYY')
    }

    public static getYearLabel(date: Date): string {
        return moment(date).format('YYYY')
    }

    public static getDependantReportId(dependantId: string, sortDate: Date): string {
        return `${dependantId}_${moment(sortDate).format('YYYY-MM-DD')}`
    }

    public static reviewTeacherNote(
        entryType: string,
        isFromParent: boolean,
        visibility: string,
        dailyReportDate: Date | undefined,
        question_name?: string,
        type?: string,
        please_brings?: Array<string>
    ): boolean {
        const teacherNote =
            entryType === 'note' &&
            !question_name &&
            type !== 'med_notes' &&
            type !== 'please_bring' &&
            (please_brings ?? []).length === 0
        const shouldShow =
            (!isFromParent && visibility !== 'calendar') ||
            (visibility === 'calendar' && dailyReportDate)

        return teacherNote && !!shouldShow
    }

    public static getDailyReportDate(
        checkOutAt: Date | null | undefined,
        reportPublishedAt: Date | null,
        date: Date
    ): Date | undefined {
        return checkOutAt || reportPublishedAt ? date : undefined
    }

    public static getSummaryEqualDependents(
        summaries: MemoriesSummaryDisplay[],
        dependentIds: Array<string>
    ): MemoriesSummaryDisplay[] {
        return summaries.filter((summary) => {
            return summary.dependentIdList.filter((dependentId) =>
                dependentIds.includes(dependentId)
            )
        })
    }

    public static getPorfolioDescription(assessmentPeriod: string, assessmentYear: number) {
        return assessmentPeriod
            ? `${assessmentPeriod[0]?.toUpperCase()}${assessmentPeriod.slice(1)} ${assessmentYear}`
            : ''
    }

    public static isValidMemoriesPortfolio(
        entryType: string,
        type: string,
        attachmentId: string | undefined
    ): boolean {
        return entryType === 'portfolio' && !!attachmentId && type !== 'curriculum_plan'
    }

    public static getSubjects(subjectNames: Array<string> | undefined): string | undefined {
        if (subjectNames && subjectNames.length > 1) {
            return subjectNames.join(', ')
        } else if (subjectNames && subjectNames.length === 1) {
            return subjectNames[0]
        } else {
            return undefined
        }
    }
}
