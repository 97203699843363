import { createAction, props } from '@ngrx/store'
import { IDailyReportMap } from './daily-report.reducers'

export const generateDailyReportSignedUrl = createAction(
    '[Daily Report] generate daily report PDF URL',
    props<{ id: string }>()
)

export const generateDailyReportSignedUrlSuccess = createAction(
    '[Daily Report] generate daily report PDF URL successfully',
    props<{
        id: string
        url: string
        fileName: string
    }>()
)

export const generateDailyReportSignedUrlError = createAction(
    '[Daily Report] generate daily report PDF URL Error',
    props<{ error: Error }>()
)

export const downloadDailyReport = createAction(
    '[Daily Report] download daily report PDF',
    props<{ report: IDailyReportMap }>()
)

export const shareDailyReport = createAction(
    '[Daily Report] share daily report PDF',
    props<{ report: IDailyReportMap }>()
)
