import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { PaymentInfo } from '../models/payer'

@Injectable({
    providedIn: 'root'
})
export class PayerInfoService {
    constructor(private http: HttpClient) {}

    public getPaymentSession(centerId: number, isAutoPay: boolean): Observable<PaymentInfo> {
        return this.http
            .get(
                `${
                    environment.config().security.gatewayHost
                }/fic/mbd/payer/paymentsession?centerId=${centerId}&isAutoPay=${isAutoPay}`
            )
            .pipe(
                map((data: any) => {
                    return new PaymentInfo(data)
                })
            )
    }
}
