export class Questionnaire {
    public static readonly QUESTION_TYPE_HANDLED = ['radio']

    id: string
    name: string
    questionGroups: QuestionGroups[]
    creationDate?: Date
    updateDate?: Date
    centerId: string

    constructor(data: any, centerId: string) {
        this.id = data.id
        this.name = data.name
        this.questionGroups = (data.question_groups || [])
            .map((q: any) => new QuestionGroups(q))
            .filter((g: QuestionGroups) => g.questions.length > 0)
        this.creationDate = data.created && new Date(data.created)
        this.updateDate = data.updated && new Date(data.updated)
        this.centerId = centerId
    }
}

export class QuestionGroups {
    groupDescription: string
    questions: Question[]

    constructor(data: any) {
        this.groupDescription = data.group_description
        this.questions = (data.questions || [])
            .filter(
                (q: any) =>
                    Questionnaire.QUESTION_TYPE_HANDLED.indexOf(q.kind) >= 0 && !!q.is_required
            )
            .map((q: any) => new Question(q))
    }
}

export class Question {
    kind: string
    text: string
    alertValue: string
    isRequired: boolean
    shouldAlert: boolean
    options: string[]

    constructor(data: any) {
        this.kind = data.kind
        this.text = data.text
        this.alertValue = data.alert_value
        this.isRequired = data.is_required
        this.shouldAlert = data.should_alert
        this.options = data.options
    }
}

export type Answers = string[][]

export type QuestionnaireCenterMap = { [centerId: string]: Questionnaire }

export type QuestionnaireCenterBeenSubmittedTodayMap = { [id: string]: boolean }