import { createAction, props } from '@ngrx/store'
import { TuitionMessage } from '../models/tuition'

export const listTuitionMessages = createAction('[Tuition] List all Messages')

export const listTuitionMessagesSuccess = createAction(
    '[Tuition] Listed all Tuition Messages with success',
    props<{ messagesList: TuitionMessage[] }>()
)

export const listTuitionMessagesError = createAction(
    '[Tuition] Error listing all Tuition Messages',
    props<{ error: Error }>()
)

export const postTuitionAcknowledgment = createAction(
    '[Tuition] Post Tuition Acknowledgement',
    props<{ messageId: string }>()
)

export const postTuitionAcknowledgmentSuccess = createAction(
    '[Tuition] Post Tuition Acknowledgement Sucess',
    props<{ messageId: string }>()
)

export const postTuitionAcknowledgmentError = createAction(
    '[Tuition] Error Posting Tuition Acknowledgement',
    props<{ error: Error }>()
)

export const postTuitionRefusal = createAction(
    '[Tuition] Post Tuition Refusal',
    props<{ messageId: string; reason: string }>()
)

export const postTuitionRefusalSuccess = createAction('[Tuition] Post Tuition Refusal Success')

export const postTuitionDeclineError = createAction(
    '[Tuition] Error Posting Tuition Refusal',
    props<{ error: Error }>()
)
