export class Shareability {
    guardianId: string
    memoryId: string
    isShareable: boolean

    constructor(guardianId: string, memoryId: string, data: any) {
        this.guardianId = guardianId
        this.memoryId = memoryId
        this.isShareable = !!data.is_shareable
    }
}
