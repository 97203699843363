import { createFeatureSelector, createSelector } from '@ngrx/store'
import { Profile } from '../models/profile'
import { profileFeatureKey, ProfileState } from './profile.reducers'

const profileSelector = createFeatureSelector<ProfileState>(profileFeatureKey)

export const selectProfile = createSelector(profileSelector, (state: ProfileState) => state.profile)

export const selectLoggedUserId = createSelector(
    profileSelector,
    (state: ProfileState) => state.profile?.id
)

export const selectHomeUrl = createSelector(profileSelector, (state: ProfileState) =>
    getHomeBaseUrl(state.profile)
)

export const selectMemoriesUrl = createSelector(profileSelector, (state: ProfileState) =>
    getMemoriesBaseUrl(state.profile)
)

export const selectCalendarUrl = createSelector(profileSelector, (state: ProfileState) =>
    getCalendarBaseUrl(state.profile)
)

export const selectHomeUrlQuickTasks = createSelector(
    profileSelector,
    (state: ProfileState) => `${getHomeBaseUrl(state.profile)}?quick_tasks_open=2'`
)

const getHomeBaseUrl = (profile?: Profile): string => {
    return `/home/${profile?.id}/activity-feed`
}

const getMemoriesBaseUrl = (profile?: Profile): string => {
    return `/home/${profile?.id}/memories`
}

const getCalendarBaseUrl = (profile?: Profile): string => {
    return `/home/${profile?.id}/calendar`
}

export const getAccountMetaData = createSelector(
    profileSelector,
    (state: ProfileState) => state.accountMetaData
)
