import { MediaUtil } from '@bh/design-system'
import { ActionReducer, createReducer, on } from '@ngrx/store'
import { generateDailyReportSignedUrlSuccess } from './daily-report.actions'

export const dailyReportFeatureKey = 'daily-report'

export interface IDailyReportMap {
    id: string
    url: string
    fileName: string
}

export interface DailyReportState {
    dailyReportMap: { [id: string]: IDailyReportMap }
}

const initialState: DailyReportState = {
    dailyReportMap: {}
}

export const DailyReportReducer: ActionReducer<DailyReportState> = createReducer(
    initialState,
    on(generateDailyReportSignedUrlSuccess, (state: DailyReportState, { id, url, fileName }) => {
        return {
            ...state,
            dailyReportMap: {
                [MediaUtil.getMediaKey(id, false)]: {
                    id,
                    url,
                    fileName
                }
            }
        }
    })
)
