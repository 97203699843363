import { ActionReducer, createReducer, on } from '@ngrx/store'
import {
    linkGenerationProcessSuccess,
    recentDownloadItemsRequest,
    recentDownloadItemsRequestError,
    recentDownloadItemsRequestSuccess,
    resetMediaId,
    setSelectedDownloadId
} from './bulk-media-download.actions'
import { BulkMediaEntry } from '@events'
import moment from 'moment'

export const mediaDownloadFeatureKey = 'bulkMediaDownload'

export interface BulkMediaDownloadState {
    selectedDownloadId: string
    downloadLinkData: BulkMediaEntry[]
}

const initialState: BulkMediaDownloadState = {
    selectedDownloadId: '',
    downloadLinkData: []
}

export const BulkMediaDownloadReducer: ActionReducer<BulkMediaDownloadState> = createReducer(
    initialState,
    on(setSelectedDownloadId, (state: BulkMediaDownloadState, { selectedDownloadId }) => {
        return {
            ...state,
            selectedDownloadId
        }
    }),
    on(
        linkGenerationProcessSuccess,
        (state: BulkMediaDownloadState, { linkGenerationResponse }) => {
            const mediaData = state.downloadLinkData ? [...state.downloadLinkData] : []

            const index =
                mediaData.findIndex(
                    (media: BulkMediaEntry) => media.mediaId == linkGenerationResponse.mediaId
                ) ?? -1
            if (index !== -1) {
                mediaData.splice(index, 1, linkGenerationResponse)
            } else {
                mediaData.push(linkGenerationResponse)
            }
            mediaData.sort((a, b) => moment(b.created).valueOf() - moment(a.created).valueOf())
            return {
                ...state,
                downloadLinkData: mediaData
            }
        }
    ),
    on(resetMediaId, (state: BulkMediaDownloadState) => {
        return {
            ...state,
            selectedDownloadId: ''
        }
    }),
    on(recentDownloadItemsRequest, (state: BulkMediaDownloadState) => {
        return {
            ...state,
            downloadLinkData: []
        }
    }),
    on(recentDownloadItemsRequestSuccess, (state: BulkMediaDownloadState, { recentDownload }) => {
        return {
            ...state,
            downloadLinkData: recentDownload.filter((data) => !!data.attachmentId)
        }
    }),
    on(recentDownloadItemsRequestError, (state: BulkMediaDownloadState) => {
        return {
            ...state,
            downloadLinkData: []
        }
    })
)
