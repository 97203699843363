import { ActionReducer, createReducer, on } from "@ngrx/store"
import { deleteArrivalNoteError, deleteArrivalNoteSuccess, deleteFormEntries, loadFrequentEntrySuccess, resetEntriesDeleted, resetNoteSubmissionStatus, submitArrivalNotesError, submitArrivalNotesSuccess, updateArrivalNoteSuccess } from "./arrival-notes.actions"
import { EntryTimes } from "@events"

export const arrivalNotesFeatureKey = 'arrivalNotes'

export const enum NoteStausEnum {
    START = 0,
    SUCCESS = 1,
    FAILURE = 2
}

export interface ArrivalNotesState {
    entriesToDelete: number
    entriesDeleted: number
    noteSubmissionStatus: NoteStausEnum,
    entryTimes: EntryTimes
}

const initialState: ArrivalNotesState = {
    entriesToDelete: 0,
    entriesDeleted: 0,
    noteSubmissionStatus: NoteStausEnum.START,
    entryTimes: {} as EntryTimes
}

export const ArrivalNotesReducer: ActionReducer<ArrivalNotesState> = createReducer(
    initialState,
    on(resetEntriesDeleted, (state: ArrivalNotesState) => ({
        ...state,
        entriesToDelete: 0,
        entriesDeleted: 0
    })),
    on(deleteArrivalNoteSuccess, deleteArrivalNoteError, (state: ArrivalNotesState) => {
        return {
            ...state,
            entriesDeleted: state.entriesDeleted + 1
        }
    }),
    on(deleteFormEntries, (state: ArrivalNotesState, { entries }) => {
        return {
            ...state,
            entriesToDelete: state.entriesToDelete + entries
        }
    }),
    on(submitArrivalNotesSuccess, updateArrivalNoteSuccess, (state: ArrivalNotesState) => {
        return {
            ...state,
            noteSubmissionStatus: NoteStausEnum.SUCCESS
        }
    }),
    on(resetNoteSubmissionStatus, (state: ArrivalNotesState) => {
        return {
            ...state,
            noteSubmissionStatus: NoteStausEnum.START
        }
    }),
    on(submitArrivalNotesError, (state: ArrivalNotesState) => {
        return {
            ...state,
            noteSubmissionStatus: NoteStausEnum.FAILURE
        }
    }),
    on(loadFrequentEntrySuccess, (state: ArrivalNotesState, { frequentEntryTimes }) => {
        return {
            ...state,
            entryTimes: frequentEntryTimes ?? state.entryTimes
        }
    })
)
