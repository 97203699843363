import { createFeatureSelector, createSelector } from '@ngrx/store'
import moment from 'moment'
import { DocumentItem, PhotoItem } from './memories.actions'
import { memoriesFeatureKey, MemoriesState } from './memories.reducers'

const memoriesSelector = createFeatureSelector<MemoriesState>(memoriesFeatureKey)

export const selectTabSelected = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.tabSelected
)

export const selectActiveFilter = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.activeFilter
)
export const selectGroupedPhotos = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.groupedPhotos
)
export const selectGroupedDocs = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.groupedDocuments
)
export const selectGroupedPhotosDetail = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.groupedPhotosDetail
)
export const selectGroupedDocsDetail = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.groupedDocumentsDetail
)
export const selectGroupedReports = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.groupedReports
)
export const selectActiveMonth = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.activeMonth
)
export const selectActiveYear = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.activeYear
)
export const selectLastMemoryMonth = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.lastMemoryMonth
)
export const selectLastMemoryYear = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.lastMemoryYear
)

export const selectMemoriesSummary = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.memoriesSummary
)

export const selectMemoriesDays = createSelector(memoriesSelector, (state: MemoriesState) =>
    Object.keys(state.memoriesSummary).sort((a, b) => moment(b).valueOf() - moment(a).valueOf())
)

export const selectDocumentMap = createSelector(memoriesSelector, (state: MemoriesState) =>
    Object.keys(state.groupedDocumentsDetail)
        .filter((_, index) => index === 0)
        .map((key) => state.groupedDocumentsDetail[key])
        .reduce(
            (accDocList, docList) =>
                accDocList.concat(
                    docList.reduce((accDoc, doc) => accDoc.concat(doc.units), [] as DocumentItem[])
                ),
            [] as DocumentItem[]
        )
        .reduce(
            (acc, doc) => ({
                ...acc,
                [doc.memoryId]: doc
            }),
            {} as { [id: string]: DocumentItem }
        )
)

export const selectReportMap = createSelector(memoriesSelector, (state: MemoriesState) =>
    Object.keys(state.groupedReports)
        .filter((_, index) => index === 0)
        .map((key) => state.groupedReports[key])
        .reduce(
            (accDocList, docList) =>
                accDocList.concat(
                    docList.reduce((accDoc, doc) => accDoc.concat(doc.units), [] as DocumentItem[])
                ),
            [] as DocumentItem[]
        )
        .reduce(
            (acc, doc) => ({
                ...acc,
                [doc.id]: doc
            }),
            {} as { [id: string]: DocumentItem }
        )
)

export const selectPhotosMap = createSelector(memoriesSelector, (state: MemoriesState) =>
    Object.keys(state.groupedPhotosDetail)
        .filter((_, index) => index === 0)
        .map((key) => state.groupedPhotosDetail[key])
        .reduce(
            (accPhotoList, photoList) =>
                accPhotoList.concat(
                    photoList.reduce(
                        (accPhoto, photo) => accPhoto.concat(photo.units),
                        [] as PhotoItem[]
                    )
                ),
            [] as PhotoItem[]
        )
        .reduce(
            (acc, photo) => ({
                ...acc,
                [photo.memoryId]: photo
            }),
            {} as { [id: string]: PhotoItem }
        )
)

export const selectActiveDocument = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.activeDocumentList[0]
)

export const selectActiveDocumentData = createSelector(memoriesSelector, (state: MemoriesState) => {
    if (state.activeDocumentList.length > 0) {
        const allItems: any[] = []
        Object.values(state.groupedDocumentsDetail['daily'])
            .map((m) => m.units)
            .forEach((arr) => arr.forEach((item) => allItems.push(item)))
        Object.values(state.groupedPhotosDetail['daily'])
            .map((m) => m.units)
            .forEach((arr) => arr.forEach((item) => allItems.push(item)))
        return allItems.find((item) => item.memoryId === state.activeDocumentList[0])
    } else {
        return null
    }
})

export const selectCardInfo = createSelector(memoriesSelector, (state: MemoriesState) => ({
    title: state.title,
    description: state.description
}))

export const selectActiveDocumentShareable = createSelector(
    memoriesSelector,
    (state: MemoriesState) => state.activeDocumentShareable
)

export const selectMemoriesDaysWithData = createSelector(memoriesSelector, (state: MemoriesState) =>
    state.groupedPhotos.daily
        .filter((u) => u.units.length > 0 && u.datePeriod)
        .map((u) => u.datePeriod)
        .sort((a, b) => moment(b).valueOf() - moment(a).valueOf())
)

export const selectDocumentsMonthsWithData = createSelector(
    memoriesSelector,
    (state: MemoriesState) =>
        Array.from(
            new Set(
                state.groupedDocuments.daily
                    .filter((u) => u.units.length > 0 && u.datePeriod)
                    .map((u) => u.datePeriod.replace(/-([^-]+)$/, ''))
                    .sort((a, b) => moment(b).valueOf() - moment(a).valueOf())
            ).values()
        )
)

export const selectLastMemoriesDay = createSelector(
    memoriesSelector,
    (state: MemoriesState) =>
        state.groupedPhotos.daily.length > 0 &&
        state.groupedPhotos.daily[state.groupedPhotos.daily.length - 1].datePeriod
)

export const selectLastDocumentsMonth = createSelector(
    memoriesSelector,
    (state: MemoriesState) =>
        state.groupedDocuments.daily.length > 0 &&
        state.groupedDocuments.daily[state.groupedDocuments.daily.length - 1].datePeriod.replace(
            /-([^-]+)$/,
            ''
        )
)
