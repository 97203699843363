import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { CenterInfo } from '../models/center-info'
import { CenterInfoService } from '../services/center-info.service'
import {
    loadCenterInfo,
    loadCenterInfoSuccess,
    loadCenterInfoError,
    healthCheckCardClicked
} from './center-info.actions'
import { loadingStarted, loadingDone } from '../loading/loading.actions'
import { healthCheckCardTapped } from '../health-check-card/health-check-card.actions'

@Injectable()
export class CenterInfoEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private centerInfoService: CenterInfoService,
        private router: Router,
        private store: Store
    ) {
        this.logger = this.loggerFactory.getLogger('CenterInfoEffects')
    }

    loadCenterInfo = createEffect(() =>
        this.actions.pipe(
            ofType(loadCenterInfo),
            tap((centerInfoId) => {
                this.logger.debug(`Starting to load Center info for ${centerInfoId}`)
                this.store.dispatch(loadingStarted({ model: 'CenterInfo' }))
            }),
            mergeMap(({ centerInfoId }) =>
                this.centerInfoService.getCenterInfoList(centerInfoId).pipe(
                    tap((centerInfo) => this.logger.debug('Center info loaded', centerInfo)),
                    mergeMap((centerInfo: CenterInfo) => [
                        loadCenterInfoSuccess({ centerInfo }),
                        loadingDone({ model: 'CenterInfo' })
                    ]),
                    catchError((error: Error) => {
                        this.logger.error('Error loading center info', error.message, error)
                        this.store.dispatch(loadingDone({ model: 'CenterInfo' }))
                        return of(loadCenterInfoError({ error }))
                    })
                )
            )
        )
    )

    handleCenterInfoClick = createEffect(() =>
        this.actions.pipe(
            ofType(healthCheckCardTapped),
            tap(() => this.logger.debug(`Navigating to center info page`)),
            mergeMap((data) => {
                return from(this.router.navigate(['/health-check/center', data.id])).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => healthCheckCardClicked())
                )
            })
        )
    )
}
