import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
    settingsFeatureKey,
    SettingsState,
    Subscriptions,
    SettingItem,
    SubscriptionType
} from './settings.reducers'

const subNames = {
    checkin_email: 'Center Check In',
    checkout_email: 'Center Check Out',
    mcc_learning_at_home_email: 'Learning at Home',
    mcc_development_email: 'My Bright Journey Insights',
    mbj_portfolio_email: 'My Bright Journey Portfolio',
    center_announcement_push: 'Center Announcements',
    checkin_push: 'Center Check In',
    checkout_push: 'Center Check Out & Daily Report',
    center_closure_push: 'Center Closures',
    classroom_activity_push: 'Classroom Activities',
    daily_report_email: 'Daily Report',
    mcc_learning_at_home_push: 'Learning at Home',
    mapp_learning_at_home_push: 'Learning at Home',
    mbj_portfolio_push: 'My Bright Journey Portfolio',
    new_email_or_text_push: 'New Email or Text',
    mcc_development_push: 'My Bright Journey Insights',
    intraday_bathroom: 'Bathroom',
    intraday_meal: 'Meals',
    intraday_medication: 'Medications',
    intraday_nap: 'Naps',
    intraday_note: 'Teacher Notes',
    intraday_skin: 'Sunscreen/Insect Repellant',
    questionnaire_six: '6:00 AM Reminder',
    questionnaire_seven: '7:00 AM Reminder',
    questionnaire_eight: '8:00 AM Reminder',
    questionnaire_nine: '9:00 AM Reminder',
    please_bring_six: '6:00 AM Reminder',
    please_bring_seven: '7:00 AM Reminder',
    please_bring_eight: '8:00 AM Reminder',
    please_bring_nine: '9:00 AM Reminder',
    observation_push: 'Developmental Observations',
    media_push: 'Snapshots',
    curriculum_plan_email: 'Curriculum Plans',
    curriculum_plan_push: 'Curriculum Plans',
    bulk_download_email: 'Bulk Download Email',
    bulk_download_push: 'Bulk Download'
}

const pushGeneralKeys = [
    'center_announcement_push',
    'checkin_push',
    'checkout_push',
    'center_closure_push',
    'classroom_activity_push',
    'curriculum_plan_push',
    'mapp_learning_at_home_push',
    'mbj_portfolio_push',
    'new_email_or_text_push',
    'mcc_development_push',
    'bulk_download_push'
]

const emailKeys = [
    'checkin_email',
    'checkout_email',
    'daily_report_email',
    'curriculum_plan_email',
    'mcc_learning_at_home_email',
    'mcc_development_email',
    'mbj_portfolio_email',
    'bulk_download_email'
]

const careEventsKeys = [
    'intraday_bathroom',
    'intraday_meal',
    'intraday_medication',
    'intraday_nap',
    'intraday_note',
    'intraday_skin'
]

const healthcheckKeys = [
    {
        sort: 1,
        name: 'questionnaire_six',
    },
    {
        sort: 2,
        name: 'questionnaire_seven',
    },
    {
        sort: 3,
        name: 'questionnaire_eight',
    },
    {
        sort: 4,
        name: 'questionnaire_nine',
    }
]

const pleaseBringKeys = [
    {
        sort: 1,
        name: 'please_bring_six',
    },
    {
        sort: 2,
        name: 'please_bring_seven',
    },
    {
        sort: 3,
        name: 'please_bring_eight',
    },
    {
        sort: 4,
        name: 'please_bring_nine',
    }
]

const photosAndActivitiesKeys = ['observation_push', 'media_push']

const settingsSelector = createFeatureSelector<SettingsState>(settingsFeatureKey)

export const selectSubscriptions = createSelector(
    settingsSelector,
    (state: SettingsState): Subscriptions => state.subscriptions
)

export const selectTuitionAlertVisibility = createSelector(
    settingsSelector,
    (state: SettingsState): boolean => state.tuitionAlert.visible
)

export const selectTuitionAlertDecline = createSelector(
    settingsSelector,
    (state: SettingsState): boolean => state.tuitionAlert.decline
)

export const selectTuitionMsgId = createSelector(
    settingsSelector,
    (state: SettingsState): string => state.tuitionAlert.messageId
)

export const selectFormattedSubs = createSelector(settingsSelector, (state: SettingsState) => {
    const { subscriptions } = state
    const subs = Object.keys(state.subscriptions).map(
        (key) =>
            ({
                key,
                name: (subNames as any)[key],
                enabled: (subscriptions as any)[key],
                primary: false
            } as SettingItem)
    )
    return subs
})

export const selectFormattedGeneralPushSubs = createSelector(
    settingsSelector,
    (state: SettingsState) => {
        const { subscriptions } = state
        const subs = Object.keys(state.subscriptions)
            .filter((item) => {
                const d = item as SubscriptionType
                return pushGeneralKeys.includes(d)
            })
            .map(
                (key) =>
                    ({
                        key,
                        name: (subNames as any)[key],
                        enabled: (subscriptions as any)[key],
                        primary: false
                    } as SettingItem)
            )
            .sort((a, b) => (a.name < b.name ? -1 : 1))
        const enabled = subs.filter((sub) => sub.enabled)
        subs.unshift({
            key: 'all-general-notifications',
            name: 'Receive all general notifications',
            enabled: enabled.length === subs.length,
            primary: true
        })
        return subs
    }
)

export const selectFormattedEmailSubs = createSelector(settingsSelector, (state: SettingsState) => {
    const { subscriptions } = state
    const subs = Object.keys(state.subscriptions)
        .filter((item) => {
            const d = item as SubscriptionType
            return emailKeys.includes(d)
        })
        .map(
            (key) =>
                ({
                    key,
                    name: (subNames as any)[key],
                    enabled: (subscriptions as any)[key],
                    primary: false
                } as SettingItem)
        )
        .sort((a, b) => (a.name < b.name ? -1 : 1))
    const enabled = subs.filter((sub) => sub.enabled)
    subs.unshift({
        key: 'all-email-notifications',
        name: 'Receive all email notifications',
        enabled: enabled.length === subs.length,
        primary: true
    })
    return subs
})

export const selectFormattedCareEventsSubs = createSelector(
    settingsSelector,
    (state: SettingsState) => {
        const { subscriptions } = state
        const subs = Object.keys(state.subscriptions)
            .filter((item) => {
                const d = item as SubscriptionType
                return careEventsKeys.includes(d)
            })
            .map(
                (key) =>
                    ({
                        key,
                        name: (subNames as any)[key],
                        enabled: (subscriptions as any)[key],
                        primary: false
                    } as SettingItem)
            )
            .sort((a, b) => (a.name < b.name ? -1 : 1))
        const enabled = subs.filter((sub) => sub.enabled)
        subs.unshift({
            key: 'all-care-events-notifications',
            name: 'Receive all care event notifications',
            enabled: enabled.length === subs.length,
            primary: true
        })
        return subs
    }
)

export const selectFormattedHealthSubs = createSelector(
    settingsSelector,
    (state: SettingsState) => {
        const { subscriptions } = state
        const subs = Object.keys(state.subscriptions)
            .filter((item) => {
                const d = item as SubscriptionType
                return healthcheckKeys.map((hc) => hc.name).includes(d)
            })
            .map(
                (key) =>
                    ({
                        key,
                        name: (subNames as any)[key],
                        enabled: (subscriptions as any)[key],
                        primary: false,
                        sort: healthcheckKeys.filter((h) => h.name === key).map((hc) => hc.sort)[0]
                    } as SettingItem)
            )
        return subs.sort((a, b) => a.sort! - b.sort! )
    }
)

export const selectFormattedPleaseBringSubs = createSelector(
    settingsSelector,
    (state: SettingsState) => {
        const { subscriptions } = state
        const subs = Object.keys(state.subscriptions)
            .filter((item) => {
                const d = item as SubscriptionType
                return pleaseBringKeys.map((pb) => pb.name).includes(d)
            })
            .map(
                (key) =>
                    ({
                        key,
                        name: (subNames as any)[key],
                        enabled: (subscriptions as any)[key],
                        primary: false,
                        sort: pleaseBringKeys.filter((p) => p.name === key ).map((pb) => pb.sort)[0]
                    } as SettingItem)
            )
        return subs.sort((a, b) => a.sort! - b.sort! )
    }
)

export const selectFormattedPhotosActivitiesSubs = createSelector(
    settingsSelector,
    (state: SettingsState) => {
        const { subscriptions } = state
        const subs = Object.keys(state.subscriptions)
            .filter((item) => {
                const d = item as SubscriptionType
                return photosAndActivitiesKeys.includes(d)
            })
            .map(
                (key) =>
                    ({
                        key,
                        name: (subNames as any)[key],
                        enabled: (subscriptions as any)[key],
                        primary: false
                    } as SettingItem)
            )
            .sort((a, b) => (a.name < b.name ? -1 : 1))
        return subs
    }
)
