import { createAction, props, Action } from '@ngrx/store'

export const refreshData = createAction('[RefreshAction] Refresh Data')

export const updateRefreshActions = createAction(
    '[RefreshAction] Update Actions',
    props<{ actions: Action[] }>()
)

export const addRefreshAction = createAction(
    '[RefreshAction] Add Action',
    props<{ action: Action }>()
)

export const removeAllRefreshActions = createAction('[RefreshAction] Clear All Actions')
