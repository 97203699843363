import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import moment from 'moment'
import { Observable, Subject, of } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { CurriculumPlans } from '../models/curriculum-plans'
import { ObservationEntry } from '../models/activity-feed'
import { Cacheable } from '@bh/data'

@Injectable({
    providedIn: 'root'
})
export class MyLearnersService {
    private cancelLoadCurriculumPlans$ = new Subject()
    private cancelLoadObservations$ = new Subject()

    constructor(private http: HttpClient) {}

    public getCurriculumPlans(
        dependentId: string,
        startDate: string | Date,
        endDate: string | Date
    ): Observable<CurriculumPlans[]> {
        const start = moment(startDate).format('YYYY-MM-DD')
        const end = moment(endDate).format('YYYY-MM-DD')

        return this.http
            .get<unknown[]>(
                `${
                    environment.config().security.backendHost
                }/curriculum_plans?dependents=${dependentId}&start=${start}&end=${end}`
            )
            .pipe(
                takeUntil(this.cancelLoadCurriculumPlans$),
                map((data) =>
                    data && data.length > 0 ? data.map((plan) => new CurriculumPlans(plan)) : []
                )
            )
    }

    public getDevObservations(
        dependentId: string,
        startDate: string,
        endDate: string,
        limit: number
    ): Observable<MyLearnersObservations> {
        return endDate === moment().format('YYYY-MM-DD')
            ? this._getCurrentDevObservations(dependentId, startDate, endDate, limit)
            : this._getPastDevObservations(dependentId, startDate, endDate, limit)
    }

    @Cacheable({ key: 'currentDevObservations' })
    private _getCurrentDevObservations(
        dependentId: string,
        startDate: string,
        endDate: string,
        limit: number
    ): Observable<MyLearnersObservations> {
        return this._getDevObservations(dependentId, startDate, endDate, limit)
    }

    @Cacheable({ key: 'pastDevObservations', ttl: 6.048e8 })
    private _getPastDevObservations(
        dependentId: string,
        startDate: string,
        endDate: string,
        limit: number
    ): Observable<MyLearnersObservations> {
        return this._getDevObservations(dependentId, startDate, endDate, limit)
    }

    private _getDevObservations(
        dependentId: string,
        startDate: string,
        endDate: string,
        limit: number
    ): Observable<MyLearnersObservations> {
        const params = {
            start_date: startDate,
            end_date: endDate,
            limit
        }
        
        return this.http
            .get<unknown[]>(
                `${
                    environment.config().security.backendHost
                }/dependant/${dependentId}/observations`,
                {
                    params
                }
            )
            .pipe(
                takeUntil(this.cancelLoadObservations$),
                map((data: any) => {
                    const lastFetchedDate = moment.unix(data.last_date).format('YYYY-MM-DD')
                    return {
                        observations:
                            data && data.entries.length > 0
                                ? data.entries.map((obs: any) => new ObservationEntry(obs))
                                : [],
                        lastFetchedDate
                    } as MyLearnersObservations
                })
            )
    }

    cancelObservationRequest() {
        this.cancelLoadObservations$.next()
        return of(null)
    }

    cancelCurriculumPlanRequest() {
        this.cancelLoadCurriculumPlans$.next()
        return of(null)
    }
}

export interface MyLearnersObservations {
    observations: ObservationEntry[]
    lastFetchedDate: string
    isPaginationDisabled: boolean
}
