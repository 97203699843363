import { createAction, props } from '@ngrx/store'
import { Notification } from '../models/notification'

export const listNotifications = createAction(
    '[Notifications] List all notifications',
    props<{ guardianId: string }>()
)

export const listNotificationsSuccess = createAction(
    '[Notifications] Listed all notifications with success',
    props<{ notificationList: Notification[] }>()
)

export const listNotificationsError = createAction(
    '[Notifications] Error listing all notifications',
    props<{ error: Error }>()
)

export const updateNotificationReadStatus = createAction(
    '[Notifications] Update Notification read status',
    props<{
        guardianId: string
        notificationId: string
        isRead: boolean
        readInMessages: boolean
        readInReminders: boolean
    }>()
)

export const updateNotificationReadStatusSuccess = createAction(
    '[Notifications] Update Notification read status success',
    props<{
        notificationId: string
        isRead: boolean
        readInMessages: boolean
        readInReminders: boolean
    }>()
)

export const updateNotificationReadStatusError = createAction(
    '[Notifications] Error updating Notification read status',
    props<{ error: Error }>()
)

export const markNotificationAsDeleted = createAction(
    '[Notifications] Mark Notification as deleted',
    props<{
        guardianId: string
        notificationId: string
        isRead: boolean
        showInMessages: boolean
        showInReminders: boolean
        showInHomepage: boolean
        readInMessages: boolean
        readInReminders: boolean
    }>()
)

export const markNotificationAsDeletedSuccess = createAction(
    '[Notifications] Mark Notification as deleted success',
    props<{ notificationId: string }>()
)

export const markNotificationAsDeletedError = createAction(
    '[Notifications] Error marking Notification as deleted',
    props<{ error: Error }>()
)
