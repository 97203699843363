import { createAction, props } from '@ngrx/store'
import { Profile } from '../models/profile'
import { AccountMetaData } from '../models/account-meta-data'

export const loadProfile = createAction('[Profile] Load Profile')

export const loadProfileSuccess = createAction(
    '[Profile] Load Profile Success',
    props<{ profile: Profile }>()
)

export const loadProfileError = createAction(
    '[Profile] Load Profile Error',
    props<{ error: Error }>()
)

export const loadAccountMetaData = createAction('[Profile] Load Account MetaData')

export const loadAccountMetaDataSuccess = createAction(
    '[Profile] Load Account MetaData Success',
    props<{ metaData: AccountMetaData }>()
)

export const loadAccountMetaDataError = createAction(
    '[Profile] Load Account MetaData Error',
    props<{ error: Error }>()
)
