export class CenterInfo {
    id: string
    name: string
    phone: string
    emergencyPhone: string
    email: string
    primaryEvacuationLocation: string[]
    secondaryEvacuationLocation: string[]
    operational_hours: CenterOperationalHours[]
    address: string[]
    settings: Settings
    questionnaireId?: string
    timeZone: string
    model: string
    centerNumber: string

    constructor(data: any) {
        this.id = data.id
        this.name = data.name
        this.phone = data.phone
        this.emergencyPhone = data.emergency_phone
        this.email = data.email
        this.primaryEvacuationLocation = getAddress(data.primary_evacuation_location)
        this.secondaryEvacuationLocation = getAddress(data.secondary_evacuation_location)
        this.address = getAddress(data.address)
        this.settings = data.settings && new Settings(data.settings)
        this.timeZone = data.timezone
        this.model = data.model
        this.centerNumber = data.school_number
        this.operational_hours =
            data.operational_hours &&
            Object.keys(data.operational_hours).reduce((current: any[], key: string) => {
                const day = data.operational_hours[key]
                const start = day && day.open ? day.open : ''
                const end = day && day.close ? day.close : ''
                return [...current, { name: key, value: start ? `${start} - ${end}` : '' }]
            }, [])
    }
}

export interface CenterOperationalHours {
    name: string
    value: string
}

export type CenterInfoMap = { [key: string]: CenterInfo }

export const timeConvert = (value: string) => {
    // Check correct time format and split into components
    let time: any[] = value.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [value]

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1) // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
        time[0] = +time[0] % 12 || 12 // Adjust hours
    }
    return time.join('') // return adjusted time or original string
}

export const getAddress = (address: any) => {
    return address
        ? [
              `${(address['name'] && address['name'] + ' ') || ''}${
                  (address['address_line_1'] && address['address_line_1']) || ''
              }${address['address_line_2'] || ''}`,
              `${(address['city'] && address['city'] + ', ') || ''} ${address['state'] || ''} ${
                  address['postal_code'] || ''
              }`
          ]
        : []
}

export class Settings {
    healthCheckEnabled: boolean
    parentAttendanceEnabled: boolean
    arrivalNotesSubmissionConfig: number
    autoCheckoutTime: string

    constructor(data: any) {
        this.healthCheckEnabled = !!data.health_check_enabled
        this.parentAttendanceEnabled = !!data.parent_attendance_enabled
        this.arrivalNotesSubmissionConfig = data.parent_dropoff_time_limit
        this.autoCheckoutTime = data.auto_checkout_local_time
    }
}
