import { documentsClicked } from '@bh/design-system'
import { ActionReducer, createReducer, on } from '@ngrx/store'
import moment from 'moment'
import {
    loadMemoriesSummaryForDependantListSuccess,
    loadMultiMemoriesByPeriodSuccess,
    loadActivityFeedByPeriodSuccess,
    loadMemoriesPorfolioByPeriodSuccess
} from '../dependant/dependant.actions'
import {
    ActivityEntry,
    ActivityFeed,
    ActivityFeedPair,
    SnapshotEntry
} from '../models/activity-feed'
import { MemoriesSummary, MemoriesSummaryDisplay } from '../models/memories-summary'
import {
    clearMemories,
    deletePhotosByPeriod,
    DocItemMap,
    DocumentItem,
    documentsCleared,
    DocUnitItem,
    getMemoryShareability,
    getMemoryShareabilityError,
    getMemoryShareabilitySuccess,
    GroupedDocType,
    GroupedPhotoType,
    memoriesByMonthSelected,
    memoriesByYearSelected,
    memoriesFilterSelected,
    MemoriesFilterType,
    MemoriesTabType,
    memoryDetailsClosed,
    MemoryItem,
    PhotoItem,
    PhotoItemMap,
    PhotoUnitItem,
    tabSelected
} from './memories.actions'
import { MemoryDocument } from '../models/memory-data'
import { MemoryUtil } from '@events'

export const memoriesFeatureKey = 'memories'

export interface MemoriesState {
    activeDocumentList: string[]
    activeDocumentShareable: boolean | null
    activeFilter: MemoriesFilterType
    tabSelected: MemoriesTabType
    groupedDocuments: GroupedDocType
    groupedDocumentsDetail: GroupedDocType
    groupedPhotos: GroupedPhotoType
    groupedPhotosDetail: GroupedPhotoType
    groupedReports: GroupedDocType
    activeMonth: string
    activeYear: string
    title: string
    description: string
    lastMemoryMonth: string
    lastMemoryYear: string
    memoriesSummary: { [date: string]: MemoriesSummaryDisplay[] }
}

const initialState: MemoriesState = {
    activeDocumentList: [],
    activeDocumentShareable: null,
    activeFilter: 'daily',
    tabSelected: 'media',
    groupedDocuments: { daily: [], monthly: [], yearly: [] },
    groupedDocumentsDetail: { daily: [], monthly: [], yearly: [] },
    groupedPhotos: { daily: [] },
    groupedPhotosDetail: { daily: [] },
    groupedReports: { daily: [], monthly: [], yearly: [] },
    activeMonth: '',
    activeYear: '',
    title: '',
    description: '',
    lastMemoryMonth: '',
    lastMemoryYear: '',
    memoriesSummary: {}
}

export interface Report {
    id: string
    dependent_id: string
    attachment_id: string
    assessment_year: string
    assessment_period: string
    published: string
    created: string
    updated: string
    deleted: null
    type: string
}

export const MemoriesReducer: ActionReducer<MemoriesState> = createReducer(
    initialState,
    on(clearMemories, (state: MemoriesState) => ({
        ...state,
        groupedDocuments: {},
        groupedDocumentsDetail: {},
        groupedPhotos: {},
        groupedPhotosDetail: {},
        groupedReports: {}
    })),
    on(tabSelected, (state: MemoriesState, { item }) => ({
        ...state,
        tabSelected: item
    })),
    on(documentsClicked, (state: MemoriesState, { id, title, description }) => ({
        ...state,
        activeDocumentList: [id].concat(state.activeDocumentList),
        title,
        description
    })),
    on(documentsCleared, (state: MemoriesState) => ({
        ...state,
        activeDocumentList: []
    })),
    on(memoriesByMonthSelected, (state: MemoriesState, { month }) => {
        return {
            ...state,
            activeMonth: month
        }
    }),
    on(memoriesByYearSelected, (state: MemoriesState, { year }) => {
        return {
            ...state,
            activeYear: year
        }
    }),
    on(getMemoryShareability, (state: MemoriesState) => {
        return {
            ...state,
            activeDocumentShareable: null
        }
    }),
    on(getMemoryShareabilitySuccess, (state: MemoriesState, { isShareable }) => {
        return {
            ...state,
            activeDocumentShareable: isShareable
        }
    }),
    on(getMemoryShareabilityError, (state: MemoriesState) => {
        return {
            ...state,
            activeDocumentShareable: false
        }
    }),
    on(memoryDetailsClosed, (state: MemoriesState, { id }) => {
        return {
            ...state,
            activeDocumentList: state.activeDocumentList.filter((doc) => !doc?.includes(id)),
            groupedDocumentsDetail:
                state.groupedDocuments['daily'] && state.groupedDocuments['daily'].length
                    ? state.groupedDocuments
                    : state.groupedDocumentsDetail,
            groupedPhotosDetail:
                state.groupedPhotos?.daily && state.groupedPhotos?.daily.length
                    ? state.groupedPhotos
                    : state.groupedPhotosDetail
        }
    }),
    on(loadMemoriesPorfolioByPeriodSuccess, (state: MemoriesState, { memoriesDocuments }) => {
        const ports = memoriesDocuments
        return {
            ...state,
            groupedReports: {
                daily: Object.entries(
                    ports.reduce(
                        (acc, doc) => ({
                            ...acc,
                            [doc.dayLabel]: (acc[doc.dayLabel] || ([] as DocumentItem[])).concat(
                                [doc].filter(
                                    (d) =>
                                        !(acc[doc.dayLabel] || ([] as DocumentItem[])).find(
                                            (memD) => memD.id === d.id
                                        )
                                )
                            )
                        }),
                        (state.groupedReports.daily || []).reduce(
                            (acc, doc) => ({
                                ...acc,
                                [doc.unit]: doc.units
                            }),
                            {} as DocItemMap
                        )
                    )
                ).map((unit: any) => ({
                    unit: unit[0],
                    units: unit[1].slice().sort(sortByDate),
                    datePeriod: moment(unit[1].slice()[0].sortDate).format('YYYY-MM-DD')
                })),
                monthly: Object.entries(
                    ports.reduce(
                        (acc, doc) => ({
                            ...acc,
                            [doc.monthLabel]: (
                                acc[doc.monthLabel] || ([] as DocumentItem[])
                            ).concat(
                                [doc].filter(
                                    (d) =>
                                        !(acc[doc.monthLabel] || ([] as DocumentItem[])).find(
                                            (memD) => memD.id === d.id
                                        )
                                )
                            )
                        }),
                        (state.groupedReports.monthly || []).reduce(
                            (acc, doc) => ({
                                ...acc,
                                [doc.unit]: doc.units
                            }),
                            {} as DocItemMap
                        )
                    )
                ).map((unit: any) => ({
                    unit: unit[0],
                    units: unit[1].slice().sort(sortByDate),
                    datePeriod: moment(unit[1].slice()[0].sortDate).format('YYYY-MM-DD')
                })),
                yearly: Object.entries(
                    ports.reduce(
                        (acc, doc) => ({
                            ...acc,
                            [doc.yearLabel]: (acc[doc.yearLabel] || ([] as DocumentItem[])).concat(
                                [doc].filter(
                                    (d) =>
                                        !(acc[doc.yearLabel] || ([] as DocumentItem[])).find(
                                            (memD) => memD.id === d.id
                                        )
                                )
                            )
                        }),
                        (state.groupedReports.yearly || []).reduce(
                            (acc, doc) => ({
                                ...acc,
                                [doc.unit]: doc.units
                            }),
                            {} as DocItemMap
                        )
                    )
                ).map((unit: any) => ({
                    unit: unit[0],
                    units: unit[1].slice().sort(sortByDate),
                    datePeriod: moment(unit[1].slice()[0].sortDate).format('YYYY-MM-DD')
                }))
            }
        }
    }),
    on(
        loadMemoriesSummaryForDependantListSuccess,
        (state: MemoriesState, { memoriesSummaryList }) => {
            const monthDeps: { [month: string]: Set<string> } = {}
            const yearlyDeps: { [month: string]: Set<string> } = {}

            const allMemories = memoriesSummaryList
                .reduce((acc: MemoriesSummaryDisplay[], depMemories: MemoriesSummary[]) => {
                    return acc.concat(
                        depMemories.map((item) => {
                            const date = moment(item.date).format('YYYY-MM')
                            const year = date.split('-')[0]

                            if (!monthDeps[date]) {
                                monthDeps[date] = new Set<string>()
                            }

                            monthDeps[date].add(item.dependentIdList[0])

                            if (!yearlyDeps[year]) {
                                yearlyDeps[year] = new Set<string>()
                            }

                            yearlyDeps[year].add(item.dependentIdList[0])

                            return {
                                date,
                                dateLabel: moment(date).format('MMMM YYYY'),
                                lastSnapshot: item.lastSnapshot,
                                dependentIdList: item.dependentIdList,
                                sortDate: item.sortDate,
                                units: []
                            }
                        })
                    )
                }, [])
                .sort((a, b) => b.sortDate - a.sortDate)

            const memoriesSummary = [...allMemories].reverse().reduce(
                (acc, dailyReport) => {
                    const year = dailyReport.date.split('-')[0]

                    if (!acc.monthly.find((m) => m.date === dailyReport.date)) {
                        const units = allMemories
                            .filter((a) => a.date === dailyReport.date)
                            .sort((a, b) => a.sortDate - b.sortDate)
                        //find an entries which has snapshot
                        const report = units.find((unit) => unit.lastSnapshot.id !== '')
                        if (report) {
                            dailyReport = report
                        }

                        acc.monthly.push({
                            ...dailyReport,
                            dependentIdList: Array.from(monthDeps[dailyReport.date]),
                            units: units
                        })
                    }

                    if (dailyReport.lastSnapshot.id) {
                        if (!acc.yearly.find((m) => m.date === year)) {
                            acc.yearly.push({
                                ...dailyReport,
                                date: year,
                                dateLabel: year,
                                dependentIdList: Array.from(yearlyDeps[year]),
                                units: allMemories.filter(
                                    (a) => a.date.split('-')[0] === year && a.lastSnapshot.id
                                )
                            })
                        }
                    }

                    return acc
                },
                { monthly: [], yearly: [] } as { [date: string]: MemoriesSummaryDisplay[] }
            )

            return {
                ...state,
                memoriesSummary: {
                    monthly: [...memoriesSummary.monthly].reverse(),
                    yearly: [...memoriesSummary.yearly].reverse()
                }
            }
        }
    ),
    on(
        loadActivityFeedByPeriodSuccess,
        (state: MemoriesState, { dependantId, activityFeed, memoryId }) => {
            const newActivityFeed = activityFeed || []
            const photos = extractPhotosFromFeed(newActivityFeed, dependantId)
            const docs = extractDocumentsFromFeed(newActivityFeed, dependantId)

            const photosState =
                state.groupedPhotos['daily'] && state.groupedPhotos['daily'].length
                    ? state.groupedPhotos
                    : state.groupedPhotosDetail
            const docsState =
                state.groupedDocuments['daily'] && state.groupedDocuments['daily'].length
                    ? state.groupedDocuments
                    : state.groupedDocumentsDetail

            let dailyPhotos = aggregatePhotos(photosState.daily, photos, 'daily')
            let dailyDocs = aggregateDocs(docsState.daily, docs, 'daily')

            // If the activity feed being loaded was triggered by clicking on a memory item from the home feed,
            // Find the photos that are relevant to that particular memory item
            if (memoryId !== undefined) {
                // Find the index of the activities array that features the snapshot/event that was clicked on
                let activityBase: any = newActivityFeed[0]?.activities ?? []
                let type = 'activities'
                let x = activityBase.findIndex(
                    (a: any) => a.snapshots.findIndex((e: any) => e.id === memoryId) !== -1
                )
                if (x === -1) {
                    x = (newActivityFeed[0]?.snapshots ?? []).findIndex((a) => a.id === memoryId)
                    activityBase = newActivityFeed[0]?.snapshots
                    type = 'snapshots'
                }
                if (x === -1) {
                    x = (newActivityFeed[0]?.observations ?? []).findIndex((a) => a.id === memoryId)
                    activityBase = newActivityFeed[0]?.observations
                    type = 'observations'
                }

                if (x !== -1) {
                    const newPhotos = []
                    const newDocs = []
                    const items =
                        type === 'activities' ? activityBase[x].snapshots : [activityBase[x]]
                    for (const i of items) {
                        const item = {
                            dependantId,
                            sortDate: i.sortDate,
                            attachmentId:
                                i.attachment === undefined ? i.attachmentId : i.attachment,
                            id: i.id,
                            memoryId: MemoryUtil.getMemoryId(i.id, dependantId),
                            activityType: 'snapshot',
                            note: i.note,
                            dayLabel: MemoryUtil.getDailyLabel(i.sortDate),
                            monthLabel: MemoryUtil.getMonthLabel(i.sortDate),
                            yearLabel: MemoryUtil.getYearLabel(i.sortDate),
                            title: type === 'activities' ? activityBase[x].subjects : '',
                            description: type === 'activities' ? activityBase[x].note : ''
                        } as PhotoItem
                        newPhotos.push(item)

                        if (type === 'observations') {
                            const docItem = {
                                id: i.id,
                                memoryId: MemoryUtil.getMemoryId(i.id, dependantId),
                                dayLabel: MemoryUtil.getDailyLabel(i.sortDate),
                                monthLabel: MemoryUtil.getMonthLabel(i.sortDate),
                                yearLabel: MemoryUtil.getYearLabel(i.sortDate),
                                dependantId: dependantId,
                                sortDate: i.sortDate,
                                description: i.note,
                                title: i.title,
                                type: 'observation',
                                attachment: i.attachmentId,
                                selections: i.selections,
                                visibility: 'daily'
                            } as DocumentItem
                            newDocs.push(docItem)
                        }
                    }

                    dailyPhotos = aggregatePhotos([], newPhotos, 'daily')

                    if (type === 'observations') {
                        dailyDocs = aggregateDocs([], newDocs, 'daily')
                    }
                }
            }

            return {
                ...state,
                groupedPhotosDetail: { daily: dailyPhotos },
                groupedDocumentsDetail: {
                    daily: dailyDocs,
                    monthly: aggregateDocs(docsState.monthly, docs, 'monthly'),
                    yearly: aggregateDocs(docsState.yearly, docs, 'yearly').sort(
                        (a, b) => parseInt(b.unit) - parseInt(a.unit)
                    )
                }
            }
        }
    ),
    on(memoriesFilterSelected, (state: MemoriesState, { activeFilter }) => ({
        ...state,
        activeFilter
    })),
    on(deletePhotosByPeriod, (state: MemoriesState, { datePeriod }) => {
        const newItems = state.groupedPhotos.daily.map((photo) => {
            return photo.datePeriod !== datePeriod
                ? photo
                : {
                      ...photo,
                      units: photo.units.map(
                          (u) =>
                              ({
                                  sortDate: u.sortDate,
                                  dependantId: u.dependantId
                              } as unknown as PhotoItem)
                      )
                  }
        })

        return {
            ...state,
            groupedPhotos: {
                daily: newItems
            }
        }
    }),
    on(
        loadMultiMemoriesByPeriodSuccess,
        (
            state: MemoriesState,
            { loadMedia, loadReports, memoriesDataResponse, dependantIdsList, activityFeeds }
        ) => {
            const { memoriesMedia, memoriesDocuments } = memoriesDataResponse

            let allPhotos: PhotoItem[] = []
            let allDocs: DocumentItem[] = []
            const excludeMediaEntriesTypes = ['my_bright_journey']
            dependantIdsList.forEach((id) => {
                allPhotos = allPhotos
                    .concat(
                        memoriesMedia?.filter(
                            (a) =>
                                a.dependantId === id &&
                                !excludeMediaEntriesTypes.includes(a.entryType)
                        ) || []
                    )
                    ?.sort(sortByDate)

                allDocs = allDocs.concat(
                    extractValidMemoriesDocuments(
                        memoriesDocuments?.filter((a) => a.dependantId === id) || [],
                        id
                    )
                )

                allDocs.sort((a, b) => b.sortDate.valueOf() - a.sortDate.valueOf())

                const today = moment().format('YYYY-MM-DD')
                if (
                    Object.keys(activityFeeds).length &&
                    activityFeeds[today] &&
                    Object.values(activityFeeds[today]).find(
                        (feed: ActivityFeedPair) =>
                            feed.group.dependantId === id && feed.group.checkOutAt === null
                    )
                ) {
                    {
                        allPhotos =
                            allPhotos.filter((photo) => {
                                return !(
                                    photo.dependantId === id && 
                                    MemoryUtil.getDailyLabel(photo.createdAt) === photo.dayLabel &&
                                    MemoryUtil.getDailyLabel(new Date()) === photo.dayLabel &&
                                    photo.visibility === 'daily'
                                )
                            }) || []
                        allDocs =
                            allDocs.filter((doc) => {
                                return !(
                                    doc.dependantId === id &&
                                    MemoryUtil.getDailyLabel(new Date()) === doc.dayLabel &&
                                    doc.visibility === 'daily' &&
                                    doc.type !== 'portfolio'
                                )
                            }) || []
                    }
                }
            })

            return {
                ...state,
                groupedPhotos: {
                    daily: loadMedia
                        ? aggregatePhotos(state.groupedPhotos.daily, allPhotos, 'daily')
                        : state.groupedPhotos.daily
                },
                groupedDocuments: {
                    daily: loadReports
                        ? aggregateDocs(state.groupedDocuments.daily, allDocs, 'daily')
                        : state.groupedDocuments.daily
                },
                groupedPhotosDetail: {
                    daily: loadMedia
                        ? aggregatePhotos(state.groupedPhotosDetail.daily, allPhotos, 'daily')
                        : state.groupedPhotosDetail.daily
                },
                groupedDocumentsDetail: {
                    daily: loadReports
                        ? aggregateDocs(state.groupedDocumentsDetail.daily, allDocs, 'daily')
                        : state.groupedDocumentsDetail.daily
                }
            }
        }
    )
)

export const sortByDate = (a: MemoryItem, b: MemoryItem): number => {
    if (a.captureDate && b.captureDate) {
        return b.captureDate.getTime() - a.captureDate.getTime()
    } else {
        return b.sortDate.getTime() - a.sortDate.getTime()
    }
}

const extractPhotosFromFeed = (activityFeed: ActivityFeed[], dependantId: string): PhotoItem[] => {
    const withwtMap = {} as { [snapId: string]: ActivityEntry }
    return activityFeed
        .reduce((acc, activitiesFeed) => {
            const photoList = activitiesFeed.observations
                .filter((observation) => !!observation.attachmentId)
                .map((observation) => {
                    return {
                        dependantId,
                        sortDate: observation.sortDate,
                        attachmentId: observation.attachmentId,
                        id: observation.id,
                        memoryId: MemoryUtil.getMemoryId(observation.id, dependantId),
                        activityType: 'observation',
                        note: observation.note,
                        dayLabel: MemoryUtil.getDailyLabel(observation.sortDate),
                        monthLabel: MemoryUtil.getMonthLabel(observation.sortDate),
                        yearLabel: MemoryUtil.getYearLabel(observation.sortDate),
                        visibility: observation.visibility,
                        selections: observation.selections
                    } as PhotoItem
                })
                .concat(
                    activitiesFeed.snapshots
                        .filter((snapshot) => !!snapshot.attachment)
                        .map((snapshot) => {
                            return {
                                dependantId,
                                sortDate: snapshot.capturedAt || snapshot.sortDate,
                                attachmentId: snapshot.attachment,
                                id: snapshot.id,
                                memoryId: MemoryUtil.getMemoryId(snapshot.id, dependantId),
                                activityType: 'snapshot',
                                note: snapshot.note,
                                dayLabel: MemoryUtil.getDailyLabel(
                                    snapshot.capturedAt ?? snapshot.sortDate
                                ),
                                monthLabel: MemoryUtil.getMonthLabel(
                                    snapshot.capturedAt ?? snapshot.sortDate
                                ),
                                yearLabel: MemoryUtil.getYearLabel(
                                    snapshot.capturedAt ?? snapshot.sortDate
                                ),
                                visibility: snapshot.visibility
                            } as PhotoItem
                        })
                )
                .concat(
                    activitiesFeed.activities
                        .reduce((activity, act) => {
                            act.snapshots.forEach((s) => (withwtMap[s.id] = act))
                            return activity.concat(act.snapshots)
                        }, [] as SnapshotEntry[])
                        .filter((snapshot) => {
                            return !!snapshot.attachment
                        })
                        .map((snapshot) => {
                            return {
                                dependantId,
                                sortDate: snapshot.capturedAt || snapshot.sortDate,
                                attachmentId: snapshot.attachment,
                                id: snapshot.id,
                                memoryId: MemoryUtil.getMemoryId(snapshot.id, dependantId),
                                note: snapshot.note,
                                activityType: 'snapshot',
                                dayLabel: MemoryUtil.getDailyLabel(
                                    snapshot.capturedAt ?? snapshot.sortDate
                                ),
                                monthLabel: MemoryUtil.getMonthLabel(
                                    snapshot.capturedAt ?? snapshot.sortDate
                                ),
                                yearLabel: MemoryUtil.getYearLabel(
                                    snapshot.capturedAt ?? snapshot.sortDate
                                ),
                                title: withwtMap[snapshot.id].subjects,
                                description: withwtMap[snapshot.id].note,
                                visibility: snapshot.visibility
                            } as PhotoItem
                        })
                )

            return acc.concat(photoList)
        }, [] as PhotoItem[])
        .sort(sortByDate)
}

const extractDocumentsFromFeed = (
    activityFeed: ActivityFeed[],
    dependantId: string
): DocumentItem[] => {
    return activityFeed
        .reduce((acc, activitiesFeed) => {
            const dailyReportDate =
                activitiesFeed.checkOutAt || activitiesFeed.reportPublishedAt
                    ? activitiesFeed.date
                    : undefined
            const docList = activitiesFeed.teacherNotes
                .filter(
                    (n) =>
                        !n.isFromParent &&
                        (n.visibility !== 'calendar' ||
                            (n.visibility === 'calendar' && dailyReportDate))
                )
                .map((note) => {
                    return {
                        dependantId,
                        id: note.id,
                        memoryId: MemoryUtil.getMemoryId(note.id, dependantId),
                        sortDate: note.sortDate,
                        type: 'note',
                        description: note.note,
                        dayLabel: MemoryUtil.getDailyLabel(note.sortDate),
                        monthLabel: MemoryUtil.getMonthLabel(note.sortDate),
                        yearLabel: MemoryUtil.getYearLabel(note.sortDate),
                        visibility: note.visibility
                    } as DocumentItem
                })
                .concat(
                    activitiesFeed.observations
                        .filter((o) => !o.isFromParent && !o.attachmentId)
                        .map((observation) => {
                            return {
                                dependantId,
                                id: observation.id,
                                memoryId: MemoryUtil.getMemoryId(observation.id, dependantId),
                                sortDate: observation.sortDate,
                                type: 'observation',
                                description: observation.note,
                                title: observation.title,
                                attachment: observation.attachmentId,
                                dayLabel: MemoryUtil.getDailyLabel(observation.sortDate),
                                monthLabel: MemoryUtil.getMonthLabel(observation.sortDate),
                                yearLabel: MemoryUtil.getYearLabel(observation.sortDate),
                                selections: observation.selections,
                                visibility: observation.visibility
                            } as DocumentItem
                        })
                )

            if (dailyReportDate) {
                const centerId = activitiesFeed.centerId
                const sortDate = moment(dailyReportDate).toDate()
                acc.push({
                    dependantId,
                    description: '',
                    title: 'Daily Report',
                    type: 'report',
                    id: `${dependantId}_${moment(sortDate).format('YYYY-MM-DD')}`,
                    memoryId: `${dependantId}_${moment(sortDate).format('YYYY-MM-DD')}`,
                    dayLabel: MemoryUtil.getDailyLabel(dailyReportDate),
                    monthLabel: MemoryUtil.getMonthLabel(dailyReportDate),
                    yearLabel: MemoryUtil.getYearLabel(dailyReportDate),
                    sortDate: sortDate,
                    centerId: centerId,
                    visibility: ''
                })
            }

            return acc.concat(docList)
        }, [] as DocumentItem[])
        .sort(sortByDate)
}

const extractValidMemoriesDocuments = (
    memoriesDocuments: MemoryDocument[],
    dependantId: string
): MemoryDocument[] => {
    const discardEntryTypes = ['activity', 'curriculum']
    return memoriesDocuments
        .reduce((acc, memoryDocument) => {
            const dailyReportDate = memoryDocument.dailyReportDate
            const validObservation =
                memoryDocument.type === 'observation' &&
                !memoryDocument.isFromParent &&
                !memoryDocument.attachment

            const docList = memoryDocument.isTeacherNote === true ? [memoryDocument] : []

            if (validObservation) {
                acc.push(memoryDocument)
            }

            if (dailyReportDate || (memoryDocument.isEmptyDailyReport && dailyReportDate)) {
                const centerId = memoryDocument.centerId
                const sortDate = moment(dailyReportDate).toDate()
                acc.push({
                    ...memoryDocument,
                    dependantId,
                    description: '',
                    title: 'Daily Report',
                    type: 'report',
                    id: `${dependantId}_${moment(sortDate).format('YYYY-MM-DD')}`,
                    memoryId: `${dependantId}_${moment(sortDate).format('YYYY-MM-DD')}`,
                    dayLabel: MemoryUtil.getDailyLabel(dailyReportDate),
                    monthLabel: MemoryUtil.getMonthLabel(dailyReportDate),
                    yearLabel: MemoryUtil.getYearLabel(dailyReportDate),
                    sortDate: sortDate,
                    centerId: centerId,
                    visibility: ''
                })
            }

            return acc.concat(docList)
        }, [] as MemoryDocument[])
        .sort(sortByDate)
        .filter((memoryDoc) => !discardEntryTypes.includes(memoryDoc.type))
}

const aggregatePhotos = (
    origPhotos: PhotoUnitItem[],
    newPhotos: PhotoItem[],
    time: MemoriesFilterType
): PhotoUnitItem[] => {
    return Object.entries(
        newPhotos.reduce(
            (acc, photo) => ({
                ...acc,
                [time === 'daily'
                    ? photo.dayLabel
                    : time === 'monthly'
                    ? photo.monthLabel
                    : photo.yearLabel]: (
                    acc[
                        time === 'daily'
                            ? photo.dayLabel
                            : time === 'monthly'
                            ? photo.monthLabel
                            : photo.yearLabel
                    ] || ([] as PhotoItem[])
                ).concat(
                    [photo].filter(
                        (p) =>
                            !(
                                acc[
                                    time === 'daily'
                                        ? photo.dayLabel
                                        : time === 'monthly'
                                        ? photo.monthLabel
                                        : photo.yearLabel
                                ] || ([] as PhotoItem[])
                            ).find((memP) => memP.memoryId === p.memoryId)
                    )
                )
            }),
            (origPhotos || []).reduce((acc, photo) => {
                const unit = updateDailyLabel(photo.unit, photo.units[0].sortDate)
                return {
                    ...acc,
                    [unit]: newPhotos.find((p) => p.dayLabel === unit)
                        ? photo.units.filter((p) => p.attachmentId)
                        : photo.units
                }
            }, {} as PhotoItemMap)
        )
    ).map((unit) => {
        return {
            unit: unit[0],
            units: unit[1].slice().sort(sortByDate),
            datePeriod: unit[1][0].sortDate
                ? moment(unit[1].slice()[0].sortDate).format('YYYY-MM-DD')
                : origPhotos.find((u) => u.unit === unit[0])?.datePeriod || ''
        }
    })
}

const aggregateDocs = (
    origDocs: DocUnitItem[],
    newDocs: DocumentItem[],
    time: MemoriesFilterType
): DocUnitItem[] => {
    return Object.entries(
        newDocs.reduce(
            (acc, doc) => ({
                ...acc,
                [time === 'daily'
                    ? doc.dayLabel
                    : time === 'monthly'
                    ? doc.monthLabel
                    : doc.yearLabel]: (
                    acc[
                        time === 'daily'
                            ? doc.dayLabel
                            : time === 'monthly'
                            ? doc.monthLabel
                            : doc.yearLabel
                    ] || ([] as DocumentItem[])
                ).concat(
                    [doc].filter((d) => {
                        return !(
                            acc[
                                time === 'daily'
                                    ? doc.dayLabel
                                    : time === 'monthly'
                                    ? doc.monthLabel
                                    : doc.yearLabel
                            ] || ([] as DocumentItem[])
                        ).find((memD) =>
                            d.memoryId ? memD.memoryId === d.memoryId : memD.id === d.id
                        )
                    })
                )
            }),
            (origDocs || []).reduce((acc, doc) => {
                const unit = updateDailyLabel(doc.unit, doc.units[0].sortDate)
                return { ...acc, [unit]: doc.units }
            }, {} as DocItemMap)
        )
    ).map((unit) => {
        return {
            unit: unit[0],
            units: unit[1].slice().sort(sortByDate),
            datePeriod: unit[1][0].sortDate
                ? moment(unit[1].slice()[0].sortDate).format('YYYY-MM-DD')
                : origDocs.find((u) => u.unit === unit[0])?.datePeriod || ''
        }
    })
}

const updateDailyLabel = (currentLabel: string, sortDate: Date): string => {
    return currentLabel.includes('Today,') || currentLabel.includes('Yesterday,')
        ? MemoryUtil.getDailyLabel(sortDate)
        : currentLabel
}
