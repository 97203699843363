<div class="report-content">
    <div class="title-container">
        <bh-text [text]="centerName"></bh-text>
        <bh-heading size="large" [text]="'documents.report' | translate"></bh-heading>
        <div class="date">
            <bh-text size="small" [text]="(date | date: 'longDate') || ''"></bh-text>
        </div>
    </div>

    <bh-backdrop
        *ngIf="dependent"
        [profilePicture]="getProfilePic(dependent.photoId | photo | async)"
        color="light-blue"
        [initials]="dependent.dependentInitials"
    >
        <div class="backdrop-content">
            <bh-heading
                class="name"
                [text]="dependent ? dependent.firstName + ' ' + dependent.lastName : ''"
            ></bh-heading>

            <div *ngIf="reminderList$ | async as reminderList" class="reminders">
                <bh-heading
                    *ngIf="reminderList.length > 0"
                    size="medium"
                    [text]="'home.reminders' | translate"
                ></bh-heading>

                <div *ngFor="let reminder of reminderList" [ngSwitch]="reminder.type">
                    <div class="calendar-tile">
                        <bh-calendar-tile
                            *ngSwitchCase="'please_bring'"
                            [header]="'calendar.bring' | translate"
                            icon="assets/Classroom.svg"
                            [description]="
                                reminder.pleaseBring
                                    ? (reminder.pleaseBring | join: ', ' | titlecase) +
                                      '.' +
                                      (reminder.note ? ' ' + reminder.note : '')
                                    : ''
                            "
                            type="child"
                        >
                        </bh-calendar-tile>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="activityFeed$ | async as activityFeed">
                <bh-grouped-activity
                    [dependentId]="dependentId"
                    [activityFeed]="activityFeed"
                    [dailyReportView]="true"
                    [showReaction]="false"
                ></bh-grouped-activity>
            </ng-container>
        </div>
    </bh-backdrop>
</div>
