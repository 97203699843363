import { ArrivalNoteStatusInfo } from 'projects/arrival-notes/src/lib/arrival-notes/child-notes/child-notes.component'
import { FoodAmountType } from './activity-feed'

export type BathroomType = 'diaper' | 'toilet'

export type BathroomEventType =
    | 'wet'
    | 'dry'
    | 'bowel movement'
    | 'bm'
    | 'diaper cream'
    | 'no diaper cream'
    | 'pee'
    | 'urine'
    | 'tried'
    | 'accident'
export type SleepType = 'overnight' | 'nap'
export type LiquidsType = 'bottle' | 'breastfeeding'
export type BottleType = 'breast milk' | 'formula' | 'milk' | 'other'
export type MealType = 'solids' | 'bottle' | 'breastfeeding'
export type FoodAmount = ''
export type ArrivalNotesLiquidTypeToggle = 'bottle' | 'breastfeeding'
export type ArrivalNotesLiquidType = 'breast milk' | 'formula' | 'milk' | 'other'

export class ArrivalNote {
    note_entry?: AdditionalNote
    nap_entry?: SleepNote
    meal_entry?: FoodNote[]
    bathroom_entry?: BathroomNote
    medication_entry?: MedicineNote
    contact_entries?: ContactNote[] = []

    constructor(data: any) {
        this.note_entry =
            (data.additionalNote || data.id) &&
            new AdditionalNote(data.additionalNote, data.type, data.id, data.isDeleted)
        this.meal_entry = data.liquids
            ? [new FoodNote(data.liquids)]
            : data.solids
            ? [new FoodNote(data.solids)]
            : undefined
        this.bathroom_entry = data.bathroom && new BathroomNote(data.bathroom)
        this.medication_entry = data.medication && new MedicineNote(data.medication)
        this.nap_entry = data.nap && new SleepNote(data.nap)
    }
}

export class SleepNote {
    note?: string
    entry_start_time?: Date | null
    entry_end_time?: Date | null
    reminder_delay? = 0
    capture_time?: string
    id?: string
    is_deleted?: boolean

    constructor(data: any) {
        this.capture_time = new Date().toISOString()

        const overnightIsFilled = data.wakeTime !== '' || data.overnightNotes !== ''

        const napIsFilled = data.startTime !== '' || data.endTime !== '' || data.napNotes !== ''

        const typeFilled =
            overnightIsFilled && napIsFilled
                ? data.sleepType
                : overnightIsFilled
                ? 'overnight'
                : 'nap'

        if (typeFilled === 'overnight') {
            this.entry_end_time = data.wakeTime || null
            this.entry_start_time = null
            this.note = data.overnightNotes
        } else {

            this.note = data.napNotes || ''
            this.entry_start_time = data.startTime || null
            this.entry_end_time = data.endTime || null
        }
        this.id = data.id
        this.is_deleted = data.isDeleted
    }
}

export class FoodNote {
    meal_type?: MealType
    food_name?: BottleType
    amount_offered?: string
    note?: string
    entry_time?: Date
    capture_time?: string
    foods_eaten?: FoodNoteItem[]
    foods_eaten_note?: string
    todays_food?: FoodNoteItem[]
    todays_food_note?: string
    id?: string
    is_deleted?: boolean

    constructor(data: {
        mealType?: MealType
        type?: BottleType
        amount?: string
        bottleNotes?: string
        breastfeedingNotes?: string
        entryTime?: Date
        foodsEaten?: FoodType[]
        foodsEatenNotes?: string
        todaysFood?: FoodType[]
        todaysFoodNotes?: string
        id?: string
        isDeleted?: boolean
    }) {
        this.meal_type = data.mealType
        this.food_name = data.type
        this.amount_offered = data.amount
        this.entry_time = data.entryTime ? data.entryTime : undefined
        this.note = this.meal_type === 'bottle' ? data.bottleNotes : data.breastfeedingNotes
        this.capture_time = new Date().toISOString()

        const filterFood = data.foodsEaten?.filter(
            (c) => (!!c.food && c.food.trim() !== '') || c.id
        )
        this.foods_eaten =
            filterFood && filterFood.length > 0
                ? filterFood.map((f) => this.formatFood('food', f))
                : []

        this.foods_eaten_note = data.foodsEatenNotes?.trim() !== '' ? data.foodsEatenNotes : ''

        const filterTodayFood = data.todaysFood?.filter(
            (c) => (!!c.food && c.food.trim() !== '') || c.id
        )
        this.todays_food =
            filterTodayFood && filterTodayFood.length > 0
                ? filterTodayFood.map((f) => this.formatFood('fooddrop', f))
                : []

        this.todays_food_note = data.todaysFoodNotes?.trim() !== '' ? data.todaysFoodNotes : ''
        this.id = data.id
        this.is_deleted = data.isDeleted
    }

    private formatFood(type: FoodNoteItemType, f: FoodType): FoodNoteItem {
        return type === 'food'
            ? {
                  amount_eaten: f.amount,
                  food_name: f.food,
                  meal_type: type,
                  id: f.id,
                  is_deleted: f.isDeleted
              }
            : {
                  amount_offered: f.amount,
                  food_name: f.food,
                  meal_type: type,
                  id: f.id,
                  is_deleted: f.isDeleted
              }
    }
}

export type FoodType = { food: string; amount: FoodAmountType; id: string; isDeleted: boolean }

export class BathroomNote {
    note?: string
    bathroom_type?: string
    bathroom_events?: BathroomEventType[]
    entry_time?: Date
    capture_time?: string
    id?: string
    is_deleted?: boolean

    constructor(data: {
        bathroomType?: BathroomType
        bathroomEvents?: BathroomEventType[]
        entryTime?: Date
        notes?: string
        id?: string
        isDeleted?: boolean
    }) {
        this.note = data.notes
        this.bathroom_type = data.bathroomType === 'toilet' ? 'potty' : 'diaper'
        this.bathroom_events = data.bathroomEvents?.map((e) =>
            e === 'urine' ? 'pee' : e === 'bowel movement' ? 'bm' : e
        )

        if (data.entryTime) {
            this.entry_time = data.entryTime
        }

        this.capture_time = new Date().toISOString()
        this.id = data.id
        this.is_deleted = data.isDeleted
    }
}

export class MedicineNote {
    received_medication?: boolean | null
    received_medication_time?: Date | null
    received_medication_id?: string | null
    received_medication_deleted?: boolean | null
    scheduled_medication?: boolean | null
    scheduled_medication_time?: Date
    scheduled_medication_id?: string | null
    scheduled_medication_deleted?: boolean | null
    insect_repellent_applied?: boolean | null
    insect_repellent_applied_time?: Date
    insect_repellent_applied_id?: string | null
    insect_repellent_applied_deleted?: boolean | null
    sunscreen_applied?: boolean | null
    sunscreen_applied_time?: Date
    sunscreen_applied_id?: string | null
    sunscreen_applied_deleted?: boolean | null
    note?: string
    note_id?: string | null
    note_deleted?: boolean | null
    capture_time?: string

    constructor(data: {
        receivedMedication?: boolean
        receivedMedicationTime?: Date
        receivedMedicationId?: string
        receivedMedicationDeleted?: boolean
        scheduledMedication?: boolean
        scheduledMedicationTime?: Date
        scheduledMedicationId?: string
        scheduledMedicationDeleted?: boolean
        insectRepellentApplied?: boolean
        insectRepellentAppliedTime?: Date
        insectRepellentAppliedId?: string
        insectRepellentAppliedDeleted?: boolean
        sunscreenApplied?: boolean
        sunscreenAppliedTime?: Date
        sunscreenAppliedId?: string
        sunscreenAppliedDeleted?: boolean
        notes?: string
        medicineNotesId?: string
        noteDeleted: boolean
    }) {
        this.received_medication = data.receivedMedication
        this.received_medication_time = data.receivedMedicationTime || undefined
        this.received_medication_id = data.receivedMedicationId || undefined
        this.received_medication_deleted = data.receivedMedicationId
            ? data.receivedMedicationDeleted
            : undefined

        this.scheduled_medication = data.scheduledMedication
        this.scheduled_medication_time = data.scheduledMedicationTime || undefined
        this.scheduled_medication_id = data.scheduledMedicationId || undefined
        this.scheduled_medication_deleted = data.scheduledMedicationId
            ? data.scheduledMedicationDeleted
            : undefined

        this.insect_repellent_applied = data.insectRepellentApplied
        this.insect_repellent_applied_time = data.insectRepellentAppliedTime || undefined
        this.insect_repellent_applied_id = data.insectRepellentAppliedId || undefined
        this.insect_repellent_applied_deleted = data.insectRepellentAppliedId
            ? data.insectRepellentAppliedDeleted
            : undefined

        this.sunscreen_applied = data.sunscreenApplied
        this.sunscreen_applied_time = data.sunscreenAppliedTime || undefined
        this.sunscreen_applied_id = data.sunscreenAppliedId || undefined
        this.sunscreen_applied_deleted = data.sunscreenAppliedId
            ? data.sunscreenAppliedDeleted
            : undefined

        this.note = data.notes
        this.note_id = data.medicineNotesId || undefined
        this.note_deleted = data.medicineNotesId ? data.noteDeleted : undefined
        this.capture_time = new Date().toISOString()
    }
}

export class AdditionalNote {
    type: string
    note?: string
    capture_time: string = new Date().toISOString()
    id?: string
    is_deleted?: boolean

    constructor(note: string, type: string, id?: string, isDeleted?: boolean) {
        this.note = note
        this.type = type
        this.id = id
        this.is_deleted = isDeleted
    }
}

export class ContactNote {
    capture_time: string = new Date().toISOString()
    type?: string
    question?: string
    question_name?: string
    answer?: string
    answer_type?: string
    id?: string
    is_deleted?: boolean

    constructor(data: {
        type?: string
        question?: string
        questionName?: string
        answer?: string
        answerType?: string
        id?: string
        isDeleted?: boolean
    }) {
        this.type = data.type
        this.question = data.question
        this.question_name = data.questionName
        this.answer = data.answer
        this.answer_type = data.answerType
        this.id = data.id ?? undefined
        this.is_deleted = data.isDeleted
    }
}

export type FoodNoteItemType = 'food' | 'fooddrop'

export class FoodNoteItem {
    meal_type: FoodNoteItemType
    food_name: string
    amount_offered?: FoodAmountType
    amount_eaten?: FoodAmountType
    id?: string
    is_deleted?: boolean

    constructor(
        mealType: FoodNoteItemType,
        name: string,
        amount: FoodAmountType,
        id: string,
        isDeleted: boolean
    ) {
        this.meal_type = mealType
        this.food_name = name
        this.amount_offered = amount
        this.amount_eaten = amount
        this.id = id
        this.is_deleted = isDeleted
    }
}

export type ArrivalNoteStatusMap = { [dependentId: string]: ArrivalNoteStatusInfo }

export class PreviousNoteEntry {
    date: Date
    note: string

    constructor(date: Date, note: string) {
        this.date = date
        this.note = note
    }
}

export class PreviousMealEntry {
    meal: string

    constructor(meal: string) {
        this.meal = meal
    }
}

export enum ArrivalNoteKeys {
    arrivalNotes = 'arrivalNotes',
    arrivalMeals = 'arrivalMeals'
}

export interface IArrivalNotePreviousListMap {
    [key: string]: {
        [key: string]: PreviousNoteEntry[] | PreviousMealEntry[]
    }
}

export interface IArrivalNotePreviousItem {
    bottleNotes: boolean
    breastfeedingNotes: boolean
    napNotes: boolean
    overnightNotes: boolean
    diaperNotes: boolean
    toiletNotes: boolean
    foodsEatenNotes: boolean
    foodsEaten: boolean
    todaysFoodNotes: boolean
    todaysFood: boolean
    medicineNotes: boolean
    notes: boolean
}

export enum ArrivalNotesSections {
    bottleNotes = 'bottleNotes',
    breastfeedingNotes = 'breastfeedingNotes',
    napNotes = 'napNotes',
    overnightNotes = 'overnightNotes',
    diaperNotes = 'diaperNotes',
    toiletNotes = 'toiletNotes',
    foodsEatenNotes = 'foodsEatenNotes',
    foodsEaten = 'foodsEaten',
    todaysFoodNotes = 'todaysFoodNotes',
    todaysFood = 'todaysFood',
    medicineNotes = 'medicineNotes',
    notes = 'notes'
}

export class EntryTimes {
    napEntries: string[]
    mealEntries: string[]
    bathroomEntries: string[]
    medicationEntries: string[]
    liquidEntries: string[]

    constructor(data: any) {
        this.napEntries = data.nap_entries
        this.mealEntries = data.meal_entries
        this.bathroomEntries = data.bathroom_entries
        this.medicationEntries = data.medication_entries
        this.liquidEntries = data.liquid_entries
    }
}
