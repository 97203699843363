import { ActionReducer, createReducer, on } from '@ngrx/store'
import { activityDateSelected, updateScrollDirection, updateScrollPosition } from './nav.actions'

export const navFeatureKey = 'nav2'

export interface NavState {
    position: number
    isScrolledUp: boolean
}

const initialState: NavState = {
    position: 0,
    isScrolledUp: false
}

export const NavReducer: ActionReducer<NavState> = createReducer(
    initialState,
    on(updateScrollPosition, (state: NavState, { position }) => {
        return { ...state, position }
    }),
    on(activityDateSelected, (state: NavState, { date }) => {
        return { ...state, currentDate: date }
    }),
    on(updateScrollDirection, (state: NavState, { isScrolledUp }) => {
        return { ...state, isScrolledUp }
    })
)
