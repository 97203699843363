import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Platform } from '@ionic/angular'

@Injectable({
    providedIn: 'root'
})
export class HeaderGuard {
    private body!: HTMLBodyElement
    constructor(@Inject(DOCUMENT) private document: Document, private platform: Platform) {
        this.body = this.document.querySelectorAll('body')[0] as HTMLBodyElement
    }

    canActivate(route: ActivatedRouteSnapshot) {
        const { headerColor } = route.data as HeaderData
        const isAndroid = /Android/.test(window.navigator.userAgent)

        switch (headerColor) {
            case 'white': {
                this.body.classList.replace('blue-header', 'white-header')
                this.setStatusBarStyle(isAndroid ? Style.Dark : Style.Light)
                break
            }
            case 'blue': {
                this.body.classList.replace('white-header', 'blue-header')
                this.setStatusBarStyle(Style.Dark)
                break
            }
        }
        return true
    }

    private setStatusBarStyle(style: Style): void {
        if (this.platform.is('capacitor')) {
            StatusBar.setStyle({ style })
        }
    }
}

export type HeaderColorType = 'white' | 'blue'

export interface HeaderData {
    headerColor: HeaderColorType
}
