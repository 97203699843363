import { Injectable } from '@angular/core'
import { FullstoryService } from '@bh/logging'
import { Network } from '@capacitor/network'
import { CapacitorJailbreakRootDetection } from '@evehr/capacitor-jailbreak-root-detection'
import { loadDependantByDate, Profile, selectDependantListLength, selectProfile } from '@events'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { combineLatest, from, Observable } from 'rxjs'
import { defaultIfEmpty, filter, map, switchMap, take } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class ShellGuard {
    constructor(private store: Store, private fullStoryService: FullstoryService) {}

    canActivate(): Observable<boolean> {
        return from(Network.getStatus()).pipe(
            take(1),
            filter((status) => status.connected),
            switchMap(() => {
                combineLatest([
                    from(CapacitorJailbreakRootDetection.isJailbrokenOrRooted()),
                    this.getProfile()
                ]).pipe(take(1)).subscribe(([obj, profile]) => {
                        const guardianId = profile.id
                        if (obj.result) {
                            this.fullStoryService.sendEvent('using-rooted-device', { guardianId })
                        }
                        this.store.dispatch(
                            loadDependantByDate({ guardianId, date: moment().toDate() })
                        )
                    })
                return this.hasChildList().pipe(filter((val) => !!val))
            }),
            defaultIfEmpty(true)
        )
    }

    private getProfile(): Observable<Profile> {
        return this.store.select(selectProfile).pipe(
            filter((profile) => !!profile),
            map((profile) => profile as Profile)
        )
    }

    private hasChildList(): Observable<boolean> {
        return this.store.select(selectDependantListLength).pipe(map((len) => len > 0))
    }
}
