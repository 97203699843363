import { ActionReducer, createReducer, on } from '@ngrx/store'
import { Profile } from '../models/profile'
import { loadAccountMetaDataSuccess, loadProfileSuccess } from './profile.actions'
import { AccountMetaData } from '../models/account-meta-data'

export const profileFeatureKey = 'profile'

export interface ProfileState {
    profile?: Profile
    accountMetaData?: AccountMetaData
}

const initialState: ProfileState = {
    profile: undefined,
    accountMetaData: undefined
}

export const ProfileReducer: ActionReducer<ProfileState> = createReducer(
    initialState,
    on(loadProfileSuccess, (state: ProfileState, { profile }) => ({ ...state, profile })),
    on(loadAccountMetaDataSuccess, (state: ProfileState, { metaData }) => ({
        ...state,
        accountMetaData: metaData
    }))
)
