import { ActionReducer, createReducer, on } from '@ngrx/store'
import { QuestionnaireCenterBeenSubmittedTodayMap, QuestionnaireCenterMap } from '../models/questionnaire'
import { QuestionnaireResponseMap } from '../models/questionnaire-response'
import {
    healthCheckReminderEmpty,
    healthCheckReminderInteraction,
    healthCheckReminderInteractionCleared,
    loadHealthCheckQuestionnaireResponseSuccess,
    loadHealthCheckQuestionnaireSuccess,
    ShouldRemindHealthCheckType,
    setHasBeenSubmittedToday
} from './health-check.actions'

export const healthCheckFeatureKey = 'health-check'

export interface HealthCheckState {
    questionnaireMap: QuestionnaireCenterMap
    questionnaireResponseMap: QuestionnaireResponseMap
    shouldRemindHealthCheck: ShouldRemindHealthCheckType
    hasBeenSubmittedToday: QuestionnaireCenterBeenSubmittedTodayMap[]
}

const initialState: HealthCheckState = {
    questionnaireMap: {},
    questionnaireResponseMap: {},
    shouldRemindHealthCheck: ShouldRemindHealthCheckType.DO_NOT_VALIDATE,
    hasBeenSubmittedToday: []
}

export const HealthCheckReducer: ActionReducer<HealthCheckState> = createReducer(
    initialState,
    on(
        loadHealthCheckQuestionnaireSuccess,
        (state: HealthCheckState, { questionnaire, centerId }) => ({
            ...state,
            questionnaireMap: {
                ...state.questionnaireMap,
                [centerId]: questionnaire
            }
        })
    ),
    on(
        loadHealthCheckQuestionnaireResponseSuccess,
        (state: HealthCheckState, { questionnaireId, centerID, questionnaireResponse }) => ({
            ...state,
            questionnaireResponseMap: {
                ...state.questionnaireResponseMap,
                [centerID + questionnaireId]: questionnaireResponse
            }
        })
    ),
    on(healthCheckReminderInteraction, (state: HealthCheckState) => {
        return {
            ...state,
            shouldRemindHealthCheck: state.shouldRemindHealthCheck + 1
        }
    }),
    on(healthCheckReminderInteractionCleared, (state: HealthCheckState) => {
        return {
            ...state,
            shouldRemindHealthCheck: ShouldRemindHealthCheckType.DO_NOT_VALIDATE
        }
    }),
    on(healthCheckReminderEmpty, (state: HealthCheckState) => {
        return {
            ...state,
            shouldRemindHealthCheck: ShouldRemindHealthCheckType.DO_NOT_VALIDATE
        }
    }),
    on(setHasBeenSubmittedToday, (state: HealthCheckState, { centerId, submitted }) => {
        const removed = state.hasBeenSubmittedToday.filter((a) => !a[centerId] )
        const updated = removed.concat({ [centerId]: submitted })
        
        return {
            ...state,
            hasBeenSubmittedToday: updated
        }
    })
)
