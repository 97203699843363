export class Reminder {
    personId: number
    personName: string
    centerName: string
    clientId: number
    clientName: string
    reminderTypeId: string
    reminderTypeName: string
    reminderCenterName: string
    reminderTypeNotificationName: string
    isBillingReminder: boolean
    reminderTitle: string
    reminderMessage: string
    reminderId: number
    reminderActions: reminderAction[]
    sortOrder: number

    constructor(data: any) {
        this.personId = data.personId
        this.personName = data.personName
        this.centerName = data.centerName
        this.clientId = data.clientId
        this.clientName = data.clientName
        this.reminderTypeId = data.reminderTypeId
        this.reminderTypeName = data.reminderTypeName
        this.reminderCenterName = data.reminderCenterName
        this.reminderTypeNotificationName = Reminder.setNotificationType(
            data.reminderTypeId,
            data.reminderMessage
        )
        this.isBillingReminder = data.isBillingReminder
        this.reminderTitle = data.reminderTitle
        this.reminderMessage = data.reminderMessage
        this.reminderId = data.reminderId
        this.reminderActions = data.reminderActions
        this.sortOrder = data.sortOrder
    }

    private static setNotificationType(typeReminder: number, message: string): string {
        let type = ''
        switch (typeReminder) {
            case 7:
                message.includes('next')
                    ? (type = 'upcoming_draft')
                    : (type = 'recurring_draft')
                break
            case 4:
                type = 'payment_overdue'
                break
        }

        return type
    }
}

export type reminderAction = {
    actionTypeId: number
    actionName: string
}
