import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DesignSystemModule } from '@bh/design-system'
import { IonicModule } from '@ionic/angular'
import { EffectsModule } from '@ngrx/effects'
import { TranslateModule } from '@ngx-translate/core'
import { AuthEffects } from './events/auth.effects'
import { LoginRoutingModule } from './login-routing.module'
import { LoginComponent } from './login.component'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@shared'

@NgModule({
    declarations: [LoginComponent],
    imports: [
        LoginRoutingModule,
        IonicModule.forRoot(),
        DesignSystemModule,
        FormsModule,
        ReactiveFormsModule,
        EffectsModule.forFeature([AuthEffects]),
        TranslateModule,
        SharedModule,
        CommonModule
    ],
    providers: [],
    exports: [LoginComponent]
})
export class LoginModule {}
