import { createAction, props } from '@ngrx/store'

export const logoutNavigationCompleted = createAction('[Settings] Logout navigation completed')

export const navigateToItem = createAction(
    '[Settings] Settings item navigation',
    props<{ item: string }>()
)

export const pushNavigationCompleted = createAction(
    '[Settings] Push notification navigation completed'
)
