<ng-container *ngIf="activity" [ngSwitch]="activity.activityType">
    <ng-container *ngIf="dependent$ | async as dependent">
        <div *ngSwitchCase="'pickup'"
        >
        <bh-cards
            [id]="activity.id"
            [ngClass]="{ 'timeline-activity-card' : isTimelineView }"
            [attachmentDescription]="('home.pickup' | translate) + ' ' + ('home.atDate' | translate: { value: (activity.entryTime | date: 'h:mm aaa')})"
            [isActivityFeed]="true"
            [timelineViewTitle]="isTimelineView ? ('arrival.title' | translate) : ''"
            icon="assets/arrival-pickup.svg"
            [hasAttachment]="true"
        >
        </bh-cards>
        </div>
        <bh-cards
            *ngSwitchCase="'pleaseBring'"
            [id]="activity.id"
            [ngClass]="{ 'timeline-activity-card' : isTimelineView }"            
            icon="assets/teacher-notes.svg"
            [title]="'home.pleaseBring' | translate"
            [attachmentDescription]="
                $any(activity).pleaseBring
                    ? ($any(activity).pleaseBring | join: ', ' | titlecase) +
                    '.'
                    : ''
            "
            [supportingText]="activity.note"
            [hasAttachment]="true"
            [date]="activity.entryTime"
            [isActivityFeed]="true"
            [timelineViewTitle]="isTimelineView ? ('activity-feed.notes' | translate) : ''"
        >
        </bh-cards>
        <bh-cards
            [id]="activity.id"
            *ngSwitchCase="'snapshot'"
            icon="assets/snapshot.svg"
            [supportingText]="activity.note"
            [useGallery]="$any(activity).attachment"
            [date]="activity.entryTime"
            [isActivityFeed]="true"
            [timelineViewTitle]="isTimelineView ? ('home.snapshot' | translate) : ''"
            [ngClass]="'note-without-bullet' + (isTimelineView ? ' timeline-activity-card' : '')"
            [showDefaultNote]="true"
        >
            <bh-memories-media
                [id]="$any(activity).attachment"
                [src]="$any(activity).attachment"
                clickAction="dispatch"
                (click)="selectMedia($any(activity))"
                [thumbnail]="dependent.photoId || ''"
                [mediaAltTextList]="mediaAltTextList"
                [useThumbnailForVideo]="true"
                [dependentId]="dependentId"
                [showReaction]="showReaction"
            >
            </bh-memories-media>
        </bh-cards>
        <bh-cards
            [id]="activity.id"
            [ngClass]="{ 'timeline-activity-card' : isTimelineView }"
            *ngSwitchCase="'witwht'"
            [icon]="getWitwhtImg($any(activity).subjects)"
            [title]="$any(activity).subjects && $any(activity).subjects.length > 0 ? $any(activity).subjects : ''"
            [attachmentDescription]="activity.note"
            [textElements]="$any(activity).textElements"
            [hasAttachment]="true"
            [tiledView]="dailyReportView && $any(activity).snapshots.length > 1"
            [useGallery]="$any(activity).snapshots.length > 0"
            [useInternalTemplate]="$any(activity).snapshots.length > 0"
            [snapshots]="$any(activity).snapshots"
            [photoId]="dependent.photoId || ''"
            [mediaAltTextList]="mediaAltTextList"
            [date]="activity.entryTime"
            (slideClick)="selectMedia(
                $event,
                $any(activity).subjects,
                activity.note
            )"
            [isActivityFeed]="true"
            [timelineViewTitle]="isTimelineView ? ('home.witwht' | translate) : ''"
            [dependentId]="dependentId"
            [showReaction]="showReaction"

        >
        </bh-cards>
        <bh-cards
            [id]="activity.id"
            *ngSwitchCase="'observation'"
            [ngClass]="'note-without-bullet' + (isTimelineView ? ' timeline-activity-card' : '')"
            icon="assets/boxes.svg"
            [supportingText]="activity.note"
            [multiAttachment]="$any(activity).selections | sortMultiAttachment"
            [hasAttachment]="true"
            [useGallery]="$any(activity).attachmentId"
            [isActivityFeed]="true"
            [showDefaultNote]="true"
            [timelineViewTitle]="isTimelineView ? ('home.developmental-observation' | translate) : ''"
        >
            <bh-memories-media
                [id]="$any(activity).attachmentId"
                [src]="$any(activity).attachmentId"
                clickAction="dispatch"
                (click)="selectMedia($any(activity))"
                [thumbnail]="dependent.photoId || ''"
                [mediaAltTextList]="mediaAltTextList"
                [useThumbnailForVideo]="true"
                [dependentId]="dependentId"
                [showReaction]="showReaction"
            >
            </bh-memories-media>
        </bh-cards>
        <ng-container *ngSwitchCase="'learning'">
            <bh-cards
                [ngClass]="{ groupLearningCards : !isTimelineView }"
                *ngFor="let exp of $any(activity).experiences"
                [id]="activity.id"
                icon="assets/learning-at-home.svg"
                class="alignLeft"
                [title]="'home.learning' | translate"
                [activityTitle]="exp.title"
                [activityDetails]="[{title: 'home.try-this' | translate, description: exp.description}, {title: 'home.learning-at-home-domains' | translate, description: exp.domain}]"
                [activitySubTitle]="'home.more-information' | translate"
                [linkElements]="exp.linkElements"
                [type]="isTimelineView ? 'individual' : 'grouped'"
            ></bh-cards>
        </ng-container>
        <ng-container *ngSwitchCase="'note'">
            <div [ngClass]="{ 'timeline-activity-card' : isTimelineView }">
                <bh-cards
                    [id]="activity.id"
                    *ngIf="!activity.isFromParent"
                    icon="assets/teacher-notes.svg"
                    [title]="('home.teacher-notes' | translate)"
                    [hasAttachment]="true"
                    [attachmentDescription]="activity.note"
                    [date]="activity.entryTime"
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('activity-feed.notes' | translate) : ''"
                >
                </bh-cards>
                <bh-cards
                    [id]="activity.id"
                    *ngIf="activity.isFromParent && $any(activity).type !== 'question'"
                    icon="assets/teacher-notes.svg"
                    [title]="('home.family-note' | translate)"
                    [subtitle]="('home.my-entry' | translate)"
                    [hasAttachment]="true"
                    [attachmentDescription]="activity.note"
                    [date]="activity.entryTime"
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('activity-feed.notes' | translate) : ''"
                    [isFromParent]="true"
                >
                </bh-cards>
                <bh-cards
                    [id]="activity.id"
                    *ngIf="
                        activity.isFromParent &&
                        $any(activity).type === 'question' &&
                        $any(activity).blockAttachmentEntry
                    "
                    icon="assets/teacher-notes.svg"
                    [title]="('home.today-contact' | translate)"
                    [subtitle]="
                        activity.isFromParent
                            ? ('home.my-entry' | translate)
                            : activity.createdBy
                            ? 'by ' + activity.createdBy
                            : ''
                    "
                    [blockAttachmentEntry]="$any(activity).blockAttachmentEntry"
                    [hasAttachment]="true"
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('activity-feed.notes' | translate) : ''"
                    [isFromParent]="true"
                >
                </bh-cards>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'meal'">
            <div [ngClass]="isTimelineView ? 'timeline-activity-card' : ''">
            <ng-container *ngIf="$any(activity).isFromParent">
                <bh-cards
                    [id]="activity.id"
                    *ngIf="
                        $any(activity).type !== 'Bottle' && $any(activity).type !== 'Breastfeeding'
                    "
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.meals' | translate) : ''"
                    icon="assets/Meal.svg"
                    [class.multi-food-meals]="$any(activity).multipleFoods"
                    [title]="$any(activity).type === 'Fooddrop'
                    ? ('home.food-provided' | translate)
                    : ('home.last-meal' | translate)"
                    [subtitle]="'home.my-entry' | translate"
                    [supportingText]="activity.note"
                    [attachmentDescription]="
                        $any(activity).multipleFoods
                            ? $any(activity).multipleFoodsNote
                            : $any(activity).offered && $any(activity).food
                            ? $any(activity).food
                            : $any(activity).eaten && $any(activity).food
                            ? ($any(activity).eaten | titlecase) + ' of the ' + $any(activity).food
                            : $any(activity).food
                    "
                    [hasAttachment]="true"
                    [showDefaultNote]="
                        !($any(activity).multipleFoods ||
                            $any(activity).offered && $any(activity).food ||
                            $any(activity).eaten && $any(activity).food || $any(activity).food)
                    "
                    [class.note-without-bullet]="
                        !($any(activity).multipleFoods ||
                                $any(activity).offered && $any(activity).food ||
                                $any(activity).eaten && $any(activity).food || $any(activity).food)
                    "
                    [date]="activity.entryTime"
                    [isFromParent]="$any(activity).isFromParent"
                >
                </bh-cards>
                <bh-cards
                    [id]="activity.id"
                    *ngIf="$any(activity).type === 'Bottle'"
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.meals' | translate) : ''"
                    icon="assets/Meal.svg"
                    [title]="($any(activity).type | titlecase)
                    "
                    [subtitle]="'home.my-entry' | translate"
                    [supportingText]="activity.note"
                    [attachmentDescription]="
                        $any(activity).eaten &&
                        $any(activity).offered &&
                        $any(activity).eaten !== 'none' &&
                        $any(activity).offered !== 'none'
                            ? ('home.bottle-amount'
                              | translate
                                  : {
                                        foodContent: $any(activity).food ? ($any(activity).food | titlecase) + ',' : '',
                                        amountOffered: $any(activity).offered,
                                        amountTaken: $any(activity).eaten
                                    })
                            : $any(activity).offered && $any(activity).offered !== 'none'
                            ? ('home.amount-taken-only'
                              | translate: 
                                { 
                                    foodContent: $any(activity).food ? ($any(activity).food | titlecase) + ',' : '',
                                    amountTaken: $any(activity).offered 
                                })
                            : ($any(activity).food | titlecase)
                    "
                    [hasAttachment]="true"
                    [showDefaultNote]="
                        !(($any(activity).eaten &&
                        $any(activity).offered &&
                        $any(activity).eaten !== 'none' &&
                        $any(activity).offered !== 'none') ||
                        ($any(activity).offered && $any(activity).offered !== 'none') || $any(activity).food)
                    "
                    [class.note-without-bullet]="
                        !(($any(activity).eaten &&
                        $any(activity).offered &&
                        $any(activity).eaten !== 'none' &&
                        $any(activity).offered !== 'none') ||
                        ($any(activity).offered && $any(activity).offered !== 'none') || $any(activity).food)
                    "
                    [date]="activity.entryTime"
                    [isFromParent]="$any(activity).isFromParent"
                >
                </bh-cards>
                <bh-cards
                    [id]="activity.id"
                    *ngIf="$any(activity).type === 'Breastfeeding'"
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.meals' | translate) : ''"
                    icon="assets/Meal.svg"
                    [title]="($any(activity).type | titlecase)"
                    [subtitle]="'home.my-entry' | translate"
                    [showDefaultNote]="$any(activity).note"
                    [hasAttachment]="true"
                    [date]="activity.entryTime"
                    [isFromParent]="$any(activity).isFromParent"
                    class="note-without-bullet"
                    [supportingText]="activity.note"
                >
                </bh-cards>
            </ng-container>
            <ng-container *ngIf="!$any(activity).isFromParent">
                <bh-cards
                    [id]="activity.id"
                    class="multi-food-meals"
                    *ngIf="
                        $any(activity).multipleFoods &&
                        $any(activity).type === 'Food' &&
                        ($any(activity).name | sortFoodCard) === 'default'
                    "
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.meals' | translate) : ''"
                    icon="assets/Meal.svg"
                    [title]="$any(activity).name ? ($any(activity).name | titlecase) : ($any(activity).type | titlecase)
                "
                    [subtitle]="activity.createdBy ? 'by ' + activity.createdBy : ''"
                    [supportingText]="activity.note"
                    [attachmentDescription]="$any(activity).multipleFoodsNote"
                    [date]="activity.entryTime"
                    [hasAttachment]="true"
                    [showDefaultNote]="!$any(activity).multipleFoodsNote"
                    [class.note-without-bullet]="!$any(activity).multipleFoodsNote"
                    [isFromParent]="$any(activity).isFromParent"
                >
                </bh-cards>
                <bh-cards
                    [id]="activity.id"
                    class="multi-food-meals"
                    *ngIf="
                        $any(activity).multipleFoods &&
                        $any(activity).type === 'Food' &&
                        ($any(activity).name | sortFoodCard) === 'snack'
                    "
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.meals' | translate) : ''"
                    icon="assets/Meal.svg"
                    [title]="$any(activity).name ? $any(activity).name : ($any(activity).type | titlecase)"
                    [subtitle]="activity.createdBy ? 'by ' + activity.createdBy : ''"
                    [supportingText]="activity.note"
                    [attachmentDescription]="$any(activity).multipleFoodsNote"
                    [date]="activity.entryTime"
                    [hasAttachment]="true"
                    [showDefaultNote]="!$any(activity).multipleFoodsNote"
                    [class.note-without-bullet]="!$any(activity).multipleFoodsNote"
                    [isFromParent]="$any(activity).isFromParent"
                >
                </bh-cards>
                <bh-cards
                    [id]="activity.id"
                    class="multi-food-meals"
                    *ngIf="
                        $any(activity).multipleFoods &&
                        $any(activity).type === 'Food' &&
                        ($any(activity).name | sortFoodCard) === 'breakfast'
                    "
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.meals' | translate) : ''"
                    icon="assets/Meal.svg"
                    [title]="$any(activity).name ? ($any(activity).name | titlecase) : ($any(activity).type | titlecase)"
                    [subtitle]="activity.createdBy ? 'by ' + activity.createdBy : ''"
                    [supportingText]="activity.note"
                    [attachmentDescription]="$any(activity).multipleFoodsNote"
                    [date]="activity.entryTime"
                    [hasAttachment]="true"
                    [showDefaultNote]="!$any(activity).multipleFoodsNote"
                    [class.note-without-bullet]="!$any(activity).multipleFoodsNote"
                    [isFromParent]="$any(activity).isFromParent"
                >
                </bh-cards>
                <bh-cards
                    [id]="activity.id"
                    *ngIf="
                        !$any(activity).multipleFoods &&
                        $any(activity).type === 'Food' &&
                        ($any(activity).name | sortFoodCard) === 'default'
                    "
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.meals' | translate) : ''"
                    icon="assets/Meal.svg"
                    [title]="$any(activity).name ? ($any(activity).name | titlecase) : ($any(activity).type | titlecase)
                    "
                    [subtitle]="activity.createdBy ? 'by ' + activity.createdBy : ''"
                    [supportingText]="activity.note"
                    [attachmentDescription]="
                        $any(activity).eaten && $any(activity).food
                            ? ($any(activity).eaten | titlecase) + ' of the ' + $any(activity).food
                            : $any(activity).eaten ? ($any(activity).eaten | titlecase) : $any(activity).food
                    "
                    [date]="activity.entryTime"
                    [hasAttachment]="true"
                    [showDefaultNote]="!($any(activity).eaten || $any(activity).food)"
                    [class.note-without-bullet]="!($any(activity).eaten || $any(activity).food)"
                    [isFromParent]="$any(activity).isFromParent"
                >
                </bh-cards>
                <bh-cards
                    [id]="activity.id"
                    *ngIf="
                        !$any(activity).multipleFoods &&
                        $any(activity).type === 'Food' &&
                        ($any(activity).name | sortFoodCard) === 'snack'
                    "
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.meals' | translate) : ''"
                    icon="assets/Meal.svg"
                    [title]="$any(activity).name ? $any(activity).name : ($any(activity).type | titlecase)"
                    [subtitle]="activity.createdBy ? 'by ' + activity.createdBy : ''"
                    [supportingText]="activity.note"
                    [attachmentDescription]="
                        $any(activity).eaten && $any(activity).food
                            ? ($any(activity).eaten | titlecase) + ' of the ' + $any(activity).food
                            : $any(activity).eaten ? ($any(activity).eaten | titlecase) : $any(activity).food
                    "
                    [date]="activity.entryTime"
                    [hasAttachment]="true"
                    [showDefaultNote]="!($any(activity).eaten || $any(activity).food)"
                    [class.note-without-bullet]="!($any(activity).eaten || $any(activity).food)"
                    [isFromParent]="$any(activity).isFromParent"
                >
                </bh-cards>
                <bh-cards
                    [id]="activity.id"
                    *ngIf="
                        !$any(activity).multipleFoods &&
                        $any(activity).type === 'Food' &&
                        ($any(activity).name | sortFoodCard) === 'breakfast'
                    "
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.meals' | translate) : ''"
                    icon="assets/Meal.svg"
                    [title]="$any(activity).name ? ($any(activity).name | titlecase) : ($any(activity).type | titlecase)"
                    [subtitle]="activity.createdBy ? 'by ' + activity.createdBy : ''"
                    [supportingText]="activity.note"
                    [attachmentDescription]="
                        $any(activity).eaten && $any(activity).food
                            ? ($any(activity).eaten | titlecase) + ' of the ' + $any(activity).food
                            : $any(activity).eaten ? ($any(activity).eaten | titlecase) : $any(activity).food
                    "
                    [date]="activity.entryTime"
                    [hasAttachment]="true"
                    [showDefaultNote]="!($any(activity).eaten || $any(activity).food)"
                    [class.note-without-bullet]="!($any(activity).eaten || $any(activity).food)"
                    [isFromParent]="$any(activity).isFromParent"
                >
                </bh-cards>
                <bh-cards
                    [id]="activity.id"
                    *ngIf="$any(activity).type === 'Fooddrop'"
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.meals' | translate) : ''"
                    icon="assets/Meal.svg"
                    [title]="($any(activity).name | titlecase)"
                    [subtitle]="activity.createdBy ? 'by ' + activity.createdBy : ''"
                    [supportingText]="activity.note"
                    [attachmentDescription]="
                        $any(activity).eaten && $any(activity).food
                            ? ($any(activity).eaten | titlecase) + ' of the ' + $any(activity).food
                            : $any(activity).food
                    "
                    [date]="activity.entryTime"
                    [hasAttachment]="true"
                    [showDefaultNote]="!($any(activity).eaten && $any(activity).food || $any(activity).food)"
                    [class.note-without-bullet]="!($any(activity).eaten && $any(activity).food || $any(activity).food)"
                    [isFromParent]="$any(activity).isFromParent"
                >
                </bh-cards>
                <bh-cards
                    [id]="activity.id"
                    *ngIf="$any(activity).type === 'Bottle'"
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.meals' | translate) : ''"
                    icon="assets/Meal.svg"
                    [title]="($any(activity).type | titlecase)
                    "
                    [subtitle]="activity.createdBy ? 'by ' + activity.createdBy : ''"
                    [supportingText]="activity.note"
                    [attachmentDescription]="
                        $any(activity).eaten &&
                        $any(activity).offered &&
                        $any(activity).eaten !== 'none' &&
                        $any(activity).offered !== 'none'
                            ? ('home.bottle-amount'
                              | translate
                                  : {
                                        foodContent: $any(activity).food ? ($any(activity).food | titlecase) + ',' : '',
                                        amountOffered: $any(activity).offered,
                                        amountTaken: $any(activity).eaten
                                    })
                            : $any(activity).eaten && $any(activity).eaten !== 'none'
                            ? ('home.amount-taken-only'
                              | translate: 
                                { 
                                    foodContent: $any(activity).food ? ($any(activity).food | titlecase) + ',' : '',
                                    amountTaken: $any(activity).eaten
                                })
                            : $any(activity).offered && $any(activity).offered !== 'none'
                            ? ('home.amount-offered-only'
                              | translate: 
                                { 
                                    foodContent: $any(activity).food ? ($any(activity).food | titlecase) + ',' : '',
                                    amountOffered: $any(activity).offered
                                })
                            : ($any(activity).food | titlecase)
                    "
                    [date]="activity.entryTime"
                    [hasAttachment]="true"
                    [showDefaultNote]="
                        !(($any(activity).eaten &&
                        $any(activity).offered &&
                        $any(activity).eaten !== 'none' &&
                        $any(activity).offered !== 'none') ||
                        ($any(activity).offered && $any(activity).offered !== 'none') || $any(activity).food)
                    "
                    [class.note-without-bullet]="
                        !(($any(activity).eaten &&
                        $any(activity).offered &&
                        $any(activity).eaten !== 'none' &&
                        $any(activity).offered !== 'none') ||
                        ($any(activity).offered && $any(activity).offered !== 'none') || $any(activity).food)
                    "
                    [isFromParent]="$any(activity).isFromParent"
                >
                </bh-cards>
                <bh-cards
                    [id]="activity.id"
                    *ngIf="$any(activity).type === 'Breastfeeding'"
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.meals' | translate) : ''"
                    icon="assets/Meal.svg"
                    [title]="($any(activity).type | titlecase)"
                    [subtitle]="activity.createdBy ? 'by ' + activity.createdBy : ''"
                    [supportingText]="activity.note"
                    [attachmentDescription]="
                        $any(activity).eaten && $any(activity).food
                            ? ('home.bottle-amount'
                              | translate
                                  : {
                                        amountOffered: $any(activity).offered,
                                        amountTaken: $any(activity).eaten
                                    })
                            : $any(activity).food
                    "
                    [date]="activity.entryTime"
                    [hasAttachment]="true"
                    [showDefaultNote]="!($any(activity).eaten && $any(activity).food || $any(activity).food)"
                    [class.note-without-bullet]="!($any(activity).eaten && $any(activity).food || $any(activity).food)"
                    [isFromParent]="$any(activity).isFromParent"
                >
                </bh-cards>
            </ng-container>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'nap'">
            <bh-cards
                [ngClass]="{ 'timeline-activity-card' : isTimelineView }"            
                [id]="activity.id"
                icon="assets/nap.svg"
                [title]="
                    $any(activity).isFromParent && $any(activity).isOverNight
                        ? ('home.overnight' | translate)
                        : $any(activity).napType === 'rest'
                        ? ('home.rest' | translate)
                        : $any(activity).napType === 'sleep'
                        ? ('home.sleep' | translate)
                        : ('home.nap' | translate)
                "
                [subtitle]="
                    activity.isFromParent
                        ? ('home.my-entry' | translate)
                        : activity.createdBy
                        ? 'by ' + activity.createdBy
                        : ''
                "
                [hasAttachment]="true"
                [showDefaultNote]="!$any(activity).sleepStart && !$any(activity).sleepEnd && !!activity.note"
                [supportingText]="activity.note"
                [showDefaultNote]="!$any(activity).sleepStart && !$any(activity).sleepEnd"
                [attachmentDescription]="
                    !$any(activity).sleepStart && !$any(activity).sleepEnd
                        ? ''
                        : !$any(activity).sleepStart
                        ? $any(activity).napType === 'rest'
                            ? ('home.rest-end' | translate : { restEnd: $any(activity).sleepEnd })
                            : ('home.wake-time' | translate : { sleepEnd: $any(activity).sleepEnd })
                        : $any(activity).isSleeping
                        ? $any(activity).napType === 'rest'
                            ? ('home.rest-start'
                              | translate : { restStart: $any(activity).sleepStart })
                            : ('home.asleep'
                              | translate : { sleepStart: $any(activity).sleepStart })
                        : ($any(activity).napType === 'rest'
                              ? ('home.rest-period-start' | translate)
                              : ('home.sleep-period-start' | translate)) +
                          ($any(activity).sleepDurationHours
                              ? ' ' +
                                ('home.sleep-period-hours'
                                    | translate
                                        : {
                                              sleepDurationHours: $any(activity).sleepDurationHours
                                          })
                              : '') +
                          ($any(activity).sleepDurationMinutes
                              ? ' ' +
                                ('home.sleep-period-mins'
                                    | translate
                                        : {
                                              sleepDurationMinutes:
                                                  $any(activity).sleepDurationMinutes
                                          })
                              : '') +
                          ' ' +
                          ('home.sleep-period-end'
                              | translate
                                  : {
                                        sleepStart: $any(activity).sleepStart,
                                        sleepEnd: $any(activity).sleepEnd
                                    })
                "
                [date]="activity.entryTime"
                [isActivityFeed]="true"
                [timelineViewTitle]="isTimelineView ? ('home.sleep' | translate) : ''"
                [isFromParent]="$any(activity).isFromParent"
            ></bh-cards>
        </ng-container>

        <ng-container   *ngSwitchCase="'bathroom'">
            <div [ngClass]="isTimelineView ? 'timeline-activity-card' : ''">
                <bh-cards
                    [id]="activity.id"
                  
                    icon="assets/Diaper.svg"
                    [title]=" $any(activity).type === 'Potty' ? ('home.toilet' | translate) : $any(activity).type"
                    [showDefaultNote]="!$any(activity).events.length && !!activity.note"
                    [subtitle]="
                    activity.isFromParent
                    ? ('home.my-entry' | translate)
                    : activity.createdBy
                    ? 'by ' + activity.createdBy
                    : ''
                    "
                    [supportingText]="activity.note"
                    [attachmentDescription]="$any(activity).events"
                    [date]="activity.entryTime"
                    [hasAttachment]="true"
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.bathroom' | translate) : ''"
                    [isFromParent]="activity.isFromParent"
                >
                </bh-cards>
                </div>
        
        </ng-container>

        <ng-container *ngSwitchCase="'medicine'">
            <div *ngIf="$any(activity).isFromParent" class="parent-entry">
                <ng-container *ngFor="let attach of $any(activity).attachmentEntries">
                    <ng-container *ngFor="let attribute of attach.attachmentEntry ; let i = index">
                        <div [ngClass]="isTimelineView ? 'timeline-activity-card' : 'group-activity-card'">
                        <bh-cards
                            [id]="activity.id"
                            [icon]="'assets/medicine.svg'"
                            [title]="('home.medicine' === attach.header) ? ('home.medicine' | translate) : ('home.application' | translate)"
                            [subtitle]="
                            'home.my-entry' | translate
                            "
                            [supportingText]="attribute.sentence | translate: { time: attribute.value }" 
                            [attachmentDescription]="attach.header | translate"
                            [date]="convertToDate(attribute.value)"
                            [hasAttachment]="true"
                            [isActivityFeed]="true"
                            [timelineViewTitle]="isTimelineView ? ('home.medicine' | translate) : ''"
                            [isFromParent]="$any(activity).isFromParent"
                        >
                        </bh-cards>
                    </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="$any(activity).note">
                    <div [ngClass]="isTimelineView ? 'timeline-activity-card' : 'group-activity-card'">
                    <bh-cards
                            [id]="activity.id"
                            [icon]="'assets/medicine.svg'"
                            [title]="'home.medicine' | translate"
                            [subtitle]="
                            'home.my-entry' | translate
                            "
                            
                            [attachmentDescription]="activity.note"
                            [date]="activity.entryTime"
                            [hasAttachment]="true"
                            [isActivityFeed]="true"
                            [timelineViewTitle]="isTimelineView ? ('home.medicine' | translate) : ''"
                        >
                        </bh-cards>
                        </div>
                </ng-container>
            </div>
            <div *ngIf="!$any(activity).isFromParent"  [ngClass]="isTimelineView ? 'timeline-activity-card' : ''">
                <bh-cards
                    [id]="activity.id"
                    *ngIf="$any(activity).type === 'medicine'"
                    [icon]="'assets/medicine.svg'"
                    [title]="'home.medicine' | translate"
                    [subtitle]="
                        activity.isFromParent
                            ? ('home.my-entry' | translate)
                            : activity.createdBy
                            ? 'by ' + activity.createdBy
                            : ''
                    "
                    [showDefaultNote]="!$any(activity).description && !!activity.note"
                    [supportingText]="activity.note"
                    [attachmentDescription]="$any(activity).description"
                    [date]="activity.entryTime"
                    [hasAttachment]="true"
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.medicine' | translate) : ''"
                >
                </bh-cards>

                <bh-cards
                    [id]="activity.id"
                    *ngIf="$any(activity).type === 'skin_application'"
                    [icon]="'assets/medicine.svg'"
                    [title]="'home.application' | translate"
                    [subtitle]="
                        activity.isFromParent
                            ? ('home.my-entry' | translate)
                            : activity.createdBy
                            ? 'by ' + activity.createdBy
                            : ''
                    "
                    [supportingText]="activity.note"
                    [attachmentDescription]="
                        $any(activity).name
                            ? ('home.medicine-applied' | translate : { name: $any(activity).name })
                            : ''
                    "
                    [date]="activity.entryTime"
                    [hasAttachment]="true"
                    [isActivityFeed]="true"
                    [timelineViewTitle]="isTimelineView ? ('home.medicine' | translate) : ''"
                >
                </bh-cards>
            </div>
        </ng-container>
        <div 
            [ngClass]="{ 'timeline-activity-card' : isTimelineView }"
            *ngSwitchCase="'checkin'">
            <bh-cards
                [id]="activity.id"
                icon="assets/arrival-pickup.svg"
                [attachmentDescription]="('home.checkin' | translate) + ' at ' +(activity.entryTime | date: 'h:mm aaa')"
                [timelineViewTitle]="isTimelineView ? ('arrival.title' | translate) : ''"
                [isActivityFeed]="true"
                [hasAttachment]="true"
            ></bh-cards>
        </div>
        <div [ngClass]="{ 'timeline-activity-card' : isTimelineView }"
            *ngSwitchCase="'contactDetails'">
            <bh-cards
                [id]="activity.id"
                [title]="('home.todaysContact' | translate)"
                icon="assets/arrival-pickup.svg"
                [timelineViewTitle]="isTimelineView ? ('arrival.title' | translate) : ''"
                [isActivityFeed]="true"
                [attachmentDescription]="$any(activity).entryTime ? 
                                        $any(activity).contactName + ', ' + $any(activity).contactNumber + ', ' + ('home.pickUpTime' | translate) + ' ' + ( $any(activity).entryTime | date: 'h:mm aaa') : 
                                        $any(activity).contactName + ', ' + $any(activity).contactNumber"
                [hasAttachment]="true"
                [subtitle]="('home.myEntry' | translate)"
            >
            </bh-cards>
        </div>
        <bh-cards
            [id]="activity.id"
            *ngSwitchCase="'checkout'"
            icon="assets/checkout.svg"
            [title]="'home.checkout' | translate"
            [subtitle]="activity.createdBy ? 'by ' + activity.createdBy : ''"
            [date]="activity.entryTime"
        >
        </bh-cards>
    </ng-container>
</ng-container>
<ng-container *ngIf="calenderFeed">
    <bh-cards 
        [icon]="''"
        [type]="'grouped'"
        [id]="calenderFeed.id"
        [supportingText]="calenderFeed.message || ''"
        [cardBackgroundClass]="cardBackgroundClass"
        [startEndDateText]="getDate(calenderFeed.start) 
                            + ' ' + (checkTimeInput(calenderFeed.start,calenderFeed.end)  ? 
                            ('activity-feed.toDate' | translate: { value: getDate(calenderFeed.end)}) : '') ">
    </bh-cards>
</ng-container>
