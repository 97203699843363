import { ActionReducer, createReducer, on } from '@ngrx/store'
import { PaymentInfo } from '../models/payer'
import { getPaymentInfoSuccess, getAutoPaymentInfoSuccess } from './payer.actions'

export const payerFeatureKey = 'payer'

export interface PayerState {
    payment: PaymentInfo
    autoPayment: PaymentInfo
}

const initialState: PayerState = {
    payment: <PaymentInfo>{},
    autoPayment: <PaymentInfo>{}
}

export const PayerReducer: ActionReducer<PayerState> = createReducer(
    initialState,
    on(getPaymentInfoSuccess, (state: PayerState, { payment }) => ({
        ...state,
        payment: payment
    })),
    on(getAutoPaymentInfoSuccess, (state: PayerState, { payment }) => ({
        ...state,
        autoPayment: payment
    }))
)
