import { DateUtil } from '@events'
import moment from 'moment'

export interface DownloadItem {
    media?: PhotoSelectionItem
    period?: PhotoSelectionItem
    startDate?: Date
    endDate?: Date
}
export interface PhotoSelectionItem {
    columnIndex: number
    text: string
    value: string
}

export enum LinkGenerationStatus {
    SUCCESS = 'complete',
    PROGRESS = 'progress'
}

export class BulkMediaEntry {
    attachmentId?: string
    mediaId?: string
    mediaType?: string
    requestedRange?: string
    mediaCount?: number
    guardianId?: string
    startDate?: Date
    endDate?: Date
    created: Date
    status: LinkGenerationStatus
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        let dateStartArr = data.start_date?.split('T')[0].split('-')
        let dateEndArr = data.end_date?.split('T')[0].split('-')
        this.attachmentId = data.attachment_id
        this.mediaId = data.id ?? data._id
        this.mediaType = data.media_type
        this.requestedRange = data.requested_range
        this.mediaCount = data.media_count
        this.guardianId = data.guardian_id
        this.startDate = dateStartArr ? DateUtil.ignoreTZDate(dateStartArr[2], dateStartArr[1], dateStartArr[0]) : undefined
        this.endDate = dateStartArr ? DateUtil.ignoreTZDate(dateEndArr[2], dateEndArr[1], dateEndArr[0]) : undefined
        this.created = moment(data.created).toDate()
        this.status = data.status
    }
}

export const mediaTypeMapping: { [key: string]: string } = {
    'all': 'All Media',
    'video': 'Video',
    'audio': 'Audio',
    'photo': 'Photos'
}

export const requestedRangeMapping: { [key: string]: string } = {
    'last_30_days': 'Last 30 days',
    'last_3_months': 'Last 3 months',
    'last_6_months': 'Last 6 months'
}

export type LinkFilters = 'media' | 'period'
