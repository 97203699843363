import { ActionReducer, createReducer } from '@ngrx/store'
import { MediaMap } from '../models/media'

export const mediaFeatureKey = 'media'

export interface MediaState {
    mediaMap: MediaMap
}

const initialState: MediaState = {
    mediaMap: {}
}

export const MediaReducer: ActionReducer<MediaState> = createReducer(initialState)
