import { DependentContact } from './dependent-contact'
import { DependentClassroomTransition } from './dependent-classroom-transition'
import moment from 'moment'
import { DependentClassroomHistory } from './dependent-classroom-history'
import { DateUtil } from '../utils/date-util'
import { DependentStageHistory, StageEnum } from './dependent-stage-history'
import { DAYS_OF_WEEK } from '../dependant/dependant.reducers'

export class Dependant {
    id: string
    firstName: string
    lastName: string
    centerId: string
    photoId?: string
    schedule: string[]
    scheduledCenterIds: DependentScheduleMap
    isScheduledToday: boolean
    enrollmentDate?: Date
    originalEnrollmentDate?: Date
    graduationDate?: Date
    birthDate?: Date
    creationDate?: Date
    updatedDate?: Date
    status: string
    stage: string
    centerName?: string
    earliestMemory: Date
    age?: string
    dependentInitials: string
    contacts: DependentContact[]
    classroomHistory?: DependentClassroomHistory[]
    classroomTransition?: DependentClassroomTransition
    multipleIds: ScheduledDependent[]
    lastStatusChange?: Date
    stageHistory: DependentStageHistory[]
    isAfterCenterCheckout?: boolean
    scheduleDate?: string
    nextScheduleDate?: string

    constructor(data: any) {
        this.id = data.id
        this.firstName = data.first_name
        this.lastName = data.last_name
        this.centerId = data.center
        this.photoId = data.photo 
        this.schedule = data.status === 'scheduled' ?  ["Mo", "Tu", "We", "Th", "Fr"] : data.schedule
        this.isScheduledToday = Dependant.getScheduledForToday(this.schedule)
        this.dependentInitials =
            data && data.first_name && data.last_name
                ? data.first_name.slice(0, 1) + data.last_name.slice(0, 1)
                : ''
        this.enrollmentDate = data.enrollment_date && DateUtil.getUtcDate(data.enrollment_date)
        this.originalEnrollmentDate =
            data.original_enrollment_date && DateUtil.getUtcDate(data.original_enrollment_date)
        this.graduationDate = data.graduation_date && new Date(data.graduation_date)
        this.birthDate = data.birth_date && new Date(data.birth_date)
        this.creationDate = data.created && new Date(data.created)
        this.updatedDate = data.updated && new Date(data.updated)
        this.status = data.status
        this.scheduledCenterIds = {}
        this.stage = data.stage
        this.earliestMemory = data.earliest_memory && new Date(data.earliest_memory)
        this.contacts = data.contacts && data.contacts.map((c: any) => new DependentContact(c))
        this.multipleIds = []
        this.lastStatusChange = data.status_changed && new Date(data.status_changed)
        this.classroomHistory =
            data.classroom_history &&
            data.classroom_history.map((c: any) => new DependentClassroomHistory(c))

        this.stageHistory =
            data.stage_history && data.stage_history.map((c: any) => new DependentStageHistory(c))

        this.classroomTransition =
            data.classroom_transition && new DependentClassroomTransition(data.classroom_transition)

        if (typeof this.birthDate == 'object') {
            this.age = Dependant.getAgeOnDate(this.birthDate, new Date())
        }
        this.isAfterCenterCheckout = false
        this.scheduleDate = ''
        this.nextScheduleDate = ''
    }

    // returns age on specified date given birthdate; age returned as a string, example '5y, 3m'
    public static getAgeOnDate(birthdate: Date, date: Date): string {
        let age: string
        let ageYears = 0
        let ageMonths = 0
        const years = date.getFullYear() - birthdate.getFullYear()

        if (date.getMonth() < birthdate.getMonth()) {
            ageYears = years - 1
            ageMonths = 12 - birthdate.getMonth() + date.getMonth()
        } else if (
            date.getMonth() == birthdate.getMonth() &&
            date.getDate() < birthdate.getDate()
        ) {
            ageYears = years - 1
            ageMonths = 11
        } else {
            ageYears = years
            ageMonths = date.getMonth() - birthdate.getMonth()
        }

        age = ageYears + 'y'
        if (ageMonths > 0) {
            age += ', ' + ageMonths + 'm'
        }

        return age
    }

    public static getScheduledForToday(schedule: string[], date: Date = new Date()): boolean {
        return schedule && !!schedule.find((s) => s === date.toString().substring(0, 2))
    }

    public static getDependentIds(scheduleMap: DependentScheduleMap): string[] {
        return [
            ...new Set(
                Object.values(scheduleMap)
                    .map((s) => s.dependentId)
                    .filter((id) => !!id)
            )
        ]
    }

    public static getCenterIds(scheduleMap: DependentScheduleMap): string[] {
        return [
            ...new Set(
                Object.values(scheduleMap)
                    .map((s) => s.centerId)
                    .filter((id) => !!id)
            )
        ]
    }

    public static nextScheduledDay(scheduleMap: DependentScheduleMap): Date {
        const nextDayScheduled = new Date(new Date())
        let dayName = DateUtil.getDayOfWeekForDate(nextDayScheduled)
        do {
            nextDayScheduled.setDate(nextDayScheduled.getDate() + 1)
            dayName = DateUtil.getDayOfWeekForDate(nextDayScheduled)
        } while (!Dependant.scheduledOnDay(scheduleMap, dayName))

        return nextDayScheduled
    }

    public static getEarliestScheduleDay(scheduleMap: DependentScheduleMap, forDate: Date | string = new Date()) {
        const nextDayScheduled = new Date(forDate)
        let dayName = DateUtil.getDayOfWeekForDate(nextDayScheduled)
        let counter = 0 // This prevents infinte loop when an empty schedule is received for withdrwan child
        while (!Dependant.scheduledOnDay(scheduleMap, dayName) && counter < DAYS_OF_WEEK.length) {
            nextDayScheduled.setDate(nextDayScheduled.getDate() + 1)
            dayName = DateUtil.getDayOfWeekForDate(nextDayScheduled)
            counter++
        }
        return nextDayScheduled
    }

    public static scheduledOnDay(scheduleMap: DependentScheduleMap, day: string): boolean {
        return scheduleMap[day].centerId !== '' && scheduleMap[day].dependentId !== ''
    }

    public toString(): string {
        return this.firstName
    }

    public static getDependentIdsFromWeek(dependant: Dependant): string[] {
        let ids: string[] = []
        const DAYS_OF_WEEK = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
        DAYS_OF_WEEK.forEach((day) => {
            ids =
                dependant.scheduledCenterIds[day].dependentId !== '' &&
                !ids.includes(dependant.scheduledCenterIds[day].dependentId)
                    ? [...ids, dependant.scheduledCenterIds[day].dependentId]
                    : ids
        })
        return ids
    }

    public static getInsideDaysScheduledTransition(
        classroomTransition: DependentClassroomTransition
    ): boolean {
        const today = moment().startOf('d')
        const ninety_days_after = today.add(90, 'd')
        return moment(classroomTransition.date).startOf('d').isSameOrBefore(ninety_days_after)
    }

    public static hasTwosStage(dependent: Dependant) {
        const isInTwos = dependent.stage === StageEnum.TWOS
        return (
            isInTwos ||
            this.hasPreviousMatchingStage(dependent, StageEnum.TWOS) ||
            this.getNextStage(dependent) === StageEnum.TWOS
        )
    }

    public static getNextStage(dependent: Dependant) {
        return dependent.classroomTransition?.stage?.toLowerCase() ?? ''
    }

    public static hasPreviousMatchingStage(dependent: Dependant, stage: string) {
        return !!dependent.stageHistory?.find(s => s.stage === stage)
    }

    public static isProjectedTransition(classroomTransition?: DependentClassroomTransition) {
        return classroomTransition?.detail?.toLowerCase() === 'projected transition' ||
            classroomTransition?.detail?.toLowerCase() === 'revised projected'
    }

    public static isScheduledTransition(classroomTransition?: DependentClassroomTransition) {
        return classroomTransition?.detail?.toLowerCase() === 'scheduled transition'
    }
}

export class DependantInfo {
    id: string
    firstName: string
    scheduledFor: string

    constructor(data: any) {
        this.id = data.id
        this.firstName = data.first_name
        this.scheduledFor = data.scheduled_for.split('T')[0]
    }
}

export class DependantsDetail {
    dependents: Dependant[]
    scheduledForDate: DependantInfo[]
    scheduledNextDate: DependantInfo[]
    centers: string[]

    constructor(data: any) {
        this.dependents = data.dependents.map((d: any) => new Dependant(d))
        this.scheduledForDate = data.scheduled_for_date?.map((d: any) => new DependantInfo(d))
        this.scheduledNextDate = data.scheduled_next_date?.map((d: any) => new DependantInfo(d))
        this.centers = [...data.centers]
    }
}

export type DependantMap = { [id: string]: Dependant }
export type DependantByCenterMap = { [centerId: string]: Dependant[] }
export type CenterByDependentMap = {
    [id: string]: { [date: string]: string | undefined } | undefined
}

export type DependentScheduleMap = { [dayOfWeek: string]: ScheduledDependent }

export type DependentsDateMap = { [date: string]: DependantInfo[] }

export interface ScheduledDependent {
    centerId: string
    dependentId: string
}

export interface PendoData {
    enrollmentDays: number[]
    dependentNames: string[]
    dependentAges: string[]
    transitionDetails: string[]
    centerNumbers: string[]
    centerTZs: string[]
    centerModels: string[]
    centerAddresses: string[]
}
