import { ActionReducer, createReducer, on } from '@ngrx/store'
import { loadHealthCheckQuestionnaireSuccess } from '../health-check/health-check.actions'
import { CenterInfoMap } from '../models/center-info'
import { loadCenterInfoSuccess } from './center-info.actions'

export const centerInfoFeatureKey = 'centerInfo'

export interface CenterInfoState {
    centerInfoMap: CenterInfoMap
}

const initialState: CenterInfoState = {
    centerInfoMap: {}
}

export const CenterInfoReducer: ActionReducer<CenterInfoState> = createReducer(
    initialState,
    on(loadCenterInfoSuccess, (state: CenterInfoState, { centerInfo }) => {
        return {
            ...state,
            centerInfoMap: {
                ...state.centerInfoMap,
                [centerInfo.id]: centerInfo
            }
        }
    }),
    on(
        loadHealthCheckQuestionnaireSuccess,
        (state: CenterInfoState, { centerId, questionnaire }) => {
            return {
                ...state,
                centerInfoMap: {
                    ...state.centerInfoMap,
                    [centerId]: {
                        ...state.centerInfoMap[centerId],
                        questionnaireId: questionnaire.id
                    }
                }
            }
        }
    )
)
