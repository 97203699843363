import moment from 'moment'
import { TextUtil } from '../utils/text-util'
import { RemovePII } from '@bh/session'
export class ActivityFeed {
    id: string
    date: Date
    checkInAt: Date
    firstCheckInAt?: Date
    checkOutAt?: Date
    dependantId: string
    centerId: string
    bathrooms: BathroomEntry[]
    meals: MealEntry[]
    solids: SolidEntry[]
    naps: NapEntry[]
    teacherNotes: NoteEntry[]
    contactEntries: ContactEntry
    pleaseBringReminders: PleaseBringEntry[]
    contactNotes: NoteEntry[]
    medicationNotes: MedicationNoteEntry[]
    medications: MedicationAllEntries[]
    observations: ObservationEntry[]
    medicationEntries: MedicationEntry[]
    activities: ActivityEntry[]
    snapshots: SnapshotEntry[]
    learningAtHomes: LearningAtHomeEntry[]
    attendanceItems: AttendanceEntry[]
    reportPublishedAt: Date
    groupedNotes: NoteEntry[] | PleaseBringEntry[]
    centerAnnouncement?:CalenderEntry[]
    dueDate?:CalenderEntry[]
    classroomActivity?:CalenderEntry[]

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        this.id = data.id
        this.date = moment(data.for_date).toDate()
        this.checkInAt = data.checkin_time && new Date(data.checkin_time)
        this.firstCheckInAt = data.first_checkin_time && new Date(data.first_checkin_time)
        this.checkOutAt = data.checkout_time && new Date(data.checkout_time)
        this.dependantId = data.dependent_id
        this.centerId = data.center_id
        this.reportPublishedAt = data.last_email_sent && new Date(data.last_email_sent)

        const sortDescending = (a: any, b: any) => b.sortDate?.getTime() - a.sortDate?.getTime()
        const noteContactEntries = data.note_entries
            ? data.note_entries.filter(
                  (n: any) =>
                      (n.type === 'question' && !n.question_name) ||
                      n.question_name === 'contact' ||
                      n.question_name === 'contact_number' ||
                      n.question_name === 'pick_up'
              )
            : []

        const contactDetailList = {
            contactName: '',
            contactNumber: '',
            pickUpTime: '',
            createdAt: ''
        }
        noteContactEntries.forEach((note: any) => {
            switch (note.question_name) {
                case 'contact':
                    contactDetailList.contactName = note.answer
                    contactDetailList.createdAt = note.capture_time
                    break
                case 'contact_number':
                    contactDetailList.contactNumber = note.answer
                    break
                case 'pick_up':
                    const pickUpTime =  moment(`${moment().format('YYYY-MM-DD')} ${note.answer}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss')
                    contactDetailList.pickUpTime = pickUpTime
                    break
            }
        })

        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        this.bathrooms = (data.bathroom_entries || [])
            .filter((b: any) => this.checkInAt || b.is_from_parent)
            .map((b: any) => new BathroomEntry(b))
            .sort(sortDescending)
        this.meals = (data.meal_entries || [])
            .filter((m: any) => this.checkInAt || m.is_from_parent)
            .map((m: any) => new MealEntry(m))
            .sort(sortDescending)
        this.solids = (data.meal_entries || [])
        .filter((m: any) => this.checkInAt || m.is_from_parent)
            .map((m: any) => new SolidEntry(m))
            .sort(sortDescending)
        this.naps = (data.nap_entries || [])
            .filter((n: any) => this.checkInAt || n.is_from_parent)
            .map((n: any) => new NapEntry(n))
            .sort(sortDescending)
        this.teacherNotes = (data.note_entries || [])
            .filter(
                (n: any) =>
                    !n.question_name &&
                    n.type !== 'med_notes' &&
                    n.type !== 'please_bring' &&
                    (n.please_brings || []).length === 0 &&
                    (this.checkInAt || n.is_from_parent)
            )
            .map((n: any) => new NoteEntry(n))
            .sort(sortDescending)
        this.contactEntries = noteContactEntries.length && new ContactEntry(noteContactEntries)

        this.pleaseBringReminders = (data.note_entries || [])
            .filter(
                (n: any) =>
                    !n.question_name &&
                    n.type !== 'med_notes' &&
                    (n.type === 'please_bring' || (n.please_brings || []).length > 0)
            )
            .map((n: any) => new PleaseBringEntry(n))
            .sort(sortDescending)
        this.contactNotes = (data.note_entries || [])
            .filter(
                (n: any) =>
                    n.type === 'question' && n.is_from_parent && n.answer_type === 'FreeForm'
            )
            .map((n: any) => new NoteEntry(n))
            .sort(sortDescending)
        this.medicationNotes = (data.note_entries || [])
            .filter(
                (n: any) =>
                    (n.question_name || n.type === 'med_notes') && n.answer_type != 'FreeForm' && (this.checkInAt || n.is_from_parent)
            )
            .map((n: any) => new MedicationNoteEntry(n))
            .sort(sortDescending)
        this.medications = (this.medicationNotes || [])
            .filter((n: any, i: number) => i === 0)
            .map(() => new MedicationAllEntries(this.medicationNotes))
            .sort(sortDescending)
        this.medicationEntries = (data.medication_entries || [])
            .filter((m: any) => this.checkInAt || m.is_from_parent)
            .map((m: any) => new MedicationEntry(m))
            .sort(sortDescending)
        this.observations = (data.observation_entries || [])
            .filter((o: any) => this.checkInAt || o.is_from_parent)
            .map((o: any) => new ObservationEntry(o))
            .sort(sortDescending)
        this.activities = (data.activity_entries || [])
            .filter((a: any) => this.checkInAt || a.is_from_parent)
            .map((a: any) => new ActivityEntry(a))
            .sort(sortDescending)
        this.snapshots = (data.snapshot_entries || [])
            .filter((s: any) => this.checkInAt || s.is_from_parent)
            .map((s: any) => new SnapshotEntry(s))
            .sort(sortDescending)
        this.learningAtHomes = (data.learning_at_home_entries || [])
            .filter((s: any) => this.checkInAt || s.is_from_parent)
            .map((s: any) => new LearningAtHomeEntry(s))
            .sort(sortDescending)
            
        const calenderEntryFeed =
            (data.calendar_entries || []).length > 0 && this.checkOutAt
                ? data.calendar_entries.reduce((acc: any, code: any) => {
                      acc[code.entry_type] = acc[code.entry_type] || []
                      acc[code.entry_type].push(code)
                      return acc
                  }, Object.create(null))
                : []

        this.centerAnnouncement = calenderEntryFeed.center_announcement || []
        this.classroomActivity = calenderEntryFeed.classroom_activity || []
        this.dueDate = calenderEntryFeed.due_date || []

        this.attendanceItems = []
        this.attendanceItems.push(
            new AttendanceEntry({
                entry_time: contactDetailList.pickUpTime,
                updated: contactDetailList.createdAt,
                type: 'contactDetails',
                contactName: contactDetailList.contactName,
                contactNumber: contactDetailList.contactNumber
            })
        )
        this.attendanceItems = this.attendanceItems.filter(
            (attendance) => !!attendance.entryTime || !!attendance.contactName
        )
        this.groupedNotes = []
    }

    public static getActivityAfterTime(
        groupedActivity: ActivityFeed,
        afterDate: Date = moment('1900-01-01').toDate()
    ): ActivityFeed {
        return {
            ...groupedActivity,
            teacherNotes: groupedActivity.teacherNotes.filter(
                (n) => n.isFromParent && n.sortDate > afterDate
            ),
            naps: groupedActivity.naps.filter((n) => n.isFromParent && n.sortDate > afterDate),
            bathrooms: groupedActivity.bathrooms.filter(
                (n) => n.isFromParent && n.sortDate > afterDate
            ),
            meals: groupedActivity.meals.filter((n) => n.isFromParent && n.sortDate > afterDate),
            medicationNotes: groupedActivity.medicationNotes.filter(
                (n) => n.isFromParent && n.sortDate > afterDate
            ),
            contactNotes: groupedActivity.contactNotes.filter(
                (n) => n.isFromParent && n.sortDate > afterDate
            )
        }
    }

    public static hasArrivalNotes(groupedActivity: ActivityFeed): boolean {
        return (
            groupedActivity.teacherNotes.filter((n) => n.isFromParent).length > 0 ||
            groupedActivity.medicationNotes.filter((n) => n.isFromParent).length > 0 ||
            groupedActivity.naps.filter((n) => n.isFromParent).length > 0 ||
            groupedActivity.bathrooms.filter((n) => n.isFromParent).length > 0 ||
            groupedActivity.meals.filter((n) => n.isFromParent).length > 0 ||
            groupedActivity.contactNotes.filter((n) => n.isFromParent).length > 0
        )
    }
}

abstract class Entry {
    @RemovePII()
    note: string

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        this.note = data.note
    }
}

export abstract class ActivityFeedEntry extends Entry {
    id: string
    createdBy?: string
    createdDate?: Date
    capturedAt?: Date
    entryTime: Date
    updatedAt: Date
    sortDate: Date
    isFromParent: boolean
    activityType: ActivityFeedEntryType
    multipleFoods?: boolean
    multipleFoodsNote?: string
    contactName?: string
    @RemovePII()
    contactNumber?: string

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any, type: ActivityFeedEntryType) {
        super(data)
        this.id = data.id
        this.contactName = data.contactName
        this.contactNumber = data.contactNumber
        this.capturedAt = data.capture_time && new Date(data.capture_time)
        this.createdDate = data.created && new Date(data.created)
        this.createdBy =
            data.actor && data.prepared_actor
                ? TextUtil.initials(data.prepared_actor) + ', ' + TextUtil.initials(data.actor)
                : data.actor && !data.prepared_actor
                ? TextUtil.initials(data.actor)
                : !data.actor && data.prepared_actor
                ? TextUtil.initials(data.prepared_actor)
                : undefined

        this.entryTime = data.entry_time && moment(data.entry_time).toDate()
        this.updatedAt = moment(data.updated).toDate()
        this.sortDate = this.entryTime || this.updatedAt
        this.isFromParent = !!data.is_from_parent
        this.activityType = type
    }
}

export class AttendanceEntry extends ActivityFeedEntry {

    constructor(data: any) {
        super(data, data.type)
    }
}

export class BathroomEntry extends ActivityFeedEntry {
    type: string
    events: string
    createdBy?: string
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        super(data, 'bathroom')

        this.note = TextUtil.createLinks(data.note)
        this.type = formatName(data.bathroom_type)
        this.events = (data.bathroom_events || [])
            .map((e: string) => {
                switch (e) {
                    case 'bm':
                        return formatName(e).replace('Bm', 'Bowel Movement')
                    case 'pee':
                        return formatName(e).replace('Pee', 'Urine')
                    default:
                        return formatName(e)
                }
            })
            .join(', ')
    }
}

export class MealEntry extends ActivityFeedEntry {
    type: string
    name: string
    food: string
    offered: FoodAmountType
    eaten: FoodAmountType

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any, entryType: ActivityFeedEntryType = 'meal') {
        super(data, entryType)

        this.type = formatName(data.meal_type)
        this.name = formatName(data.meal_name)
        this.food = data.food_name || ''
        this.offered = data.amount_offered
        this.eaten = data.amount_eaten
        this.note = TextUtil.createLinks(this.note)
    }
}

export class SolidEntry extends MealEntry {
    constructor(data: any) {
        super(data, 'solid')
    }
}

export class NapEntry extends ActivityFeedEntry {
    reminder: number
    sleepChecks: SleepCheck[]
    isSleeping: boolean
    sleepStart?: string
    sleepEnd?: string
    sleepDurationHours?: string
    sleepDurationMinutes?: string
    isOverNight = false
    napType: NapType

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        super(data, 'nap')

        this.reminder = data.reminder_delay
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        this.sleepChecks = (data.sleep_checks || []).map((c: any) => new SleepCheck(c))
        this.isSleeping = !data.entry_end_time
        this.sleepStart = data.entry_start_time && moment(data.entry_start_time).format('h:mm A')
        this.sleepEnd = data.entry_end_time && moment(data.entry_end_time).format('h:mm A')
        this.note = TextUtil.createLinks(this.note)

        this.isOverNight = !!(
            data.is_from_parent &&
            data.entry_start_time == null &&
            data.entry_end_time
        )
        this.entryTime = (data.entry_end_time && moment(data.entry_end_time).toDate() || 
                         data.entry_start_time && moment(data.entry_start_time).toDate())
        this.sortDate = this.entryTime || this.capturedAt

        const sleepDuration =
            !this.isSleeping &&
            moment
                .utc(moment(data.entry_end_time).diff(moment(data.entry_start_time)))
                .format('H:m')

        this.sleepDurationHours =
            sleepDuration &&
            (sleepDuration.indexOf('0:') < 0 || sleepDuration.split(':')[0].length > 1)
                ? sleepDuration.split(':')[0]
                : undefined
        this.sleepDurationMinutes =
            sleepDuration && sleepDuration.indexOf(':00') < 0
                ? sleepDuration.split(':')[1]
                : undefined
        this.napType = data.nap_type
    }
}

export class SleepCheck extends Entry {
    captured: Date
    staff: string
    position: SleepPositionType

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        super(data)

        this.captured = data.captured && new Date(data.captured)
        this.staff = data.staff_name
        this.position = data.position
    }
}

export class NoteEntry extends ActivityFeedEntry {
    pleaseBring: PleaseBringType[]
    @RemovePII()
    note: string
    type?: string
    question?: string
    @RemovePII()
    answer?: string
    visibility: string

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any, entryType: ActivityFeedEntryType = 'note') {
        super(data, entryType)

        this.pleaseBring = data.please_brings || []
        this.note = TextUtil.createLinks(data.note)
        this.type = data.type
        this.question = data.question_name
        this.answer = data.answer
        this.visibility = data.visibility
        this.sortDate = this.entryTime || this.capturedAt
    }
}

export class ContactEntry extends ActivityFeedEntry {
    blockAttachmentEntry: ItemBlockAttachmentEntry[] = []
    type: string
    pleaseBring: PleaseBringType[]
    visibility: string

    constructor(data: any[], entryType: ActivityFeedEntryType = 'note') {
        super(data[0] ? data[0] : [], entryType)

        data.filter((n: any) => n.question_name === 'contact').map((n: any) => {
            return this.blockAttachmentEntry.push(
                new ItemBlockAttachmentEntry(n.question_name, n.answer)
            )
        })
        data.filter((n: any) => n.question_name === 'contact_number').map((n: any) => {
            return this.blockAttachmentEntry.push(
                new ItemBlockAttachmentEntry(n.question_name, n.answer)
            )
        })
        data.filter((n: any) => n.question_name === 'pick_up').map((n: any) => {
            return this.blockAttachmentEntry.push(
                new ItemBlockAttachmentEntry(n.question_name, n.answer)
            )
        })
        data.filter((n: any) => n.question_name === null).map((n: any) => {
            return this.blockAttachmentEntry.push(new ItemBlockAttachmentEntry(n.note, n.answer))
        })

        this.type = 'question'
        this.pleaseBring = []
        this.visibility = ''
    }
}

export class ItemBlockAttachmentEntry {
    text: string
    @RemovePII()
    value?: string

    constructor(text: string, value?: string) {
        this.text = ItemBlockAttachmentEntry.formatKeyValue(text)
        this.value = value
    }

    public static formatKeyValue(text: string): string {
        switch (text) {
            case 'contact':
                text = 'home.name-contact'
                break
            case 'contact_number':
                text = 'home.phone-contact'
                break
            case 'pick_up':
                text = 'home.pickup-contact'
                break
        }

        return text
    }
}

export class PleaseBringEntry extends NoteEntry {
    constructor(data: any) {
        super(data, 'pleaseBring')

        this.type = data.type || 'please_bring'
        this.sortDate = this.entryTime || this.capturedAt
    }
}

export class MedicationNoteEntry extends ActivityFeedEntry {
    answer: string
    answer_type: string
    note: string
    question: string
    question_name: string
    type: string

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        super(data, 'note')

        this.answer = data.answer
        this.answer_type = data.answer_type
        this.note = data.note
        this.question = data.question
        this.question_name = data.question_name
        this.type = data.type
    }
}

export class MedicationAllEntries extends ActivityFeedEntry {
    attachmentEntries: AttachmentEntry[] = []
    icon: string

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any[]) {
        super(data, 'medicine')

        this.sortDate = data[0].sortDate
        data.filter((n: any) => n.type === 'med_notes').map((n: any) => (this.note = n.note))
        data.filter(
            (n: any) => n.type === 'question' && n.question_name === 'insect_repellent_applied'
        ).map((n: any) => {
            return n.answer !== 'No' && n.answer !== ''
                ? this.attachmentEntries?.push(
                      new AttachmentEntry('home.insect-repellent', 'home.applied-at', n.answer)
                  )
                : n.answer === 'No'
                ? this.attachmentEntries?.push(
                      new AttachmentEntry('home.insect-repellent', 'home.not-applied-at-repellent')
                  )
                : this.attachmentEntries?.push(
                      new AttachmentEntry('home.insect-repellent', 'home.applied-at-no-time')
                  )
        })
        data.filter(
            (n: any) => n.type === 'question' && n.question_name === 'sunscreen_applied'
        ).map((n: any) => {
            return n.answer !== 'No' && n.answer !== ''
                ? this.attachmentEntries?.push(
                      new AttachmentEntry('home.sunscreen', 'home.applied-at', n.answer)
                  )
                : n.answer === 'No'
                ? this.attachmentEntries?.push(
                      new AttachmentEntry('home.sunscreen', 'home.not-applied-at-sunscreen')
                  )
                : this.attachmentEntries?.push(
                      new AttachmentEntry('home.sunscreen', 'home.applied-at-no-time')
                  )
        })
        data.filter(
            (n: any) => n.type === 'question' && n.question_name === 'received_medication'
        ).map((n) => {
            return n.answer !== 'No' && n.answer !== ''
                ? this.addMedicine('home.received-at', n.answer)
                : n.answer === 'No'
                ? this.addMedicine('home.not-received-meds')
                : this.addMedicine('home.received-at-no-time')
        })
        data.filter(
            (n: any) => n.type === 'question' && n.question_name === 'scheduled_medication'
        ).map((n) => {
            return n.answer !== 'No' && n.answer !== ''
                ? this.addMedicine('home.needs-to-be-received', n.answer)
                : n.answer === 'No'
                ? this.addMedicine('home.will-not-receive-meds')
                : this.addMedicine('home.needs-received')
        })

        this.icon =
            data
                .filter((n) => n.answer !== 'No')
                .map((n) => n.question_name)
                .indexOf('received_medication') !== -1 ||
            data
                .filter((n) => n.answer !== 'No')
                .map((n) => n.question_name)
                .indexOf('scheduled_medication') !== -1
                ? 'medicine'
                : 'skin_application'
        this.isFromParent = true
    }

    private addMedicine(sentence: string, value?: string): void {
        const findIndex = this.attachmentEntries?.map((n) => n.header).indexOf('home.medicine')
        if (findIndex === -1) {
            value
                ? this.attachmentEntries?.push(
                      new AttachmentEntry('home.medicine', sentence, value)
                  )
                : this.attachmentEntries?.push(new AttachmentEntry('home.medicine', sentence))
        } else {
            value
                ? this.attachmentEntries[findIndex].attachmentEntry.push({
                      sentence: sentence,
                      value: value
                  })
                : this.attachmentEntries[findIndex].attachmentEntry.push({
                      sentence: sentence
                  })
        }
    }
}

export class AttachmentEntry {
    header: string
    attachmentEntry: MedicationAttachmentEntry[] = []

    constructor(header: string, sentence: string, value?: string) {
        this.header = header
        sentence && value
            ? this.attachmentEntry.push(new MedicationAttachmentEntry(sentence, value))
            : this.attachmentEntry.push(new MedicationAttachmentEntry(sentence))
    }
}

export class MedicationAttachmentEntry {
    sentence: string
    value?: string

    constructor(sentence: string, value?: string) {
        this.sentence = sentence
        this.value = value
    }
}

export class MedicationEntry extends ActivityFeedEntry {
    name: string
    unit: string
    quantity: string
    type: MedicationEntryType
    description: string

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        super(data, 'medicine')

        this.name = data.medication_name
        this.note = TextUtil.createLinks(this.note)
        this.unit = data.unit_of_measurement
        this.quantity = data.quantity
        this.type =
            (data.unit_of_measurement && data.unit_of_measurement.length > 0) ||
            (data.medication_name !== 'sunscreen' &&
                data.medication_name !== 'insect repellant' && // Inputs returned by Dashboard
                data.medication_name !== 'Sunscreen' &&
                data.medication_name !== 'Insect repellent') // Inputs returned by TeacherApp
                ? 'medicine'
                : 'skin_application'

        this.description =
            this.type === 'medicine'
                ? this.quantity && this.unit
                    ? this.name
                        ? `${this.quantity} ${this.unit} of ${this.name}`
                        : `${this.quantity} ${this.unit}`
                    : this.name
                    ? `${this.name}`
                    : ''
                : this.name
                ? `${this.name}`
                : ''
    }
}

export class ObservationEntry extends ActivityFeedEntry {
    centerId: string
    staffId?: string
    attachmentId?: string
    addedByParent: boolean
    selections: ObservationSelectionEntry[]
    title: 'note'
    createdInMapp: string
    visibility: string
    selectorTags?: string

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        super(data, 'observation')

        this.centerId = data.center_id
        this.title = data.title
        this.staffId = data.selected_staff_id
        this.attachmentId = data.attachment_id
        this.addedByParent = !!data.added_by_child
        this.note = TextUtil.createLinks(data.note)
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        this.selections = (data.selections || []).map((s: any) => new ObservationSelectionEntry(s))
        this.createdInMapp = data.created_in_mapp
        this.visibility = data.visibility
        this.sortDate = this.capturedAt || this.updatedAt
    }
}

export class ObservationSelectionEntry {
    domainId: string
    attributeId: string
    progressionId: string
    domain?: string
    attribute?: string
    progression?: string

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        this.domainId = data.domain_id
        this.attributeId = data.attribute_id
        this.progressionId = data.progression_id
    }
}

export class ActivityEntry extends ActivityFeedEntry {
    snapshots: SnapshotEntry[]
    learningAtHomes: LearningAtHomeEntry[]
    subjects?: string
    textElements?: string[]
    visibility: string

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        super(data, 'witwht')

        this.note = TextUtil.createLinks(data.description)
        this.snapshots = (data.snapshots || [])
            .sort((a: any, b: any) => new Date(b.created).getTime() - new Date(a.created).getTime())
            .map((s: any) => new SnapshotEntry(s))
        this.learningAtHomes = (data.learningAtHomes || []).map(
            (s: any) => new LearningAtHomeEntry(s)
        )
        this.textElements =
            this.snapshots.length > 0 ? this.snapshots.map((s: any) => s.note) : undefined
        this.subjects =
            data.subject_names.length > 1 ? data.subject_names.join(', ') : data.subject_names[0]
        this.visibility = data.visibility
    }
}

export class SnapshotEntry extends ActivityFeedEntry {
    attachment: string
    visibility: string

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        super(data, 'snapshot')

        this.attachment = data.attachment_id
        this.note = TextUtil.createLinks(data.note)
        this.visibility = data.visibility
    }
}

export class LearningAtHomeEntry extends ActivityFeedEntry {
    experiences: LearningAtHomeExperienceEntry

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        super(data, 'learning')

        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        this.experiences = (data.experiences || []).map(
            (e: any) => new LearningAtHomeExperienceEntry(e)
        )
    }
}

export class LearningAtHomeExperienceEntry {
    title: string
    domain: string
    description: string
    linkElements?: []

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(data: any) {
        this.title = data.title
        this.domain = data.domain
        this.description = TextUtil.createLinks(data.description)
        this.linkElements = data.link ? data.link.split('\n') : undefined
    }
}

export interface CalenderEntry {
    entry_type: string
    message: string
    start: Date
    end: Date
    id:string
}

export type FoodAmountType = 'none' | 'most' | 'all' | 'some' | ''
export type SleepPositionType = 'back' | 'tummy' | 'side (L)' | 'side (R)'
export type PleaseBringType =
    | 'bedding'
    | 'books'
    | 'breast milk'
    | 'bug spray'
    | 'cereal'
    | 'clothes'
    | 'diaper cream'
    | 'diapers'
    | 'formula'
    | 'infant food'
    | 'lunch money'
    | 'ointment'
    | 'pack lunch'
    | 'permission slip'
    | 'pull-ups'
    | 'shoes'
    | 'sunscreen'
    | 'swimsuit'
    | 't-shirt'
    | 'toothpaste'
    | 'towel'
    | 'wipes'
export type ActivityFeedType = { [id: string]: ActivityFeedPair }
export type ActivityFeedPair = { group: ActivityFeed; timeline: ActivityFeedEntry[] }
export type ActivityFeedEntryType =
    | 'dr'
    | 'snapshot'
    | 'witwht'
    | 'meal'
    | 'nap'
    | 'bathroom'
    | 'food'
    | 'medicine'
    | 'checkin'
    | 'pickup'
    | 'note'
    | 'observation'
    | 'learning'
    | 'solid'
    | 'pleaseBring'
    | 'contactDetails'
    | 'calender' 
export type ActivityFeedFilter = 'group' | 'timeline'
export type MedicationEntryType = 'medicine' | 'skin_application'
export type ActivityFeedMap = { [date: string]: ActivityFeedType }
export type NapType = 'sleep' | 'rest'

const formatName = (name: string): string =>
    name ? name.slice(0, 1).toUpperCase() + name.slice(1) : ''
