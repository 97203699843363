import { CommonModule, DatePipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { DesignSystemModule } from '@bh/design-system'
import { IonicModule } from '@ionic/angular'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { TranslateModule } from '@ngx-translate/core'
import { NavRoutingModule } from './nav-routing.module'
import { NavEffects } from './nav/events/nav.effects'
import { navFeatureKey, NavReducer } from './nav/events/nav.reducer'
import { NavWrapperComponent } from './nav/nav-wrapper.component'
import { NavComponent } from './nav/nav.component'

@NgModule({
    declarations: [NavComponent, NavWrapperComponent],
    imports: [
        NavRoutingModule,
        IonicModule.forRoot(),
        DesignSystemModule,
        CommonModule,
        EffectsModule.forFeature([NavEffects]),
        StoreModule.forFeature(navFeatureKey, NavReducer),
        TranslateModule
    ],
    exports: [NavComponent],
    providers: [DatePipe]
})
export class NavModule {}
