export class AccountMetaData {
    observations: boolean
    curriculumPlans: boolean
    portfolios: boolean

    constructor(data: any) {
        this.observations = data.developmental_observation_shared
        this.curriculumPlans = data.curriculum_plan_shared
        this.portfolios = data.portfolio_shared
    }
}
