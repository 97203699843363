import { createFeatureSelector, createSelector } from '@ngrx/store'
import { myLearnersFeatureKey, MyLearnersState } from './my-learners.reducers'

const myLearnersSelector = createFeatureSelector<MyLearnersState>(myLearnersFeatureKey)

export const selectGroupedDevelopmentalObservations = createSelector(
    myLearnersSelector,
    (state: MyLearnersState) => state.groupedDevelopmentalObservations
)

export const selectGroupedMBJPReports = createSelector(
    myLearnersSelector,
    (state: MyLearnersState) => state.groupedReports
)

export const selectDepId = createSelector(
    myLearnersSelector,
    (state: MyLearnersState) => state.depId
)

export const selectCurriculumPlans = createSelector(
    myLearnersSelector,
    (state: MyLearnersState) => state.groupedCurriculumPlans
)

export const selectPolaroidPhotos = createSelector(
    myLearnersSelector,
    (state: MyLearnersState) => state.polaroidPhotos
)

export const selectLastFetchedDate = createSelector(
    myLearnersSelector,
    (state: MyLearnersState) => state.groupedDevelopmentalObservations.lastFetchedDate
)

export const selectPaginationState = createSelector(
    myLearnersSelector,
    (state: MyLearnersState) => state.groupedDevelopmentalObservations.isPaginationDisabled
)
