import { Inject, Injectable } from '@angular/core'
import { NavigationExtras, Router } from '@angular/router'
import {
    LocalNotifications,
    ActionPerformed as LocalPushActionPerformed
} from '@capacitor/local-notifications'
import {
    ActionPerformed as PushActionPerformed,
    PushNotificationSchema,
    PushNotifications
} from '@capacitor/push-notifications'
import { Platform } from '@ionic/angular'
import { Store } from '@ngrx/store'
import { documentsCleared } from '@events'
import { BehaviorSubject, of } from 'rxjs'
import { take } from 'rxjs/operators'
import { DBService } from '@bh/data'
import { clickBottomNav, dismissQuickActions } from '@bh/design-system'
import { DeepLinkBulkDownloadEvent } from './models/deep-linking-data'

@Injectable({
    providedIn: 'root'
})
export class DeepLinkingService {
    public static readonly REMINDERS_OPENED_KEY = '__DEEP_LINKING_REMINDERS_OPENED'
    public static readonly QUICK_TASKS_OPENED_KEY = '__DEEP_LINKING_QUICK_TASKS_OPENED'
    public static readonly NEXT_ROUTE_KEY = '__DEEP_LINKING_NEXT_ROUTE'
    public static readonly CARE_EVENT_KEY = '__DEEP_LINKING_CARE_EVENT'
    public static readonly MEMORIES_DOCUMENT_KEY = '__DEEP_LINKING_MEMORIES_DOCUMENT'

    public static readonly NOTIFICATION_DELAY = 2000

    private static careEventSubject = new BehaviorSubject<DeepLinkCareEvent | null>(null)
    public static readonly careEventObs = DeepLinkingService.careEventSubject.asObservable()

    private static calendarEventSubject = new BehaviorSubject<DeepLinkCareEvent | null>(null)
    public static readonly calendarEventObs = DeepLinkingService.calendarEventSubject.asObservable()

    private static myLearningEventSubject = new BehaviorSubject<MyLearnerEventType | null>(null)
    public static readonly myLearningEventObs = DeepLinkingService.myLearningEventSubject.asObservable()

    private static alertEventSubject = new BehaviorSubject<DeepLinkAlertEvent | null>(null)
    public static readonly alertEventObs = DeepLinkingService.alertEventSubject.asObservable()

    private static bulkDownloadEventSubject = new BehaviorSubject<DeepLinkBulkDownloadEvent | null>(null)
    public static readonly bulkDownloadEventObs = DeepLinkingService.bulkDownloadEventSubject.asObservable()

    constructor(
        private platform: Platform,
        private router: Router,
        @Inject(DBService) private dbService: DBService,
        private store: Store
    ) {}

    public registerDeepLinks(): Promise<boolean> {
        if (this.platform.is('capacitor')) {
            PushNotifications.addListener(
                'pushNotificationActionPerformed',
                (action: PushActionPerformed) => {
                    if (!action.notification.data.b.g) {
                        action.notification.data.b = JSON.parse(action.notification.data.b)
                    }

                    setTimeout(() => {
                        this.clearScreen(action.notification.data)
                        DeepLinkingService.pushNotificationClicked(action.notification.data)
                        this.handleBottomNavSelection(action.notification.data)
                        const nextRoute = DeepLinkingService.getNextRoute()

                        if (nextRoute) {
                            this.router.navigate(nextRoute.route, { ...nextRoute.params })
                        }
                    }, DeepLinkingService.NOTIFICATION_DELAY)
                }
            )

            LocalNotifications.addListener(
                'localNotificationActionPerformed',
                (action: LocalPushActionPerformed) => {
                    this.clearScreen(action.notification.extra)

                    DeepLinkingService.pushNotificationClicked(action.notification.extra)
                    this.handleBottomNavSelection(action.notification.extra)

                    const nextRoute = DeepLinkingService.getNextRoute()

                    if (nextRoute) {
                        this.router.navigate(nextRoute.route, { ...nextRoute.params })
                    }
                }
            )

            PushNotifications.addListener(
                'pushNotificationReceived',
                (notification: PushNotificationSchema) => {
                    this.pushNotificationReceived(notification.data)
                }
            )
        }

        return of(true).toPromise()
    }

    // Method used to clear any modals, browsers, or navgiations before deep link
    public clearScreen(data: any): void {
        this.store.dispatch(documentsCleared())

        if (data.b.l !== '2') {
            this.store.dispatch(dismissQuickActions())
        }
    }

    public handleBottomNavSelection(data: any) {
        const homepageNumberList = ['1', '2', '3', '6', '10']

        if (homepageNumberList.includes(data.b.l)) {
            this.store.dispatch(clickBottomNav({ index: 0, data: 'activity-feed' }))
        } else if (data.b.l === '4') {
            this.store.dispatch(clickBottomNav({ index: 3, data: 'calendar' }))
        } else if (data.b.l === '5') {
            this.store.dispatch(clickBottomNav({ index: 2, data: 'my-learners' }))
        }
    }

    private pushNotificationReceived(data: any): void {
        if (!data.b.g) {
            data.b = JSON.parse(data.b)
        }

        if (data.b.l === '6') {
            // Care events for checked in
            if (data.b.alert.includes('checked in')) {
                this.dbService
                    .deleteCacheByKey('apiCache', `dependentListDate_${data.b.g}_${data.b.ed}`)
                    .pipe(take(1))
                    .subscribe()
            }
        }
    }

    private static pushNotificationClicked(data: any): void {
        if (!data.b.g) {
            data.b = JSON.parse(data.b)
        }

        // reminders please bring
        if (data.b.l === '1') {
            const route: DeepLinkNextRoute = { route: ['home', data.b.g, 'activity-feed'] }
            DeepLinkingService.setNextRoute(route)
            const alertEvent: DeepLinkAlertEvent = { guardianId: data.b.g, type: 'please_bring' }
            DeepLinkingService.setAlertEvent(alertEvent)
        }
        // health check
        else if (data.b.l === '2') {
            const route: DeepLinkNextRoute = { route: ['home', data.b.g, 'activity-feed'] }
            DeepLinkingService.setNextRoute(route)
            const alertEvent: DeepLinkAlertEvent = { guardianId: data.b.g, type: 'healthcheck' }
            DeepLinkingService.setAlertEvent(alertEvent)
        }
        // emergency alert
        else if (data.b.l === '3') {
            const route: DeepLinkNextRoute = { route: ['home', data.b.g, 'activity-feed'] }
            DeepLinkingService.setNextRoute(route)
            const alertEvent: DeepLinkAlertEvent = { guardianId: data.b.g, type: 'emergency_alert' }
            DeepLinkingService.setAlertEvent(alertEvent)
        }
        else if (data.b.l === '5') {
            const myLearnerEvent = this.handleCurriculumAndPortfolioEvent(data)
            const route: DeepLinkNextRoute = { route: ['home', data.b.g, 'my-learners'] }
            DeepLinkingService.setNextRoute(route)
            DeepLinkingService.setMyLearningEvent(myLearnerEvent)
        }
        // calendar events
        else if (data.b.l === '4') {
            const route: DeepLinkNextRoute = {
                route: ['home', data.b.g, 'calendar'],
                params: {
                    queryParams: {
                        notificationid: data.b.ce,
                        notificationDate: data.b.ed
                    }
                }
            }
            DeepLinkingService.setNextRoute(route)
            const calendarEvent: DeepLinkCareEvent = {
                dependentId: data.b.g,
                careEventId: data.b.ce,
                isDailyReport: false,
                date: data.b.ed
            }
            DeepLinkingService.setCalendarEvent(calendarEvent)
        }
        else if (data.b.l === '6') {
            // Care events and my-learners DO events
            this.handleCareAndDOEvent(data)
        }
        // Bright Horizon messages
        else if (data.b.l === '7') {
            const route: DeepLinkNextRoute = { route: ['messages', 'centers', data.b.g] }
            DeepLinkingService.setNextRoute(route)
        }
        // admin center messages
        else if (data.b.l === '8') {
            const route: DeepLinkNextRoute = { route: ['messages', 'admins', data.b.c] }
            DeepLinkingService.setNextRoute(route)
        }
        // teacher note messages
        else if (data.b.l === '9') {
            const route: DeepLinkNextRoute = {
                route: ['messages', 'teachers', data.b.c, data.b.alert.split(' ')[4]]
            }
            DeepLinkingService.setNextRoute(route)
        }
        // bulk download
        else if (data.b.l === '10') {
            DeepLinkingService.handleBulkDownloadEvent(data)
        }
    }

    private static getItemAndDelete(key: string): string | null {
        const val = sessionStorage.getItem(key)

        sessionStorage.removeItem(key)

        return val
    }

    public static getRemindersOpened(): boolean {
        const storedVal = DeepLinkingService.getItemAndDelete(
            DeepLinkingService.REMINDERS_OPENED_KEY
        )

        return storedVal === 'true'
    }

    private static setNextRoute(nextRoute: DeepLinkNextRoute): void {
        sessionStorage.setItem(DeepLinkingService.NEXT_ROUTE_KEY, JSON.stringify(nextRoute))
    }

    public static getNextRoute(): DeepLinkNextRoute | undefined {
        const storedVal = DeepLinkingService.getItemAndDelete(DeepLinkingService.NEXT_ROUTE_KEY)
        return storedVal ? JSON.parse(storedVal) : undefined
    }

    public static setCareEvent(careEvent: DeepLinkCareEvent | null): void {
        this.careEventSubject.next(careEvent)
    }

    public static setCalendarEvent(careEvent: DeepLinkCareEvent | null): void {
        this.calendarEventSubject.next(careEvent)
    }

    public static setMyLearningEvent(myLearnerEvent: MyLearnerEventType | null): void {
        this.myLearningEventSubject.next(myLearnerEvent)
    }

    public static setAlertEvent(alertEvent: DeepLinkAlertEvent | null): void {
        this.alertEventSubject.next(alertEvent)
    }

    public static setBulkDownloadEvent(bulkDownloadEvent: DeepLinkBulkDownloadEvent | null): void {
        this.bulkDownloadEventSubject.next(bulkDownloadEvent)
    }

    public static handleCurriculumAndPortfolioEvent(data: any): DeepLinkCurriculumEvent | DeepLinkPortfolioEvent {
        let myLearnerEvent: DeepLinkCurriculumEvent | DeepLinkPortfolioEvent | null = null
        if (data.b.cpi) {
            // my-learner curriculum event
            myLearnerEvent = {
                dependentId: data.b.d,
                curriculumPlanId: data.b.cpi,
                attachmentId: data.b.e,
                date: data.b.ed
            }
        } else {
            // my-learners MBJP report event
            myLearnerEvent = {
                dependentId: data.b.d,
                portfolioEventId: data.b.e,
            }
        }

        return myLearnerEvent
    }

    public static handleCareAndDOEvent(data: any): void {
        if ((data.b.visibility === 'portfolio' || data.b.visibility === 'email' || data.b.visibility === 'daily') && !data.b.is_daily_report) {
            // In case of visibility 'portfolio' (observations shared from MAPP),
            // setting the data to '5' which will help route to my-learners page.
            data.b.l = '5'
            const route: DeepLinkNextRoute = { route: ['home', data.b.g, 'my-learners'] }
            DeepLinkingService.setNextRoute(route)
            const myLearnerDOEvent: DeepLinkMyLearnerDoEvent = {
                dependentId: data.b.d,
                observationEventId: data.b.e
            }
            DeepLinkingService.setMyLearningEvent(myLearnerDOEvent)
        } else {
            // care events
            const route: DeepLinkNextRoute = { route: ['home', data.b.g, 'activity-feed'] }
            DeepLinkingService.setNextRoute(route)
            const careEvent: DeepLinkCareEvent = {
                dependentId: data.b.d,
                careEventId: data.b.e,
                isDailyReport: !!data.b.is_daily_report,
                date: data.b.ed
            }
            DeepLinkingService.setCareEvent(careEvent)
        }
    }

    public static handleBulkDownloadEvent(data: any): void {
        const route: DeepLinkNextRoute = { route: ['home', data.b.g, 'activity-feed'] }
        DeepLinkingService.setNextRoute(route)
        const bulkEvent: DeepLinkBulkDownloadEvent = {
            mediaType: data.b.media_type,
            attachmentId: data.b.attachment_id,
            alert: data.b.alert,
            mediaId: data.b.e,
            mediaNumber: data.b.num_medias,
            guardianId: data.b.g
        }
        DeepLinkingService.setBulkDownloadEvent(bulkEvent)
    }
}

export interface DeepLinkNextRoute {
    route: string[]
    params?: NavigationExtras
}

export interface DeepLinkCareEvent {
    dependentId: string
    careEventId: string
    isDailyReport: boolean
    date: string
}

export interface DeepLinkMyLearnerDoEvent {
    dependentId: string
    observationEventId: string
}

export interface DeepLinkAlertEvent {
    type?: AlertType
    guardianId: string
}

export interface DeepLinkPortfolioEvent {
    dependentId: string
    portfolioEventId: string
}

export interface DeepLinkCurriculumEvent {
    dependentId: string
    curriculumPlanId: string
    attachmentId: string,
    date: Date
}

export type AlertType = 'emergency_alert' | 'please_bring' | 'healthcheck'
export type MyLearnerEventType = DeepLinkPortfolioEvent | DeepLinkMyLearnerDoEvent | DeepLinkCurriculumEvent
