import { ActionReducer, createReducer, on } from '@ngrx/store'
import { loadingStarted, loadingDone, clearLoading } from './loading.actions'

export const loadingFeatureKey = 'loading'

export interface LoadingState {
    loadingCountByModel: { [key: string]: number }
}

const initialState: LoadingState = {
    loadingCountByModel: {}
}

export const LoadingReducer: ActionReducer<LoadingState> = createReducer(
    initialState,
    on(loadingStarted, (state: LoadingState, { model }) => {
        let loadingCount = state.loadingCountByModel[model] ? state.loadingCountByModel[model] : 0

        loadingCount = loadingCount + 1
        const loadingCountByModel = { ...state.loadingCountByModel }
        loadingCountByModel[model] = loadingCount
        return {
            ...state,
            loadingCountByModel: loadingCountByModel
        }
    }),
    on(loadingDone, (state: LoadingState, { model }) => {
        let loadingCount = state.loadingCountByModel[model] ? state.loadingCountByModel[model] : 0

        loadingCount = loadingCount > 0 ? loadingCount - 1 : 0
        const loadingCountByModel = { ...state.loadingCountByModel }
        loadingCountByModel[model] = loadingCount
        return {
            ...state,
            loadingCountByModel: loadingCountByModel
        }
    }),
    on(clearLoading, (state: LoadingState) => {
        return {
            ...state,
            loadingCountByModel: {}
        }
    })
)
