import { Injectable } from '@angular/core'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { from, of } from 'rxjs'
import { map } from 'rxjs/operators'
import packageJson from '../../../../../package.json'
import { AbstractAppVersionService } from '@bh/security'

@Injectable({
    providedIn: 'root'
})
export class ParentAppVersionService extends AbstractAppVersionService {
    getVersion() {
        if (Capacitor.isNativePlatform()) {
            return from(App.getInfo())
                .pipe(map(info => info.version))
        }
        return of(packageJson.version)
    }
}
