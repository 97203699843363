import { createFeatureSelector, createSelector } from '@ngrx/store'
import { CenterInfo, CenterInfoMap } from '../models/center-info'
import { centerInfoFeatureKey, CenterInfoState } from './center-info.reducers'

const centerInfoSelector = createFeatureSelector<CenterInfoState>(centerInfoFeatureKey)

export const selectCenterInfo = createSelector(
    centerInfoSelector,
    (state: CenterInfoState): CenterInfoMap => sortCenterInfo(state.centerInfoMap)
)

export const selectCenterInfoById = (centerId: string) =>
    createSelector(selectCenterInfo, (centerInfoMap): CenterInfo => {
        return centerInfoMap[centerId]
    })

export const selectCenterInfoHealthCheck = createSelector(
    centerInfoSelector,
    (state: CenterInfoState): CenterInfoMap =>
        Object.keys(sortCenterInfo(state.centerInfoMap))
            .filter((id) => state.centerInfoMap[id].settings.healthCheckEnabled)
            .reduce(
                (acc, id) => ({
                    ...acc,
                    [id]: state.centerInfoMap[id]
                }),
                {} as CenterInfoMap
            )
)

export const selectCenterArrivalNotesConfig = (centerId: string): any =>
    createSelector(
        centerInfoSelector,
        (state: CenterInfoState) =>
            state.centerInfoMap[centerId].settings.arrivalNotesSubmissionConfig
    )

export const selectCenterCount = createSelector(
    centerInfoSelector,
    (state: CenterInfoState): number => Object.keys(state.centerInfoMap).length
)

const sortCenterInfo = (centerMap: CenterInfoMap): CenterInfoMap => {
    return Object.keys(centerMap)
        .sort((a, b) => {
            const nameA = centerMap[a].name
            const nameB = centerMap[b].name

            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }

            return 0
        })
        .reduce((acc, key) => {
            return {
                ...acc,
                [key]: centerMap[key]
            }
        }, {} as CenterInfoMap)
}
