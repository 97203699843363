import { createAction, props } from '@ngrx/store'

export const loadEmergencyAlerts = createAction('[EmergencyAlerts] Load Emergency Alerts')

export const clearEmergencyAlert = createAction(
    '[EmergencyAlerts] Create Emergency Alert by ID',
    props<{ id: string }>()
)

export const loadEmergencyAlertsSuccess = createAction(
    '[EmergencyAlerts] Load Emergency Alerts Success',
    props<{ emergencyAlerts: any[] }>()
)

export const loadEmergencyAlertsError = createAction(
    '[EmergencyAlerts] Load Emergency Alerts Error',
    props<{ error: Error }>()
)
