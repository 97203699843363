import { createFeatureSelector, createSelector } from '@ngrx/store'
import { payerFeatureKey, PayerState } from './payer.reducers'

const payerSelector = createFeatureSelector<PayerState>(payerFeatureKey)

export const selectPayerInfo = createSelector(
    payerSelector,
    (state: PayerState) => state.payment
)

export const selectAutoPayerInfo = createSelector(
    payerSelector,
    (state: PayerState) => state.autoPayment
)
