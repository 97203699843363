import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
    developmentalObservationFeatureKey,
    DevelopmentalObservationState
} from './developmental-observation.reducers'

const devObservationSelector = createFeatureSelector<DevelopmentalObservationState>(developmentalObservationFeatureKey)

export const selectDomainFilter = createSelector(
    devObservationSelector,
    (state: DevelopmentalObservationState) => state.domainFilterSelected
)

export const selectStageFilter = createSelector(
    devObservationSelector,
    (state: DevelopmentalObservationState) => state.stageFilterSelected
)

export const selectRemainingStageToFilter = createSelector(
    devObservationSelector,
    (state: DevelopmentalObservationState) => state.stagesRemainingToFilter
)
