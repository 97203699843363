import { Injectable } from '@angular/core'
import { Dependant } from '../models/dependant'
import moment from 'moment'

@Injectable({
    providedIn: 'root'
})
export class PendoService {
    private _window: any

    constructor() {
        this._window = window as any
    }

    getClient(): Pendo {
        return this._window.pendo
    }

    getDependentPendoData(key: string, dependent: Dependant, childNum: number) {
        const depKey = `${key}${childNum}`
        const dependentDataMap: { [key: string]: string | null } = {
            [`${depKey}_Stage`]: dependent.stage,
            [`${depKey}_GraduationDate`]: moment.utc(dependent.graduationDate ?? null).startOf('day').toISOString(),
            [`${depKey}_OriginalEnrollmentDate`]: moment.utc(dependent.originalEnrollmentDate ?? null).startOf('day').toISOString(),
            [`${depKey}_RecentEnrollmentDate`]: moment.utc(dependent.enrollmentDate ?? null).startOf('day').toISOString(),
            [`${depKey}_TransitionClass`]: dependent.classroomTransition?.name ?? null,
            [`${depKey}_TransitionDate`]: moment.utc(dependent.classroomTransition?.date ?? null).startOf('day').toISOString(),
            [`${depKey}_TransitionDetails`]: dependent.classroomTransition?.detail ?? null
        }

        return dependentDataMap
    }
}

export interface Identity {
    /** visitor.id is required if user is logged in, otherwise an anonymous ID is generated and tracked by a cookie */
    visitor?: IdentityMetadata
    account?: IdentityMetadata
    parentAccount?: IdentityMetadata
}

export interface Metadata {
    [key: string]: string | number | boolean | string[]
}

type IdentityMetadata = { id?: string } & Metadata

export interface InitOptions extends Identity {
    apiKey?: string
    excludeAllText?: boolean
    excludeTitle?: boolean
    disablePersistence?: boolean
    guides?: {
        delay?: boolean
        disable?: boolean
        timeout?: number
        tooltip?: {
            arrowSize?: number
        }
    }
    events?: EventCallbacks
}

export interface EventCallbacks {
    ready?(): void
    guidesLoaded?(): void
    guidesFailed?(): void
}

export interface Pendo {
    // Initialization and Identification
    initialize(options?: InitOptions): void
    identify(visitorId: string, accountId?: string): void
    identify(identity: Identity): void
    isReady(): boolean
    flushNow(): Promise<any>
    updateOptions(options: Identity): void
    getVersion(): string
    getVisitorId(): string
    getAccountId(): string
    getCurrentUrl(): string

    // Guides and Guide Center
    findGuideByName(name: string): Guide | void
    findGuideById(id: string): Guide | void
    showGuideByName(name: string): void
    showGuideById(id: string): void
    toggleLauncher(): void
    removeLauncher(): void

    // Troubleshooting
    loadGuides(): void
    startGuides(): void
    stopGuides(): void

    // Debugging
    enableDebugging(): void
    disableDebugging(): void
    isDebuggingEnabled(coerce?: false): 'Yes' | 'No'
    isDebuggingEnabled(coerce: true): boolean
    debugging: Debugging

    // Events
    events: Events
    track(trackType: string, metadata?: Metadata): void

    // Guide Events
    onGuideAdvanced(step?: GuideStep): void
    onGuideAdvanced(steps: { steps: number }): void
    onGuidePrevious(step?: GuideStep): void
    onGuideDismissed(step?: GuideStep): void
    onGuideDismissed(until: { until: 'reload' }): void

    // feedback
    feedback: Feedback

    // Other
    validateInstall(): void
    dom(input: any): HTMLElement
}

export interface FeedbackOptions {
    anchor: HTMLElement
}
export interface Feedback {
    loginAndRedirect(options?: FeedbackOptions): void
}

export interface Debugging {
    getEventCache(): any[]
    getAllGuides(): Guide[]
    getAutoGuides(): { auto: Guide[]; override: Guide[] }
    getBadgeGuides(): Guide[]
    getLauncherGuides(): Guide[]
}

type Events = {
    [K in keyof EventCallbacks]-?: (callback: EventCallbacks[K]) => Events
}

export interface Guide {
    createdByUser: User
    createdAt: number
    lastUpdatedByUser: User
    lastUpdatedAt: number
    kind: string
    rootVersionId: string
    stableVersionId: string
    id: string
    name: string
    state: 'published' | 'staged' | 'draft' | 'disabled'
    launchMethod: 'api' | 'automatic' | 'badge' | 'dom' | 'launcher'
    isMultiStep: boolean
    steps: GuideStep[]
    attributes: {
        type: string
        device: { desktop: boolean; mobile: boolean; type: 'desktop' | 'mobile' }
        badge: any
        priority: number
        launcher: { keywords: string[] }
    }
    audience: any[]
    audienceUiHint: { filters: any[] }
    resetAt: number
    publishedAt: number
}

export interface User {
    id: string
    username: string
    first: string
    last: string
    role: number
    userType: string
}

export interface GuideStep {
    id: string
    guideId: string
    type: string
    elementPathRule: string
    contentType: string
    contentUrl?: string
    contentUrlCss?: string
    contentUrlJs?: string
    rank: number
    advanceMethod: 'button' | 'programatic' /* sic */ | 'element'
    thumbnailUrls?: string
    attributes: {
        height: number
        width: number
        autoHeight: boolean
        position: string
        css: string
        variables: any
    }
    lastUpdatedAt: number
    resetAt: number
}
