import { createFeatureSelector, createSelector } from '@ngrx/store'
import { QuestionnaireCenterMap } from '../models/questionnaire'
import { healthCheckFeatureKey, HealthCheckState } from './health-check.reducers'

const healthCheckSelector = createFeatureSelector<HealthCheckState>(healthCheckFeatureKey)

export const selectQuestionnaire = createSelector(
    healthCheckSelector,
    (state: HealthCheckState): QuestionnaireCenterMap => state.questionnaireMap
)

export const selectQuestionnaireResponse = createSelector(
    healthCheckSelector,
    (state: HealthCheckState) => state.questionnaireResponseMap
)

export const selectHealthCheckReminderInteraction = createSelector(
    healthCheckSelector,
    (state: HealthCheckState) => state.shouldRemindHealthCheck
)

export const selectHasBeenSubmittedToday = createSelector(
    healthCheckSelector,
    (state: HealthCheckState) => state.hasBeenSubmittedToday
)
