import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'sortMultiAttachment'
})
export class SortMultiAttachment implements PipeTransform {
    transform(selections: Array<any>): Array<any> {
        const returnArray: any[] = []
        const addedDomainMap = new Map()

        const findDomainIndex = (array: any | any[], sameDomain: string) => {
            for (let i = 0; i < array.length; i++) {
                if (array[i][0] === sameDomain) {
                    return i
                }
            }
            return -1
        }

        if (selections === null || selections.length === 0) {
            return returnArray
        }

        for (let i = 0; i < selections.length; i++) {
            if (!addedDomainMap.has(selections[i].domain)) {
                addedDomainMap.set(selections[i].domain, 'checked')
                const newDomain = [selections[i].domain, [selections[i].attribute]]
                returnArray.push(newDomain)
            } else {
                const domainIndex = findDomainIndex(returnArray, selections[i].domain)
                if (domainIndex >= 0) {
                    returnArray[domainIndex][1].push(selections[i].attribute)
                }
            }
        }

        return returnArray
    }
}
