import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { Observable } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { selectDependantMap } from '../dependant/dependant.selectors'
import { CalendarEntry } from '../models/calendar-entry'
import { DependantMap } from '../models/dependant'

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    private SHORT_DATE_FORMAT = 'yyyy-MM-DD'
    private dependentList: DependantMap = {}

    constructor(private http: HttpClient, private store: Store) {}

    public getAllCalendarEntries(guardianId: string, startDate: Date): Observable<CalendarEntry[]> {
        const start = moment(startDate)
            .subtract(1, 'months')
            .startOf('month')
            .format(this.SHORT_DATE_FORMAT)
        const endDate = moment(startDate)
            .add(1, 'month')
            .endOf('month')
            .format(this.SHORT_DATE_FORMAT)
        return this.http
            .get(
                `${
                    environment.config().security.backendHost
                }/guardian/${guardianId}/calendar?start_date=${start}&end_date=${endDate}`
            )
            .pipe(
                filter((data) => !!data),
                map((data) => data as any[]),
                map((data: any[]) => {
                    const entries: CalendarEntry[] = []
                    this.store
                        .select(selectDependantMap)
                        .pipe(take(1))
                        .subscribe((depList) => {
                            this.dependentList = depList
                        })

                    const calendarEvents = data.filter((e) => e.entry_type !== 'please_bring')
                    calendarEvents.forEach((entry) => {
                        const calEntry = new CalendarEntry(entry)
                        const firstNameList: string[] = []

                        entry.dependent_ids.forEach((a: string) => {
                            if (this.dependentList[a]) {
                                firstNameList.push(this.dependentList[a].firstName)
                            }
                        })
                        const active = entry.dependent_ids.every((a: string) => {
                            return this.dependentList[a].status === 'active'
                        })
                        if (
                            (entry.dependent_ids.length > 0 &&
                                calEntry.entryType !== 'center_closure') ||
                            (calEntry.entryType === 'center_closure' &&
                                !entries.find((e) => e.id === calEntry.id))
                        ) {
                            const parts = entry.start.split('-')
                            const startDate = moment(new Date(parts[0], parts[1] - 1, parts[2]))
                            const currentDate = moment()
                            if (entry.entry_type === 'absence') {
                                if (active) {
                                    entries.push({
                                        ...calEntry,
                                        dependentId: entry.dependent_ids,
                                        dependentNames: firstNameList.join('; ')
                                    })
                                } else if (currentDate > startDate) {
                                    entries.push({
                                        ...calEntry,
                                        dependentId: entry.dependent_ids,
                                        dependentNames: firstNameList.join('; ')
                                    })
                                }
                            } else {
                                entries.push({
                                    ...calEntry,
                                    dependentId: entry.dependent_ids,
                                    dependentNames: firstNameList.join('; ')
                                })
                            }
                        }
                    })
                    return entries
                })
            )
    }
}
