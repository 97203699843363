import { Pipe, PipeTransform } from '@angular/core'
import { loadDynamicMedia, MediaUtil, selectMediaMap } from '@bh/design-system'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { filter, map, tap } from 'rxjs/operators'
import { Media } from '../models/media'

@Pipe({
    name: 'photo'
})
export class PhotoPipe implements PipeTransform {
    constructor(private store: Store) {}

    transform(id?: string, thumbnail?: boolean): Observable<Media> {
        if (!id) {
            return of(new Media({ filename: '' }))
        }

        return this.store.select(selectMediaMap).pipe(
            map((mediaMap) => {
                const mediaKey = mediaMap[MediaUtil.getMediaKey(id, !!thumbnail)]
                if (mediaKey?.url) {
                    return mediaKey
                } else {
                    return mediaMap[id] ?? mediaKey
                }
            }),
            tap((media) => {
                if (!media) {
                    this.store.dispatch(loadDynamicMedia({ id, thumbnail: !!thumbnail }))
                }
            }),
            filter((media) => {
                return !!media
            }),
            map((media) => {
                return media as unknown as Media
            })
        )
    }
}
