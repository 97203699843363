import { ActionReducer, createReducer, on } from '@ngrx/store'
import {
    loadActivityFeedDomainSuccess,
    loadBrightJourneyReportsByPeriodSuccess
} from '../dependant/dependant.actions'
import { ObservationEntry } from '../models/activity-feed'
import { DocumentItem, MemoryItem } from '../memories/memories.actions'
import {
    groupedObservationsCleared,
    groupedMBJPReportsCleared,
    setDepId,
    loadCurriculumPlansSuccess,
    groupedCurriculumPlansCleared,
    loadPolaroidPhotos,
    curriculumPlansListSortToggle,
    loadDevObservationsSuccess,
    clearPolaroidPhotos
} from './my-learners.actions'
import { ActivityFeedDomain } from '../models/activity-feed-domain'
import { CurriculumPlans } from '../models/curriculum-plans'
import { Report } from '../memories/memories.reducers'
import { MemoryUtil } from '@events'

export const myLearnersFeatureKey = 'myLearners'

export interface GroupedObservations {
    observations: ObservationEntry[]
    lastFetchedDate: string
    isPaginationDisabled: boolean
}

export interface GroupedReports {
    reports: DocumentItem[]
}

export interface GroupedCurriculum {
    curriculumPlans: CurriculumPlans[]
    groupedCoverImage: string
}

export interface MyLearnersState {
    groupedDevelopmentalObservations: GroupedObservations
    groupedReports: GroupedReports
    groupedCurriculumPlans: GroupedCurriculum
    depId: string
    activityFeedDomain: ActivityFeedDomain
    polaroidPhotos: string[]
}

const initialState: MyLearnersState = {
    groupedDevelopmentalObservations: {
        observations: [],
        lastFetchedDate: '',
        isPaginationDisabled: false
    },
    groupedReports: {
        reports: []
    },
    depId: '',
    activityFeedDomain: {
        attributeList: [],
        domainList: [],
        progressionList: []
    },
    groupedCurriculumPlans: {
        curriculumPlans: [],
        groupedCoverImage: 'assets/curriculum-plans.svg'
    },
    polaroidPhotos: []
}

export const MyLearnersReducer: ActionReducer<MyLearnersState> = createReducer(
    initialState,
    on(groupedObservationsCleared, (state: MyLearnersState) => ({
        ...state,
        groupedDevelopmentalObservations: {
            observations: [],
            lastFetchedDate: '',
            isPaginationDisabled: false
        }
    })),
    on(groupedMBJPReportsCleared, (state: MyLearnersState) => ({
        ...state,
        groupedReports: {
            reports: []
        }
    })),
    on(groupedCurriculumPlansCleared, (state: MyLearnersState) => ({
        ...state,
        groupedCurriculumPlans: {
            ...state.groupedCurriculumPlans,
            curriculumPlans: []
        }
    })),
    on(
        loadDevObservationsSuccess,
        (
            state: MyLearnersState,
            { devObservations, lastFetchedDate, isPaginationDisabled, pullToRefresh }
        ) => {
            let developmentalObservation = devObservations

            developmentalObservation = developmentalObservation.map((o) => ({
                ...o,
                selections: o.selections.map((s) => ({
                    ...s,
                    attribute:
                        state.activityFeedDomain.attributeList.find((a) => a.id === s.attributeId)
                            ?.name ?? '',
                    domain:
                        state.activityFeedDomain.domainList.find((d) => d.id === s.domainId)
                            ?.name ?? '',
                    progression:
                        state.activityFeedDomain.progressionList.find(
                            (p) => p.id === s.progressionId
                        )?.name ?? ''
                }))
            }))

            developmentalObservation = developmentalObservation
                .map((o) => ({
                    ...o,
                    selectorTags: o.selections
                        .filter(
                            (s, index, self) =>
                                index === self.findIndex((i) => i.domain === s.domain)
                        )
                        .map((s) => s.domain)
                        .join(', ')
                }))
                .sort((a: any, b: any) => b.sortDate.getTime() - a.sortDate.getTime())

            //Remove duplicates we get from the cache
            //Add existing observations into a map
            const exsistingObsMap = new Map(
                state.groupedDevelopmentalObservations.observations.map((obs) => [obs.id, obs])
            )
            //Filter new observations coming back from endpoint or cache to make sure no duplicates are added
            const filteredObs = developmentalObservation.filter(
                (obs) => !exsistingObsMap.has(obs.id)
            )
            //Insert existing observations and new observations into the store
            developmentalObservation = pullToRefresh
                ? [...filteredObs, ...state.groupedDevelopmentalObservations.observations]
                : [...state.groupedDevelopmentalObservations.observations, ...filteredObs]
            
            const newDevelopmentalObservation = developmentalObservation.sort((a: any, b: any) => b.sortDate.getTime() - a.sortDate.getTime())    

            return {
                ...state,
                groupedDevelopmentalObservations: {
                    observations: newDevelopmentalObservation,
                    lastFetchedDate,
                    isPaginationDisabled
                },
                groupedReports: {
                    ...state.groupedReports
                }
            }
        }
    ),
    on(
        loadBrightJourneyReportsByPeriodSuccess,
        (state: MyLearnersState, { dependantId, reports }) => {
            const ports = extractPortfoliosFromFeed(reports || [], dependantId)
            return {
                ...state,
                groupedReports: {
                    ...state.groupedReports,
                    reports: ports
                }
            }
        }
    ),
    on(loadActivityFeedDomainSuccess, (state: MyLearnersState, { activityFeedDomain }) => ({
        ...state,
        activityFeedDomain
    })),
    on(loadCurriculumPlansSuccess, (state: MyLearnersState, { curriculumPlans }) => ({
        ...state,
        groupedCurriculumPlans: {
            ...state.groupedCurriculumPlans,
            curriculumPlans: curriculumPlans
                .slice()
                .sort((a, b) => b.date.getTime() - a.date.getTime())
        }
    })),
    on(setDepId, (state: MyLearnersState, { depId }) => {
        return {
            ...state,
            depId
        }
    }),
    on(loadPolaroidPhotos, (state: MyLearnersState, { photos }) => {
        return {
            ...state,
            polaroidPhotos: photos
        }
    }),
    on(clearPolaroidPhotos, (state: MyLearnersState) => {
        return {
            ...state,
            polaroidPhotos: []
        }
    }),
    on(curriculumPlansListSortToggle, (state: MyLearnersState) => {
        return {
            ...state,
            groupedCurriculumPlans: {
                ...state.groupedCurriculumPlans,
                curriculumPlans: state.groupedCurriculumPlans.curriculumPlans.slice().reverse()
            }
        }
    })
)

export const extractPortfoliosFromFeed = (
    reports: Report[],
    dependantId: string
): DocumentItem[] => {
    return reports
        .filter((report) => !!report.attachment_id && report.type != 'curriculum_plan')
        .map((report) => {
            return {
                dependantId,
                id: report.id,
                sortDate: new Date(report.updated || report.created),
                type: 'portfolio',
                description: report.assessment_period
                    ? report.assessment_period[0]?.toUpperCase() +
                      report.assessment_period.slice(1) +
                      ' ' +
                      report.assessment_year
                    : '',
                attachment: report.attachment_id,
                dayLabel: MemoryUtil.getDailyLabel(new Date(report.updated ?? report.created)),
                monthLabel: MemoryUtil.getMonthLabel(new Date(report.updated ?? report.created)),
                yearLabel: MemoryUtil.getYearLabel(new Date(report.updated ?? report.created))
            } as DocumentItem
        })
        .sort(sortByDate)
}

const sortByDate = (a: MemoryItem, b: MemoryItem): number => {
    return b.sortDate.getTime() - a.sortDate.getTime()
}
