import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import {
    ActivityFeed,
    DateUtil,
    Dependant,
    selectGroupedActivityForDependentOnDate,
    selectCenterInfo,
    selectDependantMap,
    selectMemoriesUrl,
    selectDependantList
} from '@events'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { combineLatest, Observable, of } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { UnsubscriberComponent } from '@bh/security'

@Component({
    selector: 'bh-daily-report',
    templateUrl: './daily-report.component.html',
    styleUrls: ['./daily-report.component.scss']
})
export class DailyReportComponent extends UnsubscriberComponent
implements OnDestroy, OnInit {
    public homeLink$: Observable<string>
    @Input() date = ''
    @Input() dependentId = ''
    @Input() centerId = ''
    public centerName = ''
    public dependent?: Dependant
    public emptyActivityFeed = new ActivityFeed({})
    public activityFeed$: Observable<ActivityFeed> = of(this.emptyActivityFeed)
    public reminderList$: Observable<any> = of({})

    constructor(protected store: Store) {
        super()
        this.homeLink$ = this.store.select(selectMemoriesUrl)
    }

    ngOnInit(): void {
        this.activityFeed$ = this.store.select(
            selectGroupedActivityForDependentOnDate(
                this.dependentId,
                DateUtil.localToUtc(new Date(this.date || new Date()))
                    .toISOString()
                    .split('T')[0]
            )
        )

        this.reminderList$ = this.activityFeed$.pipe(
            map((dataArr) => {
                return dataArr.pleaseBringReminders
            })
        )

        combineLatest([
            this.store.select(selectDependantMap),
            this.store.select(selectCenterInfo),
            this.store.select(selectDependantList)
        ])
            .pipe(takeUntil(this.destroy$))
            .subscribe((dataArr) => {
                const dependentMap = dataArr[0]
                const centerInfoMap = dataArr[1]
                const dependantList = dataArr[2]

                this.dependent = dependentMap[this.dependentId]

                const dependantScheduled = dependantList.length === 1 ? dependantList[0].id : this.dependentId

                if (dependantScheduled) {
                    const checkInDependantSchedule = 
                    dependantScheduled === this.dependentId ? true : false

                    this.dependentId = checkInDependantSchedule
                    ? this.dependentId
                    : dependantScheduled
                }

                if (!this.dependent) {
                    const foundDepId = Object.keys(dependentMap).find((id) => {
                        const dep = dependentMap[id]
                        const found = Dependant.getDependentIds(dep.scheduledCenterIds).includes(
                            this.dependentId
                        )

                        this.centerId =
                            dep.scheduledCenterIds[
                                DateUtil.getDayOfWeekForDate(
                                    moment(this.date || new Date()).toDate()
                                )
                            ].centerId
                        return found
                    })
                    this.dependent = foundDepId ? dependentMap[foundDepId] : this.dependent
                }
                
                this.centerName = centerInfoMap[this.centerId] ? centerInfoMap[this.centerId].name : ''
            })
    }

    getProfilePic(data: any): string {
        if (data === null) {
            return ''
        }

        return data.url
    }
}

export interface MediaItem {
    contentType: string
    fileName: string
    shortContentType: string
    url: string
}
