import { Inject, Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { combineLatest, of } from 'rxjs'
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators'
import { AppreciationService } from '../services/appreciation.service'
import { addReaction, deleteReaction, addReactionSuccess, addReactionError, deleteReactionSuccess, deleteReactionError, updateReaction } from '@bh/design-system'
import { DBService } from '@bh/data'
@Injectable()
export class AppreciationEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private appreciationService: AppreciationService,
        @Inject(DBService) private dbService: DBService,
    ) {
        this.logger = this.loggerFactory.getLogger('AppreciationEffects')
    }

    updateReaction = createEffect(() =>
        this.actions.pipe(
            ofType(updateReaction),
            tap(({ id, dependantId, reaction }) => this.logger.debug(`update reaction for media id ${id} dependent id ${dependantId} and reaction is ${reaction}`)),
            map(({ id, dependantId, reaction }) =>
                reaction ? addReaction({
                    id, dependantId, reaction
                }) : deleteReaction({ id, dependantId })
            )
        )
    )


    addReaction = createEffect(() =>
        this.actions.pipe(
            ofType(addReaction),
            tap(({ id, dependantId, reaction }) => this.logger.debug(`add reaction for media id ${id} dependent id ${dependantId} and reaction is ${reaction}`)),
            mergeMap(({ id, dependantId, reaction }) =>
                this.appreciationService.addReaction(dependantId, id, reaction).pipe(
                    tap(() => this.logger.debug('reation for media', id, ' dependant ', dependantId, ' ' + reaction)),
                    map(() => addReactionSuccess({ id, reaction })),
                    tap(() => {
                        combineLatest([
                            this.dbService.deleteCacheByKey('mediaCache',`mediaAttachment_${id}_true`),
                            this.dbService.deleteCacheByKey('mediaCache',`mediaAttachment_${id}_false`)
                        ]).pipe(take(1)).subscribe()
                    }),
                    catchError((error: Error) => {
                        this.logger.error(`reation for media ${id}`, error.message, error)
                        return of(addReactionError({ error }))
                    })
                )
            )
        )
    )

    deleteReaction = createEffect(() =>
        this.actions.pipe(
            ofType(deleteReaction),
            tap(({ id, dependantId }) => {
                this.logger.debug(`add reaction for media id ${id} dependent id ${dependantId}`)
            }),
            mergeMap(({ id, dependantId }) =>
                this.appreciationService.deleteReaction(dependantId, id).pipe(
                    tap((reaction) => this.logger.debug('reation for media', id, ' dependant ', dependantId, ' ' + reaction)),
                    map(() => deleteReactionSuccess({ id })),
                    tap(() => {
                        combineLatest([
                            this.dbService.deleteCacheByKey('mediaCache',`mediaAttachment_${id}_true`),
                            this.dbService.deleteCacheByKey('mediaCache',`mediaAttachment_${id}_false`)
                        ]).pipe(take(1)).subscribe()
                    }),
                    catchError((error: Error) => {
                        this.logger.error(`reation for media ${id}`, error.message, error)
                        return of(deleteReactionError({ error }))
                    })
                )
            )
        )
    )
}
