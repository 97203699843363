import { Injectable } from '@angular/core'
import {
    selectBiometrics,
    selectBiometricsAvailable,
    updateBiometrics,
    AuthenticationService
} from '@bh/security'
import { Store } from '@ngrx/store'
import { NativeBiometric, AvailableResult } from 'capacitor-native-biometric'
import { Observable, combineLatest, from } from 'rxjs'
import { GetResult, Preferences } from '@capacitor/preferences'
import { filter, take } from 'rxjs/operators'
import packageJson from '../../../../../package.json'
import { environment } from '../../../../app/src/environments/environment'
import { Biometrics, BiometricsType } from '../models/biometrics'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { TranslateService } from '@ngx-translate/core'
import { presentNotification } from '../toast/toast.actions'

@Injectable({
    providedIn: 'root'
})
export class BiometricService {
    public biometricsAvailable$: Observable<boolean>
    public biometricInfo$: Observable<Biometrics>
    private name: string = packageJson.name
    public BIOMETRICS_ENABLED: string = 'biometricsEnabled'

    public biometryType: string = ''
    private logger: LoggerService

    constructor(
        private store: Store,
        private loggerFactory: LoggerFactory,
        private translateService: TranslateService
    ) {
        this.logger = this.loggerFactory.getLogger('BiometricService')
        this.biometricsAvailable$ = this.store.select(selectBiometricsAvailable)
        this.biometricInfo$ = this.store.select(selectBiometrics)
    }

    public getBiometricsResult(): void {
        let biometryImage = 'faceid'
        combineLatest([
            this.biometricInfo$,
            from(Preferences.get({ key: this.BIOMETRICS_ENABLED })),
            from(NativeBiometric.isAvailable())
        ])
            .pipe(take(1))
            .subscribe((dataArr: [Biometrics, GetResult, AvailableResult]) => {
                const biometricsInfo: Biometrics = dataArr[0]
                const isBioEnabled: boolean | null = dataArr[1].value
                    ? JSON.parse(dataArr[1].value)
                    : null
                /* isBioEnabled
                    true = User has enabled Biometrics on this install
                    false = User has manually turned off Biometrics
                    null = User has not set Biometrics on this install
                */
                const availableResult: AvailableResult = dataArr[2]

                switch (availableResult.biometryType) {
                    case 1:
                        this.biometryType = 'Touch ID'
                        biometryImage = 'fingerprint'
                        break
                    case 2:
                        this.biometryType = 'Face ID'
                        break
                    case 3:
                        this.biometryType = 'Fingerprint'
                        biometryImage = 'fingerprint'
                        break
                    case 4:
                        this.biometryType = 'Face Auth'
                        break
                    case 5:
                        this.biometryType = 'Iris Auth'
                        biometryImage = 'iris'
                        break
                    default:
                        this.biometryType = 'Biometrics'
                        break
                }

                this.store.dispatch(
                    updateBiometrics({
                        username: biometricsInfo.username,
                        isAvailable: availableResult.isAvailable,
                        isEnabled: isBioEnabled === true && availableResult.isAvailable,
                        notifyUser: biometricsInfo.notifyUser,
                        biometryId: isBioEnabled === false ? 0 : availableResult.biometryType,
                        biometryType: this.biometryType as BiometricsType,
                        biometryImagePath: `assets/${biometryImage}.svg`
                    })
                )
            })
    }

    public saveNewCredentials(): void {
        combineLatest([
            from(Preferences.get({ key: AuthenticationService.STORAGE_USER })),
            from(Preferences.get({ key: AuthenticationService.STORAGE_PASS })),
            this.biometricInfo$
        ])
            .pipe(
                filter(
                    (dataArr: [GetResult, GetResult, Biometrics]) =>
                        dataArr[0].value !== null &&
                        dataArr[1].value !== null &&
                        dataArr[2].isAvailable
                ),
                take(1)
            )
            .subscribe((dataArr: [GetResult, GetResult, Biometrics]) => {
                const username = dataArr[0].value
                const password = dataArr[1].value
                const buildEnv = environment.config().name

                if (username && password) {
                    this.setBiometricCredentials(username, password, buildEnv).catch((error) => {
                        this.logger.debug('Failed to save new credentials: ', error)
                    })
                }
            })
    }

    public async setBiometricCredentials(
        username: string,
        password: string,
        buildEnv: string
    ): Promise<void> {
        await NativeBiometric.deleteCredentials({ server: this.name })
        await NativeBiometric.setCredentials({
            username: buildEnv !== 'prod' ? buildEnv + '-' + username : username,
            password: password,
            server: this.name
        })
    }

    public async storeBiometricsStatus(): Promise<void> {
        await Preferences.set({ key: this.BIOMETRICS_ENABLED, value: 'true' })
    }

    public async presentToast(requireUsername: boolean, isEnabled: boolean, biometryType: string) {
        const message = await this.translateService
            .get(
                requireUsername
                    ? 'login.bio.enter-username'
                    : 'settings.bio-' + (isEnabled ? 'enabled' : 'disabled'),
                { biometryType }
            )
            .toPromise()

        this.store.dispatch(
            presentNotification({
                message: message,
                color: requireUsername ? 'light' : 'success',
                icon: requireUsername ? 'information-circle' : 'checkmark-circle',
                position: 'top',
                duration: requireUsername ? 900000 : 3000,
                class: requireUsername ? 'info' : 'info success',
                canSwipe: requireUsername === false
            })
        )
    }
}
