import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { selectProfile } from '../../../../events/src/lib/profile/profile.selectors'

@Injectable({
    providedIn: 'root'
})
export class ProfileGuard {
    constructor(private store: Store) {}

    canDeactivate(): Observable<boolean> {
        return this.store.select(selectProfile).pipe(
            filter((profile) => !!profile),
            map(() => true)
        )
    }
}
