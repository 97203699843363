import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'sortFoodCard'
})
export class SortFoodCard implements PipeTransform {
    transform(foodName: string): string {
        if (foodName.length === 0) {
            return 'default'
        }

        const food = foodName.toLowerCase()
        if (food.includes('snack')) {
            return 'snack'
        } else if (food.includes('breakfast')) {
            return 'breakfast'
        }

        return 'default'
    }
}
