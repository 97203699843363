import { createAction, props } from '@ngrx/store'
import { ArrivalNote, EntryTimes } from '../models/arrival-note'

export const submitArrivalNotes = createAction(
    '[Arrival Notes] Creating Arrival Notes for dependent',
    props<{ dependentId: string; arrivalNote: ArrivalNote, date?: Date }>()
)

export const submitArrivalNotesSuccess = createAction(
    '[Arrival Notes] Success creating Arrival Notes for dependent'
)

export const submitArrivalNotesError = createAction(
    '[Arrival Notes] Error creating Arrival Notes for dependent',
    props<{ error: Error }>()
)

export const deleteArrivalNote = createAction(
    '[Arrival Notes] Deleting Arrival Note for dependent',
    props<{ dependentId: string; arrivalNoteId: string }>()
)

export const deleteArrivalNoteSuccess = createAction(
    '[Arrival Notes] Success deleting Arrival Note for dependent'
)

export const deleteArrivalNoteError = createAction(
    '[Arrival Notes] Error deleting Arrival Note for dependent',
    props<{ error: Error }>()
)

export const updateArrivalNote = createAction(
    '[Arrival Notes] Updating Arrival Note for dependent',
    props<{
        dependentId: string
        deletedNoteIds: string[]
        updatedArrivalNote: ArrivalNote
        changesFound: boolean,
        date?: Date,
    }>()
)

export const updateArrivalNoteSuccess = createAction(
    '[Arrival Notes] Success updating Arrival Note for dependent'
)

export const updateArrivalNoteError = createAction(
    '[Arrival Notes] Error updating Arrival Note for dependent',
    props<{ error: Error }>()
)

export const deleteFormEntries = createAction(
    '[Arrival Notes] Deleting Arrival Note Form for dependent',
    props<{ entries: number }>()
)

export const resetEntriesDeleted = createAction(
    '[Arrival Notes] Deleted entries cleared'
)

export const resetNoteSubmissionStatus = createAction(
    '[Arrival Notes] Reset Note Submission status'
)

export const loadFrequentEntry = createAction(
    '[Arrival Notes] Load Frequent Entry Times',
    props<{ dependentId: string }>()
)

export const loadFrequentEntrySuccess = createAction(
    '[Arrival Notes] Load Frequent Entry Times Success',
    props<{ frequentEntryTimes: EntryTimes }>()
)

export const loadFrequentEntryError = createAction(
    '[Arrival Notes] Load Frequent Entry Times Error',
    props<{ error: Error }>()
)
