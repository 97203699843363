import { Component, Input, OnInit } from '@angular/core'
import {
    setAllAccordions,
    selectExpandAll,
    selectExpanded,
    setStorageAccordions,
    AccordionComponent,
    setInitialExpandStatus,
    AccordionMap
} from '@bh/design-system'
import { GetResult, Preferences } from '@capacitor/preferences'
import { ActivityFeed } from '@events'
import { Store } from '@ngrx/store'
import { Observable, combineLatest, from, of } from 'rxjs'
import { map, take, filter } from 'rxjs/operators'
@Component({
    selector: 'bh-grouped-activity',
    templateUrl: './grouped-activity.component.html',
    styleUrls: ['./grouped-activity.component.scss']
})
export class GroupedActivityComponent implements OnInit {
    @Input() activityFeed: ActivityFeed = new ActivityFeed({})
    @Input() expandable = false
    @Input() dependentId = ''
    @Input() isTimelineView = false
    @Input() dailyReportView = false
    @Input() showReaction = true

    isExpandAll$ = of(true)
    expandedList: boolean[] = []
    selectAccordions$: Observable<AccordionMap>

    public accordionGroupId = `activityFeed`

    constructor(private store: Store) {
        this.selectAccordions$ = this.store.select(selectExpanded)
    }

    ngOnInit(): void {
        this.accordionGroupId = `${this.accordionGroupId}_${this.dependentId}`

        this.getAccordionStorage()

        this.isExpandAll$ = this.store.select(selectExpandAll).pipe(
            map((expanded) => {
                return expanded[this.accordionGroupId]
            })
        )
    }

    updateExpand() {
        this.isExpandAll$.pipe(take(1)).subscribe((isExpandAll) => {
            this.store.dispatch(
                setAllAccordions({ expanded: !isExpandAll, group: this.accordionGroupId })
            )
        })

        this.selectAccordions$.pipe(take(1)).subscribe(async (toggleMap: AccordionMap) => {
            const accordions = toggleMap[this.accordionGroupId]
            await Preferences.set({
                key: AccordionComponent.ACCORDION_STORAGE + this.accordionGroupId,
                value: JSON.stringify(accordions)
            })
        })
    }

    public getAccordionStorage() {
        combineLatest([
            this.selectAccordions$,
            from(
                Preferences.get({
                    key: AccordionComponent.ACCORDION_STORAGE + this.accordionGroupId
                })
            )
        ])
            .pipe(
                filter(
                    (dataArr: [AccordionMap, GetResult]) =>
                        dataArr[0][this.accordionGroupId] === undefined
                ),
                take(1)
            )
            .subscribe((dataArr: [AccordionMap, GetResult]) => {
                //This should only run one time, pulls in accordions from storage when the user first enters group page
                const toggleMap: AccordionMap = dataArr[0]
                const tabStorage: GetResult = dataArr[1]
                if (!toggleMap[this.accordionGroupId] && tabStorage.value) {
                    const accordions = JSON.parse(tabStorage.value)
                    const expanded = Object.values(accordions).every((data) => data === true)
                    this.store.dispatch(
                        setStorageAccordions({
                            accordions: accordions,
                            group: this.accordionGroupId
                        })
                    )
                    this.store.dispatch(
                        setInitialExpandStatus({
                            expanded: !expanded,
                            group: this.accordionGroupId
                        })
                    )
                    return
                }
                this.store.dispatch(
                    setInitialExpandStatus({ expanded: true, group: this.accordionGroupId })
                )
            })
    }
}
