import { BaseSession, RegisterPIIKey, RemovePII } from '@bh/session'

export class ParentAuthentication extends BaseSession {
    @RemovePII()
    apiKey: string
    visitorID: string
    user: string

    constructor(token: string, apiKey: string, visitorID: string, user: string, appVersion: string) {
        super(token, appVersion)

        this.apiKey = apiKey
        this.visitorID = visitorID
        this.user = user
    }
}

RegisterPIIKey(ParentAuthentication, 'token')
