import { Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Preferences } from '@capacitor/preferences'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import moment from 'moment'
import { Observable, combineLatest, of } from 'rxjs'
import {
    catchError,
    filter,
    delay,
    map,
    mergeMap,
    tap,
    withLatestFrom,
    exhaustMap
} from 'rxjs/operators'
import { loadingDone, loadingStarted } from '../loading/loading.actions'
import { Dependant, DependantsDetail } from '../models/dependant'
import { MemoriesSummary } from '../models/memories-summary'
import { DependantService } from '../services/dependant.service'
import {
    loadActivityFeed,
    loadActivityFeedByPeriod,
    loadActivityFeedByPeriodError,
    loadActivityFeedByPeriodSuccess,
    loadActivityFeedDomain,
    loadActivityFeedDomainError,
    loadActivityFeedDomainSuccess,
    loadActivityFeedError,
    loadActivityFeedSuccess,
    cancelActivityFeedRequest,
    loadBrightJourneyReportsByPeriod,
    loadBrightJourneyReportsByPeriodError,
    loadBrightJourneyReportsByPeriodSuccess,
    cancelLoadBrightJourneyReportsRequest,
    loadDependant,
    loadDependantError,
    loadDependantSuccess,
    loadMemoriesSummaryForDependantList,
    loadMemoriesSummaryForDependantListError,
    loadMemoriesSummaryForDependantListSuccess,
    loadMultiMemoriesByPeriod,
    loadMultiMemoriesByPeriodError,
    loadMultiMemoriesByPeriodSuccess,
    loadLatestMultiMemories,
    loadMemoriesPorfolioByPeriodSuccess,
    loadDependantByDate,
    loadDependantByDateSuccess
} from './dependant.actions'
import { MemoryUtil } from '../utils/memory-util'
import { MemoryDocument, MemoryMedia } from '../models/memory-data'
import { selectActivityFeed } from './dependant.selectors'
import { loadCenterInfo } from '../center-info/center-info.actions'
import { AttendanceService } from '../services/attendance.service'

@Injectable()
export class DependantEffects {
    public static readonly LATEST_MEMORY_LOADED_KEY = 'latestMemory'

    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private dependantService: DependantService,
        private store: Store,
        private attendanceService: AttendanceService
    ) {
        this.logger = this.loggerFactory.getLogger('DependantEffects')
    }

    loadDependant = createEffect(() =>
        this.actions.pipe(
            ofType(loadDependant),
            tap((guardianId) => {
                this.logger.debug(`Starting to load dependant info for ${guardianId}`)
                this.store.dispatch(loadingStarted({ model: 'Dependant' }))
            }),
            mergeMap(({ guardianId }) =>
                this.dependantService.getDependantList(guardianId).pipe(
                    tap((dependants) => this.logger.debug('Dependants loaded', dependants)),
                    mergeMap((dependantList: Dependant[]) => [
                        loadDependantSuccess({ dependantList }),
                        loadingDone({ model: 'Dependant' })
                    ]),
                    catchError((error: Error) => {
                        this.logger.error('Error loading dependants', error.message, error)
                        this.store.dispatch(loadingDone({ model: 'Dependant' }))
                        return of(loadDependantError({ error }))
                    })
                )
            )
        )
    )

    loadDependantByDate = createEffect(() =>
        this.actions.pipe(
            ofType(loadDependantByDate),
            tap(({ guardianId, date }) => {
                this.logger.debug(`Starting to load dependant info for ${guardianId} for ${date}`)
                this.store.dispatch(loadingStarted({ model: 'Dependant' }))
            }),
            mergeMap(({ guardianId, date }) =>
                this.dependantService
                    .getDependantListByDate(
                        guardianId, 
                        moment(date).format('yyyy-MM-DD'), 
                        encodeURI(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    )
                    .pipe(
                        tap((dependantDetail) =>
                            this.logger.debug(
                                'Dependant details loaded',
                                dependantDetail,
                                ' ',
                                date
                            )
                        ),
                        filter(
                            (dependantDetail: DependantsDetail) =>
                                dependantDetail.dependents.length > 0
                        ),
                        mergeMap((dependantDetail: DependantsDetail) => {
                            const actions: Action[] = []
                            dependantDetail.centers.forEach((cen) =>
                                actions.push(loadCenterInfo({ centerInfoId: cen }))
                            )
                            actions.push(
                                loadDependantSuccess({ dependantList: dependantDetail.dependents })
                            )
                            actions.push(
                                loadDependantByDateSuccess({
                                    dependantListDate: dependantDetail.scheduledForDate,
                                    dependantListNextDate: dependantDetail.scheduledNextDate,
                                    date: moment(date).format('yyyy-MM-DD'),
                                    centers: dependantDetail.centers
                                })
                            )
                            actions.push(loadingDone({ model: 'Dependant' }))

                            return actions
                        }),
                        catchError((error: Error) => {
                            this.logger.error(
                                'Error loading dependant details by date',
                                error.message,
                                error
                            )
                            this.store.dispatch(loadingDone({ model: 'Dependant' }))
                            return of(loadDependantError({ error }))
                        })
                    )
            )
        )
    )

    loadActivityFeed = createEffect(() =>
        this.actions.pipe(
            ofType(loadActivityFeed),
            tap(({ dependantId, date }) => {
                this.logger.debug(`Starting to load activity feed for ${dependantId} for ${date}`)
                this.store.dispatch(loadingStarted({ model: 'ActivityFeed' }))
            }),
            mergeMap(({ dependantId, date }) => {
                return combineLatest([
                    this.dependantService.getActivityFeed(dependantId, date),
                    this.attendanceService.getAttendanceFromCenterByDate(dependantId, date)
                ]).pipe(
                    tap(([activityFeed]) =>
                        this.logger.debug(`Activity Feed loaded for ${dependantId}`, activityFeed)
                    ),
                    mergeMap(([activityFeed, dependantAttendanceData]) => {
                        return [
                            loadActivityFeedSuccess({
                                date: moment(date).format('yyyy-MM-DD'),
                                activityFeed,
                                dependantAttendance: dependantAttendanceData || []
                            }),
                            loadingDone({ model: 'ActivityFeed' })
                        ]
                    }),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error loading activity feed for ${dependantId}`,
                            error.message,
                            error
                        )
                        this.store.dispatch(loadingDone({ model: 'ActivityFeed' }))
                        return of(loadActivityFeedError({ error }))
                    })
                )
            })
        )
    )

    loadMemoriesSummaryForDependantList = createEffect(() =>
        this.actions.pipe(
            ofType(loadMemoriesSummaryForDependantList),
            delay(500),
            tap(({ dependantIdList }) =>
                this.logger.debug(
                    `Starting to load memories summary for dependents`,
                    dependantIdList
                )
            ),
            mergeMap(({ dependantIdList }) => {
                return combineLatest(
                    dependantIdList.map((d) => this.dependantService.getDailyReportSummary(d))
                ).pipe(
                    tap((memoriesSummaryList: MemoriesSummary[][]) => {
                        this.logger.debug(
                            'Loaded memories summary for dependents',
                            dependantIdList,
                            memoriesSummaryList
                        )
                    }),
                    map((memoriesSummaryList: MemoriesSummary[][]) => {
                        return loadMemoriesSummaryForDependantListSuccess({ memoriesSummaryList })
                    }),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error loading memories summary dependents`,
                            dependantIdList,
                            error.message,
                            error
                        )

                        return of(loadMemoriesSummaryForDependantListError({ error }))
                    })
                )
            })
        )
    )

    loadActivityFeedByPeriod = createEffect(() =>
        this.actions.pipe(
            ofType(loadActivityFeedByPeriod),
            tap(({ dependantId, start, end }) => {
                this.logger.debug(
                    `Starting to load activity feed for ${dependantId} for period ${start} - ${end}`
                )
                this.store.dispatch(loadingStarted({ model: 'ActivityFeed' }))
            }),
            mergeMap(({ dependantId, start, end, memoryId }) =>
                this.dependantService
                    .getActivityFeedByPeriod(
                        dependantId,
                        moment(start).format('yyyy-MM-DD'),
                        moment(end).format('yyyy-MM-DD')
                    )
                    .pipe(
                        tap((activityFeed) =>
                            this.logger.debug(
                                `Activity Feed loaded for ${dependantId} for period`,
                                activityFeed
                            )
                        ),
                        mergeMap((activityFeed) => {
                            return [
                                loadActivityFeedByPeriodSuccess({
                                    dependantId,
                                    activityFeed,
                                    memoryId
                                }),
                                loadingDone({ model: 'ActivityFeed' })
                            ]
                        }),
                        catchError((error: Error) => {
                            this.logger.error(
                                `Error loading activity feed for ${dependantId} for period`,
                                error.message,
                                error
                            )
                            this.store.dispatch(loadingDone({ model: 'ActivityFeed' }))
                            return of(loadActivityFeedByPeriodError({ error }))
                        })
                    )
            )
        )
    )

    cancelAPIRequest = createEffect(
        () =>
            this.actions.pipe(
                ofType(cancelActivityFeedRequest),
                exhaustMap(() => this.dependantService.cancelActivityFeedRequest())
            ),
        { dispatch: false }
    )

    cancelMbjRequest = createEffect(
        () =>
            this.actions.pipe(
                ofType(cancelLoadBrightJourneyReportsRequest),
                exhaustMap(() => this.dependantService.cancelMbjRequest()),
                map(() => loadingDone({ model: 'Report' }))
            )
    )

    loadActivityFeedDomain = createEffect(() =>
        this.actions.pipe(
            ofType(loadActivityFeedDomain),
            tap(() => {
                this.logger.debug(`Starting to load activity feed domains`)
                this.store.dispatch(loadingStarted({ model: 'ActivityFeedDomain' }))
            }),
            mergeMap(() =>
                this.dependantService.getActivityFeedDomain().pipe(
                    tap((activityFeedDomain) =>
                        this.logger.debug(`Activity Feed domain loaded`, activityFeedDomain)
                    ),
                    mergeMap((activityFeedDomain) => [
                        loadActivityFeedDomainSuccess({
                            activityFeedDomain
                        }),
                        loadingDone({ model: 'ActivityFeedDomain' })
                    ]),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error loading activity feed domains`,
                            error.message,
                            error
                        )
                        this.store.dispatch(loadingDone({ model: 'ActivityFeedDomain' }))
                        return of(loadActivityFeedDomainError({ error }))
                    })
                )
            )
        )
    )

    loadBrightJourneyReportsByPeriod = createEffect(() =>
        this.actions.pipe(
            ofType(loadBrightJourneyReportsByPeriod),
            tap(({ dependantId, start, end }) => {
                this.logger.debug(
                    `Starting to Bright Journey Reports for ${dependantId} for period ${start} - ${end}`
                )
                this.store.dispatch(loadingStarted({ model: 'Report' }))
            }),
            mergeMap(({ dependantId, start, end }) =>
                this.dependantService
                    .getBrightJourneyReportsByPeriod(
                        dependantId,
                        start.toISOString().split('T')[0],
                        end.toISOString().split('T')[0]
                    )
                    .pipe(
                        tap((reports) =>
                            this.logger.debug(
                                `Bright Journey Reports loaded for ${dependantId} for period`,
                                reports
                            )
                        ),
                        mergeMap((reports) => [
                            loadBrightJourneyReportsByPeriodSuccess({
                                dependantId,
                                reports
                            }),
                            loadingDone({ model: 'Report' })
                        ]),
                        catchError((error: Error) => {
                            this.logger.error(
                                `Error loading Bright Journey Reports for ${dependantId} for period`,
                                error.message,
                                error
                            )
                            this.store.dispatch(loadingDone({ model: 'Report' }))
                            return of(loadBrightJourneyReportsByPeriodError({ error }))
                        })
                    )
            )
        )
    )

    loadMultiMemoriesByPeriod = createEffect(() =>
        this.actions.pipe(
            ofType(loadMultiMemoriesByPeriod),
            tap(({ memoryMediaRequest }) => {
                this.logger.debug(
                    `Starting to load multiple memories for dependents ${JSON.stringify(
                        memoryMediaRequest
                    )}`
                )
                this.store.dispatch(loadingStarted({ model: 'Memories' }))
            }),
            mergeMap(({ memoryMediaRequest, loadMedia, loadReports }) => {
                const { dependentIdList, startDate, endDate } = memoryMediaRequest
                const loadMemories: Array<Observable<MemoryMedia[] | MemoryDocument[]>> = []
                if (loadMedia) {
                    loadMemories.push(
                        this.dependantService.getDependentMediasByPeriod(
                            dependentIdList,
                            startDate,
                            endDate
                        )
                    )
                }
                if (loadReports) {
                    loadMemories.push(
                        this.dependantService.getDependetDocumentsByPeriod(
                            dependentIdList,
                            startDate,
                            endDate
                        )
                    )
                }
                return combineLatest(loadMemories).pipe(
                    tap(() => {
                        Preferences.set({
                            key: DependantEffects.LATEST_MEMORY_LOADED_KEY,
                            value: moment().format('yyyy-MM-DD')
                        })
                    }),
                    withLatestFrom(this.store.select(selectActivityFeed)),
                    mergeMap((memoryData) => {
                        const [[media, documents], dependentFeeds] = memoryData
                        const memoriesMedia = loadMedia ? (media as MemoryMedia[]) : []
                        const memoryReports = loadReports ? (media as MemoryDocument[]) : []
                        const memoriesDocuments =
                            loadMedia && loadReports
                                ? (documents as MemoryDocument[])
                                : memoryReports
                        const activityFeeds = dependentFeeds

                        const brightJourneyReports = memoriesDocuments?.filter((doc) =>
                            MemoryUtil.isValidMemoriesPortfolio(
                                doc.type,
                                doc.subType,
                                doc.attachment
                            )
                        )

                        return [
                            loadMultiMemoriesByPeriodSuccess({
                                loadMedia,
                                loadReports,
                                memoriesDataResponse: {
                                    memoriesMedia,
                                    memoriesDocuments
                                },
                                dependantIdsList: memoryMediaRequest.dependentIdList,
                                activityFeeds
                            }),
                            loadMemoriesPorfolioByPeriodSuccess({
                                memoriesDocuments: brightJourneyReports || []
                            }),
                            loadingDone({ model: 'Memories' })
                        ]
                    }),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error loading multiple memories for dependents ${memoryMediaRequest.dependentIdList
                                .map((dependentId: string) => dependentId)
                                .join(',')}`,
                            error.message,
                            error
                        )
                        this.store.dispatch(loadingDone({ model: 'Memories' }))
                        return of(loadMultiMemoriesByPeriodError({ error }))
                    })
                )
            })
        )
    )

    loadLatestMultiMemories = createEffect(() =>
        this.actions.pipe(
            ofType(loadLatestMultiMemories),
            tap(({ dependentIdList }) =>
                this.logger.debug(
                    `Starting to load memories for dependents ${dependentIdList.join(',')}`
                )
            ),
            withLatestFrom(Preferences.get({ key: DependantEffects.LATEST_MEMORY_LOADED_KEY })),
            filter(([_, latestMemoryDate]) => !!latestMemoryDate.value),
            mergeMap(([{ dependentIdList }, latestMemoryDate]) => {
                const startDate = moment(latestMemoryDate.value ?? undefined)
                    .subtract(1, 'day')
                    .format('yyyy-MM-DD')
                const endDate = moment().format('yyyy-MM-DD')
                return combineLatest([
                    this.dependantService.getDependentMediasByPeriod(
                        dependentIdList,
                        startDate,
                        endDate
                    ),
                    this.dependantService.getDependetDocumentsByPeriod(
                        dependentIdList,
                        startDate,
                        endDate
                    )
                ]).pipe(
                    tap(() => {
                        Preferences.set({
                            key: DependantEffects.LATEST_MEMORY_LOADED_KEY,
                            value: moment().format('yyyy-MM-DD')
                        })
                    }),
                    withLatestFrom(this.store.select(selectActivityFeed)),
                    mergeMap((dataArr) => {
                        const [[memoriesMedia, memoriesDocuments], activityFeedState] = dataArr
                        const activityFeeds = activityFeedState
                        const brightJourneyReports = memoriesDocuments.filter((doc) =>
                            MemoryUtil.isValidMemoriesPortfolio(
                                doc.type,
                                doc.subType,
                                doc.attachment
                            )
                        )

                        return [
                            loadMultiMemoriesByPeriodSuccess({
                                memoriesDataResponse: {
                                    memoriesMedia,
                                    memoriesDocuments
                                },
                                loadMedia: true,
                                loadReports: true,
                                dependantIdsList: dependentIdList,
                                activityFeeds
                            }),
                            loadMemoriesPorfolioByPeriodSuccess({
                                memoriesDocuments: brightJourneyReports
                            })
                        ]
                    }),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error loading multiple memories for dependents ${dependentIdList.join(
                                ','
                            )}`,
                            error.message,
                            error
                        )
                        return of(loadMultiMemoriesByPeriodError({ error }))
                    })
                )
            })
        )
    )
}
