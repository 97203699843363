import { createFeatureSelector, createSelector } from '@ngrx/store'
import { balanceInfoFeatureKey, BalanceState } from './balance-info.reducers'

const balanceInfoSelector = createFeatureSelector<BalanceState>(balanceInfoFeatureKey)

export const selectUserIsPlayer = createSelector(balanceInfoSelector, (state: BalanceState) => {
    return state.isPlayer
})

export const selectBalanceInfo = createSelector(balanceInfoSelector, (state: BalanceState) => {
    return state.balanceList
})

export const selectReminders = createSelector(balanceInfoSelector, (state: BalanceState) => {
    return state.reminders
})

export const selectBalanceRemindersNotification = createSelector(balanceInfoSelector, (state: BalanceState) => {
    return state.remindersNotification
})

export const selectUnreadBalanceRemindersNotificationCount = createSelector(balanceInfoSelector, (state: BalanceState) =>
    state.remindersNotification.filter(
        (n, index, array) =>
            n.showInHomepage &&
            n.isReminder &&
            !n.readInReminders &&
            index ===
                array.findIndex(
                    (findn) => findn.startDate === n.startDate && findn.message === n.message && findn.dependentId === n.dependentId
                )
    ).length
)

export const selectUserCanShowMyBalance = createSelector(balanceInfoSelector, (state: BalanceState) => {
    return state.isShowMyBalance
})

export const selectBalanceCenterId = createSelector(balanceInfoSelector, (state: BalanceState) => {
    return state.centerId
})
