import { AttendanceTransitionType } from './attendance-transition'

export class CenterAttendance {
    id: string
    dependentId: string
    status: CenterAttendanceType
    note: string
    inTime: Date
    outTime: Date
    date: string
    dependentName: string

    constructor(data: any, dependentName = '') {
        this.id = data.id
        this.dependentId = data.dependent_id
        this.status = data.status
        this.note = data.note
        this.date = data.for_date
        this.inTime = data.in_time && new Date(data.in_time)
        this.outTime = data.out_time && new Date(data.out_time)
        this.dependentName = dependentName
    }
}

export type CenterAttendanceType = AttendanceTransitionType | 'sick' | 'vacation' | 'eta' | 'other'
