import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { LoggerFactory, LoggerService } from '@bh/logging'
import {
    GroupedObservations,
    Media,
    ObservationEntry,
    PhotoPipe,
    clearRemainingFilter,
    loadingDone,
    loadingStarted,
    selectRemainingStageToFilter
} from '@events'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { combineLatest, from, of } from 'rxjs'
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { CurriculumPlans } from '../models/curriculum-plans'
import { selectProfile } from '../profile/profile.selectors'
import { MyLearnersObservations, MyLearnersService } from '../services/my-learners.service'
import {
    portfolioListClicked,
    portfolioListRouted,
    backToMyLearners,
    backToMyLearnersRouted,
    observationsLandingClicked,
    observationsLandingRouted,
    observationsListClicked,
    observationsListRouted,
    loadCurriculumPlans,
    loadCurriculumPlansSuccess,
    loadCurriculumPlansError,
    cancelLoadCurriculumPlanRequest,
    ourCurriculumBannerClicked,
    outCurriculumBannerRouted,
    loadPolaroidPhotos,
    updatePolaroidPhotos,
    curriculumPlansListClicked,
    curriculumPlansListRouted,
    portfolioDetailClicked,
    portfolioDetailRouted,
    curriculumPlansClicked,
    observationsDetailClicked,
    loadDevObservations,
    loadDevObservationsSuccess,
    loadDevObservationsError,
    cancelLoadObservationsRequest
} from './my-learners.actions'
import {
    selectCurriculumPlans,
    selectGroupedDevelopmentalObservations,
    selectGroupedMBJPReports,
    selectLastFetchedDate
} from './my-learners.selectors'
import { DeepLinkingService } from '@deep-linking'
import moment from 'moment'
import { InfiniteScrollCustomEvent } from '@ionic/angular'

@Injectable()
export class MyLearnersEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private router: Router,
        private store: Store,
        private myLearnersService: MyLearnersService,
        private translateService: TranslateService,
        private photoPipe: PhotoPipe
    ) {
        this.logger = this.loggerFactory.getLogger('MyLearnersEffects')
    }

    handleMyLearnersClick = createEffect(() =>
        this.actions.pipe(
            ofType(backToMyLearners),
            tap(() => this.logger.debug(`Navigating to share portfolio page`)),
            mergeMap(() => {
                return this.store.select(selectProfile).pipe(filter((profile) => !!profile))
            }),
            mergeMap((data) => {
                return from(this.router.navigate(['/home', data!.id, 'my-learners'])).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => backToMyLearnersRouted())
                )
            })
        )
    )

    handlePortfolioListClick = createEffect(() =>
        this.actions.pipe(
            ofType(portfolioListClicked),
            tap(() => this.logger.debug(`Navigating to share portfolio page`)),
            mergeMap(() => {
                return from(this.router.navigate(['/journey-portfolios'])).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => portfolioListRouted())
                )
            })
        )
    )

    handleObservationsLandingClick = createEffect(() =>
        this.actions.pipe(
            ofType(observationsLandingClicked),
            tap(() => this.logger.debug(`Navigating to observations landing page`)),
            mergeMap(() => {
                return this.store.select(selectProfile).pipe(filter((profile) => !!profile))
            }),
            mergeMap((data) => {
                return from(this.router.navigate(['developmental-observations'])).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => observationsLandingRouted())
                )
            })
        )
    )

    handleObservationsListClick = createEffect(() =>
        this.actions.pipe(
            ofType(observationsListClicked),
            tap(() => this.logger.debug(`Navigating to observations list page`)),
            mergeMap(() => {
                return this.store.select(selectProfile).pipe(filter((profile) => !!profile))
            }),
            mergeMap((data) => {
                return from(
                    this.router.navigate(['developmental-observations/observations-list'])
                ).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => observationsListRouted())
                )
            })
        )
    )

    getCurriculumPlans = createEffect(() =>
        this.actions.pipe(
            ofType(loadCurriculumPlans),
            tap(({ dependentId }) => {
                this.logger.debug(`Starting to load all Curriculum Plans for ${dependentId}`)
                this.store.dispatch(loadingStarted({ model: 'CurriculumPlans' }))
            }),
            mergeMap(({ dependentId, startDate, endDate }) =>
                this.myLearnersService.getCurriculumPlans(dependentId, startDate, endDate).pipe(
                    tap((curriculumPlans: CurriculumPlans[]) =>
                        this.logger.debug('Curriculum Plans loaded', curriculumPlans)
                    ),
                    map((curriculumPlans: CurriculumPlans[]) => {
                        this.store.dispatch(loadingDone({ model: 'CurriculumPlans' }))
                        return loadCurriculumPlansSuccess({
                            curriculumPlans
                        })
                    }),
                    catchError((error: Error) => {
                        this.store.dispatch(loadingDone({ model: 'CurriculumPlans' }))
                        this.logger.error('Error loading Curriculum Plans', error.message, error)
                        return of(loadCurriculumPlansError({ error }))
                    })
                )
            )
        )
    )

    handleOurCurriculumBanner = createEffect(() =>
        this.actions.pipe(
            ofType(ourCurriculumBannerClicked),
            tap(() => this.logger.debug(`Navigating to our curriculum info`)),
            mergeMap(() => {
                return from(this.router.navigate(['assessment-planning'])).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => outCurriculumBannerRouted())
                )
            })
        )
    )

    createPolaroidPhotos = createEffect(() =>
        this.actions.pipe(
            ofType(updatePolaroidPhotos),
            switchMap(({ startDate, endDate }) =>
                this.store.select(selectGroupedDevelopmentalObservations).pipe(
                    filter((data: GroupedObservations) => data.observations.length > 0),
                    take(1),
                    switchMap(async (data: GroupedObservations) => {
                        if (!startDate) {
                            return loadPolaroidPhotos({ photos: [] })
                        }
                        const validObservations = data.observations.filter((a) =>
                            moment(a.capturedAt).isBetween(
                                startDate,
                                endDate ? endDate : moment().toDate(),
                                'day',
                                '[]'
                            )
                        )
                        const photoRotation: Media[] = []
                        const observationAttachments = validObservations
                            .filter((a: ObservationEntry) => a.attachmentId)
                            .sort(() => Math.random() - 0.5) //Randomly sorting
                        for (const observation of observationAttachments) {
                            const mediaItem = await this.photoPipe
                                .transform(observation.attachmentId, true)
                                .pipe(
                                    filter((media) => !!media.url),
                                    take(1)
                                )
                                .toPromise()
                            /* Added condition to exclude media with placeholder since this is being shared with memories
                            and placeholders are being created for videos without thumbnail */
                            if (
                                mediaItem.shortContentType === 'image' &&
                                mediaItem.url &&
                                !mediaItem.url.includes('assets/memories-placeholder.jpg')
                            ) {
                                photoRotation.push(mediaItem)
                            }
                            if (
                                photoRotation.length === 3 ||
                                photoRotation.length === observationAttachments.length
                            ) {
                                //If we have 3 random images or if user has only one/two images in total
                                return loadPolaroidPhotos({
                                    photos: photoRotation.map((a: Media) => a.url)
                                })
                            }
                        }
                        // Will make it here if user has more than 3 observations with attachments and less than 3 images
                        if (!photoRotation.length) {
                            return loadPolaroidPhotos({ photos: [] })
                        }

                        return loadPolaroidPhotos({
                            photos: photoRotation.map((a: Media) => a.url)
                        })
                    })
                )
            )
        )
    )

    handleCurriculumPlansListClick = createEffect(() =>
        this.actions.pipe(
            ofType(curriculumPlansListClicked),
            tap(() => this.logger.debug(`Navigating to curriculum plans list`)),
            mergeMap(() => {
                return from(this.router.navigate(['curriculum-plans'])).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => curriculumPlansListRouted())
                )
            })
        )
    )

    handlePortfolioDetailClick = createEffect(() =>
        this.actions.pipe(
            ofType(portfolioDetailClicked),
            tap(({ url }) => this.logger.debug(`Navigating to portfolio detail list ${url}`)),
            mergeMap(({ url, isFromDeepLink }) => {
                return combineLatest([
                    this.photoPipe.transform(url),
                    this.translateService.get('documents.portfolio'),
                    this.store.select(selectGroupedMBJPReports)
                ]).pipe(
                    filter(([media, , mbjpReports]) => !!media.url && !!mbjpReports.reports.length),
                    take(1),
                    tap(([mediaItem, title]) => {
                        const fileName = mediaItem.fileName ? mediaItem.fileName : 'portfolio'
                        this.router.navigate(
                            ['/pdf-viewer', mediaItem.url, title, `${fileName}.pdf`],
                            {
                                queryParams: {
                                    isFromDeepLink
                                }
                            }
                        )
                        DeepLinkingService.setMyLearningEvent(null)
                    }),
                    map(() => portfolioDetailRouted())
                )
            })
        )
    )

    handleObservationsDetailClick = createEffect(
        () =>
            this.actions.pipe(
                ofType(observationsDetailClicked),
                tap(({ id }) => this.logger.debug(`Navigating to observations detail page ${id}`)),
                mergeMap(({ id, dependentId, isFromDeepLink }) => {
                    return this.store.select(selectGroupedDevelopmentalObservations).pipe(
                        filter((data: GroupedObservations) => data.observations.length > 0),
                        map((observationsList) => {
                            const observation = observationsList.observations.find(
                                (ob) => ob.id === id
                            )
                            return { isFromDeepLink, observation }
                        }),
                        filter((data) => !!data.observation),
                        take(1),
                        tap(({ observation }) => {
                            this.router.navigate(
                                ['developmental-observations', 'observations-details', dependentId],
                                {
                                    queryParams: {
                                        isFromDeepLink,
                                        memoryId: observation?.id
                                    },
                                    state: observation
                                }
                            )
                            DeepLinkingService.setMyLearningEvent(null)
                        })
                    )
                })
            ),
        { dispatch: false }
    )

    handleCurriculumPlansClicked = createEffect(() =>
        this.actions.pipe(
            ofType(curriculumPlansClicked),
            tap(({ id }) => this.logger.debug(`Navigating to curriculum plan page ${id}`)),
            mergeMap(({ id, date, isFromDeepLink }) => {
                return this.store.select(selectCurriculumPlans).pipe(
                    filter((curriculums) => !!curriculums.curriculumPlans.length),
                    take(1),
                    tap(() => {
                        this.router.navigate(
                            [
                                'curriculum-plans',
                                'curriculum-plan',
                                { date: moment(date).format('M/D'), attachmentId: id }
                            ],
                            {
                                queryParams: {
                                    isFromDeepLink
                                }
                            }
                        )
                        DeepLinkingService.setMyLearningEvent(null)
                    }),
                    map(() => portfolioDetailRouted())
                )
            })
        )
    )

    loadDevObservations = createEffect(() =>
        this.actions.pipe(
            ofType(loadDevObservations),
            tap(({ dependentId }) => {
                this.logger.debug(`Starting to load all Curriculum Plans for ${dependentId}`)
                this.store.dispatch(loadingStarted({ model: 'DevObservations' }))
            }),
            concatLatestFrom(() => [
                this.store.select(selectLastFetchedDate),
                this.store.select(selectRemainingStageToFilter)
            ]),
            switchMap(
                ([
                    {
                        dependentId,
                        startDate,
                        endDate,
                        stageHistory,
                        limit,
                        paginationEvent,
                        pullToRefresh
                    },
                    lastFetchedDate,
                    stagesRemainingToFilter
                ]) => {
                    return this.myLearnersService
                        .getDevObservations(
                            dependentId,
                            startDate,
                            endDate ?? lastFetchedDate,
                            limit
                        )
                        .pipe(
                            tap((devObservations: MyLearnersObservations) =>
                                this.logger.debug(
                                    'Developmental Observations loaded ',
                                    devObservations.observations.length
                                )
                            ),
                            map((devObservations: MyLearnersObservations) => {
                                if (paginationEvent) {
                                    //If paginationEvent is passed in we are on the list view and we'll call complete when API finishes
                                    ;(
                                        paginationEvent as InfiniteScrollCustomEvent
                                    ).target.complete()
                                }

                                //If theres no observations left, but we still have remaining stages to pull observation from
                                if (
                                    !devObservations.observations.length &&
                                    stagesRemainingToFilter.length > 1 &&
                                    stageHistory?.length
                                ) {
                                    //Get Start and End dates for the next stage in the filtering process to recall API
                                    const nextStageIndex: number = stageHistory
                                        .map((d: any) => d.stage)
                                        .indexOf(stagesRemainingToFilter[1])
                                    const nextStageStart = moment(
                                        stageHistory[nextStageIndex].startDate
                                    ).format('YYYY-MM-DD')
                                    const nextStageEnd = stageHistory[nextStageIndex].endDate
                                        ? moment(stageHistory[nextStageIndex].endDate).format(
                                              'YYYY-MM-DD'
                                          )
                                        : moment().format('YYYY-MM-DD')

                                    //Clear first stage in the list, we have pulled in all observations from this stage
                                    this.store.dispatch(clearRemainingFilter())
                                    this.store.dispatch(loadingDone({ model: 'DevObservations' }))

                                    //Recall the loadDevObservations API with the next stage on the filter
                                    return loadDevObservations({
                                        dependentId,
                                        startDate: nextStageStart,
                                        endDate: nextStageEnd,
                                        stageHistory,
                                        limit,
                                        paginationEvent
                                    })
                                }

                                this.store.dispatch(loadingDone({ model: 'DevObservations' }))
                                return loadDevObservationsSuccess({
                                    devObservations: devObservations.observations,
                                    lastFetchedDate: devObservations.lastFetchedDate,
                                    pullToRefresh,
                                    isPaginationDisabled: devObservations.observations.length === 0
                                    //If Observations are empty, it means we are at the end of the list and we should disable pagination
                                })
                            }),
                            catchError((error: Error) => {
                                this.store.dispatch(loadingDone({ model: 'DevObservations' }))
                                this.logger.error(
                                    'Error loading Developmental Observations',
                                    error.message,
                                    error
                                )
                                return of(loadDevObservationsError({ error }))
                            })
                        )
                }
            )
        )
    )

    cancelLoadObservationsRequest = createEffect(
        () =>
            this.actions.pipe(
                ofType(cancelLoadObservationsRequest),
                exhaustMap(() => this.myLearnersService.cancelObservationRequest()),
                tap(() => this.store.dispatch(loadingDone({ model: 'DevObservations' })))
            ),
        { dispatch: false }
    )

    cancelLoadCurriculumPlanRequest = createEffect(
        () =>
            this.actions.pipe(
                ofType(cancelLoadCurriculumPlanRequest),
                exhaustMap(() => this.myLearnersService.cancelCurriculumPlanRequest()),
                tap(() => this.store.dispatch(loadingDone({ model: 'CurriculumPlans' })))
            ),
        { dispatch: false }
    )
}
