import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import moment from 'moment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { Absence } from '../models/attendance'
import { AttendanceTransitionType } from '../models/attendance-transition'
import { CenterAttendance } from '../models/center-attedance'
import { ETAAttendance } from '../models/eta-attendance'
import { ParentAttendance } from '../models/parent-attendance'

@Injectable({
    providedIn: 'root'
})
export class AttendanceService {
    constructor(private http: HttpClient) {}

    public getAttendance(
        dependentId: string,
        dependentName: string,
        startDate: Date
    ): Observable<CenterAttendance[]> {
        const date = moment(startDate).format('yyyy-MM-DD')
        return this.http
            .get(
                `${
                    environment.config().security.backendHost
                }/dependent/${dependentId}/attendance?start_date=${date}&dependent_id=${dependentId}`
            )
            .pipe(
                map((data) => {
                    return data
                        ? (data as any[])
                              .filter((a) => !!a && !a.deleted)
                              .map((d) => new CenterAttendance(d, dependentName))
                        : []
                })
            )
    }

    public submitAbsence(absence: Absence) {
        return this.http
            .post(
                `${environment.config().security.backendHost}/dependent/${
                    absence.dependentId
                }/attendance/absence/${absence.startDate}`,
                absence
            )
            .pipe(
                map(() => {
                    return
                })
            )
    }

    public updateAbsence(absence: Absence) {
        return this.http
            .patch(
                `${environment.config().security.backendHost}/dependent/${
                    absence.dependentId
                }/attendance/absence/${absence.startDate}`,
                absence
            )
            .pipe(
                map(() => {
                    return
                })
            )
    }

    public deleteAbsence(absence: Absence) {
        return this.http
            .delete(
                `${environment.config().security.backendHost}/dependent/${
                    absence.dependentId
                }/attendance/absence/${absence.startDate}`
            )
            .pipe(
                map(() => {
                    return
                })
            )
    }

    public getArrival(guardianId: string, dependentId: string): Observable<ETAAttendance> {
        return this.http
            .get(
                `${
                    environment.config().security.backendHost
                }/dependent/${dependentId}/guardian/${guardianId}/eta`
            )
            .pipe(
                map((data) => {
                    const newData = data === 'null' || data === null ? { eta: undefined } : data
                    return new ETAAttendance(newData)
                })
            )
    }

    public submitArrival(
        isHere: boolean,
        eta: string,
        guardianId: string,
        dependentId: string
    ): Observable<ETAAttendance> {
        return this.http
            .post(
                `${
                    environment.config().security.backendHost
                }/dependent/${dependentId}/guardian/${guardianId}/eta`,
                {
                    is_here: isHere,
                    eta
                }
            )
            .pipe(
                map((data) => {
                    const newData = data === 'null' || data === null ? {} : data
                    return new ETAAttendance(newData)
                })
            )
    }

    public getAttendanceFromParentByDate(
        dependentId: string,
        date: Date
    ): Observable<ParentAttendance> {
        return this.http
            .get(
                `${
                    environment.config().security.backendHost
                }/dependent/${dependentId}/attendance/guardian/${moment(date).format('yyyy-MM-DD')}`
            )
            .pipe(
                map((data) => new ParentAttendance(data))
            )
    }

    public getAttendanceFromCenterByDate(
        dependentId: string,
        date: Date
    ): Observable<CenterAttendance[]> {
        return this.http
            .get<any[]>(
                `${
                    environment.config().security.backendHost
                }/dependent/${dependentId}/attendance/${moment(date).format('yyyy-MM-DD')}`
            )
            .pipe(
                map((data: any[]) => data.map((d) => new CenterAttendance(d)))
            )
    }

    public submitAttendance(
        dependentId: string,
        guardianId: string,
        action: AttendanceTransitionType
    ): Observable<ParentAttendance> {
        return this.http
            .post(
                `${
                    environment.config().security.backendHost
                }/dependent/${dependentId}/attendance/guardian/${guardianId}`,
                {
                    is_checked_in: action === 'in',
                    capture_time: new Date().toISOString()
                }
            )
            .pipe(map((data) => new ParentAttendance(data)))
    }
}
