import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import moment from 'moment'
import { of, Subject, Observable } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { Cacheable } from '@bh/data'
import { ActivityFeed } from '../models/activity-feed'
import { ActivityFeedDomain } from '../models/activity-feed-domain'
import { Dependant, DependantsDetail } from '../models/dependant'
import { DailyReportSummary, MemoriesSummary } from '../models/memories-summary'
import { MemoryDocument, MemoryMedia } from '../models/memory-data'

@Injectable({
    providedIn: 'root'
})
export class DependantService {
    private readonly WEEK_TO_MS = 1000 * 60 * 60 * 24 * 7

    public cancelActivityFeedRequest$ = new Subject<void>()
    private cancelMbjRequest$ = new Subject<void>()

    constructor(private http: HttpClient) {}

    // Cache is set to 4hrs - that will apprximately follow the B* sync pattern that happens in MBD.
    @Cacheable({ key: 'dependentList', isList: true, ttl: 1.44e7 })
    private _getDependantList(guardianId: string): Observable<any[]> {
        return this.http.get<unknown[]>(
            `${environment.config().security.backendHost}/dependents/guardian/${guardianId}`
        )
    }

    public getDependantList(guardianId: string): Observable<Dependant[]> {
        return this._getDependantList(guardianId).pipe(
            map((data: unknown[]) =>
                (data || [])
                    .map((d) => new Dependant(d))
                    .filter((d) => {
                        const today = new Date().getTime()
                        const enrollmentDate = new Date(d.enrollmentDate || '').getTime()
                        const enrolledWithinWeek = enrollmentDate - today < this.WEEK_TO_MS

                        return (
                            enrolledWithinWeek &&
                            (d.status === 'active' || d.status === 'suspended')
                        )
                    })
                    .sort((a, b) => {
                        const aDob = new Date(a.birthDate || '')
                        const bDob = new Date(b.birthDate || '')

                        if (isNaN(aDob.getTime())) {
                            return 1
                        } else if (isNaN(bDob.getTime())) {
                            return -1
                        }

                        if (aDob > bDob) {
                            return -1
                        } else if (bDob > aDob) {
                            return 1
                        }

                        return a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
                    })
            )
        )
    }

    // Cache is set to 4hrs - that will apprximately follow the B* sync pattern that happens in MBD.
    @Cacheable({ key: 'dependentListDate', isList: false, ttl: 1.44e7 })
    private _getDependantListByDate(guardianId: string, date: string, device_timezone: string): Observable<any[]> {
        return this.http.get<unknown[]>(
            `${environment.config().security.backendHost}/dependents/guardian/${guardianId}/${date}`,
            {
                params: {
                    device_timezone
                }
            }
        )
    }

    public getDependantListByDate(guardianId: string, date: string, deviceTimezone: string): Observable<DependantsDetail> {
        return this._getDependantListByDate(guardianId, date, deviceTimezone).pipe(
            map((data: unknown) => new DependantsDetail(data))
        )
    }

    @Cacheable('activityFeed')
    private _getActivityFeed(dependantId: string, date: string): Observable<unknown> {
        return this.http.get<unknown>(
            `${
                environment.config().security.backendHost
            }/dependent/${dependantId}/daily_report/${date}`
        )
    }

    public getActivityFeed(dependantId: string, date: Date): Observable<ActivityFeed | null> {
        return this._getActivityFeed(dependantId, moment(date).format('yyyy-MM-DD')).pipe(
            map((data: unknown) => {
                if (data) {
                    return new ActivityFeed(data)
                } else {
                    return null
                }
            })
        )
    }

    @Cacheable({ key: 'activityFeedByPeriod', isList: true })
    private _getActivityFeedByPeriod(
        dependantId: string,
        start: string,
        end: string
    ): Observable<unknown[]> {
        return this._getLatestActivityFeedByPeriodRaw(dependantId, start, end)
    }

    private _getLatestActivityFeedByPeriodRaw(
        dependantId: string,
        start: string,
        end: string
    ): Observable<unknown[]> {
        return this.http
            .get<unknown[]>(
                `${
                    environment.config().security.backendHost
                }/dependent/${dependantId}/daily_reports`,
                {
                    params: {
                        start,
                        end
                    }
                }
            )
            .pipe(takeUntil(this.cancelActivityFeedRequest$))
    }

    public getActivityFeedByPeriod(
        dependantId: string,
        start: string,
        end: string
    ): Observable<ActivityFeed[]> {
        return this._getActivityFeedByPeriod(dependantId, start, end).pipe(
            map((data: unknown[]) => data.map((act) => new ActivityFeed(act)))
        )
    }

    @Cacheable({ key: 'observationSelections', ttl: 8.64e7 })
    public getActivityFeedDomain(): Observable<ActivityFeedDomain> {
        return this.http
            .get<unknown[]>(`${environment.config().security.backendHost}/observation/selections`)
            .pipe(map((data: unknown) => new ActivityFeedDomain(data)))
    }

    public getBrightJourneyReportsByPeriod(
        dependantId: string,
        start: string,
        end: string
    ): Observable<any> {
        return this._getBrightJourneyReportsByPeriod(dependantId, start, end)
    }

    @Cacheable({ key: 'brightJourneyReportsByPeriod', isList: true })
    private _getBrightJourneyReportsByPeriod(
        dependantId: string,
        start: string,
        end: string
    ): Observable<any> {
        return this.http.get<unknown[]>(
            `${
                environment.config().security.backendHost
            }/dependent/${dependantId}/my_bright_journey_reports`,
            {
                params: {
                    start,
                    end
                }
            }
        ).pipe(
            takeUntil(this.cancelMbjRequest$)
        )
    }

    public getDailyReportSummary(dependantId: string): Observable<MemoriesSummary[]> {
        return this._getDailyReportSummary(dependantId).pipe(
            map((list: any[]) => {
                return list
                    .map((s) => {
                        const momentDate = moment(s.for_date)
                        return {
                            date: momentDate.format('YYYY-MM-DD'),
                            dependentIdList: [dependantId],
                            lastSnapshot: this.getLastSnapshot(s),
                            sortDate: momentDate.valueOf(),
                            units: []
                        }
                    })
                    .sort((a, b) => {
                        return b.sortDate - a.sortDate
                    })
            })
        )
    }

    @Cacheable({ key: 'getDailyReportSummary', isList: true })
    private _getDailyReportSummary(dependantId: string): Observable<any> {
        return this.http.get<unknown>(
            `${
                environment.config().security.backendHost
            }/dependent/${dependantId}/daily_reports/aggregate/summary`
        )
    }

    public getDependentMediasByPeriod(
        dependantIdList: Array<string>,
        startDate: string,
        endDate: string
    ): Observable<MemoryMedia[]> {
        return this._getDependentMediasByPeriod(dependantIdList, startDate, endDate).pipe(
            map((data: unknown[]) => {
                return data.map((mediaData) => new MemoryMedia(mediaData))
            })
        )
    }

    @Cacheable({ key: 'getDependentMediasByPeriod', isList: true })
    private _getDependentMediasByPeriod(
        dependantIdList: Array<string>,
        startDate: string,
        endDate: string
    ): Observable<unknown[]> {
        return this.http.post<unknown[]>(
            `${environment.config().security.backendHost}/dependent/memories/media`,
            dependantIdList,
            { params: { start_date: startDate, end_date: endDate } }
        )
    }

    public getDependetDocumentsByPeriod(
        dependantIdList: Array<string>,
        startDate: string,
        endDate: string
    ): Observable<MemoryDocument[]> {
        return this._getDependetDocumentsByPeriod(dependantIdList, startDate, endDate).pipe(
            map((data: unknown[]) => {
                return data.map((documentData) => new MemoryDocument(documentData))
            })
        )
    }

    @Cacheable({ key: 'getDependetDocumentsByPeriod', isList: true })
    private _getDependetDocumentsByPeriod(
        dependantIdList: Array<string>,
        startDate: string,
        endDate: string
    ): Observable<unknown[]> {
        return this.http.post<unknown[]>(
            `${environment.config().security.backendHost}/dependent/memories/document`,
            dependantIdList,
            { params: { start_date: startDate, end_date: endDate } }
        )
    }

    public cancelActivityFeedRequest() {
        this.cancelActivityFeedRequest$.next()
        return of(null)
    }

    public cancelMbjRequest() {
        this.cancelMbjRequest$.next()
        return of(null)
    }

    private getLastSnapshot(s: DailyReportSummary) {
        const snapshot = s.snapshot_entries.length > 0 ? s.snapshot_entries[0] : null
        const observation = s.observation_entries.length > 0 ? s.observation_entries[0] : null
        const lastSnapshot = {
            id: snapshot?.attachment_id ?? observation?.attachment_id ?? '',
            created: snapshot?.created ?? observation?.created ?? ''
        }
        return lastSnapshot
    }
}
