import { DOCUMENT } from '@angular/common'
import { AfterViewInit, Component, Inject, Input, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { selectActiveBottomNavIndex, selectTopNavListOpened } from '@bh/design-system'
import {
    loadDependantByDate,
    loadProfileSuccess,
    Profile,
    selectCalendarminDate,
    selectCalendarStatus,
    selectIsLoading,
    selectProfile
} from '@events'
import { IonContent, Platform } from '@ionic/angular'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { Observable } from 'rxjs'
import { delay, filter, map, switchMap, take, tap } from 'rxjs/operators'

@Component({
    selector: 'bh-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements AfterViewInit {
    @Input() scrollingCallback = (pos: number): number => {
        return pos
    }
    @ViewChild(IonContent, { static: false }) content!: IonContent

    public listOpened$: Observable<boolean>
    public selectedBottomNav$: Observable<number>
    public showCalendar$: Observable<boolean>
    public isLoading$: Observable<boolean>
    public minDate$: Observable<Date>

    constructor(
        private store: Store,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document,
        private platform: Platform
    ) {
        this.listOpened$ = this.store.select(selectTopNavListOpened).pipe(map((res) => !!res))
        this.selectedBottomNav$ = this.store.select(selectActiveBottomNavIndex)

        this.activatedRoute.params
            .pipe(
                switchMap((params) =>
                    this.store.select(selectProfile).pipe(
                        take(1),
                        tap((profile) => {
                            if (!profile || !profile.id) {
                                this.store.dispatch(
                                    loadProfileSuccess({
                                        profile: new Profile({ id: params.guardianId })
                                    })
                                )
                            }
                        }),
                        map(() => new Profile({ id: params.guardianId }))
                    )
                )
            )
            .subscribe((profile) => {
                this.store.dispatch(
                    loadDependantByDate({ guardianId: profile.id, date: moment().toDate() })
                )
            })

        this.showCalendar$ = this.store.select(selectCalendarStatus)
        this.isLoading$ = this.store.select(selectIsLoading)
        this.minDate$ = this.store.select(selectCalendarminDate)

        this.router.events
            .pipe(
                filter((e) => !!this.content && e instanceof NavigationEnd),
                map((e) => e as NavigationEnd),
                delay(300)
            )
            .subscribe(() => {
                this.scrollToTop()
            })
    }

    ngAfterViewInit() {
        if (this.platform.platforms().includes('iphone')) {
            const version = window.navigator.userAgent.match(/\b[\d]+_[\d]+(?:_[\d]+)?\b/)
            if (version && +version[0].replace(/_/g, '').substring(0, 3) >= 154) {
                const toolbar = this.document.querySelectorAll(
                    'ion-header > ion-toolbar'
                ) as NodeListOf<HTMLElement>

                toolbar.forEach((t) => (t.style.paddingTop = '0'))
            }
        }
    }

    public scrollToTop(): void {
        const content = this.document.querySelector('.contentWrapper')
        content && content.scrollIntoView({ behavior: 'auto', block: 'end' })
    }

    public updateScrollPosition(event: any): void {
        this.scrollingCallback(event.detail.scrollTop)
    }
}
