import { createAction, props } from '@ngrx/store'

import { ToastLevel } from '../enums'
import { APIError } from '../models/api-error'

export const presentToast = createAction(
    '[Toast] Present Toast',
    props<{ message: string | APIError; level: ToastLevel; }>()
)

export const presentNotification = createAction(
    '[Toast] Present Notification',
    props<{
        message: string | APIError
        color: string
        icon?: string
        position?: string
        duration?: number
        class?: string
        canSwipe?: boolean
    }>()
)

export const dismissNotification = createAction('[Toast] Dismiss Notification')
