import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class AlertsService {
    private MOCK_ALERTS: any[] = [
        {
            id: '1'
        },
        {
            id: '2'
        },
        {
            id: '3'
        }
    ]

    public getEmergencyAlerts(): Observable<any[]> {
        return of(this.MOCK_ALERTS)
    }
}
