import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { Notification } from '../models/notification'

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private http: HttpClient) {}

    private _getNotifications(guardianId: string): Observable<unknown[]> {
        return this.http.get<unknown[]>(
            `${environment.config().security.backendHost}/guardian/${guardianId}/notifications`
        )
    }

    public getNotifications(guardianId: string): Observable<Notification[]> {
        return this._getNotifications(guardianId).pipe(
            map((data: unknown[]) =>
                data && data.length > 0
                    ? data
                          .map((d) => new Notification(d))
                          .sort((a, b) => b.sortDate.getTime() - a.sortDate.getTime())
                    : []
            )
        )
    }

    public updateNotificationReadStatus(
        guardianId: string,
        notificationId: string,
        readInMessages: boolean,
        readInReminders: boolean,
        isRead = true
    ): Observable<void> {
        return this.http
            .patch<unknown[]>(
                `${
                    environment.config().security.backendHost
                }/guardian/${guardianId}/notification/${notificationId}`,
                {
                    read: isRead,
                    read_in_messages: readInMessages,
                    read_in_reminders: readInReminders
                }
            )
            .pipe(
                map(() => {
                    return
                })
            )
    }

    public markNotificationAsDeleted(
        guardianId: string,
        notificationId: string,
        isRead: boolean,
        showInMessages: boolean,
        showInReminders: boolean,
        showInHomepage: boolean,
        readInMessages: boolean,
        readInReminders: boolean
    ): Observable<void> {
        return this.http
            .patch<unknown[]>(
                `${
                    environment.config().security.backendHost
                }/guardian/${guardianId}/notification/${notificationId}`,
                {
                    read: isRead,
                    show_in_messages: showInMessages,
                    show_in_reminders: showInReminders,
                    show_in_homepage: showInHomepage,
                    read_in_messages: readInMessages,
                    read_in_reminders: readInReminders
                }
            )
            .pipe(
                map(() => {
                    return
                })
            )
    }
}
