import { createAction, props } from '@ngrx/store'
import { FamilyInfo } from '../models/family-info'

export interface BalanceCenter {
    id: string
    name: string
}

export const loadFamilyInfo = createAction(
    '[Balances] Load Family Info',
    props<{ centers: BalanceCenter[] }>()
)

export const familyInfoSuccess = createAction(
    '[Balances] Listed Family Info with success',
    props<{ familyInfo: FamilyInfo; centers: BalanceCenter[] }>()
)

export const familyInfoError = createAction(
    '[Balances] Error listing all Family Notes',
    props<{ error: Error }>()
)

export const setFamilyInfoCenterId = createAction(
    '[Balances] Set the family info center id',
    props<{ centerId: string }>()
)
