import { ActionReducer, createReducer, on } from '@ngrx/store'
import {
    loadSettingsSuccess,
    setTuitionMsgId,
    toggleTuitionAlert,
    updateSettingsSuccess
} from './settings.actions'

export const settingsFeatureKey = 'settings'

export type SubscriptionType =
    | 'checkin_email'
    | 'checkin_push'
    | 'checkout_email'
    | 'checkout_push'
    | 'daily_report_email'
    | 'intraday_email'
    | 'intraday'
    | 'intraday_bathroom'
    | 'intraday_meal'
    | 'intraday_medication'
    | 'intraday_nap'
    | 'intraday_note'
    | 'intraday_skin'
    | 'mcc_development_email'
    | 'mcc_development_push'
    | 'mcc_learning_at_home_email'
    | 'mcc_learning_at_home_push'
    | 'mapp_learning_at_home_push'
    | 'mbj_portfolio_email'
    | 'mbj_portfolio_push'
    | 'marketing_announcement_email'
    | 'center_announcement_push'
    | 'new_email_or_text_push'
    | 'classroom_activity_push'
    | 'center_closure_push'
    | 'media_push'
    | 'due_date_push'
    | 'observation_push'
    | 'questionnaire_six'
    | 'questionnaire_seven'
    | 'questionnaire_eight'
    | 'questionnaire_nine'
    | 'please_bring_six'
    | 'please_bring_seven'
    | 'please_bring_eight'
    | 'please_bring_nine'
    | 'all-general-notifications'
    | 'all-email-notifications'
    | 'all-care-events-notifications'
    | 'curriculum_plan_email'
    | 'curriculum_plan_push'
    | 'bulk_download_email'
    | 'bulk_download_push'
    | ''

export interface SettingItem {
    key: SubscriptionType
    name: string
    enabled: boolean
    primary: boolean,
    sort?: number
}

export interface SubscriptionsResponse {
    guardianId: string
    subscriptions: {
        subscriptions: Subscriptions
    }
}

export interface Subscriptions {
    checkin_email: boolean
    checkin_push: boolean
    checkout_email: boolean
    checkout_push: boolean
    daily_report_email: boolean
    intraday_email: boolean
    intraday: boolean
    intraday_bathroom: boolean
    intraday_meal: boolean
    intraday_medication: boolean
    intraday_nap: boolean
    intraday_note: boolean
    intraday_skin: boolean
    mcc_development_email: boolean
    mcc_development_push: boolean
    mcc_learning_at_home_email: boolean
    mcc_learning_at_home_push: boolean
    mapp_learning_at_home_push: boolean
    mbj_portfolio_email: boolean
    mbj_portfolio_push: boolean
    marketing_announcement_email: boolean
    center_announcement_push: boolean
    new_email_or_text_push: boolean
    classroom_activity_push: boolean
    center_closure_push: boolean
    media_push: boolean
    due_date_push: boolean
    observation_push: boolean
    questionnaire_six: boolean
    questionnaire_seven: boolean
    questionnaire_eight: boolean
    questionnaire_nine: boolean
    please_bring_six: boolean
    please_bring_seven: boolean
    please_bring_eight: boolean
    please_bring_nine: boolean
    curriculum_plan_email: boolean
    curriculum_plan_push: boolean
    bulk_download_email: boolean,
    bulk_download_push: boolean
}

export interface TuitionAlert {
    decline: boolean
    messageId: string
    visible: boolean
}

export interface SettingsState {
    subscriptions: Subscriptions
    tuitionAlert: TuitionAlert
}

const initialState: SettingsState = {
    subscriptions: {
        checkin_email: false,
        checkin_push: true,
        checkout_email: true,
        checkout_push: true,
        daily_report_email: true,
        intraday_email: true,
        intraday: true,
        intraday_bathroom: true,
        intraday_meal: true,
        intraday_medication: true,
        intraday_nap: true,
        intraday_note: true,
        intraday_skin: true,
        mcc_development_email: true,
        mcc_development_push: true,
        mcc_learning_at_home_email: true,
        mcc_learning_at_home_push: true,
        mapp_learning_at_home_push: true,
        mbj_portfolio_email: true,
        mbj_portfolio_push: true,
        marketing_announcement_email: true,
        center_announcement_push: true,
        new_email_or_text_push: true,
        classroom_activity_push: true,
        center_closure_push: true,
        media_push: true,
        due_date_push: true,
        observation_push: true,
        questionnaire_six: true,
        questionnaire_seven: true,
        questionnaire_eight: true,
        questionnaire_nine: true,
        please_bring_six: true,
        please_bring_seven: true,
        please_bring_eight: true,
        please_bring_nine: true,
        curriculum_plan_email: true,
        curriculum_plan_push: true,
        bulk_download_email: true,
        bulk_download_push: true
    },
    tuitionAlert: {
        decline: false,
        messageId: '',
        visible: false
    }
}

export const SettingsReducer: ActionReducer<SettingsState> = createReducer(
    initialState,
    on(loadSettingsSuccess, (state: SettingsState, { subscriptions }) => {
        return {
            ...state,
            subscriptions
        }
    }),
    on(updateSettingsSuccess, (state: SettingsState, { subscriptions }) => {
        return {
            ...state,
            subscriptions: {
                ...subscriptions
            }
        }
    }),
    on(toggleTuitionAlert, (state: SettingsState, { decline }) => {
        return {
            ...state,
            tuitionAlert: {
                ...state.tuitionAlert,
                decline,
                visible: !state.tuitionAlert.visible
            }
        }
    }),
    on(setTuitionMsgId, (state: SettingsState, { messageId }) => {
        return {
            ...state,
            tuitionAlert: {
                ...state.tuitionAlert,
                messageId
            }
        }
    })
)
