export class QuestionnaireResponseRequestBody {
    response_groups: QRResponseGroup[]

    constructor(data: any) {
        this.response_groups = (data.response_groups || []).map((r: any) => new QRResponseGroup(r))
    }
}

export class QRResponseGroup {
    group_description: string
    responses: Response[]

    constructor(data: any) {
        this.group_description = data.group_description
        this.responses = (data.responses || []).map((r: any) => new QRResponse(r))
    }
}

export class QRResponse {
    kind: string
    text: string
    alert_value: string
    is_required: boolean
    should_alert: boolean
    options: string[]
    response_value: string

    constructor(data: any) {
        this.kind = data.kind
        this.text = data.text
        this.alert_value = data.alert_value
        this.is_required = data.is_required
        this.should_alert = data.should_alert
        this.options = data.options
        this.response_value = data.response_value
    }
}
