import { Component, Input } from '@angular/core'
import { BackgroundClassType, documentsClicked } from '@bh/design-system'
import {
    ActivityFeedEntry,
    Dependant,
    loadActivityFeedByPeriod,
    selectDependantMap,
    MediaAltText,
    MemoryUtil
} from '@events'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
    selector: 'bh-activity-card',
    templateUrl: './activity-card.component.html',
    styleUrls: ['./activity-card.component.scss']
})
export class ActivityCardComponent {
    @Input() activity?: ActivityFeedEntry
    @Input() dependentId = ''
    @Input() isTimelineView = false
    @Input() dailyReportView = false
    @Input() showReaction = true
    @Input() calenderFeed: any
    @Input() cardBackgroundClass: BackgroundClassType = ''
    public mediaAltTextList: MediaAltText = {
        image: 'memories.imageAltText',
        audio: 'memories.audioAltText',
        video: 'memories.videoAltText'
    }

    public dependent$: Observable<Dependant>

    constructor(private store: Store) {
        this.dependent$ = this.store
            .select(selectDependantMap)
            .pipe(map((depMap) => depMap[this.dependentId]))
    }

    getWitwhtImg(selections: string | undefined) {
        if (selections === undefined) {
            return `assets/learning-at-home.svg`
        }

        const montessoriDomains = [
            'Art',
            'Cultural Studies/Science',
            'Extensions',
            'Language',
            'Manipulative',
            'Math',
            'Practical Life',
            'Sensorial'
        ]

        const selection = selections.split(',')[0]

        const path = montessoriDomains.includes(selection) ? 'Montessori' : selection

        return `assets/${path}.svg`
    }

    selectMedia(unit: any, title = '', description = '') {
        this.store.dispatch(
            loadActivityFeedByPeriod({
                dependantId: this.dependentId,
                start: unit.createdDate,
                end: unit.createdDate,
                memoryId: unit.id
            })
        )

        this.store.dispatch(
            documentsClicked({
                selection: unit.activityType,
                id: MemoryUtil.getMemoryId(unit.id, this.dependentId),
                title,
                description
            })
        )
    }

    convertToDate(date: string) {
        if (date) {
            const day = moment(date, 'HH:mm a').toDate()
            return day
        }
        return undefined
    }

    checkTimeInput(start?: any, end?: any) {
        if (start && end) {
            let start_date = moment(start.split('T')[0]).format('YYYY-MM-DD')
            let end_date = moment(end.split('T')[0]).format('YYYY-MM-DD')
            if (start_date < end_date) {
                return true
            }
        }
        return false
    }

    getDate(date?: any) {
        if (date) {
            return moment(date.split('T')[0]).format('MM/DD/YYYY')
        }
        return ''
    }
}
