<ng-container *ngIf="dependant$ | async as dependant">
    <ng-container *ngIf="item$ | async as item">
        <ng-container
            *ngIf="item.attachmentId | photo | async as media; else dailyMediaNotLoaded"
        >
            <div
                class="event-info"
                [ngClass]="{
                    hidden: isZoomActive
                }"
            >
                <ng-container *ngIf="dependant.photoId | photo | async as depMedia">
                    <bh-dynamic-profile
                        size="medium"
                        [src]="depMedia.url"
                        [initials]="dependant.dependentInitials"
                    >
                    </bh-dynamic-profile>
                </ng-container>

                <div class="autor-info">
                    <div>
                        <bh-heading
                            *ngIf="item.type === 'note'"
                            [text]="'note.day' | translate : { name: dependant.firstName }"
                        >
                        </bh-heading>
                        <bh-text *ngIf="center$ | async as center" [text]="center.name"></bh-text>
                        <bh-text
                            *ngIf="item.type === 'observation' || item.type === 'snapshot'"
                            class="date"
                            size="small"
                            [text]="(item.sortDate | date : 'MMM dd, yyyy') || ''"
                        ></bh-text>
                    </div>
                    <bh-text
                        *ngIf="item.type === 'note'"
                        class="date"
                        size="small"
                        [text]="(item.sortDate | date : 'MMM dd, yyyy') || ''"
                    ></bh-text>
                </div>
            </div>
            <swiper [zoom]="media.shortContentType === 'image'" class="mySwiper" #swiperElement>
                <ng-template swiperSlide>
                    <div
                        class="direction"
                        [ngClass]="{
                            'swiper-zoom-container': media.shortContentType === 'image',
                            zoomActive: isZoomActive
                        }"
                    >
                        <bh-memories-media
                            *ngIf="
                                (item.type !== 'note' && item.type !== 'observation') ||
                                item.attachmentId
                            "
                            class="mediaImg"
                            [src]="item.attachmentId || 'assets/memories-placeholder.jpg'"
                            [mediaType]="media.shortContentType"
                            [thumbnail]="dependant.photoId || 'assets/memories-placeholder.jpg'"
                            [applyZoomFormat]="true"
                            [id]="item.attachmentId"
                            [ngClass]="{
                                'swiper-zoom-target': media.shortContentType === 'image'
                            }"
                            [mediaAltTextList]="mediaAltTextList"
                            [reactionClass]="'reaction-container-detail'"
                            [reactionSide]="'end'"
                            [dependentId]="item.dependentId"
                            [showReaction]="true"
                        >
                        </bh-memories-media>

                        <div class="note-container" *ngIf="item.description">
                            <bh-text [innerHTML]="item.description" class="card-text"></bh-text>
                        </div>

                        <div
                            [ngClass]="{
                                hidden: isZoomActive
                            }"
                        >
                            <ng-container
                                *ngIf="
                                    (item.type === 'observation' ||
                                        $any(item).activityType === 'observation') &&
                                    item.selections &&
                                    item.selections.length > 0
                                "
                            >
                                <div class="observation-container">
                                    <div class="title">
                                        <bh-text
                                            class="title"
                                            [text]="
                                                'documents.developmentalobservation' | translate
                                            "
                                        ></bh-text>
                                    </div>

                                    <div class="observation">
                                        <div
                                            *ngFor="
                                                let attach of item.selections;
                                                last as lastAttach
                                            "
                                        >
                                            <bh-heading
                                                *ngIf="attach.length > 0"
                                                text="{{ attach[0] }}"
                                                size="small"
                                            ></bh-heading>

                                            <ul *ngIf="attach.length > 1" class="attribute-list">
                                                <li *ngFor="let attribute of attach[1]">
                                                    <bh-text
                                                        size="small"
                                                        *ngIf="attribute"
                                                        [innerHTML]="attribute"
                                                        class="small card-text"
                                                    ></bh-text>
                                                </li>
                                            </ul>

                                            <hr class="divider" *ngIf="!lastAttach" />
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div
                                class="witwht-wrapper"
                                *ngIf="
                                    item.type === 'snapshot' &&
                                    (item.witwhtDescription || item.title)
                                "
                            >
                                <bh-heading
                                    *ngIf="
                                        item.witwhtDescription !== null &&
                                        item.witwhtDescription !== undefined &&
                                        item.title !== null &&
                                        item.title !== ''
                                    "
                                    [text]="'home.witwht' | translate"
                                    size="small"
                                    class="witwht-title"
                                ></bh-heading>
                                <div
                                    class="observation-container"
                                    *ngIf="
                                        item.witwhtDescription !== null &&
                                        item.witwhtDescription !== undefined &&
                                        item.title !== undefined &&
                                        item.title !== ''
                                    "
                                >
                                    <div class="observation">
                                        <bh-heading [text]="item.title" size="small"></bh-heading>

                                        <ul
                                            class="attribute-list"
                                            *ngIf="item.witwhtDescription !== ''"
                                        >
                                            <li>
                                                <bh-text
                                                    size="small"
                                                    [innerHTML]="item.witwhtDescription"
                                                    class="small card-text"
                                                ></bh-text>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </swiper>
        </ng-container>
    </ng-container>

    <ng-template #dailyMediaNotLoaded>
        <div class="noMedia">
            <img src="assets/memories-placeholder.jpg" alt="Rectangle photo" />
        </div>
    </ng-template>
</ng-container>
