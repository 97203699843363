import { RemovePII } from '@bh/session'

export class DependentContact {
    id: string
    firstName: string
    lastName?: string
    @RemovePII()
    phoneList?: string[]
    pickupTime?: string
    @RemovePII()
    currentPhone?: string
    isRestricted?: boolean

    constructor(data: any) {
        this.id = data.id
        this.firstName = data.first_name
        this.lastName = data.last_name
        this.phoneList = data.phone_numbers && Object.values(data.phone_numbers).filter((p) => !!p)
        this.isRestricted = !!data.restrict_access_to_peer_guardian_contacts
    }
}
