import { ActionReducer, createReducer, on } from '@ngrx/store'
import { TuitionMessage } from '../models/tuition'
import { listTuitionMessagesSuccess, postTuitionAcknowledgmentSuccess } from './tuition.actions'

export const tuitionFeatureKey = 'tuition'

export interface TuitionState {
    messagesList: TuitionMessage[]
}

const initialState: TuitionState = {
    messagesList: []
}

export const TuitionMessagesReducer: ActionReducer<TuitionState> = createReducer(
    initialState,
    on(listTuitionMessagesSuccess, (state: TuitionState, { messagesList }) => ({
        ...state,
        messagesList
    })),
    on(postTuitionAcknowledgmentSuccess, (state: TuitionState, { messageId }) => ({
        ...state,
        messagesList: state.messagesList.filter((m) => m.messageId !== messageId)
    }))
)
