import { ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild } from '@angular/core'
import { ChildActivationEnd, Router } from '@angular/router'
import { configBottomNav } from '@bh/design-system'
import { UnsubscriberComponent } from '@bh/security'
import { Store } from '@ngrx/store'
import { merge, Observable, of } from 'rxjs'
import { filter, map, takeUntil } from 'rxjs/operators'
import { selectScrollDirection } from './events/nav.selectors'

@Component({
    selector: 'bh-nav-wrapper',
    templateUrl: './nav-wrapper.component.html',
    styleUrls: ['./nav-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavWrapperComponent extends UnsubscriberComponent {
    public hasQuickScroll$: Observable<boolean>

    private bottomConfig = [
        {
            icon: 'md-home',
            text: 'home',
            isActive: true,
            data: 'activity-feed'
        },
        {
            icon: 'md-memories',
            text: 'memories',
            isActive: false,
            data: 'memories'
        },
        {
            icon: 'md-my-learners',
            text: 'learning',
            isActive: false,
            data: 'my-learners'
        },
        {
            icon: 'md-calendar',
            text: 'calendar',
            isActive: false,
            data: 'calendar'
        }
    ]

    @ViewChild('fab', { static: false }) fab!: ElementRef
    constructor(
        private store: Store,
        private router: Router,
        private renderer: Renderer2
    ) {
        super()
        this.store.dispatch(
            configBottomNav({
                config: this.bottomConfig
            })
        )

        this.hasQuickScroll$ = merge(
            this.router.events.pipe(
                filter((event) => event instanceof ChildActivationEnd),
                map((e) => e as ChildActivationEnd),
                filter((e) => e.snapshot.data.quickActions !== undefined && !e.snapshot.component),
                map((e) => e.snapshot.data.quickActions)
            ),
            of(this.router.url).pipe(map((url: string) => !!url.match(/home\/(.*)\/activity-feed/)))
        )

        this.handleFabOnMove()
    }

    handleFabOnMove() {
        this.store
            .select(selectScrollDirection)
            .pipe(
                filter(() => !!this.fab && !!this.fab.nativeElement.querySelector('ion-fab')),
                takeUntil(this.destroy$)
            )
            .subscribe((isScrolledUp) => {
                const fabElement = this.fab.nativeElement.querySelector('ion-fab')
                if (isScrolledUp) {
                    this.renderer.setStyle(fabElement, 'bottom', '0')
                } else {
                    this.renderer.removeStyle(fabElement, 'bottom')
                }
            })
    }
}
