import { createFeatureSelector, createSelector } from "@ngrx/store";
import { arrivalNotesFeatureKey, ArrivalNotesState } from "./arrival-notes.reducers";

const arrivalNotesSelector = createFeatureSelector<ArrivalNotesState>(arrivalNotesFeatureKey)

export const selectEntriesDeleted = createSelector(
    arrivalNotesSelector,
    (state: ArrivalNotesState) => state.entriesDeleted
)

export const selectEntriesToDelete = createSelector(
    arrivalNotesSelector,
    (state: ArrivalNotesState) => state.entriesToDelete
)

export const selectNoteSubmissionStatus = createSelector(
    arrivalNotesSelector,
    (state: ArrivalNotesState) => state.noteSubmissionStatus
)

export const selectFrequentEntry = createSelector(
    arrivalNotesSelector,
    (state: ArrivalNotesState) => state.entryTimes
)
