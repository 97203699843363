import { Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import {
    getPaymentInfo,
    getPaymentInfoSuccess,
    getPaymentInfoError,
    getAutoPaymentInfo,
    getAutoPaymentInfoSuccess,
    getAutoPaymentInfoError
} from './payer.actions'
import { PayerInfoService } from '../services/payer.service'
import { PaymentInfo } from '../models/payer'

@Injectable()
export class PayerEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private payerInfoService: PayerInfoService,
    ) {
        this.logger = this.loggerFactory.getLogger('PayerEffects')
    }

    getPaymentSession = createEffect(() =>
        this.actions.pipe(
            ofType(getPaymentInfo),
            tap(() => {
                this.logger.debug(`Starting to get payment info`)
            }),
            mergeMap((data) =>
                this.payerInfoService.getPaymentSession(data.centerId,data.isAutoPay).pipe(
                    tap((paymentInfo: PaymentInfo) =>
                        this.logger.debug('Payment info loaded', paymentInfo)
                    ), 
                    map((paymentInfo: PaymentInfo) => getPaymentInfoSuccess({ payment: paymentInfo })),
                    catchError((error: Error) => { 
                        this.logger.error('Error listing payments', error.message, error)
                        return of(getPaymentInfoError({ error })) 
                    })
                )
            )
        )
    )

    getAutoPaymentSession = createEffect(() =>
        this.actions.pipe(
            ofType(getAutoPaymentInfo),
            tap(() => {
                this.logger.debug(`Starting to get auto payment Info`)
            }),
            mergeMap((data) =>
                this.payerInfoService.getPaymentSession(data.centerId,data.isAutoPay).pipe(
                    tap((paymentInfo: PaymentInfo) =>
                        this.logger.debug('Payment info loaded', paymentInfo)
                    ), 
                    map((paymentInfo: PaymentInfo) => getAutoPaymentInfoSuccess({ payment: paymentInfo })),
                    catchError((error: Error) => { 
                        this.logger.error('Error listing auto payments', error.message, error)
                        return of(getAutoPaymentInfoError({ error })) 
                    })
                )
            )
        )
    )
}
