import { ActionReducer, createReducer, on } from '@ngrx/store'
import moment from 'moment'
import { CalendarEntry, CalendarEntryMap } from '../models/calendar-entry'
import { DateUtil } from '../utils/date-util'
import { loadCalendarMinDate, loadCalendarSuccess, updateCalendarStatus } from './calendar.actions'

export const calendarFeatureKey = 'calendar-entries'

export interface CalendarState {
    calendarEntries: CalendarEntry[]
    minDate: Date
    calendarActive: boolean
}

const initialState: CalendarState = {
    calendarEntries: [],
    minDate: moment().toDate(),
    calendarActive: false
}

export const CalendarReducer: ActionReducer<CalendarState> = createReducer(
    initialState,
    on(loadCalendarSuccess, (state: CalendarState, { calendarEntries }) => {
        const absenceEntries = calendarEntries.filter((c) => c.entryType === 'absence')
        const absenceMap = absenceEntries.reduce(
            (acc, e) => ({
                ...acc,
                [e.dependentId]: acc[e.dependentId] ? acc[e.dependentId].concat([e]) : [e]
            }),
            {} as CalendarEntryMap
        )
        const allEntries = calendarEntries.filter((e) => e.entryType !== 'absence')

        Object.keys(absenceMap).forEach((depId) => {
            const entries = absenceMap[depId].sort(
                (a, b) => a.startDate.getTime() - b.startDate.getTime()
            )
            
            const dates = DateUtil.getAggregatedDates(entries)
            dates.forEach((d) => {
                const entry = entries.find((e) => e.id === d.id)
                if (entry) {
                    allEntries.push({
                        ...entry,
                        startDate: d.startDate,
                        endDate: d.endDate
                    } as CalendarEntry)
                }
            })
        })

        return {
            ...state,
            calendarEntries: allEntries
        }
    }),
    on(loadCalendarMinDate, (state: CalendarState, { minDate }) => {
        return {
            ...state,
            minDate
        }
    }),
    on(updateCalendarStatus, (state: CalendarState, { isCalendarActive }) => {
        return {
            ...state,
            calendarActive: isCalendarActive
        }
    })
)
