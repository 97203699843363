import { createAction, props } from '@ngrx/store'

export const bottomNavNavigationCompleted = createAction('[Nav] Bottom nav navigation completed')
export const updateScrollPosition = createAction(
    '[Nav] Update Scroll Position',
    props<{ position: number }>()
)

export const messagesClicked = createAction('[Nav] Messages Selected')

export const settingsClicked = createAction('[Nav] Settings Selected')

export const linkClicked = createAction('[Nav] Link Selected')

export const domainsClicked = createAction('[Nav] Domains Selected')

export const activityDateSelected = createAction(
    '[Nav] Activity Feed Date Selected',
    props<{ date: Date }>()
)

export const updateScrollDirection = createAction(
    '[Nav] Update Scroll direction',
    props<{ isScrolledUp: boolean }>()
)
