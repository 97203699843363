import { createAction, props } from '@ngrx/store'

import { BulkMediaEntry } from '../models/photo-manager'
import { LinkGenerationRequest } from '../interfaces/photo-manager'
import { ShareActions } from '../enums'

export const submitGenerateLinkRequest = createAction(
    '[Bulk Media] Creating Generate Link Request for dependent',
    props<{ guardianId: string; filter: LinkGenerationRequest }>()
)

export const setSelectedDownloadId = createAction(
    '[Bulk Media] Set Selected Download Id',
    props<{ selectedDownloadId: string }>()
)

export const submitGenerateLinkRequestError = createAction(
    '[Bulk Media] Creating Generate Link Request for dependent Error',
    props<{ error: Error }>()
)

export const linkGenerationProcess = createAction(
    '[Bulk Media] Link generation process',
    props<{ guardianId: string; mediaId: string }>()
)

export const linkGenerationProcessSuccess = createAction(
    '[Bulk Media] Link generation process success',
    props<{ linkGenerationResponse: BulkMediaEntry }>()
)

export const linkGenerationProcessError = createAction(
    '[Bulk Media] Link generation process error',
    props<{ error: Error }>()
)

export const recentDownloadItemsRequest = createAction(
    '[Bulk Media] getting recent download data',
    props<{ guardianId: string }>()
)

export const recentDownloadItemsRequestSuccess = createAction(
    '[Bulk Media] getting recent download data success',
    props<{ recentDownload: BulkMediaEntry[] }>()
)

export const recentDownloadItemsRequestError = createAction(
    '[Bulk Media] getting recent download data error',
    props<{ error: Error }>()
)

export const resetMediaId = createAction('[Bulk Media] reset media id if page change')

export const shareBulkLink = createAction(
    '[Bulk Media] Share bulk link',
    props<{ action: ShareActions, loader: string }>()
)
