export enum CalendarEntryType {
    SNACK_MENU = 'snack_menu',
    DUE_DATE = 'due_date',
    STAFF_ANNOUNCEMENT = 'staff_announcement',
    CLASSROOM_ACTIVITY = 'classroom_activity',
    CENTER_ANNOUNCEMENT = 'center_announcement',
    MARKETING_ANNOUNCEMENT = 'marketing_announcement',
    CENTER_CLOSURE = 'center_closure',
    PLEASE_BRING = 'please_bring',
    ABSENCE = 'absence'
}
