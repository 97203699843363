import { Balance } from './balance-info'
import { Reminder } from './reminder'

export class FamilyInfo {
    isPayer: boolean
    balances: Balance[]
    reminders: Reminder[]
    isShowMyBalance: boolean

    constructor(data: any) {
        this.isPayer = data.isPayer
        this.balances = (data.balances || []).map((b: any) => new Balance(b))
        this.reminders = (data.reminders || []).map((b: any) => new Reminder(b))
        this.isShowMyBalance = data.isShowMyBalance
    }
}

export type FamilyInfoResponse = {
    isPayer: boolean
    balances: Balance[]
    reminders: Reminder[]
    isShowMyBalance: boolean
}
