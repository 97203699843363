import { createFeatureSelector, createSelector } from '@ngrx/store'
import { loadingFeatureKey, LoadingState } from './loading.reducers'

const loadingSelector = createFeatureSelector<LoadingState>(loadingFeatureKey)

export const selectIsLoading = createSelector(loadingSelector, (state: LoadingState) => {
    let isLoading = false
    let k: keyof typeof state.loadingCountByModel
    for (k in state.loadingCountByModel) {
        if (state.loadingCountByModel[k] > 0) {
            isLoading = true
            break
        }
    }
    return isLoading
})
