import { createAction, props } from '@ngrx/store'
import { IStageHistoryMap } from './stage-history.reducers'

export const getDependentStageHistory = createAction(
    '[Stage History] get dependent stage history',
    props<{ guardiandId: string }>()
)

export const getDependentStageHistorySuccess = createAction(
    '[Stage History] get dependent stage history successfully',
    props<{ stageHistory: IStageHistoryMap[] }>()
)

export const getDependentStageHistoryError = createAction(
    '[Stage History] get dependent stage history error',
    props<{ error: Error }>()
)
