import { RemovePII } from '@bh/session'
export class Balance {
    centerId: number
    centerName: string
    currentBalance: number
    isPayer: boolean
    balanceMessage: string
    isShowWarning: boolean
    hasOverduePayment: boolean
    nextDraftDate: string
    nextDraftAmount: number
    isShowRequestRefund: boolean
    isShowManageAutoPayments: boolean
    isShowMakeSelfPayment: boolean
    isShowSetupAutoPayments: boolean
    formattedMsg: string
    @RemovePII()
    phoneNumber: string

    constructor(data: any) {
        this.centerId = data.centerId
        this.centerName = data.centerName
        this.currentBalance = data.currentBalance
        this.isPayer = data.isPayer
        this.balanceMessage = data.balanceMessage
        this.isShowWarning = data.isShowWarning
        this.hasOverduePayment = data.hasOverduePayment
        this.nextDraftDate = data.nextDraftDate
        this.nextDraftAmount = data.nextDraftAmount
        this.isShowRequestRefund = data.isShowRequestRefund
        this.isShowManageAutoPayments = data.isShowManageAutoPayments
        this.isShowMakeSelfPayment = data.isShowMakeSelfPayment
        this.isShowSetupAutoPayments = data.isShowSetupAutoPayments
        this.phoneNumber = data.phoneNumber
        this.formattedMsg = data.formattedMsg
    }
}
