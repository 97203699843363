import { Pipe, PipeTransform } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { generateDailyReportSignedUrl } from '../daily-report/daily-report.actions'
import { IDailyReportMap } from '../daily-report/daily-report.reducers'
import { selectDailyReportSignedUrlMap } from '../daily-report/daily-report.selectors'

@Pipe({
    name: 'dailyReport'
})
export class DailyReportPipe implements PipeTransform {
    constructor(private store: Store) {}

    transform(id: string): Observable<IDailyReportMap> {
        this.store.dispatch(generateDailyReportSignedUrl({ id }))
        return this.store.select(selectDailyReportSignedUrlMap).pipe(
            filter((dailyReportMap) => !!dailyReportMap[id]),
            map((dailyReportMap) => dailyReportMap[id] as unknown as IDailyReportMap)
        )
    }
}
