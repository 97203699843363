<ng-container *ngIf="activeDocument$ | async as memory; else photoDetails">
    <ion-content id="doc-content">
        <bh-document-detail-list
            [id]="memory.id"
            [dependentId]="memory.dependantId"
            [centerId]="memory.centerId"
            [type]="memory.type"
            [isShareAvailable]="memory.type === 'observation'"
            [sortDate]="memory.sortDate"
            [attachmentId]="memory.attachment"
            [description]="memory.description"
            [domain]="memory.domain"
            [attribute]="memory.attribute"
            [selections]="
                $any(memory).selections ? ($any(memory).selections | sortMultiAttachment) : []
            "
            [memoryId]="memory.memoryId || ''"
            [limitDate] = "limitDate"
            [mediaType]="(memory.attachment | photo | async)?.shortContentType"
            [dependantIdList]="dependantIdList"
        >
        </bh-document-detail-list>
    </ion-content>
</ng-container>

<ng-template #photoDetails>
    <ion-content *ngIf="activePhoto$ | async as memory" id="photo-content">
        <bh-document-detail-list
            [id]="memory.id"
            [dependentId]="memory.dependantId"
            [type]="memory.type || 'snapshot'"
            [isShareAvailable]="true"
            [sortDate]="memory.sortDate"
            [attachmentId]="memory.attachmentId"
            [description]="memory.note"
            [title]="memory.title"
            [witwhtDescription]="memory.description"
            [memoryId]="memory.memoryId"
            [limitDate] = "limitDate"
            [attachmentId]="memory.attachmentId"
            [mediaType]="(memory.attachmentId | photo | async)?.shortContentType"
            [selections]="
                $any(memory).selections ? ($any(memory).selections | sortMultiAttachment) : []
            "
            [dependantIdList]="dependantIdList"
        >
        </bh-document-detail-list>
    </ion-content>
</ng-template>