import moment from 'moment'
import { DocumentItem, PhotoItem } from '../memories/memories.actions'
import { ObservationSelectionEntry } from './activity-feed'
import { MemoryUtil, TextUtil } from '@events'
import { DocumentType } from '@bh/design-system'

export class MemoryMedia implements PhotoItem {
    id: string
    dependantId: string
    dependentName: string
    date: Date
    captureDate: Date
    updatedAt: Date
    capturedAt: Date
    createdAt: Date
    sortDate: Date
    attachmentId: string
    memoryId: string
    activityType: MemoryMediaTypes
    dayLabel: string
    monthLabel: string
    yearLabel: string
    visibility: string
    centerId: string
    title: string
    note: string
    selections?: ObservationSelectionEntry[] | undefined
    entryType: string
    description: string
    subjects?: string

    constructor(data: any) {
        this.id = data.id
        this.dependantId = data.dependent_id
        this.dependentName = data.dependent_name
        this.date = moment(data.for_date.split('T')[0]).toDate()
        this.capturedAt = data.capture_time && new Date(data.capture_time)
        this.createdAt = data.created && new Date(data.created)
        this.updatedAt = moment(data.updated).toDate()
        this.captureDate = this.capturedAt ?? this.updatedAt
        this.sortDate = this.date ?? this.captureDate
        this.attachmentId = data.attachment_id
        this.memoryId = MemoryUtil.getMemoryId(data.id, data.dependent_id)
        this.activityType = MemoryUtil.getMemoryType(data.entry_type) as MemoryMediaTypes
        this.dayLabel = MemoryUtil.getDailyLabel(this.sortDate)
        this.monthLabel = MemoryUtil.getMonthLabel(this.sortDate)
        this.yearLabel = MemoryUtil.getYearLabel(this.sortDate)
        this.visibility = data.visibility
        this.centerId = ''
        this.selections = (data.selections || []).map((s: any) => new ObservationSelectionEntry(s))
        this.entryType = data.entry_type
        this.subjects = MemoryUtil.getSubjects(data.subject_names)
        this.title = this.subjects ? this.subjects : ''
        this.description = this.subjects ? TextUtil.createLinks(data.description) : ''
        this.note = TextUtil.createLinks(data.note) ?? ''
    }
}

export class MemoryDocument implements DocumentItem {
    checkOutAt?: Date | null
    reportPublishedAt: Date | null
    date: Date | undefined
    dailyReportDate: Date | undefined
    isFromParent: boolean
    visibility: string
    isTeacherNote: boolean
    dependantId: string
    id: string
    memoryId: string
    entryTime: Date | undefined
    updatedAt: Date | undefined
    sortDate: Date
    type: DocumentType
    description: string
    dayLabel: string
    monthLabel: string
    yearLabel: string
    title: string
    attachment?: string | undefined
    selections: ObservationSelectionEntry[]
    centerId: string
    objectId: string
    staffId?: string
    addedByParent: boolean
    createdInMapp: boolean
    isEmptyDailyReport: boolean
    entryType: string
    subType: string

    constructor(data: any) {
        this.checkOutAt = data.checkout_time && new Date(data.checkout_time)
        this.reportPublishedAt = data.last_email_sent && new Date(data.last_email_sent)
        this.date = moment(data.for_date.split('T')[0]).toDate()
        this.isFromParent = !!data.is_from_parent
        this.dailyReportDate = MemoryUtil.getDailyReportDate(
            this.checkOutAt,
            this.reportPublishedAt,
            this.date
        )
        this.visibility = data.visibility
        this.type = MemoryUtil.getMemoryType(data.entry_type) as DocumentType
        this.isTeacherNote = MemoryUtil.reviewTeacherNote(
            this.type,
            this.isFromParent,
            this.visibility,
            this.dailyReportDate,
            data.question_name,
            data.type,
            data.please_brings
        )
        this.dependantId = data.dependent_id

        this.entryTime = data.entry_time && new Date(data.entry_time)
        this.updatedAt = moment(data.updated).toDate()
        this.sortDate =
            this.type === 'portfolio'
                ? new Date(data.updated || data.created)
                : this.entryTime || this.updatedAt
        this.id = data.id
        this.memoryId = MemoryUtil.getMemoryId(this.id, this.dependantId)
        this.description =
            this.type === 'portfolio'
                ? MemoryUtil.getPorfolioDescription(data.assessment_period, data.assessment_year)
                : TextUtil.createLinks(data.note)
        this.dayLabel = MemoryUtil.getDailyLabel(
            this.type === 'portfolio' ? new Date(data.updated ?? data.created) : this.sortDate
        )
        this.monthLabel = MemoryUtil.getMonthLabel(
            this.type === 'portfolio' ? new Date(data.updated ?? data.created) : this.sortDate
        )
        this.yearLabel = MemoryUtil.getYearLabel(
            this.type === 'portfolio' ? new Date(data.updated ?? data.created) : this.sortDate
        )
        this.attachment = data.attachment_id
        this.title = data.title
        this.selections = (data.selections || []).map((s: any) => new ObservationSelectionEntry(s))
        this.centerId = data.center_id
        this.objectId = data.id
        this.staffId = data.selected_staff_id
        this.addedByParent = !!data.added_by_child
        this.createdInMapp = data.created_in_mapp
        this.isEmptyDailyReport = data.entry_type === 'empty_daily_report'
        this.entryType = data.entry_type
        this.subType = data.type
    }
}

export type MemoryMediaTypes = 'observation' | 'snapshot' | 'activity'
