import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { AccordionComponent } from '@bh/design-system'
import { DBService } from '@bh/data'
import {
    AuthenticationService,
    loginError,
    loginRequest,
    loginSuccess,
    stopLoading,
    setSessionExpired,
    AnalyticsService
} from '@bh/security'
import { AlertController } from '@ionic/angular'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { from } from 'rxjs'
import { delay, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { DeepLinkingService } from '@deep-linking'
import {
    loadProfile,
    PendoService,
    selectProfile,
    Pendo,
    ParentAuthentication,
    BiometricService,
    ConsentBannerService
} from '@events'
import {
    KEY_GUARDIAN_INITIALS,
    appVersionUpdated,
    loginNavigationCompleted,
    loginNavigationFailed
} from './auth.actions'
import { AppInitService } from '../../../../app/src/app/app-init.service'
import { Preferences } from '@capacitor/preferences'
import { TranslateService } from '@ngx-translate/core'
import { logoutNavigationCompleted } from '../../../../settings/src/lib/settings/events/settings.actions'
import { HttpErrorResponse } from '@angular/common/http'

export enum LOGIN_ERROR_CODES {
    SA001,
    SA002,
    E001,
    E002,
    E003,
    E004,
    E005
}

@Injectable()
export class AuthEffects {
    private logger: LoggerService
    private pendo: Pendo
    public static readonly ACCORDION_FEED_KEY = `${AccordionComponent.ACCORDION_STORAGE}activityFeed_`
    public static readonly ACCORDION_FEED_KEY_REGEX = new RegExp(`${AccordionComponent.ACCORDION_STORAGE}activityFeed_`, 'g')

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private router: Router,
        private alertController: AlertController,
        private store: Store,
        private authenticationService: AuthenticationService,
        private pendoService: PendoService,
        private translateService: TranslateService,
        @Inject(DBService) private dbService: DBService,
        private biometricService: BiometricService,
        private consentBannerService: ConsentBannerService,
        private analyticsService: AnalyticsService
    ) {
        this.logger = this.loggerFactory.getLogger('AuthEffects')

        this.pendo = this.pendoService.getClient()
    }

    handleAuthentication = createEffect(() =>
        this.actions.pipe(
            ofType(loginSuccess),
            tap((auth) => {
                this.logger.debug('Logging user ', auth)
                Preferences.set({
                    key: AppInitService.STORAGE_API_KEY,
                    value: (auth.auth as unknown as ParentAuthentication).apiKey
                })
                Preferences.set({
                    key: AppInitService.LOGIN_STATUS,
                    value: 'true'
                })
            }),
            tap(({ auth }) => this.initPendo(auth as ParentAuthentication)),
            withLatestFrom(Preferences.get({
                key: AuthenticationService.VERSION_NUMBER
            })),
            tap(([{ auth }, version]) => {
                const storedVersion = version.value
                if (!storedVersion || storedVersion !== auth.appVersion) {
                    this.store.dispatch(appVersionUpdated({
                        version: auth.appVersion
                    }))
                }
            }),
            tap(() => {
                this.store.dispatch(loadProfile())
            }),
            switchMap(() => this.store.select(selectProfile).pipe(filter((profile) => !!profile))),
            tap((profile) => {
                this.analyticsService.setUserProperties({id: profile?.id, clientId: profile?.clientId})
            }),
            mergeMap((profile) => {
                if (profile!.firstName) {
                    localStorage.setItem(
                        KEY_GUARDIAN_INITIALS,
                        `${profile!.firstName.charAt(0)}${profile!.lastName.charAt(0)}`
                    )
                }
                const nextRoute = DeepLinkingService.getNextRoute()

                return from(
                    nextRoute
                        ? this.router.navigate(nextRoute.route, { ...nextRoute.params })
                        : this.router.navigate(['home', profile?.id, 'activity-feed'])
                ).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => loginNavigationCompleted())
                )
            })
        )
    )

    registerDevice = createEffect(
        () =>
            this.actions.pipe(
                ofType(loginNavigationCompleted),
                tap(() => this.logger.debug(`Navigated after login. Registering device for push.`)),
                tap(() => this.biometricService.saveNewCredentials()),
                switchMap(() => this.authenticationService.registerPushNotification())
            ),
        { dispatch: false }
    )

    handleVersionChange = createEffect(
        () =>
            this.actions.pipe(
                ofType(appVersionUpdated),
                tap(() => this.logger.debug('Reset accordions on app version change')),
                switchMap(({ version }) => Preferences.set({
                    key: AuthenticationService.VERSION_NUMBER,
                    value: version
                })),
                switchMap(() => from(Preferences.keys())),
                map(keysResult => keysResult.keys),
                map((keys) => keys
                    .filter(key => key.match(AuthEffects.ACCORDION_FEED_KEY_REGEX))
                ),
                filter(keys => keys.length > 0),
                mergeMap(keys => keys.map((key: string) => Preferences.remove({ key })))
            ),
        { dispatch: false }
    )

    handleLoginFailed = createEffect(() =>
        this.actions.pipe(
            ofType(loginError),
            tap((auth) => this.logger.error(`Error logging user ${JSON.stringify(auth)}`)),
            mergeMap((auth: any) => {
                const errorMsg = this.handleLoginFailedMessage(auth)
                return from(this.presentAlert(errorMsg)).pipe(
                    tap((alert) => {
                        alert.present()
                    }),
                    mergeMap(() => [
                        loginNavigationFailed(),
                        stopLoading({ action: loginRequest.type })
                    ])
                )
            })
        )
    )

    sessionExpired = createEffect(() =>
        this.actions.pipe(
            ofType(setSessionExpired),
            tap(() => this.logger.error(`Session has expired`)),
            switchMap(() => 
                from(
                    // removing user password from localStorage before routing to login
                    // page in case user update their pasword.
                    Preferences.remove({
                        key: AuthenticationService.STORAGE_PASS
                    })
                )
            ),
            map(() => from(this.router.navigate(['login'], { replaceUrl: true }))),
            delay(3000),
            tap(() => this.authenticationService.clearAuth()),
            switchMap(() => this.dbService.clearCache()),
            switchMap(() =>
                from(this.presentAlert('expiredSession')).pipe(
                    switchMap((alert) =>
                        from(Preferences.set({
                            key: AppInitService.LOGIN_STATUS,
                            value: 'false',
                        })).pipe(
                            tap(() => alert.present())
                        )
                    )
                )
            ),
            map(() => logoutNavigationCompleted())
        )
    )

    public async presentAlert(errorMsg: string | HttpErrorResponse): Promise<HTMLIonAlertElement> {
        return this.alertController.create({
            cssClass: '',
            header: await this.translateService.get('login.error.header').toPromise(),
            subHeader: '',
            message:
                typeof errorMsg === 'string'
                    ? await this.translateService.get(`login.error.${errorMsg}`).toPromise()
                    : errorMsg.error.message,
            buttons: ['OK']
        })
    }

    private handleLoginFailedMessage(authResponse: HttpErrorResponse): string | HttpErrorResponse {
        let errorMessage: string | HttpErrorResponse = 'invalidLogin'
        if (authResponse?.error?.errorCode && Object.values(LOGIN_ERROR_CODES).includes(authResponse.error.errorCode)) {
            errorMessage = authResponse?.error?.errorCode
        } else if (authResponse?.error?.message) {
            errorMessage = authResponse
        }

        return errorMessage
    }

    private initPendo(auth: ParentAuthentication) {
        const currentEnv = environment.buildEnv
        const nonProdAccountId = 'MBD_internal_user'
        const nonProdVistorKeyword = '_internal'

        /* Get Prformance Cookie Consent to determine if pendo API should run */
        this.consentBannerService
            .getPerformanceConsent()
            .pipe(take(1))
            .subscribe((performanceHasConsent) => {
                if (this.pendo && performanceHasConsent !== false) {
                    const hasPendoBeenInitalized = this.pendo.isReady?.() //Checks to see if pendo has been initialized
                    if (!hasPendoBeenInitalized) {
                        this.pendo.initialize({
                            visitor: {
                                id:
                                    currentEnv === 'prod'
                                        ? auth.visitorID
                                        : auth.visitorID + nonProdVistorKeyword
                            },
                            account: {
                                id: currentEnv === 'prod' ? auth.visitorID : nonProdAccountId
                            }
                        })
                        return
                    }

                    //If user has logged in and back out, pendo is still initalized. We need to call identify
                    //in order to assign a new id if the user were to login with a different account
                    this.pendo.identify({
                        visitor: {
                            id:
                                currentEnv === 'prod'
                                    ? auth.visitorID
                                    : auth.visitorID + nonProdVistorKeyword
                        },
                        account: {
                            id: currentEnv === 'prod' ? auth.visitorID : nonProdAccountId
                        }
                    })
                }
            })
    }
}
