import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'fileName'
})
export class FileNamePipe implements PipeTransform {
    transform(text: string, length = 30, suffix = '...'): string {
        if (text?.length > length) {
            const truncated: string = text.substring(0, length).trim() + suffix
            return truncated
        }

        return text
    }
}
