import moment from 'moment'

import { AttendanceActions } from '../enums'
import { Attendance } from '../models/attendance'
import { ParentAttendance } from '../models/parent-attendance'
import { CenterAttendance } from '../models/center-attedance'
import { AttendanceTransition } from '../models/attendance-transition'
import { ETAAttendance } from '../models/eta-attendance'

export class AttendanceUtil {
    private static readonly WINDOW_TIME = 2
    public static isAttendanceComplete(attendance: Attendance) {
        return (
            !!attendance.centerOutTime &&
            (!!attendance.parentOutTime || !this.isValidTimeWindow(attendance.centerOutTime))
        )
    }

    public static isValidTimeWindow(capturedTime: Date, baseTime?: Date) {
        return (
            moment(baseTime).utc().diff(moment(capturedTime).utc(), 'hours', true) <=
            this.WINDOW_TIME
        )
    }

    public static getDependentMatchedAttendances(
        parentAttendance: ParentAttendance,
        centerAttendance: CenterAttendance[],
        arrival: ETAAttendance
    ): Attendance[] {
        // Initialize an empty array to hold the final matched attendances
        let masterAttendance: Attendance[] = []

        // If there are center attendances available
        if (centerAttendance.length) {
            // Sort the center attendances by their inTime
            masterAttendance = centerAttendance
                .sort((a, b) => a.inTime.getTime() - b.inTime.getTime())
                // Convert each CenterAttendance to an Attendance object
                .map((cAttendance) => Attendance.fromCenterAttendance(cAttendance))

            // Iterate over each parent attendance transition
            masterAttendance = parentAttendance.transitions.reduce((acc, transition) => {
                const transitionDate = transition.capturedDate
                let hasMatch = false
                // Check if the transition matches any attendance in the accumulator
                acc.forEach((attendance) => {
                    switch (transition.action) {
                        // Handle the In action transition
                        case AttendanceActions.In: {
                            if (
                                (transitionDate < attendance.centerInTime! && !hasMatch) ||
                                (transitionDate > attendance.centerInTime! &&
                                    this.isValidTimeWindow(transitionDate, attendance.centerInTime))
                            ) {
                                // If the transition matches, set or update the parentInTime
                                attendance.parentInTime = transitionDate
                                hasMatch = true
                            }
                            break
                        }
                        // Handle the Out action transition
                        case AttendanceActions.Out: {
                            if (
                                (transitionDate < attendance.centerOutTime! && !hasMatch) ||
                                (transitionDate > attendance.centerOutTime! &&
                                    this.isValidTimeWindow(
                                        transitionDate,
                                        attendance.centerOutTime
                                    ))
                            ) {
                                // If the transition matches, set or update the parentOutTime
                                attendance.parentOutTime = transitionDate
                                hasMatch = true
                            }
                            break
                        }
                    }
                })

                // If no match was found, upsert the parent attendance into the accumulator
                // This will create a new attendance object in the collection
                if (!hasMatch) {
                    acc = this.upsertParentAttendance(acc, parentAttendance, transition)
                }

                // Return the updated accumulator
                return acc
            }, masterAttendance)

            // If no center attendances are available but parent attendances are available
        } else if (parentAttendance) {
            // Iterate over each parent attendance transition and upsert it into the accumulator
            masterAttendance = parentAttendance.transitions.reduce(
                (acc, transition) => this.upsertParentAttendance(acc, parentAttendance, transition),
                masterAttendance
            )
        }
        const arrivalExpired = arrival && moment(arrival.eta, ['h:mm A']).add(15, 'm').isBefore()
        if (arrival && !arrivalExpired) {
            masterAttendance = this.upsertArrival(masterAttendance, arrival)
        }

        // Return the final matched attendances
        return masterAttendance
    }

    private static upsertParentAttendance(
        attendanceCollection: Attendance[],
        parentAttendance: ParentAttendance,
        transition: AttendanceTransition
    ) {
        if (transition.action === AttendanceActions.In) {
            attendanceCollection.push(Attendance.fromParentAttendance(parentAttendance, transition))
        } else {
            attendanceCollection[attendanceCollection.length - 1].parentOutTime =
                transition.capturedDate
        }
        return attendanceCollection
    }

    private static upsertArrival(attendanceCollection: Attendance[], arrival: ETAAttendance) {
        const latestAttendance = { ...(attendanceCollection.slice(-1)[0] || {}) }
        const { parentInTime, centerInTime, parentOutTime, centerOutTime } = latestAttendance
        if (
            !Object.keys(latestAttendance).length ||
            (this.isAttendanceComplete(latestAttendance) && arrival.date > centerOutTime!)
        ) {
            attendanceCollection.push(Attendance.fromETAAttendance(arrival))
        } else if (
            (!parentInTime && !centerInTime) ||
            ((arrival.date > parentInTime! || arrival.date > centerInTime!) && !parentOutTime)
        ) {
            latestAttendance.arrivalTime = arrival.date
            latestAttendance.note = arrival.eta
            attendanceCollection.splice(-1, 1, latestAttendance)
        }

        return attendanceCollection
    }
}
