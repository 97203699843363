import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasEvents'
})
export class HasEventsPipe implements PipeTransform {

  transform(groupedActivity: any): boolean {
    return groupedActivity ? (
      groupedActivity.attendanceItems.length > 0 ||
      groupedActivity.teacherNotes.length > 0 ||
      groupedActivity.medications.length > 0 ||
      groupedActivity.medicationEntries.length > 0 ||
      groupedActivity.medicationNotes.length > 0 ||
      groupedActivity.naps.length > 0 ||
      groupedActivity.observations.length > 0 ||
      groupedActivity.learningAtHomes.length > 0 ||
      groupedActivity.activities.length > 0 ||
      groupedActivity.snapshots.length > 0 ||
      groupedActivity.bathrooms.length > 0 ||
      groupedActivity.meals.length > 0
    ) : false
  }
}
