import { DocumentType } from '@bh/design-system'
import { createAction, props } from '@ngrx/store'
import { ObservationSelectionEntry } from '../models/activity-feed'

export const documentRouted = createAction('[Memories] document page opened')

export const tabSelected = createAction(
    '[Memories] tab type selected',
    props<{ item: MemoriesTabType }>()
)

export const memoryDetailsClosed = createAction(
    '[Memories] Memory details closed',
    props<{ id: string }>()
)

export const memoriesFilterSelected = createAction(
    '[MemoriesFilter] Memory filter selected',
    props<{ activeFilter: MemoriesFilterType }>()
)

export const memoriesByMonthSelected = createAction(
    '[Memories] Memories month selected',
    props<{ month: string }>()
)

export const memoriesByYearSelected = createAction(
    '[Memories] Memories yearly selected',
    props<{ year: string }>()
)

export const getMemoryShareability = createAction(
    '[Memories] get memory shareability',
    props<{ guardianId: string; memoryId: string; memoryType: string; activityType?: string }>()
)

export const getMemoryShareabilitySuccess = createAction(
    '[Memories] get memory shareability successfully',
    props<{ isShareable: boolean }>()
)

export const getMemoryShareabilityError = createAction(
    '[Memories] get memory shareability with error',
    props<{ error: Error }>()
)

export const deletePhotosByPeriod = createAction(
    '[Memories] delete photos by period',
    props<{ datePeriod: string }>()
)

export const clearMemories = createAction('[MemoriesFilter] Memories cleared')

export const documentsCleared = createAction('[Memories] Documents cleared')

export type MemoriesFilterType = 'daily' | 'monthly' | 'yearly'

export type MemoriesTabType = 'media' | 'documents'

export type GroupedPhotoType = { [group: string]: PhotoUnitItem[] }
export type GroupedDocType = { [group: string]: DocUnitItem[] }
export type PhotoItemMap = { [key: string]: PhotoItem[] }
export type DocItemMap = { [key: string]: DocumentItem[] }

export interface UnitItem<T> {
    unit: string
    units: T[]
    datePeriod: string
}

export interface MemoryItem {
    id: string
    memoryId: string
    dayLabel: string
    monthLabel: string
    yearLabel: string
    dependantId: string
    sortDate: Date
    centerId: string
    captureDate?: Date
}

export type DocUnitItem = UnitItem<DocumentItem>

export interface DocumentItem extends MemoryItem {
    description: string
    title: string
    type: DocumentType
    attachment?: string
    selections?: ObservationSelectionEntry[]
    domain?: string
    attribute?: string
    assessment_period?: string
    assessment_year?: string
    guardianId?: string
    progression?: string | undefined
    visibility: string
}

export interface ExtendedDocumentItem {
    guardianId: string
    item: DocumentItem
}

export type PhotoUnitItem = UnitItem<PhotoItem>

export interface PhotoItem extends MemoryItem {
    activityType: string
    length?: string
    attachmentId: string
    type?: MediaType
    note?: string
    guardianId?: string
    title?: string
    description: string
    visibility: string
    createdAt: Date
    selections?: ObservationSelectionEntry[]
}

export interface ExtendedPhotoItem {
    selections?: ObservationSelectionEntry[]
    memoryId: string
    guardianId: string
    item: PhotoItem
}

export interface MemoriesRequest {
    dependentIdList: Array<string>
    startDate: string
    endDate: string
}

export type MediaType = 'image' | 'video' | 'audio'

export enum MediaShareType {
    'share',
    'download'
}
