import { Pipe, PipeTransform } from '@angular/core'
import { CenterOperationalHours } from '@events'

@Pipe({
    name: 'sortWeekDay'
})
export class SortWeekDay implements PipeTransform {
    transform(data: Array<CenterOperationalHours>): Array<CenterOperationalHours> {
        if (data.length > 0) {
            const map: any = {
                monday: 1,
                tuesday: 2,
                wednesday: 3,
                thursday: 4,
                friday: 5,
                saturday: 6,
                sunday: 0
            }
            const result = [...data].sort(
                (a: CenterOperationalHours, b: CenterOperationalHours) =>
                    map[a.name.toLowerCase()] - map[b.name.toLowerCase()]
            )
            return result
        } else {
            return []
        }
    }
}
