import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { Cacheable } from '@bh/data'
import { FamilyInfo } from '../models/family-info'

@Injectable({
    providedIn: 'root'
})
export class FamilyInfoService {
    constructor(private http: HttpClient) {}

    @Cacheable({ key: 'balanceInfo', ttl: 900000 })
    public getFamilyInfo(): Observable<FamilyInfo> {
        return this.http
            .get(`${environment.config().security.gatewayHost}/fic/mbd/family/myaccount`)
            .pipe(
                map((data: any) => {
                    return new FamilyInfo( data )
                })
            )
    }
}
