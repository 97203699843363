import { HttpStatusCode } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { notifyAPIError, notifyError } from '@bh/error-handling'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { ToastController, ToastOptions } from '@ionic/angular'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from } from 'rxjs'
import { delay, filter, switchMap, tap } from 'rxjs/operators'

import { environment } from '../../../../app/src/environments/environment'
import { APIError } from '../models/api-error'
import { dismissNotification, presentNotification, presentToast } from './toast.actions'
import { ToastLevel } from '../enums'

@Injectable()
export class ToastEffects {
    public static readonly TOAST_DURATION = 2000
    public static readonly LOGIN_ERROR_TOAST_ID = 'loginErrorToast'

    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private toastController: ToastController
    ) {
        this.logger = this.loggerFactory.getLogger('ToastEffects')
    }

    presentAPIErrorToast = createEffect(
        () =>
            this.actions.pipe(
                ofType(notifyAPIError),
                filter(
                    (error) =>
                        (environment.config().toastErrorStatus as HttpStatusCode[]).indexOf(
                            error.status
                        ) >= 0
                ),
                tap(() => this.logger.debug(`Presenting API error toast`)),
                switchMap(({ error }) => this.createToast(error, ToastLevel.Error)),
                tap((toast) => toast.present()),
                tap(() => this.logger.debug(`API error toast presented`)),
                delay(ToastEffects.TOAST_DURATION),
                tap((toast) => toast.dismiss())
            ),
        { dispatch: false }
    )

    presentGenericErrorToast = createEffect(
        () =>
            this.actions.pipe(
                ofType(notifyError),
                filter(
                    (error) =>
                        !!error.error.message &&
                        !!environment.config().toastErrorStatus &&
                        environment.config().toastErrorStatus.length > 0
                ),
                tap(() => this.logger.debug(`Presenting generic error toast`)),
                switchMap(({ error }) =>
                    this.createToast(error.message!, ToastLevel.Error)
                ),
                tap((toast) => toast.present()),
                tap(() => this.logger.debug(`Generic error toast presented`)),
                delay(ToastEffects.TOAST_DURATION),
                tap((toast) => toast.dismiss())
            ),
        { dispatch: false }
    )

    presentToast$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(presentToast),
                tap(() => this.logger.debug(`Presenting toast`)),
                switchMap(({ message, level }) =>
                    this.createToast(
                        message,
                        level,
                        ToastEffects.LOGIN_ERROR_TOAST_ID
                    )
                ),
                tap((toast) => toast.present()),
                tap(() => this.logger.debug(`Toast presented`)),
                delay(ToastEffects.TOAST_DURATION),
                tap((toast) => toast.dismiss())
            ),
        { dispatch: false }
    )

    presentNotificationMessage = createEffect(
        () =>
            this.actions.pipe(
                ofType(presentNotification),
                tap(async (n) => {
                    this.logger.debug(`Displaying debug notification: ${n.message}`)
                    const notification = await this.toastController.create({
                        cssClass: n.class,
                        message: n.message as string,
                        position: n.position as ToastOptions['position'],
                        duration: n.duration || ToastEffects.TOAST_DURATION,
                        color: n.color || 'light',
                        buttons: [{ icon: n.icon || 'information-circle', side: 'start' }]
                    })
                    notification
                        .present()
                        .then(() => {
                            if (n.canSwipe) {
                                const toastElement = document.querySelector(
                                    'ion-toast'
                                ) as HTMLElement
                                let initialY: number = 0
                                toastElement.addEventListener('touchstart', (event: TouchEvent) => {
                                    initialY = event.touches[0].clientY
                                })
                                toastElement.addEventListener('touchend', (event: TouchEvent) => {
                                    const currentY = event.changedTouches[0].clientY
                                    if (initialY - currentY > 10) {
                                        notification
                                            .dismiss()
                                            .catch((error) =>
                                                this.logger.debug('Error closing toast:', error)
                                            )
                                    }
                                })
                            }
                        })
                        .catch((error) => {
                            this.logger.debug('Error creating toast:', error)
                        })
                })
            ),
        { dispatch: false }
    )

    closeNotification = createEffect(
        () =>
            this.actions.pipe(
                ofType(dismissNotification),
                tap(async () => {
                    this.logger.debug(`Dismiss notification`)
                    this.toastController.dismiss()
                })
            ),
        { dispatch: false }
    )

    private createToast(message: string | APIError, level: ToastLevel, id?: string) {
        let icon = ''
        let color = ''
        switch(level) {
            case ToastLevel.Info: {
                icon = 'checkmark-circle' // Review correct icon
                color = 'light'
                break
            }
            case ToastLevel.Success: {
                icon = 'checkmark-circle'
                color = 'success'
                break
            }
            case ToastLevel.Error: {
                icon = 'close-circle'
                color = 'danger'
                break
            }
        } 
        return from(
            this.toastController.create({
                id,
                message: typeof message === 'string' ? message : message.detail,
                color,
                duration: ToastEffects.TOAST_DURATION,
                buttons: [
                    {
                        icon,
                        side: 'start'
                    },
                    {
                        icon: 'close-outline',
                        role: 'cancel',
                        side: 'end',
                        cssClass: 'toast-cancel'
                    }
                ]
            })
        )
    }
}
