import { ActionReducer, createReducer, on } from '@ngrx/store'
import {
    addFoodItem,
    addTodayItem,
    deleteFoodItem,
    deleteTodayItem,
    resetFoodForm,
    updateFoodVal,
    updateTodayVal
} from './child-notes.actions'

export const childNotesFeatureKey = 'child-notes'

export interface FoodItem {
    value: string
    frequency: string
}

export interface TodayItem {
    value: string
}

export interface ChildNotesState {
    food: { items: FoodItem[] }
    today: { items: TodayItem[] }
}

const initialState: ChildNotesState = {
    food: {
        items: [
            {
                value: '',
                frequency: ''
            }
        ]
    },
    today: {
        items: [
            {
                value: ''
            }
        ]
    }
}

export const ChildNotesReducer: ActionReducer<ChildNotesState> = createReducer(
    initialState,
    on(addFoodItem, (state: ChildNotesState) => {
        return {
            ...state,
            food: {
                ...state.food,
                items: [...state.food.items, { value: '', frequency: 'all' }]
            }
        }
    }),
    on(addTodayItem, (state: ChildNotesState) => {
        return {
            ...state,
            today: {
                ...state.today,
                items: [...state.today.items, { value: '' }]
            }
        }
    }),
    on(deleteFoodItem, (state: ChildNotesState, { index }) => {
        const { items } = state.food
        const newItems = items.filter((item, i) => i !== index)

        return {
            ...state,
            food: {
                ...state.food,
                items: newItems
            }
        }
    }),
    on(deleteTodayItem, (state: ChildNotesState, { index }) => {
        const { items } = state.today
        const newItems = items.filter((item, i) => i !== index)

        return {
            ...state,
            today: {
                ...state.today,
                items: newItems
            }
        }
    }),
    on(resetFoodForm, () => {
        return initialState
    }),
    on(updateFoodVal, (state: ChildNotesState, { index, value }) => {
        const { items } = state.food
        const newItems = items.map((item, i) => {
            if (i === index) {
                return {
                    value,
                    frequency: item.frequency
                }
            }
            return item
        })

        return {
            ...state,
            food: {
                ...state.food,
                items: newItems
            }
        }
    }),
    on(updateTodayVal, (state: ChildNotesState, { index, value }) => {
        const { items } = state.today
        const newItems = items.map((item, i) => (i === index ? { value } : item))

        return {
            ...state,
            today: {
                ...state.today,
                items: newItems
            }
        }
    })
)
