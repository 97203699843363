import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Preferences } from '@capacitor/preferences'
import { from, Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { AppInitService } from '../../../../app/src/app/app-init.service'
import { environment } from '../../../../app/src/environments/environment'
import { Cacheable } from '@bh/data'
import { Profile } from '../models/profile'
import { AccountMetaData } from '../models/account-meta-data'

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    constructor(private http: HttpClient) {}

    @Cacheable({ key: 'profile', ttl: 8.64e7 })
    public loadProfile(): Observable<Profile> {
        return this.http.get(`${environment.config().security.backendHost}/user/profile`).pipe(
            map((data) => new Profile(data)),
            switchMap((profile: Profile) =>
                from(
                    Preferences.set({ key: AppInitService.STORAGE_PROFILE_ID, value: profile.id })
                ).pipe(map(() => profile))
            )
        )
    }
    public getChildAccountMetaData(): Observable<AccountMetaData> {
        return this.http
            .get(`${environment.config().security.backendHost}/guardian/metadata`)
            .pipe(map((data: any) => new AccountMetaData(data)))
    }
}
