import moment from 'moment'
import { MediaType } from '../memories/memories.actions'
import { UserReactionData } from '@bh/design-system'

export class Media {
    url: string
    fileName: string
    contentType: string
    shortContentType: MediaType
    thumbnailShortContentType: MediaType
    reactions?: UserReactionData[]

    constructor(data: any) {
        this.url = data.signed_url
        this.contentType = data.mime_type_hint || data.mime_type || 'image/jpeg'
        this.fileName = data.filename
        this.shortContentType = this.getShortContentType(this.contentType)
        this.thumbnailShortContentType = this.getShortContentType(data.mime_type)
        this.reactions = data.reactions?.map((r: { guardian_name: string, reaction: string }) => ({
            guardianName: r.guardian_name,
            reaction: r.reaction
        })) ?? []
    }

    private getShortContentType(type: string): MediaType {
        return type ? (type.split('/')[0] as MediaType) : 'image'
    }
}

export type MediaMap = { [id: string]: Media }

export interface MediaAltText{
    image: string,
    audio: string,
    video: string
}
export const ExtensionsObj: { [index: string]: string } = {
    ['application/octet-stream']: '.bin',
    ['application/pdf']: '.pdf',
    ['application/zip']: '.zip',
    ['application/vnd.ms-publisher']: '.pub',
    ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']: '.docx', 
    ['audio/mp4']: '.m4a',
    ['audio/mpeg']: '.mp3',
    ['audio/wav']: '.wav',
    ['audio/x-m4a']: '.m4a',
    ['image/avif']: '.avif',
    ['image/bmp']: '.bmp',
    ['image/heic']: '.heic',
    ['image/jpeg']: '.jpeg',
    ['image/png']: '.png',
    ['text/html']: '.html',
    ['video/mp4']: '.mp4',
    ['video/quicktime']: '.mov',
    ['video/webm']: '.webm'
}
export const MEDIA_TTL = 8.64e7

export class Attachment extends Media {
    attachmentId: string
    ttl: number
    createdAt: number
    isThumbnail: number

    constructor(data: any, isThumbnail: number, ttl?: number) {
        super(data)
        this.attachmentId = data.attachmentId
        this.ttl = ttl ? ttl + new Date().getTime() : -1
        this.createdAt = moment().valueOf()
        this.isThumbnail = isThumbnail
    }
}





