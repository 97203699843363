<ion-router-outlet tabs="true"></ion-router-outlet>
<div class="footerWrapper">
    <bh-nav-bottom></bh-nav-bottom>
</div>

<ng-container *ngIf="(hasQuickScroll$ | async)">
    <div class="quick-action-button" #fab>
        <bh-quick-actions
            [title]="'quick-actions.quick-actions-title' | translate"
        ></bh-quick-actions>
    </div>
</ng-container>

<div class="preload-img">
    <ion-icon icon="md-home"></ion-icon>
    <ion-icon icon="md-memories"></ion-icon>
    <ion-icon icon="md-my-learners"></ion-icon>
    <ion-icon icon="md-calendar"></ion-icon>
    <ion-icon icon="md-home-active"></ion-icon>
    <ion-icon icon="md-memories-active"></ion-icon>
    <ion-icon icon="md-my-learners-active"></ion-icon>
    <ion-icon icon="md-calendar-active"></ion-icon>
</div>
