import { Component, Input } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import {
    displayBiometricsModal,
    selectBiometrics,
    selectBiometricsAvailable,
    selectBiometricsModal,
    updateBiometrics
} from '@bh/security'
import { BiometricService, Biometrics, BiometricsType } from '@events'
import { Store } from '@ngrx/store'
import { NativeBiometric } from 'capacitor-native-biometric'
import { combineLatest, from, Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { LoggerFactory, LoggerService } from '@bh/logging'

@Component({
    selector: 'bh-biometrics-modal',
    templateUrl: './biometrics-modal.component.html',
    styleUrls: ['./biometrics-modal.component.scss']
})
export class BiometricsModalComponent {
    @Input() requireUsername = true
    @Input() toggleFromSettings = false

    public bioForm: FormGroup
    public biometryImagePath: string = `assets/fingerprint.svg`
    private logger: LoggerService

    public biometricsAvailable$: Observable<boolean>
    public biometricInfo$: Observable<Biometrics>
    public showModal$: Observable<boolean>

    constructor(
        private store: Store,
        private biometricService: BiometricService,
        private loggerFactory: LoggerFactory
    ) {
        this.logger = this.loggerFactory.getLogger('BiometricsModalComponent')
        this.showModal$ = this.store.select(selectBiometricsModal)
        this.biometricsAvailable$ = this.store.select(selectBiometricsAvailable)
        this.biometricInfo$ = this.store.select(selectBiometrics)
        this.bioForm = new FormGroup({
            method: new FormControl('')
        })
    }

    public enableBiometrics() {
        combineLatest([
            from(NativeBiometric.verifyIdentity({ useFallback: true, maxAttempts: 3 })),
            this.biometricInfo$
        ])
            .pipe(take(1))
            .subscribe((dataArr) => {
                const biometricInfo = dataArr[1]
                this.closeModal()
                this.biometricService
                    .presentToast(this.requireUsername, true, biometricInfo.biometryType)
                    .catch((error) => {
                        this.logger.debug('Error presenting toast: ', error)
                    })
                this.store.dispatch(
                    updateBiometrics({
                        isAvailable: true,
                        isEnabled: true,
                        notifyUser: this.requireUsername,
                        username: biometricInfo.username,
                        biometryId: biometricInfo.biometryId,
                        biometryType: biometricInfo.biometryType as BiometricsType,
                        biometryImagePath: biometricInfo.biometryImagePath
                    })
                )
                if (this.toggleFromSettings) {
                    this.biometricService.storeBiometricsStatus().catch((error) => {
                        this.logger.debug('Error storing Biometric status: ', error)
                    })
                }
            })
    }

    public closeModal(): void {
        this.store.dispatch(displayBiometricsModal({ showModal: false }))
    }
}
