import { ActionReducer, createReducer, on } from '@ngrx/store'
import {
    addDomainFilter,
    addDomainListFilter,
    addStageFilter,
    addStageListFilter,
    clearRemainingFilter,
    resetDomainFilter,
    resetStageFilter
} from './developmental-observation.actions'

export const developmentalObservationFeatureKey = 'devObservation'

export interface DevelopmentalObservationState {
    domainFilterSelected: string[]
    stageFilterSelected: string[]
    stagesRemainingToFilter: string[]
}

const initialState: DevelopmentalObservationState = {
    domainFilterSelected: [],
    stageFilterSelected: [],
    stagesRemainingToFilter: []
}

export const DevelopmentalObservationReducer: ActionReducer<DevelopmentalObservationState> = createReducer(
    initialState,
    on(resetDomainFilter, (state: DevelopmentalObservationState) => {
        return {
            ...state,
            domainFilterSelected: []
        }
    }),
    on(addDomainFilter, (state: DevelopmentalObservationState, {domainId}) => {
        const exists_ = state.domainFilterSelected.filter((c) => c === domainId)
        const exists = exists_.length > 0

        return {
            ...state,
            domainFilterSelected: exists
                ? state.domainFilterSelected.filter((c) => c !== domainId)
                : [...state.domainFilterSelected, domainId]
        }
    }),
    on(addDomainListFilter, (state: DevelopmentalObservationState, {domain}) => {
        return {
            ...state,
            domainFilterSelected: domain
        }
    }),
    on(resetStageFilter, (state: DevelopmentalObservationState) => {
        return {
            ...state,
            stageFilterSelected: [],
            stagesRemainingToFilter: []
        }
    }),
    on(addStageFilter, (state: DevelopmentalObservationState, { stageId }) => {
        const STAGES_WITH_TWOS = [
            'infant',
            'toddler',
            'twos',
            'preschool',
            'school',
            'next steps'
        ]
        const exists = state.stageFilterSelected.filter((c) => c === stageId).length > 0

        //Sort the filteredStages from newest to oldest
        const filteredStages = exists
            ? state.stageFilterSelected.filter((c) => c !== stageId)
            : [...state.stageFilterSelected, stageId].sort(
                    (a, b) => STAGES_WITH_TWOS.indexOf(b) - STAGES_WITH_TWOS.indexOf(a)
                )

        return {
            ...state,
            stageFilterSelected: filteredStages,
            stagesRemainingToFilter: filteredStages //Value will hold stages that need to be filtered through on observation list
        }
    }),
    on(clearRemainingFilter, (state: DevelopmentalObservationState) => {
        //Clear the first stage in filteredList, all observations have been loaded for this stage
        const stagesRemainingToFilter = [...state.stagesRemainingToFilter.slice(1)]
        return {
            ...state,
            stagesRemainingToFilter
        }
    }),
    on(addStageListFilter, (state: DevelopmentalObservationState, {stage}) => {
        return {
            ...state,
            stageFilterSelected: stage
        }
    }),
)