import { createAction, props } from "@ngrx/store";

export const resetDomainFilter = createAction(
    '[Developmental Observation] Reset domains'
)

export const resetStageFilter = createAction(
    '[Developmental Observation] Reset stages'
)

export const addDomainFilter = createAction(
    '[Developmental Observation] Add domain id',
    props<{ domainId: string }>()
)

export const addDomainListFilter = createAction(
    '[Developmental Observation] Add domain list ids',
    props<{ domain: string[] }>()
)

export const addStageFilter = createAction(
    '[Developmental Observation] Add stage id',
    props<{ stageId: string }>()
)

export const addStageListFilter = createAction(
    '[Developmental Observation] Add stage list ids',
    props<{ stage: string[] }>()
)

export const clearRemainingFilter = createAction(
    '[Developmental Observation] Clear stage from filtering'
)
