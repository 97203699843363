import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { confirmationDoneButtonTapped } from '@bh/design-system'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { from } from 'rxjs'
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { selectProfile } from '../profile/profile.selectors'
import { confirmationDoneCompleted } from './confirmation.actions'

@Injectable()
export class ConfirmationEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private router: Router,
        private store: Store
    ) {
        this.logger = this.loggerFactory.getLogger('ConfirmationEffects')
    }

    onConfirmationDoneTapped = createEffect(() =>
        this.actions.pipe(
            ofType(confirmationDoneButtonTapped),
            tap(() => this.logger.debug(`Navigating to home`)),
            switchMap(() => this.store.select(selectProfile).pipe(filter((profile) => !!profile))),
            mergeMap((profile) =>
                from(this.router.navigate(['home', profile!.id, 'activity-feed'])).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => confirmationDoneCompleted())
                )
            )
        )
    )
}
