import { createAction, props } from '@ngrx/store'
import { CalendarEntry } from '../models/calendar-entry'

export const loadCalendar = createAction(
    '[Calendar] Load Calendar',
    props<{ guardianId: string; startDate: Date; futureEnrollment: boolean }>()
)

export const loadCalendarSuccess = createAction(
    '[Calendar] Load Calendar Success',
    props<{ calendarEntries: CalendarEntry[] }>()
)

export const loadCalendarError = createAction(
    '[Calendar] Load Calendar Error',
    props<{ error: Error }>()
)

export const loadCalendarMinDate = createAction(
    '[Calendar] Load Calendar Min Date Success',
    props<{ minDate: Date }>()
)

export const updateCalendarStatus = createAction(
    '[Calendar] Update Calendar is active status',
    props<{ isCalendarActive: boolean }>()
)
