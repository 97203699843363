import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppIcon } from '@capacitor-community/app-icon'
import { StatusBar, Style } from '@capacitor/status-bar'
import { fromEvent } from 'rxjs'
import { take } from 'rxjs/operators'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.prodBuild) {
    enableProdMode()
}

const changeIcon = async (iconName: string) => {
    await AppIcon.change({ name: iconName, suppressNotification: true })
}

const oldIcon = async () => {
    await AppIcon.getName()
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err))

fromEvent(document, 'deviceready')
    .pipe(take(1))
    .subscribe(() => {
        const isAndroid = /Android/.test(window.navigator.userAgent)

        const iOS = /AppleWebKit/.test(window.navigator.userAgent)

        if (isAndroid) {
            fixIonAppViewPort()
        }

        if (iOS && oldIcon.toString() !== 'icon') {
            changeIcon('icon')
        }

        StatusBar.setStyle({ style: Style.Light })
    })

function fixIonAppViewPort() {
    const hack = () => {
        const ionApp = document.querySelector('ion-app')
        if (ionApp) {
            // https://github.com/ionic-team/ionic/issues/19065#issuecomment-521370741
            window.requestAnimationFrame(() => {
                ionApp.style.height = '100%'
                window.requestAnimationFrame(() => {
                    ionApp.style.height = ''
                })
            })
        }
    }
    if ('ResizeObserver' in window) {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const ResizeObserver = (window as any).ResizeObserver
        new ResizeObserver(hack).observe(document.documentElement)
    } else {
        (window as any).addEventListener('keyboardWillShow', hack)
        (window as any).addEventListener('keyboardWillHide', hack)
    }
}
