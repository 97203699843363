import { createFeatureSelector, createSelector } from '@ngrx/store'
import moment from 'moment'

import { Absence, Attendance, AttendanceMap } from '../models/attendance'
import { CenterAttendance } from '../models/center-attedance'
import { DateUtil } from '../utils/date-util'
import { attendanceFeatureKey, AttendanceState } from './attendance.reducers'

const attendanceSelector = createFeatureSelector<AttendanceState>(attendanceFeatureKey)

const getAttendanceMap: (attendanceItems: CenterAttendance[]) => {
    [key: string]: CenterAttendance[]
} = (attendanceItems) => {
    const attendanceMap: { [key: string]: CenterAttendance[] } = {}
    attendanceItems.forEach((a) => {
        if (!Object.keys(attendanceMap).includes(a.dependentId)) {
            attendanceMap[a.dependentId] = []
        }

        attendanceMap[a.dependentId].push(a)
    })
    return attendanceMap
}

const getAbsenceList: (attendanceItems: CenterAttendance[]) => Absence[] = (attendanceItems) => {
    attendanceItems = attendanceItems
        .filter((a) => a.status === 'other' || a.status === 'sick' || a.status === 'vacation')
        .sort((a, b) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime()
        })

    const absences: Absence[] = []
    attendanceItems.forEach((attendance, i) => {
        if (i === 0) {
            absences.push(
                new Absence(
                    attendance,
                    moment(attendance.date).format('yyyy-MM-DD'),
                    moment(attendance.date).add(1, 'day').format('yyyy-MM-DD')
                )
            )
        } else {
            const previous = absences[absences.length - 1]
            const previousDate = moment(previous.endDate)
            const nextDate = moment(attendance.date)

            if (
                (previousDate.isSame(nextDate) || DateUtil.isWeekend(nextDate.toDate())) &&
                previous.status === attendance.status &&
                previous.note === attendance.note
            ) {
                absences[absences.length - 1] = {
                    ...absences[absences.length - 1],
                    endDate: nextDate.add(1, 'day').format('yyyy-MM-DD')
                }
            } else {
                absences.push(
                    new Absence(
                        attendance,
                        moment(attendance.date).format('yyyy-MM-DD'),
                        moment(attendance.date).add(1, 'day').format('yyyy-MM-DD')
                    )
                )
            }
        }
    })
    return absences
}

const getAllAbsences: (attendanceList: CenterAttendance[]) => Absence[] = (attendanceList) => {
    attendanceList = attendanceList.sort((a, b) => {
        return moment(a.date).diff(moment(b.date))
    })

    const dep = attendanceList.every((x) => x.dependentName === attendanceList[0].dependentName)
    if (dep) {
        attendanceList = attendanceList.map((attendance, i, list) => ({
            ...attendance,
            dependentId:
                i > 0 &&
                i < attendanceList.length &&
                attendance.dependentName === list[i - 1].dependentName &&
                (moment(attendance.date)
                    .subtract(1, 'd')
                    .isSame(list[i - 1].date) ||
                    moment(list[i - 1].date).day() >= 5)
                    ? attendanceList[0].dependentId
                    : attendance.dependentId
        }))
    }

    const attendanceMap = getAttendanceMap(attendanceList)
    let absences: Absence[] = []
    Object.keys(attendanceMap).forEach((id) => {
        const absenceList = getAbsenceList(attendanceMap[id])
        absences = absences.concat(absenceList)
    })

    absences.sort((a, b) => {
        return (
            moment(a.startDate).diff(moment(b.startDate)) ||
            a.dependentName.localeCompare(b.dependentName)
        )
    })

    return absences
}

export const selectAbsenceList = createSelector(attendanceSelector, (state: AttendanceState) => {
    return getAllAbsences(state.attendanceList.slice())
})

export const selectAbsencesInRange = (startDate: Date, endDate: Date): any =>
    createSelector(attendanceSelector, (state: AttendanceState) => {
        const allAbsences = getAllAbsences(state.attendanceList.slice())
        return allAbsences.filter((a) => {
            const adjustedStart = DateUtil.localToUtc(moment(a.startDate).toDate())
            const adjustedEnd = DateUtil.localToUtc(moment(a.endDate).toDate())
            return (
                moment(adjustedStart).isBetween(startDate, endDate) ||
                moment(adjustedEnd).isBetween(startDate, endDate)
            )
        })
    })

export const selectAttendanceMap = createSelector(
    attendanceSelector,
    (state: AttendanceState): AttendanceMap => state.attendanceMap
)

export const selectAttendanceList = createSelector(
    attendanceSelector,
    (state: AttendanceState): Attendance[] => Object.values(state.attendanceMap)
)

export const selectAttendanceByDependent = createSelector(
    attendanceSelector,
    (state: AttendanceState): AttendanceMap => state.attendanceMap
)

export const selectEtaSubmitted = createSelector(
    attendanceSelector,
    (state: AttendanceState) => state.etaSubmitted
)

export const selectSubmittedCenterIds = createSelector(
    attendanceSelector,
    (state: AttendanceState) => state.submittedCenterIds
)
