import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable()
export class LBRouterInterceptor implements HttpInterceptor {
    public static readonly LB_HEADER = 'MBD-SERVER-DEPENDENCY'
    public static readonly LB_ROUTING_KEY = '2'

    private static readonly DENY_LIST = ['/mybrightday/login']

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const handledReq = !LBRouterInterceptor.DENY_LIST.find((s) => request.url.indexOf(s) >= 0)
            ? request.clone({
                  headers: this.appendHeader(request.headers)
              })
            : request

        return next.handle(handledReq)
    }

    private appendHeader(existingHeaders: HttpHeaders): HttpHeaders {
        return new HttpHeaders({
            ...existingHeaders
                .keys()
                .filter((h) => !!existingHeaders.get(h))
                .reduce(
                    (acc, h) => {
                        return { ...acc, [h]: existingHeaders.get(h) as string }
                    },
                    {} as {
                        [name: string]: string | string[]
                    }
                ),
            [LBRouterInterceptor.LB_HEADER]: LBRouterInterceptor.LB_ROUTING_KEY
        })
    }
}
