import { Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { getDependentStageHistory, getDependentStageHistoryError, getDependentStageHistorySuccess } from './stage-history.actions'
import { StageHistoryService } from '../services/stage-history.service'

@Injectable()
export class StageHistoryEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private stageHistoryService: StageHistoryService
    ) {
        this.logger = this.loggerFactory.getLogger('StageHistoryEffects')
    }

    handleDependentStageHistory = createEffect(() =>
        this.actions.pipe(
            ofType(getDependentStageHistory),
            tap(({ guardiandId }) => this.logger.debug(`Get dependent stage history`, guardiandId)),
            switchMap(({ guardiandId }) => {
                return this.stageHistoryService.getDependentStageHistory(guardiandId).pipe(
                    map((data) => {
                        return getDependentStageHistorySuccess({
                            stageHistory: data
                        })
                    }),
                    catchError((error: Error) => {
                        this.logger.error(
                            'Error getting dependent stage history',
                            error.message,
                            error
                        )
                        return of(getDependentStageHistoryError({ error }))
                    })
                )
            })
        )
    )
}
