import { createAction, props } from '@ngrx/store'
import { Media } from '../models/media'

export const loadMediaURLSuccess = createAction(
    '[Media] Loaded media successfully',
    props<{ id: string; media: Media }>()
)
export const loadMultipleMedia = createAction(
    '[Media] Start loading multiple media',
    props<{ attachmentIds: Array<string>; thumbnail: boolean, date: string }>()
)

export const loadMultipleMediaSuccess = createAction(
    '[Media] Load multiple media Success',
    props<{ attachmentIds: Array<string>; thumbnail: boolean }>()
)

export const loadMultipleMediaError = createAction(
    '[Media] Load multiple media Error',
    props<{ error: Error }>()
)
